<header mat-dialog-title>
  <div class="title">
    <h4 class="label">
      {{editMode ? ('learningpaths_module.edit_learning_module'| translate) :
      ('learningpaths_module.create_learning_module' | translate )}}
    </h4>
    <app-button mat-dialog-close shape="icon">
      <mat-icon>close</mat-icon>
    </app-button>
  </div>
  <mat-tab-group *ngIf="editMode" (selectedTabChange)="toggleShow($event.index)">
    <mat-tab label="General"></mat-tab>
    <mat-tab label="Description"> </mat-tab>
  </mat-tab-group>
</header>

<mat-dialog-content>
  <div *ngIf="isFormInitializing; else formContainer">
    <mat-spinner color="primary" [diameter]="40"></mat-spinner>
  </div>

  <ng-template #formContainer>
    <app-learning-module-form #formComponent [isFormLoading]="isFormLoading" [mode]="mode" [editMode]="editMode"
      [currentStep]="currentStep" (isWorkingNameFilledChange)="onWorkingNameFilledChange($event)"
      (courseIdChange)="onCourseIdChange($event)"
      (estimatedTimeInSecondsChange)="onEstimatedTimeInSecondsChange($event)" [learningModuleData]="learningModule"
      [lockedByModuleList]="lockedByModuleList" (onSubmit)="formSumbitHandler($event)"
      (currentStepChange)="currentStep = $event" (invalidStatusChanged)="handleInvalidStatusChanged($event)"
      (onDelete)="openDeleteDialog()">
    </app-learning-module-form>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
  <ng-container *ngIf="currentStep === 0 && !editMode">
    <button fxFlex="25%" mat-button (click)="close()" class="mat-secondary">
      {{'CANCEL' | translate}}
    </button>
    <button fxFlex="25%" mat-flat-button color="primary"
      [disabled]="!isWorkingNameFilled || !courseIdSelected.length || !estimatedTimeInSecondsFilled"
      (click)="nextButtonClick()">
      {{'common.next' | translate}}
    </button>
  </ng-container>
  <ng-container *ngIf="currentStep === 1 && !editMode">
    <button fxFlex="25%" mat-button (click)="close()" class="mat-secondary">
      {{'CANCEL' | translate}}
    </button>
    <button fxFlex="25%" mat-flat-button color="primary" (click)="previousButtonClick()">
      Previous
    </button>
    <app-button shape="raised" color="primary" [isLoading]="isFormLoading"
      [disabled]="isFormDisabled || translationsFormInvalid" (onButtonClick)="submitForm()">
      {{ "learningpaths_module.create_learning_module" | translate}}
    </app-button>
  </ng-container>
  <app-button *ngIf="editMode" shape="raised" color="primary" [isLoading]="isFormLoading" [disabled]="isFormDisabled"
    (onButtonClick)="submitForm()">
    {{ "learningpaths_module.save_changes" | translate}}
  </app-button>

  <app-button *ngIf="!isFormLoading && editMode" color="secondary" class="edit-form-button" shape="raised"
    (onButtonClick)="switchFormState()">
    {{isFormDisabled ? 'edit' : 'discard'}}
  </app-button>
</mat-dialog-actions>