<h2 mat-dialog-title>Table Content</h2>
<button type="button" class="close-button" mat-icon-button mat-dialog-close>
  <mat-icon class="icon-clear">clear</mat-icon>
</button>

<mat-dialog-content>

  <div class="datepicker__container" [hidden]="isDatepickerHidden">
    <h3 class="datepicker__title">Select time range</h3>

    <ngx-daterangepicker-material class="datepicker__component" (choosedDate)="choosedDate($event)"
      [startDate]="startDate" [endDate]="endDate" [autoApply]="true" #datepickerComponent>
    </ngx-daterangepicker-material>

    <div class="datepicker__inputs-container">

      <div>
        <label class="datepicker__label" for="from">From: </label>
        <input class="datepicker__input" id="from" type="text" [value]="startDate.format('DD/MM/YYYY')" readonly />
      </div>

      <div>
        <label class="datepicker__label" for="to">To: </label>
        <input class="datepicker__input" id="to" type="text" [value]="endDate.format('DD/MM/YYYY')" readonly />
      </div>

    </div>
  </div>

  <div class="select__container" *ngIf="data.isShowGroupsSelect">
    <h3 class="select__title">Select the groups</h3>

    <ul class="select__list" *ngIf="groupsList.length; else noGroupsMessage">
      <li class="select__item">
        <mat-checkbox [checked]="allGroupsChecked" color="primary" [indeterminate]="someComplete('groups')"
          (change)="setAll('groups', $event.checked)">
          All
        </mat-checkbox>
      </li>

      <li *ngFor="let group of groupsList" class="select__item">
        <mat-checkbox [(ngModel)]="group.checked" (ngModelChange)="updateAllComplete('groups')" color="primary">
          {{group.name}}
        </mat-checkbox>
      </li>
    </ul>
    <ng-template #noGroupsMessage>
      <p>Groups list is empty</p>
    </ng-template>
  </div>

  <div class="select__container">
    <h3 class="select__title">Select the categories</h3>

    <ul class="select__list" *ngIf="categoriesList.length; else noCategoriesMessage">
      <li class="select__item">
        <mat-checkbox [checked]="allCategoriesChecked" color="primary" [indeterminate]="someComplete('categories')"
          (change)="setAll('categories', $event.checked)">
          All
        </mat-checkbox>
      </li>

      <li *ngFor="let category of categoriesList" class="select__item">
        <mat-checkbox [(ngModel)]="category.checked" (ngModelChange)="updateAllComplete('categories')" color="primary">
          {{category.name}}
        </mat-checkbox>
      </li>
    </ul>
    <ng-template #noCategoriesMessage>
      <p>Categories list is empty</p>
    </ng-template>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <p *ngIf="isReportGenerating" class="action-text">Report is generating. Please, wait</p>
  <p *ngIf="!isReportGenerating && isAlreadyGenerated && isReportGenerated" class="action-text">Report is successfully
    generated!</p>



  <app-button mat-flat-button (click)="askToGenerate()" [isLoading]="isReportGenerating" shape="raised"
    color="secondary">
    Generate report
  </app-button>

  <a mat-flat-button *ngIf="isReportGenerating || isReportGenerated" [href]="report.resultFile"
    [download]="report.resultName" [disabled]="isReportGenerating && !isReportGenerated">
    Download
  </a>
</mat-dialog-actions>
