<div
  class="navigation-guide tooltip-guide"
  matTooltip="{{'on_boarding_module.tooltip_info' | translate}}"
  (click)="startOnboardingNavigation()"
>
  <mat-icon>help_outline</mat-icon>
  <div class="text-block">{{'on_boarding_module.repeat_guide' | translate}}</div>
</div>

<div class="license-management" *ngIf="!isLicensesLoading; else spinner">

  <div class="license-management__header">
    <h3 class="license-management__title">{{'license_module.header_title' | translate}}</h3>
    <button
      mat-flat-button
      color="primary"
      type="button"
      class="license-management__contact-button"
      (click)="openContactsModal()"
    >
      {{'license_module.contact_button_label' | translate}}
    </button>
  </div>

  <app-license-stats [stats]="licenseStats"></app-license-stats>
  <app-license-blocks-table [tableData]="licenseBlocks"></app-license-blocks-table>
</div>

<ng-template #spinner>
  <div class="loading-status">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>
