import {Component} from '@angular/core';

@Component({
    selector: 'app-upgrade-feature',
    templateUrl: './upgrade-feature.component.html',
    styleUrls: ['./upgrade-feature.component.scss']
})
export class UpgradeFeatureComponent {

}
