import StatusEnum from "../../domain/statusEnum";
import { User } from "../../domain/user";

export function ConvertToUser(user: any): User {
  let status: StatusEnum;

  switch (user.status) {
    case 0:
      status = StatusEnum.invited;
      break;
    case 1:
      status = StatusEnum.problem;
      break;
    case 2:
      status = StatusEnum.accepted;
      break;
    case 3:
      status = StatusEnum.activated;
      break;
    case 4:
      status = StatusEnum.playing;
    case 5:
      status = StatusEnum.deactivated
  }

  let birthdate = null;
  if (user.birthDate) {
    birthdate = new Date(user.birthDate);
  }

  

  return new User({
    id: user.id,
    name: user.name,
    email: user.email,
    job: user.job,
    birthdate,
    department: user.department,
    status,
    role: user.role,
    language: user.languageID,
    organizationID: user.organizationID,
    virtualEmail: !user.emailExists,
    licenseId: user.licenseId,
    blockId: user.licenseBlockId
  });
}
