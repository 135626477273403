<header fxLayoutAlign="left center">
    <h4>{{'PLAYENVIRONMENTS' | translate}}</h4>
</header>
<article class="list-content play-environment-list-content" fxFlex>
    <mat-spinner class="listspinner" strokeWidth="5" diameter="30" *ngIf="loading"></mat-spinner>
    <mat-list *ngIf="!loading">
        <mat-list-item *ngFor="let playEnvironment of playEnvironments; let i = index">
            <h5 class="play-environment-name"
                mat-line>
                {{playEnvironment.name}}
            </h5>

            <ng-container *ngIf="i === 0; else notFirstSlideEnv">
                <mat-slide-toggle [checked]="canRemovePlayEnvironmentFromSelectedGroup(playEnvironment)"
                                  (change)="playEnvironmentChanged($event, playEnvironment)"
                                  joyrideStep="EnvironmentsBlockAtGroupDialog"
                                  stepPosition="left"
                                  title="{{'courses_guide_module.dialog_courses_environment_title' | translate}}"
                                  text="{{'courses_guide_module.dialog_courses_environment_hint' | translate}}"
                                  color="primary">
                </mat-slide-toggle>
            </ng-container>
            <ng-template #notFirstSlideEnv>
                <mat-slide-toggle [checked]="canRemovePlayEnvironmentFromSelectedGroup(playEnvironment)"
                                  (change)="playEnvironmentChanged($event, playEnvironment)"
                                  color="primary">
                </mat-slide-toggle>
            </ng-template>
        </mat-list-item>
    </mat-list>
</article>
