import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { Course } from "../../../domain/course";
import { Group } from "../../../domain/group";
import { Courses } from "./courses";
import { DataService } from "../../dataservice";
import { map } from "rxjs/operators";
import { CourseSetting } from "../../../domain/courseSetting";
import { CourseOptions } from "../../../domain/courseOptions";
import {
  IExpirationSettings,
  IStarsSettings,
} from "app/application/interfaces/course-settings.interface";
import { ICourseCategory, ICourseInfo } from "app/application/interfaces/courses.interface";

@Injectable()
export class HttpCourseService implements Courses {
  httpHeaders = new HttpHeaders().set("apiVersion", "version2");

  constructor(
    private httpClient: HttpClient,
    private dataService: DataService,
  ) {}

  getGroupCourses(group: Group): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `/Organizations/${this.dataService.organizationID}/Groups/${group.id}/courses`,
      { headers: this.httpHeaders }
    );
  }

  addGroupToCourses(group: Group, courses: Course[]): Observable<string[]> {
    let courseIDs = _.map(courses, "id");

    return this.httpClient.post<string[]>(
      `/Organizations/${this.dataService.organizationID}/Groups/${group.id}/courses`,
      courseIDs,
      { headers: this.httpHeaders }
    );
  }

  removeGroupFromCourses(
    group: Group,
    courses: Course[]
  ): Observable<string[]> {
    let courseIDs = _.map(courses, "id");
    return this.httpClient.post<string[]>(
      `/Organizations/${this.dataService.organizationID}/Groups/${group.id}/courses/unlink-requests`,
      courseIDs,
      { headers: this.httpHeaders }
    );
  }

  updateCourse(course: Course): void {
    this.httpClient
      .put(
        `/Organizations/${this.dataService.organizationID}/Courses/${course.id}`,
        {
          id: course.id,
          isActive: course.isActive,
        },
        { responseType: "text", headers: this.httpHeaders }
      )
      .subscribe(() => {});
  }

  getExpirationCourseSettings(course: Course): Observable<IExpirationSettings> {
    return this.httpClient
      .get<IExpirationSettings>(
        `/Organizations/${this.dataService.organizationID}/Courses/${course.id}/expiration`,
        { headers: this.httpHeaders }
      )
  }

  setExpirationCourseSettings(courseId: string, expirationSettings) {
    return this.httpClient.put(
      `/Organizations/${this.dataService.organizationID}/Courses/${courseId}/expiration`,
      expirationSettings,
      { headers: this.httpHeaders }
    );
  }

  getCourseSettings(course: Course): Observable<CourseSetting[]> {
    return this.httpClient
      .get<CourseSetting[]>(
        `/Organizations/${this.dataService.organizationID}/Courses/${course.id}/settings`,
        { headers: this.httpHeaders }
      )
      .pipe(
        map((settings) => {
          return settings.map((x) => {
            return new CourseSetting(x.nodeId, x.field, x.value);
          });
        })
      );
  }

  setCourseSettings(course: Course, settings: CourseSetting[]): void {
    this.httpClient
      .post(
        `/Organizations/${this.dataService.organizationID}/Courses/${course.id}/settings`,
        settings.map((x) => {
          return {
            nodeId: x.nodeId,
            value: x.value,
            field: x.field,
          };
        }),
        { headers: this.httpHeaders }
      )
      .subscribe();
  }

  getCourseOptions(course: Course): Observable<CourseOptions[]> {
    return this.httpClient.get<CourseOptions[]>(
      `/Organizations/${this.dataService.organizationID}/Courses/${course.id}/options`,
      { headers: this.httpHeaders }
    );
  }

  getCourseStars(course: Course): Observable<IStarsSettings> {
    return this.httpClient.get<IStarsSettings>(
      `/v2/Organizations/${this.dataService.organizationID}/courses/${course.id}/stars`,
      { headers: this.httpHeaders }
    );
  }

  setCourseStars(course: Course, starsSettings: IStarsSettings) {
    return this.httpClient.put(
      `/v2/Organizations/${this.dataService.organizationID}/courses/${course.id}/stars`,
      starsSettings,
      { headers: this.httpHeaders }
    );
  }

  getCourseInfo(courseId): Observable<ICourseInfo> {
    const requestUrl = `/v2/Organizations/${this.dataService.organizationID}/Courses/${courseId}/courseInfo`;
    return this.httpClient.get<ICourseInfo>(requestUrl, {
      headers: this.httpHeaders,
    });
  }

  saveCourseInfo(courseId, courseInfo: ICourseInfo) {
    const requestUrl = `/v2/Courses/${courseId}/courseInfo`;
    return this.httpClient.post(requestUrl, courseInfo, {
      headers: this.httpHeaders,
    });
  }

  getOrganizationCourses() {
    const { organizationID, language } = this.dataService;
    const requestUrl = `/v2/Organizations/${organizationID}/categories?expand=courses&language=${language}`;
    return this.httpClient.get<ICourseCategory[]>(requestUrl, { headers: this.httpHeaders }).pipe(map((categories) => {
      return categories.reduce((courses, category) => {
        return [...courses, ...category.courses]
      }, [])
    }));
  }
}
