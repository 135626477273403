export class ImportUsersSpeadsheetValidationResult {


    public constructor(
        public readonly isValid: boolean,
        public readonly warnings: string[],
        public readonly errors: string[],
        public readonly numberOfUsers: number,
        public readonly numberOfExistingUsers: number,
        public readonly numberOfNewUsers: number,
        public readonly numberOfLicences :number,
        public readonly numberOfExpiredLicences: number,
        public readonly numberOfNewLicences: number) {

    }

}
