import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "app/infrastructure/dataservice";
import { HttpCategoryService } from "app/infrastructure/http/category/httpcategory.service";
import { HttpGameReportService, IDownloadParams, IReportFile } from "app/infrastructure/http/gamereport/httpgamereport.service";
import { HttpGroupsService } from "app/infrastructure/http/group/httpgroups.service";
import * as moment from "moment";
import { DaterangepickerComponent } from "ngx-daterangepicker-material";
import { FileSaverService } from "ngx-filesaver";
import { Subscription } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { LicenseConfirmationDialog } from "../../license-overview/components/license-confirmation-dialog/license-confirmation-dialog";

enum SideEnum {
  left = 'left',
  right = 'right'
}

export interface IGroup {
  id: string
  name: string
  checked: boolean
}

export type TType = 'groups' | 'categories'

@Component({
  selector: 'app-exports-result-dialog',
  templateUrl: './export-results-dialog.component.html',
  styleUrls: ['./export-results-dialog.component.scss']
})
export class ExportResultsDialogComponent implements OnInit, OnDestroy {
  @ViewChild('datepickerComponent', { static: true }) datePicker: DaterangepickerComponent
  isDatepickerHidden = true

  isDataLoading = false
  allCategoriesChecked: boolean = true
  categoriesList = []

  dialogConfig: MatDialogConfig = <MatDialogConfig>{
    height: '200px',
    width: '500px'
  }

  isAlreadyGenerated = false
  isReportGenerating = false
  isReportGenerated = false
  report: IReportFile = { completed: false }

  allGroupsChecked: boolean = true
  groupsList: IGroup[] = []
  endDate: moment.Moment
  startDate: moment.Moment

  statusSubscription$: Subscription

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ExportResultsDialogComponent>,
    private dialog: MatDialog,
    private groupsService: HttpGroupsService,
    private categoryService: HttpCategoryService,
    private dataservice: DataService,
    private reportService: HttpGameReportService,
    private fileSaverService: FileSaverService
  ) {
    const currentDate = moment()
    this.startDate = moment(currentDate).startOf('month'),
    this.endDate = currentDate
  }

  ngOnInit() {
    this.getGeneratedReports()
    this.getCategories()
    this.getGroups()

    this.isReportGenerating = this.reportService.isReportGenerating

    if (this.isReportGenerating) {
      this.statusSubscription$ = this.reportService.checkGenerationStatus(this.dataservice.organizationID).subscribe((generatedReport) => {
        this.isReportGenerated = true
        this.isReportGenerating = false
        this.report = {...generatedReport}
      })
    }

    setTimeout(() => {
      this.datePicker.clickPrev(SideEnum.right)
      this.datePicker.clickPrev(SideEnum.left)

      this.isDatepickerHidden = false
    }, 0);
  }

  getGeneratedReports() {
    this.statusSubscription$ = this.reportService.getGeneratedReports(this.dataservice.organizationID).subscribe((generatedReport) => {
      if (generatedReport.completed) {
        this.isReportGenerated = true
        this.report = {...generatedReport}
      }
    })
  }

  askToGenerate() {
    if (this.isReportGenerating) {
      return
    }

    if (this.isReportGenerated) {
      const dialogRef = this.dialog.open(LicenseConfirmationDialog, {
        data: {
          textContent: 'You want to generate a new report? This action will delete previous generated report'
        },
        ...this.dialogConfig,
      })

      dialogRef.afterClosed().subscribe((action) => {
        if (action) {
          this.generateReport()
        }
      })
    } else {
      this.generateReport()
    }
  }

  generateReport() {
    this.isReportGenerating = true
    this.isReportGenerated = false

    const checkedGroups = this.groupsList.filter(getCheckedItems).map(getItemIds)
    const checkedCategories = this.categoriesList.filter(getCheckedItems).map(getItemIds)

    const groupsIds = this.allGroupsChecked
      ? []
      : checkedGroups

    const caterogoriesIds = this.allCategoriesChecked
      ? []
      : checkedCategories

    const params: IDownloadParams = {
      startDate: this.startDate.format('YYYY-MM-DD'),
      endDate: this.endDate.format('YYYY-MM-DD'),
      groups: groupsIds,
      categories: caterogoriesIds
    }

    this.statusSubscription$ = this.reportService.startGenerateReport(this.dataservice.organizationID, params)
    .pipe(
      switchMap(() => this.reportService.checkGenerationStatus(this.dataservice.organizationID)
      )
    )
    .subscribe((generatedReport) => {
      this.isReportGenerating = false
      this.isReportGenerated = true
      this.isAlreadyGenerated = true
      this.report = {...generatedReport}
    })
  }

  getGroups() {
    this.groupsService.getGroups(this.dataservice.organizationID)
    .pipe(
      map((groups) => groups.filter((group) => group.name !== 'All')),
      map((groups) => {
        const newGroups: IGroup[] = groups.map((group) => ({
          id: group.id,
          name: group.name,
          checked: true
        }))

        return newGroups
      })
    )
    .subscribe((groups: IGroup[]) => {
      this.groupsList = groups
    })
  }

  getCategories() {
    this.categoryService.getCategories()
    .pipe(
      map((categories) => {
        const newCategories = categories.map((category) => ({
          id: category.id,
          name: category.name,
          checked: true
        }))

        return newCategories
      })
    )
    .subscribe((categories) => {
      this.categoriesList = categories
    })
  }

  updateAllComplete(type: TType) {
    if (type === 'groups') {
      this.allGroupsChecked = this.groupsList.every((group) => group.checked)
    }

    if (type === 'categories') {
      this.allCategoriesChecked = this.categoriesList.every((category) => category.checked)
    }
  }

  someComplete(type: TType) {
    if (type === 'groups') {
      if (!this.groupsList.length) {
        return false
      }

      return this.groupsList.filter((g) => g.checked).length > 0 && !this.allGroupsChecked

    }

    if (type === 'categories') {
      if (!this.categoriesList.length) {
        return false
      }

      return this.categoriesList.filter((c) => c.checked).length > 0 && !this.allCategoriesChecked
    }
  }

  setAll(type: TType, completed: boolean) {
    if (type === 'groups') {
      this.allGroupsChecked = completed

      if (!this.groupsList.length) {
        return
      }

      this.groupsList.forEach((g) => g.checked = completed)
    }

    if (type === 'categories') {
      this.allCategoriesChecked = completed

      if (!this.categoriesList.length) {
        return
      }

      this.categoriesList.forEach((c) => c.checked = completed)
    }
  }

  choosedDate(event) {
    this.startDate = event.startDate
    this.endDate = event.endDate
  }

  downloadResults() {
    const { resultName, resultFile }= this.report
    const fileData = new Blob([resultFile], { type: 'application/vnd.ms-excel' })
    const fileName = `results-${resultName}.xlsx`
    this.fileSaverService.save(fileData, fileName);
  }

  ngOnDestroy() {
    this.statusSubscription$.unsubscribe()
  }
}

const getCheckedItems = (item) => item.checked
const getItemIds = (item) => item.id
