import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateViaAuthGuard } from 'app/application/security/can-activate-via-auth-guard';
import { LearningPathsPageComponent } from './learning-paths-page.component';

const routes: Routes = [
  {
    path: '',
    component: LearningPathsPageComponent,
    canActivate: [CanActivateViaAuthGuard]
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LearningPathsPageRoutingModule { }
