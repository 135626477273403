import { TranslateModule } from '@ngx-translate/core';
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LearningPathCreateDialogComponent } from "../learning-path-create-dialog/learning-path-create-dialog.component";
@Component({
  selector: 'app-learning-path-header',
  templateUrl: './learning-path-header.component.html',
  styleUrls: ['./learning-path-header.component.scss']
})
export class LearningPathHeaderComponent {

  constructor(private dialog: MatDialog) {}


  openCreateDialog() {
    this.dialog.open(LearningPathCreateDialogComponent)
  }

}
