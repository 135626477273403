import {Component} from '@angular/core';
import {AuthenticationService} from '../../../infrastructure/http/authentication/authentication.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Location } from '@angular/common'


@Component({
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
	

 currentYear: number=new Date().getFullYear();


	private authenticationService: AuthenticationService;
	private formSubmitted = false;
	private formError = false;
	public requestPasswordForm: FormGroup;
	private location : Location;

	constructor(authenticationService: AuthenticationService, location: Location) {
		this.authenticationService = authenticationService;
		this.location = location;

		this.requestPasswordForm = new FormGroup({
			email: new FormControl('', Validators.required),
		});
	}

	public back(): void {
    this.location.back()
  }
	

	public isFormSubmitted(): boolean {
		return this.formSubmitted;
	}

	public isFormError(): boolean {
		return this.formError;
	}

	public canShowRequestPasswordForm(): boolean {
		return !this.formSubmitted;
	}

	public submitForm(form: any): void {
		this.authenticationService.requestResetPassword(form.email).subscribe(() => {
			this.formError = false;
			this.formSubmitted = true;
		}, () => {
			this.formError = true;
			this.formSubmitted = false;
		});
	}

}
