import {Component} from '@angular/core';
import {User} from '../../../../../domain/user';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {UserDetailComponent} from '../../detail/user-detail.component';


@Component({
	selector: 'app-user-name',
	templateUrl: './user-name.component.html',
	styleUrls: ['./user-name.component.scss']
})
export class UserNameComponent {

	private params: any;
	public user: User;
	private dialog: MatDialog;

	constructor(dialog: MatDialog) {
		this.dialog = dialog;
	}

	agInit(params: any): void {
		this.params = params;
		this.user = params.data;
	}

	public showUserProfile(user: User): void {
		const config = new MatDialogConfig();
		config.height = '832px';
		config.width = '1240px';
		config.data = {
			user: user
		};
		this.dialog.open(UserDetailComponent, config);
	}

}
