import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Observable, ReplaySubject, Subject, forkJoin, zip } from "rxjs";
import { map as rxjsMap, concatMap, switchMap, map, mergeMap, mergeAll, toArray, tap } from "rxjs/operators";
import { Category } from "../../../domain/category";
import { CollectionCache } from "../../../domain/collectioncache";
import { Course } from "../../../domain/course";
import { Group } from "../../../domain/group";
import { Organization } from "../../../domain/organization";
import { User } from "../../../domain/user";
import { AuthenticationService } from "../authentication/authentication.service";
import { Categories } from "./categories";
import { DataService } from "../../dataservice";
import { Asset } from "app/domain/asset";
import { ICategorySummary } from "app/infrastructure/http/category/categorySummary";
import { ICategoryIcon } from "../../../domain/categoryIcon";
import { HttpAssetService } from "../asset/httpasset.service";
//import { HttpGroupsService } from '../group/httpgroups.service';
//import { HttpCourseService } from '../course/httpcourse.service';

@Injectable()
export class HttpCategoryService implements Categories, CollectionCache {
  headers = new HttpHeaders({ apiVersion: "version2" });

  private httpClient: HttpClient;
  private categorySubject: Subject<Category[]>;
  private categoryRequest: Observable<Category[]>;
  private categories: Category[];

  private categoryIconsSubject: Subject<ICategoryIcon[]>;
  private categorySummaryRequest: Observable<ICategorySummary[]>;

  private courses: Course[];

  public constructor(httpClient: HttpClient, private dataService: DataService, private assetService : HttpAssetService ) {
    this.httpClient = httpClient;
    this.categorySubject = new ReplaySubject(1);
    this.categoryIconsSubject = new ReplaySubject();


  }

  public clear(): void {
    this.categoryRequest = null;
    this.categorySubject.next([]);
    this.categoryIconsSubject =  new ReplaySubject(1); 
    this.categories = [];
    this.courses = [];
  }


  public getCategoryIcons(refresh: boolean = false): Observable<ICategoryIcon[]> {
    if (refresh || !this.categorySummaryRequest) {
      const { organizationID, language } = this.dataService;

      
      this.categorySummaryRequest = this.httpClient.get<ICategorySummary[]>(
        `/v2/Organizations/${organizationID}/categories/summary?language=${language}`,
        { headers: this.headers }
      );
      
      this.categorySummaryRequest
        .subscribe(categorySummaries => { 

          var summaries = categorySummaries.map(categorySummary => this.assetService.getAsset(categorySummary.iconAssetId, refresh).pipe(
            map(asset => {

              var translated = categorySummaries.find((cs) => cs.iconAssetId == asset.id).name;
              if(translated == null) {
                this.getCategories().subscribe(categories => {
                  translated = categories.find(c => c.id == categorySummary.id).name
                });

              }

              return {
                id: asset.id, 
                downloadUri: asset.downloadUri, 
                name: translated
              }; 
            })
          ));
          
          zip(...summaries).subscribe(
            (assets) => this.categoryIconsSubject.next(assets),
            (err) => console.log(err)
          );
          
        }); 

        return this.categoryIconsSubject;
    }

    return this.categoryIconsSubject.asObservable();
  }

  public getCategories(refresh: boolean = false): Observable<Category[]> {
    if (refresh || !this.categoryRequest) {
      let httpHeaders = new HttpHeaders().set("apiVersion", "version2");
      this.courses = [];
      this.categoryRequest = this.httpClient
        .get(
          `/Organizations/${this.dataService.organizationID}/Categories?expand=courses&language=${this.dataService.language}`,
          { headers: httpHeaders }
        )
        .pipe(rxjsMap(this.mapCategories, this));

      this.categoryRequest.subscribe(
        (result) => {
          this.categories = result;

          this.categorySubject.next(result);

          this.categories.forEach((c) => {
            this.courses = this.courses.concat(c.courses);
          });
        },
        (err) => this.categorySubject.error(err)
      );
    }
    return this.categorySubject.asObservable();
  }

  public getCategoriesOfUser(
    organization: Organization,
    user: User
  ): Observable<Category[]> {
    let httpHeaders = new HttpHeaders().set("apiVersion", "version2");
    const languageId = this.dataService.language;

    return this.httpClient
      .get(
        `/Organizations/${organization.id}/users/${user.id}/categories?expand=courses&language=${languageId}`,
        { headers: httpHeaders }
      )
      .pipe(rxjsMap(this.mapCategories, this));
  }

  public getCategoriesOfGroup(group: Group): Observable<Category[]> {
    return this.getCategories().pipe(
      rxjsMap((categories) => {
        return this.filterCategories(group.courseIDs);
      }, this)
    );
  }

  public updateCategory(category: Category): void {
    let httpHeaders = new HttpHeaders().set("apiVersion", "version2");
    this.httpClient
      .put(
        `/Organizations/${this.dataService.organizationID}/Categories/${category.id}`,
        {
          id: category.id,
          isActive: category.isActive,
        },
        { responseType: "text", headers: httpHeaders }
      )
      .subscribe(() => {
        this.categorySubject.next(Object.assign([], this.categories));
      });
  }

  private filterCategories(courseIds: string[]): Category[] {
    return _.map(this.categories, (c) =>
      this.filterCategoryCourses(c, courseIds)
    ).filter((c) => c != null);
  }

  private mapCategories(response: any): Category[] {
    return _.sortBy(response.map(this.toCategory), (category: Category) => {
      return _.lowerCase(category.name);
    });
  }

  private filterCategoryCourses(
    category: Category,
    courseIds: string[]
  ): Category {
    const courses = _.sortBy(
      _.map(
        category.courses.filter((c) => courseIds.indexOf(c.id) >= 0),
        (course: any) => {
          return new Course(
            course.id,
            course.name,
            course.description,
            course.isActive,
            course.level
          );
        }
      ),
      (course: Course) => {
        return `${course.level} ${_.lowerCase(course.name)}`;
      }
    );
    if (courses.length == 0) return null;
    return new Category(
      category.id,
      category.name,
      category.description,
      category.isActive,
      courses
    );
  }

  private toCategory(category: any): Category {
    const courses = _.sortBy(
      _.map(category.courses, (course: any) => {
        return new Course(
          course.id,
          course.name,
          course.description,
          course.isActive,
          course.level
        );
      }),
      (course: Course) => {
        return `${course.level} ${_.lowerCase(course.name)}`;
      }
    );
    return new Category(
      category.id,
      category.name,
      category.description,
      category.isActive,
      courses
    );
  }
}
