import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { TOAST_TYPES } from "app/application/constants";
import { ILearningModuleCreated, ILearningPath, ILearningPathItem } from "app/application/interfaces/learning-paths.interface";
import { ToastService } from "app/application/toast/toast.service";
import { EventBusService } from "app/core/event-bus.service";
import {
  generateNewWorkingName,
  sortByName,
  sortByOrderIndex,
} from "app/utils/learning-path.utils";
import { Subscription } from "rxjs";
import { ACTIONS } from "./learning-path.actions";
import { LearningPathService } from "./services/learning-path.service";

@Component({
  selector: 'app-learning-path',
  templateUrl: './learning-path.component.html',
  styleUrls: ['./learning-path.component.scss']
})
export class LearningPathComponent implements OnInit, OnDestroy {
  learningPathsIsLoading = false;
  learningPaths: ILearningPath[];
  learningPathsList: ILearningPathItem[]

  subs$: Subscription[] = [];

  @Output() onSelect: EventEmitter<string> = new EventEmitter()
  @Output() onUnselect: EventEmitter<string> = new EventEmitter()

  constructor(
    private learningPathsService: LearningPathService,
    private eventService: EventBusService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.subscribeToEvents();
    this.getLearningPathsList();
  }

  ngOnDestroy() {
    this.eventService.removeSubscriptions(this.subs$)
  }

  subscribeToEvents() {
    const deleteSub$ = this.eventService.subscribeEvent(
      ACTIONS.LEARNING_PATH_DELETE,
      (learningPathId: string) => {
        this.deleteLearningPathItem(learningPathId);
      }
    );

    const createSub$ = this.eventService.subscribeEvent(
      ACTIONS.LEARNING_PATH_CREATE,
      (newLearningPath: ILearningPath) => {
        this.learningPathsIsLoading = true
        this.addToList(newLearningPath)
        this.learningPathsIsLoading = false
      }
    );

    const copySub$ = this.eventService.subscribeEvent(
      ACTIONS.LEARNING_PATH_COPY,
      ({ workingName, id }: ILearningPath) => {
        console.log('subscription -------- LEARNING_PATH_COPY')
        this.copyLearningPath(id, workingName);
      }
    );

    const updateSub$ = this.eventService.subscribeEvent(
      ACTIONS.LEARNING_PATH_UPDATE,
      ({
        learningPath,
        index,
      }: {
        learningPath: ILearningPath;
        index: number;
      }) => {
        this.updateLearningPathList(learningPath, index);
      }
    );

    const moduleCreateSub$ = this.eventService.subscribeEvent(
      ACTIONS.LEARNING_MODULE_CREATE,
      (
        {
          newLearningModule,
          learningPathIndex
        }: {
          newLearningModule: ILearningModuleCreated,
          learningPathIndex: number
        }
      ) => {
        const { workingName, id, orderIndex } = newLearningModule
        const { item } = this.learningPathsList[learningPathIndex]
        item.learningModules.push({ workingName, id, orderIndex })
        item.learningModules.sort(sortByOrderIndex)
        this.updateLearningPathList(item, learningPathIndex, true);
      }
    );

    const checkSub$ = this.eventService.subscribeEvent(
      ACTIONS.LEARNING_PATH_CHECK,
      (id: string) => this.onSelect.emit(id)
    )

    const uncheckSub$ = this.eventService.subscribeEvent(
      ACTIONS.LEARNING_PATH_UNCHECK,
      (id: string) => this.onUnselect.emit(id)
    )

    this.subs$.push(
      createSub$,
      copySub$,
      updateSub$,
      deleteSub$,
      checkSub$,
      uncheckSub$,
      moduleCreateSub$
    )
  }

  mapLearningPathList(learningPaths: ILearningPath[]) {
    return learningPaths.map((item) => {
      item.learningModules.sort(sortByOrderIndex)
      return {
        item,
        isLoading: false,
        isExpanded: false,
        checked: false
      }
    });
  }

  getLearningPathsList() {
    this.learningPathsIsLoading = true;
    this.learningPathsService
      .getLearningPathList()
      .subscribe((learningPaths) => {
        this.learningPathsIsLoading = false;
        this.learningPaths = learningPaths
        this.learningPathsList = this.mapLearningPathList(this.learningPaths)
      });
  }

  addToList(newItem: ILearningPath) {
    this.learningPathsList.push({ item: newItem, isLoading: false, isExpanded: false })
    this.learningPathsList.sort(sortByName)
  }

  updateLearningPathList(learningPath: ILearningPath, index: number, isExpanded = false) {
    this.learningPathsList[index] = {
      item: { ...learningPath },
      isExpanded,
      isLoading: false,
      checked: false
    }
    this.learningPathsList.sort(sortByName)
  }

  deleteLearningPathItem(learningPathId: string) {
    this.learningPathsIsLoading = true;
    this.learningPathsService.deleteLearningPath(learningPathId).subscribe(
      () => {
        const deletedIndex = this.learningPathsList.findIndex(({ item }) => item.id === learningPathId);
        if (deletedIndex !== -1) {
          this.learningPathsList.splice(deletedIndex, 1);
        }
        this.learningPathsIsLoading = false;
      },
      (error: HttpErrorResponse) => {
        this.toastService.show(error.message, TOAST_TYPES.ERROR);
        this.learningPathsIsLoading = false;
      }
    );
  }

  copyLearningPath(learningPathId: string, workingName: string) {
    const namesList = this.learningPathsList.map(({ item }) => item.workingName.toLowerCase());
    const newWorkingName = generateNewWorkingName(workingName, namesList);

    this.learningPathsIsLoading = true;
    this.learningPathsService
      .copyLearningPath(learningPathId, newWorkingName)
      .subscribe(
        (learningPathCopy: ILearningPath) => {
          this.addToList(learningPathCopy)
          this.toastService.show(`Learning path "${workingName}" is copied`);
          this.learningPathsIsLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.learningPathsIsLoading = false;
          this.toastService.show(error.message, TOAST_TYPES.ERROR);
        }
      );
  }
}
