<div class="ag-cell-label-container ag-header-cell-sorted-asc" (click)="onOwnSortRequested($event)"
	[ngStyle]="{'ag-header-cell-sorted-asc': sorted === 'asc', 'ag-header-cell-sorted-desc': sorted === 'desc', 'ag-header-cell-sorted-none': sorted === ''}"
	role="presentation">
	<div rel="eLabel" class="ag-header-cell-label" role="presentation">
		<span rel="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden" aria-hidden="true">1</span>
		<span *ngIf="params.enableSorting && sorted === 'asc'" rel="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"
			aria-hidden="true">
			<i class="fa fa-angle-down"><svg class="select-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
					<path
						d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
				</svg></i>
		</span>
		<span *ngIf="params.enableSorting && sorted === 'desc'" rel="eSortDesc"
			class="ag-header-icon ag-sort-descending-icon" aria-hidden="true">
			<i class="fa fa-angle-up"></i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
				<path
					d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z" />
			</svg>
		</span>
		<span rel="eFilter" class="ag-header-icon ag-filter-icon ag-hidden" aria-hidden="true">
			<svg width="10" height="10">
				<polygon points="0,0 4,4 4,10 6,10 6,4 10,0" class="ag-header-icon"></polygon>
			</svg>
		</span>
		<span rel="eText" class="ag-header-cell-text" role="columnheader">{{params.displayName | translate}}</span></div>
</div>

<script src="https://kit.fontawesome.com/99fe3c75dd.js" crossorigin="anonymous"></script>
