<section fxFlex fxLayout="column" (keypress)="keyDownFunction($event)">
  <header *ngIf="isShowCloseButton" class="primary-color">
    <button class="close-button" mat-icon-button type="button" mat-dialog-close>
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>
  </header>
  <mat-dialog-content fxFlex fxLayout="column" class="information-dialog-container">
    <carousel (guideFinished)="closeDialog()">
      <ng-container *ngFor="let item of items">
        <ng-container *carouselItem>
          <div class="item">
            <h5>{{item.title | translate}}</h5>
            <p>{{item.description | translate}}</p>
            <img src="{{item.src}}" alt="screen">
          </div>
        </ng-container>
      </ng-container>
    </carousel>
  </mat-dialog-content>
</section>
