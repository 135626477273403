import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { ILearningModuleListItem, ILearningPathInfo } from "app/application/interfaces/learning-paths.interface";
import { LearningModulesService } from "../../services/learning-modules.service";

@Component({
  selector: 'app-learning-module-list',
  templateUrl: './learning-module-list.component.html',
  styleUrls: ['./learning-module-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LearningModuleListComponent {

  @Input() learningModulesList: ILearningModuleListItem[] = []
  @Input() learningPath: ILearningPathInfo

  constructor(
    private learningModulesService: LearningModulesService

  ) {}

  drop(event: CdkDragDrop<ILearningModuleListItem[]>) {
    
    moveItemInArray(this.learningModulesList, event.previousIndex, event.currentIndex)
    this.learningModulesService.updateOrder(this.learningPath.id, this.learningModulesList.map( function(module){ return module.id; })).subscribe();
  }
}
