import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from '../../../infrastructure/http/authentication/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { validatePasswordMatch } from 'app/application/validators/password-match-validation';
import { Location } from '@angular/common'

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    private id: string;
    private token: string;
    private activatedRoute: ActivatedRoute;
    private authenticationService: AuthenticationService;
    private formSubmitted = false;
    private validateTokenError = false;
    private verifyingToken = false;
    private router: Router;
    public setPasswordForm: FormGroup;
    private location : Location;

   

    constructor(activatedRoute: ActivatedRoute, authenticationService: AuthenticationService, router: Router, location: Location) {
        this.activatedRoute = activatedRoute;
        this.authenticationService = authenticationService;
        this.router = router;
        this.location = location;

        this.setPasswordForm = new FormGroup({
            password: new FormControl('', Validators.required),
            passwordConfirm: new FormControl('', Validators.required),
        }, validatePasswordMatch);
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            if (!_.isNil(params['id']) && this.checkGuid(params['id']) && !_.isNil(params['token'])) {
                this.id = params['id'];
                this.token = params['token'];

                this.verifyToken(this.id, this.token);

            } else {
                this.router.navigate(['']);
            }
        });
    }
    public currentYear: number=new Date().getFullYear();

    public back(): void {
        this.location.back()
      }
    
    public showErrorForConfirmedPassword(): boolean {
        return this.setPasswordForm.controls.passwordConfirm.touched && this.setPasswordForm.hasError('validatePasswordMatch');
    }

    public isFormSubmitted(): boolean {
        return this.formSubmitted;
    }

    public canShowResetPasswordForm(): boolean {
        return !this.formSubmitted && !this.isInvalidToken();
    }

    public isVerifyingToken(): boolean {
        return this.verifyingToken;
    }

    public isInvalidToken(): boolean {
        return this.validateTokenError && !this.verifyingToken;
    }

    public submitForm(form: any): void {
        this.authenticationService.resetAccount(this.id, this.token, form.password).subscribe(() => {
            this.formSubmitted = true;
        });
    }

    private verifyToken(id: string, token: string) {
        this.verifyingToken = true;
        this.authenticationService.verifyToken(id, token).subscribe((result) => {
            this.verifyingToken = false;
            this.validateTokenError = result.success !== true;

        }, (error) => {
            this.verifyingToken = false;
            this.validateTokenError = true;
        });
    }

    private checkGuid(id: string): boolean {
        const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(id);
    }

}
