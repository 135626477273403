<h3 class="title">{{"learningpaths_module.learning_paths" | translate }}</h3>

<app-button
  class="create-button"
  [color]="'primary'"
  [shape]="'raised'"
  (onButtonClick)="openCreateDialog()"
>
  <mat-icon class="add-icon">add</mat-icon> {{"learningpaths_module.create_learning_path" | translate }}
</app-button>
