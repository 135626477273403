import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-game-link-dialog',
  templateUrl: './game-link-dialog.component.html',
  styleUrls: ['./game-link-dialog.component.scss']
})
export class GameLinkDialogComponent {
  dialogRef: MatDialogRef<GameLinkDialogComponent>;

  constructor(dialogRef: MatDialogRef<GameLinkDialogComponent>) {
    this.dialogRef = dialogRef;
  }
}
