<div class="notifications-popover__pointer"></div>
<div class="notifications-popover__container">
  <div class="notifications-popover__header" *ngIf="unreadNumber">
    <p class="notifications-popover__unread-count">{{unreadNumber}} new from {{notifications.length}}</p>
    <button type="button" class="all-read-button" (click)="markAllRead()">
      Mark all read
    </button>
  </div>

  <div *ngIf="shouldShowSpinner; else notificationsContent" class="loading-status">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>

  <ng-template #notificationsContent>

    <div class="notifications-popover__content" *ngIf="notifications.length; else emptyMessage">

      <div class="notifications-popover__list">

        <div class="notification-item" *ngFor="let n of notifications">
          <div class="notification-item__content">

            <div class="notification-item__content-header">
              <span class="notification-item__date">{{n.creationDate | date:'dd.MM.yyyy hh:mm'}}</span>
              <button type="button" class="notification-item__close-button" mat-icon-button
                (click)="deleteNotification(n.id)">
                <mat-icon class="icon-clear">clear</mat-icon>
              </button>
            </div>

            <h3 class="notification-item__title">{{n.title}}</h3>
            <p class="notification-item__description">{{n.description}}</p>
          </div>

          <div class="notification-item__controls">
            <a mat-flat-button color="primary" class="notification-item__link" *ngIf="n.viewDetailsUrl"
              (click)="closeNotifications()" [routerLink]="[n.viewDetailsUrl]" [fragment]="n.fragment">
              View details
            </a>
            <button type="button" *ngIf="!n.isRead" class="notification-item__read-button" (click)="markAsRead(n.id)">
              Mark as read
            </button>
          </div>
        </div>

      </div>
    </div>

    <ng-template #emptyMessage>
      <p class="empty-message">You have no notifications</p>
    </ng-template>

  </ng-template>

</div>