import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject, EMPTY} from 'rxjs';
import {CollectionCache} from '../../../domain/collectioncache';
import {Language} from '../../../domain/language';
import {Languages} from './languages';
import {map as rxjsMap, expand, toArray, tap} from 'rxjs/operators';
import * as _ from 'lodash';


@Injectable()
export class HttpLanguageService implements Languages, CollectionCache {
    private httpClient: HttpClient;
    private languagesSubject: Subject<Language[]>;
    private languagesRequest: Observable<Language[]>;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
        this.languagesSubject = new ReplaySubject(1);
    }

    public clear(): void {
        this.languagesRequest = null;
        this.languagesSubject.next([]);
    }

    public getLanguages(refresh: boolean = false): Observable<Language[]> {
        if (refresh || !this.languagesRequest) {
            let httpHeaders = new HttpHeaders();
            httpHeaders = httpHeaders.set('apiVersion', 'version2');

            this.languagesRequest = this.getPage('/Languages', httpHeaders)
            .pipe(
                tap(data => console.log('After getPage:', data)),
                expand(page => 
                    page.nextPageLink ? this.getPage(page.nextPageLink, httpHeaders) : EMPTY),
                toArray(),
                rxjsMap(pages => 
                    _.flatMap(pages, 'items')),
                rxjsMap(this.mapLanguages),
            );

            this.languagesRequest.subscribe(
                result => this.languagesSubject.next(result),
                err => this.languagesSubject.error(err)
            );
        }
        return this.languagesSubject.asObservable();
    }

    public getCompatibleLanguages(language: string): Observable<Language[]> {
        let languageInfo = language.split('-');
        let languageCode = languageInfo[0].toLowerCase();
        let countryCode = languageInfo[1].toUpperCase();
        return this.getLanguages().pipe(
            rxjsMap(languages => _.filter(languages, language => language.languageCode === languageCode))
        );
    }

    private mapLanguages(response: any): Language[] {
        return _.map(response, this.toLanguage).sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    private toLanguage(language: any): Language {
        return new Language(language.id, language.name, language.countryCode, language.languageCode, language.priority);
    }

    private getPage(url: string, headers: HttpHeaders): Observable<any> {
        return this.httpClient.get(url, { headers, observe: 'response' }).pipe(
          rxjsMap(response => {
            const pagination = JSON.parse(response.headers.get('x-pagination'));
            console.log('Pagination:', pagination);
            return {
              items: response.body,
              nextPageLink: pagination.nextPageLink
            };
          })
        );
    }
}
