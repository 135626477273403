<section>
	<header class="primary-color">
		<button class="close-button" mat-icon-button type="button" mat-dialog-close>
			<mat-icon class="icon-clear">clear</mat-icon>
		</button>
	</header>
	<mat-dialog-content class="info-dialog__container">
		<h5>Sorry, you cannot log in because you does not have a license</h5>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button color="primary" mat-dialog-close>Ok</button>
	</mat-dialog-actions>
</section>
