<section class="results-wrapper" fxLayout="column">
  <header fxLayoutGap="20px">
    <h3 fxFlex>{{ "RESULTS" | translate }}</h3>
    <div fxLayoutAlign="end" class="absolutes" *ngIf="LoadData">
      <mat-label ngClass="labelBeforeSlide"> {{"results.show_absolutes" | translate}}</mat-label>
      <mat-slide-toggle (click)="$event.stopPropagation()" color="primary" (change)="updateAbsolutes()"
        [(ngModel)]="showAbsolutes"></mat-slide-toggle>
    </div>
    <span fxLayoutAlign="start center" *ngIf="currentCategory">{{
      "CATEGORY" | translate
    }}</span>
    <div class="button-wrapper" joyrideStep="ChooseCategoryStep" stepPosition="right"
      title="{{ 'results_guide_module.choose_category_title' | translate }}"
      text="{{ 'results_guide_module.choose_category_hint' | translate }}">
      <button mat-flat-button color="secondary" fxLayoutAlign="end center" [matMenuTriggerFor]="categorySelect"
        *ngIf="currentCategory">
        {{ currentCategory }}
        <mat-icon aria-hidden="true"><svg class="select-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path
              d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
          </svg></mat-icon>
      </button>
    </div>

    <mat-menu #categorySelect [overlapTrigger]="false" xPosition="after" yPosition="below" >
      <button mat-menu-item (click)="switchCategory(category)" *ngFor="let category of getAvailableCategories()">
        {{ category }}
      </button>
    </mat-menu>

    <div class="button-wrapper" joyrideStep="SelectActionStep" stepPosition="right"
      title="{{ 'results_guide_module.select_action_title' | translate }}"
      text="{{ 'results_guide_module.select_action_hint' | translate }}">

      <button mat-flat-button fxLayoutAlign="end center" color="primary" [matMenuTriggerFor]="resultActions"
        [disabled]="isExporting" #btnTrigger="matMenuTrigger">
        <div class="action-button__container">
          {{'common.select_action' | translate}}
          <mat-icon aria-hidden="true" *ngIf="!isExporting; else spinnerIcon"><svg class="select-icon"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path
                d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
            </svg>
          </mat-icon>
          <ng-template #spinnerIcon>
            <mat-spinner class="export-spinner" diameter="20"></mat-spinner>
          </ng-template>
        </div>
      </button>
    </div>

    <mat-menu #resultActions [overlapTrigger]="false" xPosition="after" yPosition="below">
      <button mat-menu-item (click)="sendReminder()" joyrideStep="SendReminderStep" stepPosition="right"
        title="{{ 'results_guide_module.send_reminder_title' | translate }}"
        text="{{ 'results_guide_module.send_reminder_hint' | translate }}">
        {{ "reminder.send_reminder" | translate }}
      </button>
      <button mat-menu-item (click)="exportResults()" joyrideStep="ExportResultsStep" stepPosition="right"
        title="{{ 'results_guide_module.export_results_title' | translate }}"
        text="{{ 'results_guide_module.export_results_hint' | translate }}">
        {{ "results.export" | translate }}
      </button>
    </mat-menu>
  </header>

  <div *ngIf="!LoadData && !areResultsLoading()" fxFlex fxLayout="column" fxLayoutAlign="center center">
    <p>
      {{
        "results.data_hidden"
          | translate: { attribute: "RESULTS_attribute" | translate }
      }}
    </p>
  </div>

  <div *ngIf="areResultsLoading()" fxFlex fxLayout="column" fxLayoutAlign="center center">
    <p>
      {{
        "general.loading"
          | translate: { attribute: "RESULTS_attribute" | translate }
      }}
    </p>
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
  </div>

  <div *ngIf="noResultsAvailable() && !areResultsLoading()" fxFlex fxLayout="column" fxLayoutAlign="center center">
    <p>{{ "results.no_data" | translate }}</p>
  </div>

  <ag-grid-angular class="ag-theme-material category-results" *ngIf="canShowGridForCurrentCategory()"
    [gridOptions]="gridOptions" [rowData]="currentRowData">
  </ag-grid-angular>

  <div class="results-group-step" joyrideStep="ResultsGroupStep" stepPosition="right"
    title="{{ 'results_guide_module.results_group_title' | translate }}"
    text="{{ 'results_guide_module.results_group_hint' | translate }}"></div>

  <div *ngIf="noResultsAvailableForCurrentCategory()" fxFlex fxLayout="column" fxLayoutAlign="center center">
    <p>{{ "results.no_report_available_for_category" | translate }}</p>
  </div>
</section>
<div class="results-guide tooltip-guide" matTooltip="{{ 'on_boarding_module.tooltip_info' | translate }}"
  (click)="startOnboardingNavigation()">
  <mat-icon>help_outline</mat-icon>
  <div class="text-block">
    {{ "on_boarding_module.repeat_guide" | translate }}
  </div>
</div>

<app-group-detail-result-legenda *ngIf="currentCategory" [title]="'GROUP_PROGRESS' | translate"
  joyrideStep="GroupProgressStep" stepPosition="right"
  title="{{ 'results_guide_module.group_progress_title' | translate }}"
  text="{{ 'results_guide_module.group_progress_hint' | translate }}"></app-group-detail-result-legenda>

<script src="https://kit.fontawesome.com/99fe3c75dd.js" crossorigin="anonymous"></script>
