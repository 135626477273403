<div class="license-contacts__container">

  <div class="license-contacts__header">
    <button type="button" class="close-button" mat-icon-button mat-dialog-close>
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>
  </div>

  <div class="license-contacts__content">
    <h2 class="license-contacts__title">
      {{'license_module.contact_button_label' | translate}}
    </h2>

    <p class="license-contacts__text">
      {{'license_module.contact_text' | translate}}:
      <br />

      <a class="license-contacts__link" href="mailto:support@playitsafe.eu">
        support@playitsafe.eu
      </a>

      or +32 (0) 56 59 12 88
    </p>
  </div>

</div>
