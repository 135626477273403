import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpMessageService} from '../../infrastructure/http/message/httpmessage.service';
import {Group} from '../../domain/group';
import {NotificationService} from '../../application/notification/notification.service';
import { DataService } from '../../infrastructure/dataservice';

@Component({
	selector: 'app-send-message-dialog',
	templateUrl: './send-message.component.html',
	styleUrls: ['./send-message.component.scss']
})
export class SendMessageDialogComponent {
	private httpMessageService: HttpMessageService;
	private dialogRef: MatDialogRef<SendMessageDialogComponent>;
	public messageForm: FormGroup;
	public group: Group;
	private notification: NotificationService;
	private sendPerforming = false;

	constructor(httpMessageService: HttpMessageService, dialogRef: MatDialogRef<SendMessageDialogComponent>,
				@Inject(MAT_DIALOG_DATA) data, notification: NotificationService, private dataService: DataService) {
		this.httpMessageService = httpMessageService;
		this.dialogRef = dialogRef;
		this.messageForm = new FormGroup({
			subject: new FormControl('', Validators.required),
			content: new FormControl('', Validators.required)
		});
		this.group = data.group;
		this.notification = notification;
	}

	public sendMessage(form: any): void {
		this.sendPerforming = true;
		this.httpMessageService.sendMessageToGroup(this.dataService.organizationID, this.group? this.group.id : null, form.subject, form.content).subscribe(() => {
			this.notification.show('YOUR MESSAGE WILL BE SENT SHORTLY');
			this.dialogRef.close();
		});
	}

	public keyDownFunction(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			if (this.messageForm.valid) {
				this.sendMessage(this.messageForm.value);
			}
		}
	}

	public canSendMessage(): boolean {
		return this.messageForm.valid && !this.sendPerforming;
	}
}
