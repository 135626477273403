import { LearningPathsPageModule } from './pages/learning-paths-page/learning-paths-page.module';
import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { RouterModule, Routes } from "@angular/router";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { CookieModule } from 'ngx-cookie';
import { FileSaverModule } from 'ngx-filesaver';
import { GlobalErrorHandler } from './application/error/global-error-handler';
import { NotificationService } from './application/notification/notification.service';
import { PointReplacerPipe } from './application/point-replacer/point-replacer.pipe';
import { CanActivateViaAuthGuard } from './application/security/can-activate-via-auth-guard';
import { createTranslateLoader } from './application/translation/createTranslateLoader';
import { ValidatorGroupNameDirective } from './application/validators/group-name-validation';
import { LoggedInOrganizationService } from './infrastructure/helper/logged-in-organization.service';
import { PersistentStoreService } from './infrastructure/helper/persistentstore.service';
import { StoreService } from './infrastructure/helper/store.service';
import { AuthenticationService } from './infrastructure/http/authentication/authentication.service';
import { HttpCategoryService } from './infrastructure/http/category/httpcategory.service';
import { HttpScormService } from './infrastructure/http/scorm/httpscorm.service';
import { HttpCourseService } from './infrastructure/http/course/httpcourse.service';
import { HttpGameReportService } from './infrastructure/http/gamereport/httpgamereport.service';
import { HttpGroupsService } from './infrastructure/http/group/httpgroups.service';
import { HttpLanguageService } from './infrastructure/http/language/httplanguage.service';
import { HttpMessageService } from './infrastructure/http/message/httpmessage.service';
import { HttpOrganizationService } from './infrastructure/http/organization/httporganization.service';
import { HttpReminderService } from './infrastructure/http/reminder/httpreminder.service';
import { HttpReportsService } from './infrastructure/http/report/httpreports.service';
import { HttpUserService } from './infrastructure/http/user/httpuser.service';
import { HttpAssetService } from './infrastructure/http/asset/httpasset.service';
import { InterceptedHttp } from './infrastructure/intercepted.http';
import { NavigationService } from './infrastructure/navigation/navigation.service';
import { MaterialModule } from './material.module';
import { AppComponent } from './app.component';
import { ConfirmationDialogComponent } from './presentation/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from './presentation/information-dialog/information-dialog.component';
import { DashboardComponent } from './presentation/pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './presentation/pages/forgot-password/forgot-password.component';
import { NavigationComponent } from './presentation/pages/navigation/navigation.component';
import { ReportsDetailComponent } from './presentation/pages/reports/detail/reports-detail.component';
import { ReportsComponent } from './presentation/pages/reports/reports.component';
import { ResetPasswordComponent } from './presentation/pages/reset-password/reset-password.component';
import { GroupCourseResultComponent } from './presentation/pages/results/group-course-result/group-course-result.component';
import { GroupDetailResultComponent } from './presentation/pages/results/group-detail-result/group-detail-result.component';
import { GroupNameResultComponent } from './presentation/pages/results/group-name/group-name-result.component';
import { HeaderCourseNameComponent } from './presentation/pages/results/header-course-name/header-course-name.component';
import { ResultsComponent } from './presentation/pages/results/results.component';
import { UserCourseResultComponent } from './presentation/pages/results/user-course/user-course-result.component/user-course-result.component';
import { SupportComponent } from './presentation/pages/support/support.component';
import { SendReminderDialogComponent } from './presentation/reminder/send-reminder-dialog.component';
import { SendMessageDialogComponent } from './presentation/send-message/send-message.component';
import { SwitchOrganizationDialogComponent } from './presentation/switch-organization-dialog/switch-organization-dialog.component';
import { UpgradeFeatureComponent } from './presentation/upgrade-feature/upgrade-feature.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { GroupDetailResultLegendaComponent } from './presentation/pages/results/group-detail-result/group-detail-result-legenda/group-detail-result-legenda.component';
import { DataService } from './infrastructure/dataservice';
import { LoggedInUserService } from './infrastructure/helper/logged-in-user.service';
import { } from './presentation/pages/courses/course-detail/course-detail.component';
import { HttpOnboardingService } from './infrastructure/http/onboarding/http-onboarding.service';
import { OnboardingService } from './infrastructure/http/onboarding/onboarding.service';
import { JoyrideModule } from 'ngx-joyride';
import { CarouselComponent } from './presentation/slider/carousel.component';
import { CarouselItemDirective } from './presentation/slider/carousel-item.directive';
import { CarouselItemElementDirective } from './presentation/slider/carousel-item-element.directive';
import { DashboardGuideDialogComponent } from './presentation/pages/dashboard/dashboard-guide-dialog/dashboard-guide-dialog.component';
import { GameLinkDialogComponent } from './presentation/game-link-dialog/game-link-dialog.component';
import { NotificationsButtonComponent } from './presentation/notifications/notifications-button/notifications-button.component';
import { NotificationsButtonScormComponent } from './presentation/notifications/notifications-button-scorm/notifications-button-scorm.component';
import { NotificationsPopoverComponent } from './presentation/notifications/notifications-popover/notifications-popover.component';
import { LicenseManagementComponent } from './presentation/pages/license-management/license-management.component';
import { LicenseStatsComponent } from './presentation/pages/license-management/components/license-stats/license-stats.component';
import { LicenseBlocksTableComponent } from './presentation/pages/license-management/components/license-blocks-table/license-blocks-table.component';
import { LicenseContactsModalComponent } from './presentation/pages/license-management/components/license-contacts/license-contacts.component';
import { LicenseUsersModalComponent } from './presentation/pages/license-management/components/license-users-modal/license-users-modal.component';
import { LicenseUsersTableComponent } from './presentation/pages/license-management/components/license-users-table/license-users-table.component';
import { LicenseOverviewComponent } from './presentation/pages/license-overview/license-overview.component';
import { LicenseOverviewTableComponent } from './presentation/pages/license-overview/components/license-overview-table/license-overview-table.component';
import { LicenseRenewModalComponent } from './presentation/pages/license-overview/components/license-renew-modal/license-renew-modal.component';
import { LicenseConfirmationDialog } from './presentation/pages/license-overview/components/license-confirmation-dialog/license-confirmation-dialog';
import { InfoDialogComponent } from './presentation/info-dialog/info-dialog';
import { ExportResultsDialogComponent } from './presentation/pages/results/export-results-dialog/export-results-dialog.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LicenseBlockCreateModal } from './presentation/pages/license-overview/components/license-block-create-modal/license-block-create-modal.component';
import { NgSelectModule } from '@ng-select/ng-select'
import { LicenseGuideModalComponent } from './presentation/pages/license-management/components/license-guide-modal/license-guide-modal.component';
import { AppRoutingModule } from './app-routing.module';
import { UsersModule } from './presentation/pages/users/users.module';
import { UsersModule as UsersFeatureModule } from './features/users/users.module';
import { CoursesModule } from './presentation/pages/courses/courses.module';
import { ButtonModule } from './shared/app-button/app-button.module';
import { CourseInfoService } from './infrastructure/course-info/course-info.service';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { LayoutNavService } from './layout.navigation.service';
import { NotificationsScormPopoverComponent } from './presentation/notifications/notifications-scorm-popover/notifications-scorm-popover.component';
import { SharedModule } from "app/shared/shared.module";

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    GroupNameResultComponent,
    GroupDetailResultComponent,
    ConfirmationDialogComponent,
    InformationDialogComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    SupportComponent,
    LicenseManagementComponent,
    LicenseStatsComponent,
    LicenseBlocksTableComponent,
    LicenseContactsModalComponent,
    LicenseUsersModalComponent,
    LicenseUsersTableComponent,
    LicenseOverviewComponent,
    LicenseOverviewTableComponent,
    LicenseRenewModalComponent,
    LicenseConfirmationDialog,
    ResultsComponent,
    GroupCourseResultComponent,
    UserCourseResultComponent,
    SendMessageDialogComponent,
    CarouselComponent,
    SendReminderDialogComponent,
    SwitchOrganizationDialogComponent,
    ValidatorGroupNameDirective,
    PointReplacerPipe,
    HeaderCourseNameComponent,
    DashboardComponent,
    UpgradeFeatureComponent,
    ReportsComponent,
    ReportsDetailComponent,
    GroupDetailResultLegendaComponent,
    CarouselItemDirective,
    CarouselItemElementDirective,
    DashboardGuideDialogComponent,
    GameLinkDialogComponent,
    InfoDialogComponent,
    NotificationsButtonComponent,
    NotificationsButtonScormComponent,
    NotificationsPopoverComponent,
    NotificationsScormPopoverComponent,
    ExportResultsDialogComponent,
    LicenseBlockCreateModal,
    LicenseGuideModalComponent,
  ],
  imports: [
    PowerBIEmbedModule,
    BrowserModule,
    TranslateModule.forRoot(
      {
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CommonModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    UsersModule,
    UsersFeatureModule,
    CoursesModule,
    BrowserAnimationsModule,
    MaterialModule,
    JoyrideModule.forRoot(),
    // RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    AgGridModule.withComponents([UserCourseResultComponent]),
    FlexLayoutModule,
    VirtualScrollerModule,
    ReactiveFormsModule,
    CookieModule.forRoot(),
    FileSaverModule,
    NgxPowerBiModule,
    NgxDaterangepickerMd.forRoot(),
    NgSelectModule,
    ButtonModule,
    LearningPathsPageModule,
    SharedModule,
  ],
  providers: [
    NavigationService,
    MatIconRegistry,
    HttpUserService,
    HttpGroupsService,
    HttpCourseService,
    HttpCategoryService,
    HttpScormService,
    HttpGameReportService,
    HttpMessageService,
    HttpOrganizationService,
    HttpReminderService,
    HttpLanguageService,
    HttpReportsService,
    HttpOnboardingService,
    HttpAssetService,
    OnboardingService,
    StoreService,
    PersistentStoreService,
    DataService,
    NotificationService,
    AuthenticationService,
    CanActivateViaAuthGuard,
    LoggedInUserService,
    LoggedInOrganizationService,
    CourseInfoService,
    LayoutNavService,
    PointReplacerPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptedHttp,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
