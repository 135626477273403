import {DatePipe} from '@angular/common';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import {NotificationService} from '../../../../../application/notification/notification.service';
import {ImportUsersSpeadsheetValidationResult} from '../../../../../domain/import-users-spreadsheet-validation-result';
import {LoggedInOrganizationService} from '../../../../../infrastructure/helper/logged-in-organization.service';
import {HttpOrganizationService} from '../../../../../infrastructure/http/organization/httporganization.service';
import { TranslateService } from '@ngx-translate/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
    templateUrl: './import-users.component.html',
    styleUrls: ['./import-users.component.scss']
})
export class ImportUsersComponent implements OnInit {
    @ViewChild('fileUpload') public fileUpload: ElementRef;
    public importAction = 'skip'
    private _validationResults: ImportUsersSpeadsheetValidationResult;
    private dialogRef: MatDialogRef<ImportUsersComponent>;
    private organizationService: HttpOrganizationService;
    private notificationService: NotificationService;
    private _selectedFile: File;
    private _isImporting = false;
    private _isValidating = false;
    private _isImported = false;
    private loggedInOrganizationService: LoggedInOrganizationService;
    private _organizationId: string;
    private _templateLocation: string;


    public constructor(dialogRef: MatDialogRef<ImportUsersComponent>,
                       organizationService: HttpOrganizationService,
                       notificationService: NotificationService,
                       loggedInOrganizationService: LoggedInOrganizationService, private translate: TranslateService) {
        this.dialogRef = dialogRef;
        this.organizationService = organizationService;
        this.notificationService = notificationService;
        this.loggedInOrganizationService = loggedInOrganizationService;
    }

    public async ngOnInit(): Promise<void> {
        this._organizationId = this.loggedInOrganizationService.getLoggedInOrganizationId();
        this._templateLocation = await this.organizationService
        .getTemplateUrlForImportingUsersForOrganization(this._organizationId)
        .toPromise();
    }

    public get downloadFileName(): string {
      const dateString = new DatePipe('en-US').transform(Date.now(), 'dd/MM/yyyy');
      const fileName = 'SpreadSheetTemplate-' + dateString + '.xlsx';
      return fileName;
    }

    public get templateLocation(): string {
      return this._templateLocation;
    }

    public downloadTemplateFile(): void {
        this.organizationService.getTemplateUrlForImportingUsersForOrganization(this._organizationId).subscribe((url: string) => {

            const dateString = new DatePipe('en-US').transform(Date.now(), 'dd/MM/yyyy');
            const fileName = 'SpreadSheetTemplate-' + dateString + '.xlsx';

            const htmlAnchorElement = document.createElement('a');
            document.body.appendChild(htmlAnchorElement);
            htmlAnchorElement.setAttribute('style', 'display: none');
            htmlAnchorElement.href = url;
            htmlAnchorElement.download = fileName;
            htmlAnchorElement.click();
            window.URL.revokeObjectURL(url);
            htmlAnchorElement.remove();
        });

    }

    public selectFile(event): void {
        const files: FileList = event.currentTarget.files;
        if (_.size(files) === 1) {
            this._selectedFile = _.first(files);
        }
    }

    public validateFile(stepper: MatStepper): void {

        this._isImporting = false;
        this._isValidating = true;

        if (this.isValidating) {
           const formData: FormData = new FormData();
            formData.append('file', this._selectedFile, this._selectedFile.name);
            const includeExistingUser = (this.importAction === 'skip') ? false : true;

            this.organizationService.validateImportSpreadSheet(this._organizationId, formData, includeExistingUser)
                .subscribe((spreadsheetValidationForImportingUsers: ImportUsersSpeadsheetValidationResult) => {
                        console.log(spreadsheetValidationForImportingUsers);
                        this._isValidating = false;
                        this._validationResults = spreadsheetValidationForImportingUsers;
                        stepper.next();
                    }
                );
        }
    }

    public importFile(): void {
        this._isImporting = true;

        const expireResults = (this.importAction === 'expire') ? true : false;

        const includeExistingUser = (this.importAction === 'skip') ? false : true;

        const formData: FormData = new FormData();
        formData.append('file', this._selectedFile, this._selectedFile.name);


        this.organizationService.importUsers(this._organizationId, formData, expireResults, includeExistingUser)
            .subscribe(() => {
                this._isImported = true;
            });
    }

    public close(): void {
        if (this._isImported) {
            this.dialogRef.close(true);
        } else {
            this.dialogRef.close(false);
        }
    }


    public get filename(): string {
        return (!_.isNil(this._selectedFile)) ? this._selectedFile.name : this.translate.instant('user_import.no_spreadsheet_selected');
    }



    public get isSelected(): boolean {
        return !_.isNil(this._selectedFile);
    }

    public get isValidating(): boolean {
        return this._isValidating;
    }

    public get isValidated(): boolean {
        return !_.isNil(this._validationResults);
    }

    public get isValid(): boolean {
        return this.isValidated && this._validationResults.isValid;
    }

    public get isImporting(): boolean {
        return this._isImporting;
    }

    public get isImported(): boolean {
        return this._isImported;
    }

    public get validationResults() {
        return this._validationResults;
    }

    public get warnings(): string[] {
        return this._validationResults.warnings || [];
    }

    public get hasWarnings(): boolean {
        return !_.isEmpty(this._validationResults.warnings);
    }

    public get errors(): string[] {
        return this._validationResults.errors;
    }

    public get hasErrors(): boolean {
        return !_.isEmpty(this._validationResults.errors);
    }


    public get numberOfUsers(): number {
        return this._validationResults.numberOfUsers;
    }

    public get numberOfNewUsers(): number {
        return this._validationResults.numberOfNewUsers;
    }

    public get numberOfExistingUsers(): number {
        return this._validationResults.numberOfExistingUsers;
    }

    public get numberOfLicences(): number {
        return this._validationResults.numberOfLicences;
    }

    public get numberOfExpiredLicences(): number {
        return this._validationResults.numberOfExpiredLicences;
    }

    public get numberOfNewLicences(): number {
        return this._validationResults.numberOfNewLicences;
    }
}
