import { Injectable } from "@angular/core";
@Injectable()
export class StoreService {
    private ram = {};

    public get(key: string) {
        return this.ram[key] || false;
    }

    public set(key: string, value: string) {
        this.ram[key] = value;
    }

    public remove(key: string) {
        delete this.ram[key];
    }

    public getAll() {
        return this.ram;
    }
}
