import { Component } from '@angular/core';

export interface IPage {
	name: string
	icon: string
	path: string
	component?: any
	data?: any
	isDefault?: boolean
	showInMenu: boolean
	securedPage?: boolean
}
export class Page {
	private _name: string;
	private _icon: string;
	private _path: string;
	private _component: any;
	private _data: any;
	private _isDefault: boolean;
	private _showInMenu: boolean;
	private _securedPage: boolean;

	constructor({
		name,
		icon,
		path,
		component,
		data,
		isDefault,
		showInMenu,
		securedPage
	}: IPage) {
		this._name = name;
		this._icon = icon;
		this._path = path;
		this._component = component;
		this._data = data;
		this._isDefault = isDefault;
		this._showInMenu = showInMenu;
		this._securedPage = securedPage;
	}

	get name(): string {
		return this._name;
	}

	get icon(): string {
		return this._icon;
	}

	get path(): string {
		return this._path;
	}

	get component(): any {
		return this._component;
	}

	get data(): any {
		return this._data;
	}

	get isDefault(): boolean {
		return this._isDefault;
	}

	get showInMenu(): boolean {
		return this._showInMenu;
	}

	get securedPage(): boolean {
		return this._securedPage;
	}
}
