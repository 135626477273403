import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Category } from "app/domain/category";
import { Course } from "../../../../domain/course";
import { CourseDetailComponent } from "../../../../presentation/pages/courses/course-detail/course-detail.component";
import { AvailableCoursesDialogComponent } from "../available-courses-dialog/available-courses-dialog.component";

@Component({
  selector: 'app-available-courses-list',
  templateUrl: './available-courses-list.component.html',
  styleUrls: ['./available-courses-list.component.scss']
})
export class AvailableCoursesListComponent {
  @Input() category: Category
  @Input() isExpirationSettingsEnabled: boolean

  constructor(private dialog: MatDialog) {}

  openDialog(course: Course) {
    const data: {
      course: Course
      isExpirationCourseSettingsEnabled: boolean
    } = {
      course,
      isExpirationCourseSettingsEnabled: this.isExpirationSettingsEnabled
    }

		this.dialog.open(CourseDetailComponent,  {     height: '675px',
    width: '1029px',data: data });
  }

  editCourse(course: Course){
    const data: {
      course: Course
      isExpirationSettingsEnabled: boolean
    } = {
      course,
      isExpirationSettingsEnabled: this.isExpirationSettingsEnabled
    }

		this.dialog.open(AvailableCoursesDialogComponent,  {     height: '675px',
    width: '1029px',data: data });
  }
}
