import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ILearningModuleCreated,
  ILearningModuleCreationData,
} from "app/application/interfaces/learning-paths.interface";
import { DataService } from "app/infrastructure/dataservice";
import { delay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LearningModulesService {
  headers = new HttpHeaders({ apiVersion: "version2" });

  constructor(private http: HttpClient, private dataService: DataService) {}

  createLearningModule(
    learningPathId: string,
    data: ILearningModuleCreationData
  ) {
    const { organizationID } = this.dataService;
    return this.http.post<ILearningModuleCreated>(
      `/v2/Organizations/${organizationID}/LearningPaths/${learningPathId}/LearningModules`,
      data,
      { headers: this.headers }
    );
  }

  copyLearningModule(
    learningPathId: string,
    moduleId: string,
    newWorkingName: string
  ) {
    const { organizationID } = this.dataService;
    return this.http.post(
      `/v2/Organizations/${organizationID}/LearningPaths/${learningPathId}/LearningModules/${moduleId}/Copy?workingName=${newWorkingName}`,
      {},
      { headers: this.headers }
    ).pipe(delay(750));
  }

  updateLearningModule(
    learningPathId: string,
    moduleId: string,
    data: ILearningModuleCreated
  ) {
    const { organizationID } = this.dataService;
    return this.http.put<ILearningModuleCreated>(
      `/v2/Organizations/${organizationID}/LearningPaths/${learningPathId}/LearningModules/${moduleId}`,
      data,
      { headers: this.headers }
    );
  }

  getLearningModuleDetails(learningPathId: string, moduleId: string) {
    const { organizationID } = this.dataService;
    return this.http.get(
      `/v2/Organizations/${organizationID}/LearningPaths/${learningPathId}/LearningModules/${moduleId}`,
      { headers: this.headers }
    );
  }

  deleteLearningModule(learningPathId: string, moduleId: string) {
    const { organizationID } = this.dataService;
    return this.http.delete(
      `/v2/Organizations/${organizationID}/LearningPaths/${learningPathId}/LearningModules/${moduleId}`,
      { headers: this.headers }
    ).pipe(delay(750));
  }

  updateOrder(learningPathId: string, orderedIds: string[]){
    const{organizationID} = this.dataService;
    return this.http.post(  `/v2/Organizations/${organizationID}/LearningPaths/${learningPathId}/LearningModules/Order`,
    orderedIds,
    { headers: this.headers })
  }
}
