import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { MaterialModule } from 'app/material.module';

import { ButtonModule } from 'app/shared/app-button/app-button.module';
import { LearningPathHeaderComponent } from './components/learning-path-header/learning-path-header.component';
import { LearningPathListComponent } from './components/learning-path-list/learning-path-list.component';
import { LearningPathCreateDialogComponent } from './components/learning-path-create-dialog/learning-path-create-dialog.component';
import { LearningPathItemComponent } from './components/learning-path-item/learning-path-item.component';
import { LearningPathDeleteDialogComponent } from './components/learning-path-delete-dialog/learning-path-delete-dialog.component';
import { LearningModuleItemComponent } from './components/learning-module-item/learning-module-item.component';
import { LearningModuleListComponent } from './components/learning-module-list/learning-module-list.component';
import { LearningModuleCreateDialogComponent } from './components/learning-module-create-dialog/learning-module-create-dialog.component';
import { LearningModuleFormComponent } from './forms/learning-module-form/learning-module-form.component';
import { LearningModuleDeleteDialogComponent } from './components/learning-module-delete-dialog/learning-module-delete-dialog.component';
import { LearningPathFormComponent } from './forms/learning-path-form/learning-path-form.component';
import { LearningPathComponent } from './learning-path.component';
import { LearningModuleTranslationsComponent } from './forms/learning-module-translations/learning-module-translations.component';
import { LearningPathNamesComponent } from './forms/learning-path-names/learning-path-names.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    NgSelectModule,
    TranslateModule
  ],
  declarations: [
    LearningPathComponent,
    LearningPathHeaderComponent,
    LearningPathListComponent,
    LearningPathCreateDialogComponent,
    LearningPathFormComponent,
    LearningPathItemComponent,
    LearningPathDeleteDialogComponent,
    LearningModuleItemComponent,
    LearningModuleListComponent,
    LearningModuleCreateDialogComponent,
    LearningModuleFormComponent,
    LearningModuleDeleteDialogComponent,
    LearningModuleTranslationsComponent,
    LearningPathNamesComponent,
  ],
  exports: [LearningPathComponent]
})
export class LearningPathModule {}
