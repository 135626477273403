import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from '@angular/material/stepper';
import { ILearningPath, ILearningPathData } from "app/application/interfaces/learning-paths.interface";
import { noEmptySpaceValidator } from "app/application/validators/empty-space.validator";
import { Asset } from "app/domain/asset";
import { HttpCategoryService } from "app/infrastructure/http/category/httpcategory.service";
import { uniqueLanguageValue } from "../../learning-path.validators";

@Component({
  selector: 'app-learning-path-form',
  templateUrl: './learning-path-form.component.html',
  styleUrls: ['./learning-path-form.component.scss']
})
export class LearningPathFormComponent implements OnInit {
  @Input() isFormLoading = false
  @Input() learningPathData: ILearningPath
  @Input() actionButtonTemplate: TemplateRef<any>;
  @Input() editMode = false
  @Input() mode = 0
  @Output() onSubmit: EventEmitter<any> = new EventEmitter()
  @Output() currentStepChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() isWorkingNameFilledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isNamesControlsFilledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('stepper') stepper: MatStepper;

  currentStep = 0
  isFormDisabled = false
  learningPathForm: FormGroup
  iconOptions: Asset[];
  isWorkingNameFilled = false;
  isNamesControlsFilled = false;

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  get names(): FormArray {
    return this.learningPathForm.get('names') as FormArray
  }

  constructor(private _formBuilder: FormBuilder, private categoryService: HttpCategoryService) {
    const workingNameControl = new FormControl('', [
      Validators.required,
      noEmptySpaceValidator
    ])
    const iconIdControl = new FormControl('')

    this.learningPathForm = new FormGroup({
      workingName: workingNameControl,
      iconId: iconIdControl,
      names: new FormArray([
        new FormGroup({
          languageId: new FormControl(null, [Validators.required]),
          value: new FormControl('', Validators.required)
        })
      ], uniqueLanguageValue)
    })
  }

  ngOnInit(): void {

    this.categoryAssets$.subscribe(assets => {
      var assets2 = assets;
    });

    if (this.editMode) {
      this.initForm();
    }

    this.learningPathForm.get('workingName').valueChanges.subscribe(() => {
      this.isWorkingNameFilled = this.learningPathForm.get('workingName').value.trim() !== '';
      this.isWorkingNameFilledChange.emit(this.isWorkingNameFilled);
    });

    this.names.valueChanges.subscribe(() => {
      this.isNamesControlsFilled = this.names.controls.length > 0 && this.names.valid;
      this.isNamesControlsFilledChange.emit(this.isNamesControlsFilled);
    });
  }

  onStepChange(stepper: MatStepper) {
    this.currentStep = stepper.selectedIndex;
    this.currentStepChange.emit(this.currentStep);
  }

  setNamesForm() {
    const languageGroup = new FormGroup({
      languageId: new FormControl(null, [Validators.required]),
      value: new FormControl('', Validators.required)
    });

    this.names.push(languageGroup)
    this.learningPathForm.updateValueAndValidity()
  }

  removeNameForm(index: number) {
    this.names.removeAt(index)
  }

  initForm() {
    const {
      workingName: workingNameControl,
      iconId: iconIdControl
    } = this.learningPathForm.controls

    const {
      workingName,
      iconId,
      names
    } = this.learningPathData

    workingNameControl.setValue(workingName)
    iconIdControl.setValue(iconId)

    names.forEach(({ languageId, value }, index) => {
      if (index >= 1) {
        this.setNamesForm()
      }
      this.names.controls[index].setValue({ languageId, value });
    })

    this.formDisable()
  }

  get categoryAssets$() {
    return this.categoryService.getCategoryIcons();
  }

  formDisable() {
    this.isFormDisabled = true
    this.learningPathForm.disable()
  }

  formEnable() {
    this.isFormDisabled = false
    this.learningPathForm.enable()
  }

  submitLearningPathForm() {
    if (this.isFormLoading) {
      return
    }

    this.learningPathForm.markAllAsTouched()

    if (this.learningPathForm.invalid) {
      return
    }

    const { workingName, ...formValue } = this.learningPathForm.value as ILearningPath

    const data: ILearningPathData = {
      ...formValue,
      workingName: workingName.trim(),
      isEnabled: true,
    }

    this.onSubmit.emit(data)
  }
}
