import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Course } from "app/domain/course";

@Component({
  selector: "app-available-courses-dialog",
  templateUrl: "./available-courses-dialog.component.html",
  styleUrls: ["./available-courses-dialog.component.scss"],
})
export class AvailableCoursesDialogComponent {
  courseId = ''
  course: Course
  isExpirationSettingsEnabled = false

  constructor(@Inject(MAT_DIALOG_DATA) data: {
    course: Course
    isExpirationSettingsEnabled: boolean
  }) {
    this.course = data.course
    this.courseId = this.course.id
    this.isExpirationSettingsEnabled = data.isExpirationSettingsEnabled
  }
}
