export class Reminder {
	private _id: string;
	private _dateTimeSentUTC: Date;
	private _amountOfUsersNotified: number;

	constructor(id: string, dateTimeSentUTC: Date, amountOfUsersNotified: number) {
		this._id = id;
		this._dateTimeSentUTC = dateTimeSentUTC;
		this._amountOfUsersNotified = amountOfUsersNotified;
	}


	get id(): string {
		return this._id;
	}

	get dateTimeSentUTC(): Date {
		return this._dateTimeSentUTC;
	}

	get amountOfUsersNotified(): number {
		return this._amountOfUsersNotified;
	}
}
