<h3 class="license-table__title">Licenses view</h3>

<div class="license-table__wrapper">

  <div class="license-table__controls">
    <mat-form-field class="license-table__search">
      <mat-label>Search license by type, company, date, status</mat-label>
      <input matInput autocomplete="off" [ngModel]="searchValue" (ngModelChange)="changeHandler($event)">
      <mat-icon class="search-icon" matPrefix>search</mat-icon>
      <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearHandle()">
        <mat-icon class="icon-clear">close</mat-icon>
      </button>
    </mat-form-field>

    <button type="button" mat-flat-button color="primary" (click)="openCreateBlockModal()">
      Create License Block
    </button>
  </div>

  <div class="license-table__container">
    <div class="loading-shade" *ngIf="isDataLoading || !isDataRequested">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <table mat-table [dataSource]="tableData" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>company</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="licenseType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>license type</th>
        <td mat-cell *matCellDef="let element"> {{element.licenseType}} </td>
      </ng-container>

      <ng-container matColumnDef="licensesCap">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>number of licenses in blocks</th>
        <td mat-cell *matCellDef="let element"> {{element.licensesCap}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>status</th>
        <td mat-cell *matCellDef="let element">
          <div [class]="element.status | lowercase">
            {{element.status}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>purchase date</th>
        <td mat-cell *matCellDef="let element">
          {{element.creationDate | date:'dd.MM.yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="expirationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>expiration date</th>
        <td mat-cell *matCellDef="let element">
          {{element.expirationDate ? (element.expirationDate | date:'dd.MM.yyyy') : '-'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          No records found.
        </mat-footer-cell>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openRenewModal(row)"
        [ngClass]="{ expiration: row.expirationSoon, unlicensed: row.isUnlicensed }"
        [title]="row.isUnlicensed ? 'Unlicensed' : ''"></tr>

      <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="!isDataRequested || tableData.length"></tr>

    </table>

    <mat-paginator class="mat-paginator-sticky" [length]="paginationData?.totalCount || 0" [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
