import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import * as _ from "lodash";

@Component({
  selector: "app-user-course-result",
  templateUrl: "./user-course-result.component.html",
  styleUrls: ["./user-course-result.component.scss"],
})
export class UserCourseResultComponent implements ICellRendererAngularComp {
  public user: any;
  public courseResults: any;

  public agInit(params: any): void {
    const courseId = params.courseId;
    this.user = params.data;
    this.courseResults = _.first(
      _.filter(this.user.courses, (course: any) => {
        return _.isEqual(course.courseId, courseId);
      })
    );
  }

  public refresh(params: any): boolean {
    return false;
  }

  public getProgress(): number {
    return this.courseResults.progress;
  }

  public isCourseAvailable(): boolean {
    return this.courseResults;
  }

  public canShowStarsForProgress(): boolean {
    return _.isEqual(this.courseResults.progress, 2);
  }

  public getIconNameForProgress(): string {
    let result = "user-progress-";

    switch (this.courseResults.progress) {
      case 0:
        result += "not-started";
        break;
      case 1:
        result += "in-progress";
        break;
      case 2:
        switch (this.courseResults.stars) {
          case 1:
            result += "completed-bronze";
            break;
          case 2:
            result += "completed-silver";
            break;
          case 3:
            result += "completed-gold";
            break;
        }
        break;
      case 30:
        result += "expired";
        break;
    }

    return result;
  }
}
