import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { map, switchMap} from "rxjs/operators";
import { ICourseInfo } from "../../application/interfaces/courses.interface";
import { PlayEnvironment } from "../../domain/playEnvironment";
import { DataService } from "../dataservice";
import { HttpCourseService } from "../http/course/httpcourse.service";
import { HttpOrganizationService } from "../http/organization/httporganization.service";

@Injectable()
export class CourseInfoService
{
    constructor(
        private dataService: DataService,
        private courseService: HttpCourseService,
        private organizationService: HttpOrganizationService,
    ){}

    getCourseInfo(courseId): Observable<ICourseInfo> {
        let getPlayEnvironments = this.organizationService.getAvailablePlayEnvironments(this.dataService.organizationID, this.dataService.language);
        let getCourseInfo = this.courseService.getCourseInfo(courseId);

        console.log(this.dataService.language);

        return getCourseInfo.pipe(switchMap((courseInfo: ICourseInfo)=>{
            return getPlayEnvironments.pipe(map((playEnvironments: PlayEnvironment[])=>{
                let translation  = _.find(courseInfo.translations, (t)=> { return t.languageId == this.dataService.language})
                
                if(!translation && this.dataService.language.includes("-")) {
                    let firstFallback =  this.dataService.language.split("-")[0];
                    translation  = _.find(courseInfo.translations, (t)=> { return t.languageId == firstFallback});
                }

                if(!translation) {
                    let secondFallback =  'en';
                    translation  = _.find(courseInfo.translations, (t)=> { return t.languageId == secondFallback });
                }

                courseInfo.description = translation?.description ?? "";
                playEnvironments =  _.sortBy(_.filter(playEnvironments, (playEnvironment: PlayEnvironment) => {
                    return _.includes(courseInfo.environments, playEnvironment.id);

                }), (playEnvironment: PlayEnvironment) => {

                    return _.lowerCase(playEnvironment.name);
                });
                courseInfo.playEnvironments = playEnvironments;
                return courseInfo;
            }))
        }));
    }
}
