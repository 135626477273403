import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IBlockParams, IOrganizationBlock, IStatistics } from "app/application/interfaces/licenses.interface";

@Injectable({ providedIn: 'root'})
export class LicenceOverviewHttpService {

  headers = new HttpHeaders({ apiVersion: 'version2' });

  constructor(private http: HttpClient) {}

  getStatistics(): Observable<IStatistics> {
    const requestUrl = '/v2/Licenses/Statistics'
    return this.http.get<IStatistics>(requestUrl, { headers: this.headers })
  }

  getOrganizations(params: HttpParams): Observable<HttpResponse<IOrganizationBlock[]>> {
    const queryParams = params.toString()
    const requestUrl = `/v2/Licenses/Blocks?${queryParams}`

    return this.http.get<IOrganizationBlock[]>(requestUrl, { headers: this.headers,
    observe: 'response' })
  }

  searchOrganizations(query: string, params: HttpParams): Observable<HttpResponse<IOrganizationBlock[]>> {
    const queryParams = params.toString()
    const requestUrl = `/v2/Licenses/Blocks/search?${queryParams}`

    return this.http.post<IOrganizationBlock[]>(
      requestUrl, { search: query }, { headers: this.headers, observe: 'response' }
    )
  }

  createLicenseBlock(blockParams: IBlockParams) {
    const { organizationId, ...params } = blockParams

    return this.http.post(`/v2/Organizations/${organizationId}/Licenses/Blocks`, params, { headers: this.headers })
  }

  downloadLogs(organizationId: string): Observable<HttpResponse<Blob>> {
    const requestUrl = `/v2/Organizations/${organizationId}/Licenses/Logs/download`;
    return this.http.get<Blob>(requestUrl,
      {
        headers: this.headers,
        responseType: 'blob' as 'json',
        observe: 'response'
      }
    )
  }

  renewLicenseBlock(blockId: string) {
    const requestUrl = `/v2/Licenses/Blocks/${blockId}/Renew`
    return this.http.post(requestUrl, null, { headers: this.headers })
  }

  revokeLicenseBlock(blockId: string) {
    const requestUrl = `/v2/Licenses/Blocks/${blockId}/Revoke`
    return this.http.post(requestUrl, null, { headers: this.headers })
  }

  patchLicenseBlock(blockId: string, params) {
    const requestUrl = `/v2/Licenses/Blocks/${blockId}`
    return this.http.patch(requestUrl, [params], { headers: this.headers })
  }

  switchLicenseState(organizationId: string, state) {
    const requestUrl = `/v2/Organizations/${organizationId}/Licenses/Settings`
    return this.http.put(requestUrl, { isUnlicensed: state }, { headers: this.headers })
  }
}
