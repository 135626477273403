import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-license-confirmation-dialog',
  templateUrl: './license-confirmation-dialog.html',
  styleUrls: ['./license-confirmation-dialog.scss']
})
export class LicenseConfirmationDialog implements OnInit, OnDestroy {
  textContent: string

  keydownEvents$: Subscription

  constructor(
    private dialogRef: MatDialogRef<LicenseConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.textContent = this.data.textContent
    this.keydownEvents$ = this.keyPressed()
  }

  ngOnDestroy() {
    this.keydownEvents$.unsubscribe()
  }

  confirm() {
    this.dialogRef.close(true)
  }

  cancel() {
    this.dialogRef.close(false)
  }

  keyPressed() {
    return this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        this.confirm()
      }
    })
  }

}
