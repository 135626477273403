import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Group} from '../../../domain/group';
import {Messages} from './messages';

@Injectable()
export class HttpMessageService implements Messages {
    private httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    sendMessageToGroup(organizationId: string, groupId: string, subject: string, content: string): Observable<any> {
        let httpHeaders = new HttpHeaders().set('apiVersion', 'version2');
        return this.httpClient.post(`/Organizations/${organizationId}/Groups/${groupId}/messages`,
        {
            subject: subject,
            message: content
        }, {headers: httpHeaders});
    }
}
