<mat-list
  class="learning-module-list"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
>

  <ng-container *ngFor="let module of learningModulesList; let i = index">
    <mat-list-item
      class="learning-module-list__item"
      cdkDrag
    >
      <mat-icon
        class="drag-icon"
        svgIcon="drag"
        cdkDragHandle
      ></mat-icon>

      <app-learning-module-item
        [learningPath]="learningPath"
        [lockedByModuleList]="learningModulesList"
        [itemIndex]="i"
        [item]="module"
      ></app-learning-module-item>
    </mat-list-item>
  </ng-container>
</mat-list>
