<h2 mat-dialog-title>Create License Block</h2>
<button type="button" class="close-button" mat-icon-button mat-dialog-close>
  <mat-icon class="icon-clear">clear</mat-icon>
</button>

<div class="form-container">
  <form [formGroup]="createBlockForm" (ngSubmit)="createLicenseBlock()">

    <div class="form-controls">
      <div class="select-container">
        <ng-select class="custom" formControlName="organizationId" [items]="organizations"
          [loading]="isOrganizationsListLoading" bindLabel="name" bindValue="id" placeholder="Choose a company">
        </ng-select>

        <div
          *ngIf="createBlockForm.get('organizationId').hasError('required') && createBlockForm.get('organizationId').touched"
          class="error-message">
          Company is required
        </div>
      </div>

      <mat-form-field appearance="standard" class="select-type">
        <mat-label>Choose a license type</mat-label>
        <mat-select formControlName="licenseType">
          <mat-option *ngFor="let type of licenseTypes" [value]="type.id">
            {{type.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="createBlockForm.get('licenseType').hasError('required')">
          License type is required
        </mat-error>
      </mat-form-field>

      <div class="block-settings">
        <mat-form-field appearance="standard" class="input-cap">
          <mat-label>Number of licenses</mat-label>
          <input formControlName="licensesCap" matInput type="number" min="0" max="350000" autocomplete="off">
          <mat-error *ngIf="createBlockForm.get('licensesCap').hasError('required')">
            Number of licenses is required
          </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="enableAutoRenew" class="checkbox__autorenew" color="primary">
          Autorenew
        </mat-checkbox>
        <mat-checkbox formControlName="canExceedCap" class="checkbox__exceeding" color="primary">
          Exceeding limit cap
        </mat-checkbox>
        <mat-checkbox formControlName="hasNoExpiration" color="primary">
          Expiration date is binded to the users
        </mat-checkbox>
      </div>
    </div>

    <div class="form-actions">
      <button class="submit-button" type="submit" mat-flat-button color="primary">
        <mat-spinner *ngIf="isBlockCreating; else buttonLabel" [diameter]="20"></mat-spinner>
        <ng-template #buttonLabel>
          Create
        </ng-template>
      </button>
    </div>

  </form>
</div>
