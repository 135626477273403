<div fxFlex fxLayout="column">
  <header mat-dialog-title class="primary-color">
    <button class="close-button" mat-icon-button (click)="cancel()">
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>

    <h3>{{'update_password.dialog_title' | translate}}</h3>
  </header>
  <mat-dialog-content fxFlex fxLayout="column" fxLayoutAlign="center center" class="update-password-container">
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <div class="input-row">
        <mat-form-field class="full-width">
          <mat-error>{{'PASSWORD GUIDE' | translate}}</mat-error>
          <input matInput type="password" pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})" formControlName="newPassword" placeholder="{{'PASSWORD' | translate}}" />
        </mat-form-field>
      </div>
      <div class="input-row">
        <mat-form-field class="full-width">
          <mat-error *ngIf="passwordForm.hasError('passwordMismatch')">{{'REPEAT PASSWORD ERROR' | translate}}</mat-error>
          <input matInput type="password" formControlName="confirmPassword" placeholder="{{'REPEAT PASSWORD' | translate}}" />
        </mat-form-field>
      </div>
      <button type="submit" hidden></button>
    </form>
    <div *ngIf="passwordChangeError" class="success-message">
      {{'update_password.failed' | translate}}

    </div>
    <div *ngIf="passwordChanged" class="success-message">
      {{'PASSWORD SUCCESFULLY RESETTED' | translate}}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutGap="10px">
    <button fxFlex mat-button mat-dialog-close class="mat-secondary" *ngIf="!passwordChanged" (click)="cancel()">
      {{'CANCEL' | translate}}
    </button>
    <button fxFlex mat-flat-button color="primary" *ngIf="!passwordChanged" type="button" form="passwordForm"
      [disabled]="passwordForm.invalid" (click)="submitForm()">
      {{'update_password.submit_password' | translate}}
    </button>
    <button fxFlex mat-flat-button mat-dialog-close color="primary" *ngIf="passwordChanged">
      {{'CLOSE' | translate}}
    </button> 
  </mat-dialog-actions>
</div>
