import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'app/material.module';
import { CoursesGroupsModule } from 'app/features/courses-groups/courses-groups.module';

import { LearningPathsPageComponent } from './learning-paths-page.component';
import { LearningPathsPageRoutingModule } from './learning-paths-page-routing.module';
import { LearningPathModule } from 'app/features/learning-path/learning-path.module';
import { AvailableCoursesModule } from 'app/features/available-courses/available-courses.module';


@NgModule({
  declarations: [LearningPathsPageComponent],
  imports: [
    CommonModule,
    LearningPathsPageRoutingModule,
    MaterialModule,
    CoursesGroupsModule,
    LearningPathModule,
    AvailableCoursesModule,
    TranslateModule
  ]
})
export class LearningPathsPageModule { }
