import { AbstractControl, FormArray } from "@angular/forms";

// learning path form validator
export function uniqueLanguageValue(
  formArray: FormArray
): { [key: string]: string[] } | null {
  const valuesMap = {};

  formArray.controls.forEach((formGroup) => {
    const languageId = formGroup.get("languageId").value;
    if (languageId && valuesMap[languageId]) {
      valuesMap[languageId]++;
    }
    if (languageId && !valuesMap[languageId]) {
      valuesMap[languageId] = 1;
    }
  });

  const errorKeys = Object.entries(valuesMap).filter(([key, value]) => {
    return value > 1;
  });

  return errorKeys.length ? { uniqueLanguages: mapErrorKeys(errorKeys) } : null;
}

function mapErrorKeys(array: [string, unknown][]) {
  return array.map((item) => item[0]);
}

export function dateFormatValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  let month
  let day
  let year

  if (control.pristine) {
    return null;
  }

  if (
    control.value !== undefined &&
    control.value !== "" &&
    control.value !== null
  ) {
    let value = control.value

    if (value instanceof Date) {
      value = `${control.value.getMonth()}/${control.value.getDate()}/${control.value.getFullYear()}`
    }

    if (value.indexOf("/") !== -1) {
      const res = value.split("/");
      if (res.length > 1) {
        month = +res[0] + 1;
        day = res[1];
        year = res[2];
      }
    }

    month = Number(month);
    day = Number(day);
    year = Number(year);

    if (isNaN(month) || isNaN(day) || isNaN(year)) {
      return { dateInvalid: true };
    }

    if (month < 1 || month > 12) {
      // check month range
      return { dateInvalid: true };
    }

    if (day < 1 || day > 31) {
      return { dateInvalid: true };
    }

    if (
      (month === 4 || month === 6 || month === 9 || month === 11) &&
      day === 31
    ) {
      return { dateInvalid: true };
    }

    if (month === 2) {
      // check for february 29th
      const isLeap = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

      if (day > 29 || (day === 29 && !isLeap)) {
        return { dateInvalid: true };
      }
    }
  }

  return null;
}
