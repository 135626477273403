import { Injectable } from "@angular/core";
import { Scorms } from "./scorms";
import { DataService } from "app/infrastructure/dataservice";
import { CollectionCache } from "app/domain/collectioncache";
import { Scorm } from "app/domain/scorm";
import { BehaviorSubject, Observable, ReplaySubject, of, throwError, timer } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { delay, retryWhen, switchMap, take, scan, tap, map, catchError, repeatWhen, switchMapTo, concatMap, takeUntil } from "rxjs/operators";
import { concat, repeat, takeWhile } from "lodash";

export enum ScormStatus {
  None = 0,
  InProgress = 5,
  Failed = 10,
  Completed = 15,
  OutOfDate = 20
}

@Injectable()
export class HttpScormService implements Scorms, CollectionCache {
  private headers = {
    apiVersion: new HttpHeaders({ apiVersion: "version2" }),
    apiScorm: new HttpHeaders({ apiScorm: "scormUrl" })
  };

  private scormStatusRequest : Observable<boolean>;
  private scormStatusSubject : ReplaySubject<boolean>;
  private scormRequests = new Map<string, Observable<Scorm>>();
  private scormSubjects = new Map<string, ReplaySubject<Scorm>>();

  constructor(private httpClient: HttpClient, private dataService: DataService) {
    this.dataService.organizationChangedEvent.subscribe(() => {
      this.clear();
    })
    this.clear();
   }
  
  clear(): void {
    this.scormStatusRequest = null;
    this.scormStatusSubject = null;
    this.scormRequests.clear();
    this.scormSubjects.clear();
  }

  scormStatus(refresh: boolean = false): Observable<boolean> {
    
    if(refresh || !this.scormStatusSubject) {
      const { organizationID } = this.dataService;
      const scormStatusUrl = `/organizations/${organizationID}/status`;

      this.scormStatusSubject = new ReplaySubject<boolean>(1);

      this.scormStatusRequest = this.httpClient.get<boolean>(scormStatusUrl, { headers: this.headers.apiScorm });

      this.scormStatusRequest.subscribe(
            (result) => this.scormStatusSubject.next(result),
            (err) => this.scormStatusSubject.next(false)
          );
      
        return this.scormStatusSubject;
    }
    return this.scormStatusSubject.asObservable();
  }

  scormFileStatus(id: string, refresh: boolean = false): Observable<Scorm> {
    

    if(refresh || !this.scormSubjects[id]) {
      const { organizationID } = this.dataService;
      const scormStatusUrl = `/scorm/organizations/${organizationID}/learningpaths/${id}`;

      this.scormSubjects[id] = new ReplaySubject<Scorm>(1);

      this.scormRequests[id] = this.httpClient.get<Scorm>(scormStatusUrl, { headers: this.headers.apiScorm });

      this.scormRequests[id].subscribe(
            (result) => this.scormSubjects[id].next(result),
            (err) => this.scormSubjects[id].next({status: ScormStatus.None})
          );

      return this.scormSubjects[id];
    }
    
    return this.scormSubjects[id].asObservable();
  }

  generateScormFile(id: string) {
      const { organizationID } = this.dataService;
      const scormGenerationUrl = `/v2/organizations/${organizationID}/learningPaths/${id}/scorm`;  
      this.httpClient.get(scormGenerationUrl, { headers: this.headers.apiVersion }).subscribe();
  }

  markScormFileOutdated(id: string) : Observable<Scorm> {
    const { organizationID } = this.dataService;
    const scormOutdatedUrl = `/scorm/organizations/${organizationID}/learningpaths/${id}`;

    delete this.scormRequests[id];
    
    return this.httpClient
      .post<Scorm>(scormOutdatedUrl, null, { headers: this.headers.apiScorm });

    
  }
}