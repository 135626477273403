import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ILicenseBlock, IUsers, TProcessedUser } from "app/application/interfaces/licenses.interface";
import { map } from "rxjs/operators";
import { LicenseManagementHttpService } from "../../services/license-management-http.service";

@Component({
  selector: 'app-license-users-modal',
  templateUrl: './license-users-modal.component.html',
  styleUrls: ['./license-users-modal.component.scss']
})
export class LicenseUsersModalComponent implements OnInit {
  isDataLoading = false

  licensesLeft = 0
  licenseUsers: TProcessedUser[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ILicenseBlock,
    private licenseService: LicenseManagementHttpService
  ) {}

  ngOnInit() {
    const { licensesCap, licensesUsed, id } = this.data

    this.getUsers(id)
    this.licensesLeft = licensesCap - licensesUsed <= 0 ? 0 : licensesCap - licensesUsed
  }

  getUsers(blockId) {
    this.isDataLoading = true

    this.licenseService.getUsers(blockId)
      .pipe(map(this.spreadUsers))
      .subscribe((users) => {
        this.isDataLoading = false
        this.licenseUsers = users
      })
  }

  spreadUsers(users: IUsers[]): TProcessedUser[] {
    return users.map(({expirationDate, user}) => ({
      expirationDate: expirationDate,
      ...user
    }))
  }
}
