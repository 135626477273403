<div class="learning-paths">

  <div class="learning-paths__header"></div>

  <mat-tab-group class="learning-paths__tabs">

    <mat-tab label="{{'learningpaths_module.learning_paths' | translate }}">
      <div class="learning-paths__tab">
        <app-learning-path
          (onSelect)="selectHandler($event)"
          (onUnselect)="unselectHandler($event)"
        ></app-learning-path>

        <app-courses-groups
          [learningPathMode]="true"
          [selectedLearningPaths]="selectedPathList"
        ></app-courses-groups>
      </div>
    </mat-tab>

    <mat-tab label="{{'learningpaths_module.available_courses' | translate }}">
      <div class="learning-paths__tab">
        <app-available-courses></app-available-courses>
      </div>
    </mat-tab>

  </mat-tab-group>

</div>
