import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { InformationDialogComponent } from "../../../presentation/information-dialog/information-dialog.component";
import { JoyrideService } from "ngx-joyride";
import { LoggedInOrganizationService } from "../../helper/logged-in-organization.service";
import { HttpOnboardingService } from "./http-onboarding.service";
import { TranslateService } from "@ngx-translate/core";
import { IOnBoardingItem } from "./onboarding";

@Injectable({
  providedIn: "root",
})
export class OnboardingService {
  public onboarding = new ReplaySubject<IOnBoardingItem[]>(null);
  public isOnboardingActive = new Subject<boolean>();
  private organizationId: string;
  private guides = [];
  private textModule = "";
  private dialogConfig: MatDialogConfig = <MatDialogConfig>{
    height: "250px",
    width: "506px",
  };

  public constructor(
    private dialog: MatDialog,
    protected translate: TranslateService,
    private onboardingService: HttpOnboardingService,
    private loggedInOrganizationService: LoggedInOrganizationService,
    private readonly joyrideService: JoyrideService
  ) {
    this.loggedInOrganizationService = loggedInOrganizationService;
  }

  public getOnboarding(): Observable<IOnBoardingItem[]> {
    return this.onboarding.asObservable();
  }

  public setOnboarding(newValue: IOnBoardingItem[]): void {
    this.onboarding.next(newValue);
  }

  public deleteOnboarding(): void {
    this.onboarding.next([]);
  }

  public onboardingProgress(): Observable<boolean> {
    return this.isOnboardingActive.asObservable();
  }

  public closeOnboarding(): void {
    if (this.checkOnboardingStatus()) {
      this.joyrideService.closeTour();
    }
  }

  public checkOnboardingStatus(): boolean {
    return this.joyrideService.isTourInProgress();
  }

  public openOnboardingDialog(
    textModule: string,
    steps: string[],
    guides: string[],
    isReturn?: boolean
  ): Observable<any> {
    this.guides = guides;
    this.textModule = textModule;

    const dialogRef = this.dialog.open(InformationDialogComponent, this.dialogConfig);

    dialogRef.componentInstance.isShowCloseButton = false;
    dialogRef.componentInstance.textContent = this.textModule + "_module.welcome_title";
    dialogRef.componentInstance.descriptionContent = this.textModule + "_module.welcome_text";
    dialogRef.componentInstance.buttonContent = "on_boarding_module.lets_go";

    if (isReturn) {
      return dialogRef.afterClosed();
    } else {
      dialogRef.afterClosed().subscribe(() => {
        this.startOnboardingNavigation(steps);
      });
    }
  }

  public startOnboardingNavigation(
    steps: string[],
    textModule?: string,
    guides?: string[],
    isReturn?: boolean
  ): Observable<any> {
    if (textModule) {
      this.textModule = textModule;
      this.guides = guides;
    }
    let backButton = "";
    let nextButton = "";
    let doneButton = "";
    this.translate.get("common.back").subscribe((translation: string) => {
      backButton = translation;
    });
    this.translate.get("common.next").subscribe((translation: string) => {
      nextButton = translation;
    });
    this.translate
      .get("on_boarding_module.done_button")
      .subscribe((translation: string) => {
        doneButton = translation;
      });

    this.isOnboardingActive.next(true);

    if (isReturn) {
      return this.joyrideService.startTour({
        steps: steps,
        themeColor: "#777777",
        customTexts: { prev: backButton, next: nextButton, done: doneButton },
      });
    }

    return this.joyrideService.startTour({
      steps: steps,
      themeColor: "#777777",
      customTexts: { prev: backButton, next: nextButton, done: doneButton },
    })
  }

  public openFinishOnboardingDialog(
    textModule?: string,
    guides?: string[],
    isNavigation?: boolean
  ): void {
    if (textModule) {
      this.textModule = textModule;
      this.guides = guides;
    }
    const dialogRef: MatDialogRef<InformationDialogComponent> = this.dialog.open(
      InformationDialogComponent,
      this.dialogConfig
    );

    dialogRef.componentInstance.isShowCloseButton = false;
    dialogRef.componentInstance.textContent =
      "on_boarding_module.congrats_title";
    dialogRef.componentInstance.descriptionContent =
      this.textModule + "_module.congrats_text";
    dialogRef.componentInstance.buttonContent =
      "on_boarding_module.finish_button";

    dialogRef.afterClosed().subscribe(() => {
      this.organizationId = this.loggedInOrganizationService.getLoggedInOrganizationId();
      if (this.guides.length) {
        this.onboardingService
          .setOnboarding(this.organizationId, this.guides)
          .subscribe(() => {
            this.updateOnBoarding();
            this.showMeHowRemind(isNavigation);
          });
      }
    });
  }

  private showMeHowRemind(isNavigation?: boolean): void {
    let element = document.querySelector(".tooltip-guide");
    if (isNavigation) {
      element = document.querySelector(".tooltip-guide-nav");
    }
    element.classList.add("animate");
    setTimeout(() => {
      element.classList.remove("animate");
    }, 15000);
  }

  private updateOnBoarding(): void {
    this.onboardingService
      .getOnboarding(this.organizationId)
      .subscribe((res) => {
        this.setOnboarding(res);
        this.guides = [];
      });
  }
}
