import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Course } from "../../../../domain/course";

@Component({
  selector: "app-course-detail",
  templateUrl: "./course-detail.component.html",
  styleUrls: ["./course-detail.component.scss"],
})
export class CourseDetailComponent {
  courseId = ''
  course: Course
  isExpirationCourseSettingsEnabled = false

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.course = data.course
    this.courseId = this.course.id
    this.isExpirationCourseSettingsEnabled = data.isExpirationCourseSettingsEnabled
  }

}
