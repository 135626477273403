import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IUserLicenses } from "app/application/interfaces/licenses.interface";
import { LicencesNotificationsService } from "app/application/licences-notifications/licences-notifications.service";
import RoleEnum from "app/domain/roleEnum";
import { DataService } from "app/infrastructure/dataservice";
import { Organization } from "../../domain/organization";

import { HttpOrganizationService } from "../../infrastructure/http/organization/httporganization.service";

@Component({
  selector: "app-switch-organization-dialog",
  templateUrl: "./switch-organization-dialog.component.html",
  styleUrls: ["./switch-organization-dialog.component.scss"],
})

export class SwitchOrganizationDialogComponent implements OnInit {
  isDataLoading = true;
  originalList = [];
  organizationsList = [];
  filteredOrganizations = [];

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<SwitchOrganizationDialogComponent>,
    private organizationService: HttpOrganizationService,
    private licencsesNotificationsService: LicencesNotificationsService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.organizationService.getUserLicenses().subscribe((licenses: IUserLicenses[]) => {
      this.isDataLoading = false
      this.organizationsList = this.setOrganizationStatus(licenses)
      this.originalList = this.organizationsList;
    })
  }

  applyFilter(filterValue: string) 
  {

    this.organizationsList = this.originalList;

        this.organizationsList = this.organizationsList.filter(item => {
          return String(item.name).toLowerCase().includes(filterValue.toLowerCase());

          /*return Object.keys(item).some(key => {
            return String(item[key]).toLowerCase().includes(filterValue.toLowerCase());
          });
          */
        })
  }
 

  public switchToOrganization(chosenOrganization: Organization): void {
    this.organizationService
      .getOrganization(chosenOrganization.id)
      .subscribe((result) => {
        this.licencsesNotificationsService.getNotificationsByOrgId(
          chosenOrganization.id
        );
        this.dialogRef.close(result);
      });
  }

  // map through organizations and licenses arrays
  // and check, should we set isDisabled flag or not
  setOrganizationStatus(licenses: IUserLicenses[]) {
    const isManager = this.dataService.AuthenticatedUser.role !== RoleEnum.superuser
    const currentDate = +new Date()

    return this.data.availableOrganizations.map((organization: Organization) => {
      if (isManager && !organization.isUnlicensed) {
        const userLicense = licenses.find((license) => license.organizationId === organization.id)
        // if organization has no active licenses
        if (!userLicense) {
          organization.isDisabled = true
          organization.disableReason = 'switch_organization.disable_reason_no-license'
          return organization
        }

        const expirationDate = +new Date(userLicense.effectiveExpirationDate)

        if (expirationDate < currentDate) {
          organization.isDisabled = true
          organization.disableReason = 'switch_organization.disable_reason_expired_license'
          return organization
        }
      }

      organization.isDisabled = false
      return organization
    })
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
