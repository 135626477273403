import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment/moment";
import { Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { map as rxjsMap, takeWhile } from "rxjs/operators";
import { ConvertToUser } from "../../../application/command/convertToUser";
import { CollectionCache } from "../../../domain/collectioncache";
import { Group } from "../../../domain/group";
import { Organization } from "../../../domain/organization";
import { User } from "../../../domain/user";
import { Users } from "./users";
import { DataService } from "../../dataservice";

@Injectable()
export class HttpUserService implements Users, CollectionCache {
  private usersSubject: Subject<User[]>;
  private usersRequest: Subscription;
  private users: User[] = [];
  loadingSubject: Subject<boolean>;

  requestHeaders = new HttpHeaders().set("apiVersion", "version2")

  usersRequestCanContinue: boolean

  constructor(
    private httpClient: HttpClient,
    private dataService: DataService
  ) {
    this.usersSubject = new ReplaySubject(1);
    this.loadingSubject = new ReplaySubject(1);
  }

  public clear(): void {
    this.stopUsersRequest()
    this.usersRequest = null;
    this.usersSubject.next([]);
    this.users = [];
  }

  public getUser(organizationId: string, userId: string): Observable<User> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");
    return this.httpClient
      .get(`/Organizations/${organizationId}/users/${userId}`, { headers: httpHeaders })
      .pipe(rxjsMap(ConvertToUser));
  }

  public getUsers(refresh: boolean = false): Observable<User[]> {
    const firstPage = 1

    if (refresh || !this.usersRequest) {
      this.users = [];
      let httpHeaders = new HttpHeaders().set("apiVersion", "version2");
      this.loadingSubject.next(true);
      this.startUsersRequest()
      this.getUsersPage(`/Organizations/${this.dataService.organizationID}/users?pageSize=500`, firstPage);
    }

    return this.usersSubject.asObservable();
  }

  deactivateUsers(usersId: string[]) {
    const httpHeaders = new HttpHeaders().set("apiVersion", "version2");

    return this.httpClient.post(
      `/v2/Organizations/${this.dataService.organizationID}/Users/deactivate-users`,
      usersId,
      { headers: httpHeaders }
    )
  }

  reactivateUsers(usersId: string[]) {
    const httpHeaders = new HttpHeaders().set("apiVersion", "version2");

    return this.httpClient.post(
      `/v2/Organizations/${this.dataService.organizationID}/Users/reactivate-users`,
      usersId,
      { headers: httpHeaders }
    )
  }

  activateNewLicense(usersId: string[]) {
    const httpHeaders = new HttpHeaders().set("apiVersion", "version2");

    return this.httpClient.post(
      `/v2/Organizations/${this.dataService.organizationID}/Licenses/UserLicenses`,
      usersId,
      { headers: httpHeaders }
    )
  }

  public isLoadingUsers(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  public getUsersOfGroup(
    organizationId: string,
    group: Group
  ): Observable<User[]> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    return this.httpClient
      .get<User[]>(
        "/Organizations/" + organizationId + "/Groups/" + group.id + "/users",
        { headers: httpHeaders, observe: "response" }
      )
      .pipe(rxjsMap(({body}) => this.mapUsers(body)));
  }

  private getUsersPage(pageUrl: string, page: number) {
    this.usersRequest = this.httpClient
      .get<User[]>(`${pageUrl}&pageNumber=${page}`, { headers: this.requestHeaders, observe: 'response'})
      .pipe(
        takeWhile(() => this.usersRequestCanContinue),
      )
      .subscribe(
      ({body, headers}) => {
        let usersToAdd = this.mapUsers(body);
        this.users = this.users.concat(usersToAdd);
        this.usersSubject.next(this.users);

        const pagination = JSON.parse(headers.get("X-Pagination"));

        if (pagination && pagination.nextPageLink && this.usersRequestCanContinue) {
          this.getUsersPage(pageUrl, ++page);
        } else {
          this.loadingSubject.next(false);
        }
      },
      (err) => this.usersSubject.error(err)
    );
  }

  startUsersRequest() {
    this.usersRequestCanContinue = true
  }

  stopUsersRequest() {
    this.usersRequestCanContinue = false
  }

  public updateUser(
    organizationId: string,
    user: User,
    original: User
  ): Observable<Object> {
    let birthdate = null;

    if (!_.isNil(user.birthdate)) {
      const momentBirthDate = moment(user.birthdate);
      birthdate = moment(user.birthdate)
        .utc()
        .add(momentBirthDate.utcOffset(), "m")
        .toDate();
    }

    const body = [];
    if (user.name !== original.name)
      body.push({
        path: "name",
        value: user.name,
        op: "replace",
      });
    if (user.email !== original.email)
      body.push({
        path: "email",
        value: user.email,
        op: "replace",
      });
    if (user.job !== original.job)
      body.push({
        path: "job",
        value: user.job,
        op: "replace",
      });
    if (user.birthdate === null && original.birthdate === null) {
      //do nothing
    } else if (
      original.birthdate === null ||
      user.birthdate.valueOf() !== original.birthdate.valueOf()
    ) {
      body.push({
        path: "birthDate",
        value: birthdate,
        op: "replace",
      });
    }

    if (user.department !== original.department)
      body.push({
        path: "department",
        value: user.department,
        op: "replace",
      });
    if (user.language !== original.language)
      body.push({
        path: "languageID",
        value: user.language,
        op: "replace",
      });
    if (user.virtualEmail !== original.virtualEmail)
      body.push({
        path: "emailExists",
        value: !(user.virtualEmail && original.virtualEmail), //you can not change from a real email to a virtual email
        op: "replace",
      });
    if(user.role !== original.role)
      body.push({
        path: 'role',
        value: user.role,
        op: "replace",
      });


    return this.httpClient
      .patch(`/Organizations/${organizationId}/users/${user.id}`, body, {
        headers: this.requestHeaders,
      })
      .pipe(
        rxjsMap((res: Object) => {
          const userRef: User = _.first(_.filter(this.users, ["id", user.id]));

          if (userRef) {
            userRef.update(
              user.id,
              user.name,
              user.email,
              user.job,
              user.birthdate,
              user.department,
              user.status,
              user.role,
              user.language,
              user.organizationID,
              user.virtualEmail,
              user.licenseId,
              user.blockId
            );
            this.usersSubject.next(Object.assign([], this.users));
          }

          return res;
        })
      );
  }

  public createUser(
    organizationId: string,
    user: User,
    password: string
  ): Observable<User> {
    let httpHeaders = new HttpHeaders().set("apiVersion", "version2");

    const body = {
      name: user.name,
      email: user.email,
      emailExists: !user.virtualEmail,
    };

    if (user.virtualEmail) {
      body["password"] = password;
    }

    return this.httpClient
      .post(`/Organizations/${organizationId}/users`, body, {
        headers: httpHeaders,
      })
      .pipe(rxjsMap(ConvertToUser, this));
  }

  public sendInvitation(
    organizationId: string,
    user: User
  ): Observable<Object> {
    let httpHeaders = new HttpHeaders().set("apiVersion", "version2");

    return this.httpClient.post(
      `/Organizations/${organizationId}/users/${user.id}/send-invitation-mail`,
      {},
      { headers: httpHeaders, responseType: "text" }
    );
  }

  public deleteUsers(
    organizationId: string,
    users: User[]
  ): Observable<Object> {
    let httpHeaders = new HttpHeaders().set("apiVersion", "version2");
    const userIDs = _.map(users, (u) => {
      return u.id;
    });

    return this.httpClient.post(
      `/Organizations/${organizationId}/users/delete-requests`,
      userIDs,
      { responseType: "text", headers: httpHeaders }
    );
  }

  public isUserEmailAvailable(email: string): Observable<boolean> {
    let httpHeaders = new HttpHeaders().set("apiVersion", "version2");
    return this.httpClient.get<boolean>(
      `/Organizations/${this.dataService.organizationID}/users/email-available?email=${email}`,
      { headers: httpHeaders }
    );
  }

  public getUrlToDownloadCertificate(
    organization: Organization,
    user: User
  ): Observable<Object> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    return this.httpClient.get(
      `/Organizations/${organization.id}/users/${user.id}/certifications/export-pdf`,
      {
        headers: httpHeaders,
      }
    );
  }

  private mapUsers(users: User[]): User[] {
    if (users) {
      return users.map(ConvertToUser).sort((firstUser, secondUser) => {
        const nameA = firstUser.name.toLowerCase()
        const nameB = secondUser.name.toLowerCase()

        if (nameA > nameB) {
          return 1
        }

        if (nameA < nameB) {
          return -1
        }

        return 0
      })
    }
    return []
  }

  public updatePassword(organizationId: string, userId: string, newpassword: string): Observable<Object> 
  {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    return this.httpClient.post(
      `/v2/organizations/${organizationId}/users/${userId}/set-password`,
      JSON.stringify(newpassword),
      {
        headers: httpHeaders,
      }
    );
  }
}
