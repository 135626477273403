<div class="license-renew__container">

  <div class="license-renew__header">
    <h2 class="license-renew__title">
      Company {{data.name}}
    </h2>
    <button type="button" mat-icon-button mat-dialog-close>
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>
  </div>

  <div class="license-renew__content" *ngIf="!isDataLoading; else spinner">

    <div class="license-renew__controls">
      <h3 class="license-renew__blocks-amount">
        License blocks: {{licenses.length}}
      </h3>

      <div class="license-renew__buttons">
        <button mat-flat-button type="button" class="download-button" [ngClass]="{loading: isLogsLoading}"
          (click)="downloadLogs()">
          <mat-spinner *ngIf="isLogsLoading; else downloadLogsContent" [diameter]="18"></mat-spinner>
          <ng-template #downloadLogsContent>
            Download logs
          </ng-template>
        </button>

        <button mat-flat-button color="primary" type="button" class="unlicense-button" (click)="switchLicenseState()">
          <mat-spinner *ngIf="isUnlicenseLoading; else unlicenseText" [diameter]="18"></mat-spinner>
          <ng-template #unlicenseText>
            {{data.isUnlicensed ? 'Undo Unlicense' : 'Unlicense'}}
          </ng-template>
        </button>
      </div>
    </div>

    <div class="license-renew__blocks">

      <table mat-table [dataSource]="licenses" class="mat-elevation-z8">

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>license type</th>
          <td mat-cell *matCellDef="let element">{{element.licenseType}}</td>
        </ng-container>

        <ng-container matColumnDef="stats">
          <th mat-header-cell *matHeaderCellDef>licenses in block</th>
          <td mat-cell *matCellDef="let element">
            {{element.licensesUsed}}/{{element.licensesCap}}
          </td>
        </ng-container>

        <ng-container matColumnDef="expiration">
          <th mat-header-cell *matHeaderCellDef>expiration date</th>
          <td mat-cell *matCellDef="let element">
            {{element.expirationDate
                ? (element.expirationDate | date:'dd.MM.yyyy')
                : '-'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="renew">
          <th mat-header-cell *matHeaderCellDef>auto-renew</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="toggle-container">
              <mat-slide-toggle (change)="autoRenewHandle($event, element.id, i)"
                [checked]="element.expirationDate ? element.enableAutoRenew : false"
                [disabled]="element.shouldDisableRenew || element.expirationDate === null"></mat-slide-toggle>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="exceeding">
          <th mat-header-cell *matHeaderCellDef>exceeding limit cap</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="toggle-container">
              <mat-slide-toggle (change)="exceedLimitHandle($event, element.id, i)"
                [checked]="element.licensesCap ? element.canExceedCap : false"
                [disabled]="element.licensesCap === null"></mat-slide-toggle>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>actions</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="buttons">
              <button mat-flat-button type="button" class="license-renew__block-button"
                (click)="renewBlock(element.id, i)"
                [disabled]="element.shouldDisableRenew || element.enableAutoRenew || element.expirationDate === null">
                <mat-spinner *ngIf="isRenewLoading; else renewText" [diameter]="18"></mat-spinner>
                <ng-template #renewText>
                  Renew
                </ng-template>
              </button>

              <button mat-flat-button color="primary" type="button" class="license-renew__block-button"
                (click)="revokeBlock(element.id, i)" [disabled]="!element.canBeRevoked || element.shouldDisableRevoke">
                <mat-spinner *ngIf="isRevokeLoading; else revokeText" [diameter]="18"></mat-spinner>
                <ng-template #revokeText>
                  Revoke
                </ng-template>
              </button>
            </div>
          </td>`
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>

    <div class="license-renew__contacts-container" *ngIf="data.responsibleUser">
      <h3 class="license-renew__contacts-title">Contacts</h3>
      <p class="license-renew__contacts">
        {{data.responsibleUser?.name}} <br />
        {{data.responsibleUser?.email}} <br />
        {{data.responsibleUser?.phoneNumber}}
      </p>
    </div>

  </div>

  <ng-template #spinner>
    <div class="loading-status">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </ng-template>
</div>
