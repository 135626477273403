import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import * as _ from "lodash";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { InformationDialogComponent } from "../presentation/information-dialog/information-dialog.component";
import { PersistentStoreService } from "./helper/persistentstore.service";
import { DataService } from "./dataservice";
import { GameLinkDialogComponent } from "app/presentation/game-link-dialog/game-link-dialog.component";
import { APP_CONSTANTS } from "app/application/constants";
import { InfoDialogComponent } from "app/presentation/info-dialog/info-dialog";

@Injectable()
export class InterceptedHttp implements HttpInterceptor {
  readonly LOGIN_METHOD_NAME = "/authentication";
  readonly ACTIVATE_ACCOUNT_METHOD_NAME = "/ActivateAccount";
  readonly RESET_PASSWORD_METHOD_NAME = "/ResetPassword";
  readonly REQUEST_PASSWORD_RESET_METHOD_NAME = "/RequestPasswordReset";
  private persistentstoreService: PersistentStoreService;
  private dialog: MatDialog;
  private dialogConfig: MatDialogConfig = <MatDialogConfig>{
    height: "223px",
    width: "506px",
  };

  public constructor(
    private injector: Injector,
    private dataService: DataService
  ) { }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.persistentstoreService = this.injector.get(PersistentStoreService);
    this.dialog = this.injector.get(MatDialog);

    if (this.isAuthorizedRequest(request.url)) {
      request = request.clone({
        setHeaders: {
          Authorization: this.getAuthorizationHeader(),
        },
      });
    }

    if (request.headers.has("disableContentType")) {
      request = request.clone({
        url: this.updateUrl(request),
        setHeaders: {
          Accept: "security/json, image/*, application/json",
        },
      });
    } else {
      request = request.clone({
        url: this.updateUrl(request),
        setHeaders: {
          Accept: "security/json, image/*, application/json",
          "Content-Type": request.headers.has("Content-Type")
            ? request.headers.get("Content-Type")
            : "application/json",
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error?.error) {
          this.showErrorDialog(error.error)
        } else if (!_.isNil(error?.error?.code)) {
          const dialogRef: MatDialogRef<InformationDialogComponent> = this.dialog.open(InformationDialogComponent, this.dialogConfig);
          dialogRef.componentInstance.textContent = error.error.code;
        }

        return throwError(error);
      })
    );
  }

  private updateUrl(request: HttpRequest<any>): string {
    let updatedUrl: string = request.url;
    if (_.isEqual(request.headers.get("apiScorm"), "scormUrl")) {
      updatedUrl = environment.scormUrl + request.url;
    } else {
      if (!updatedUrl.startsWith("assets")) {
        if (_.isEqual(request.headers.get("apiVersion"), "version2")) {
          updatedUrl = environment.origin.version2 + request.url;
        } else {
          updatedUrl = environment.origin.version1 + request.url;
        }
      }
    }
    return updatedUrl;
  }

  private getAuthorizationHeader(): string {
    return "bearer " + this.dataService.authToken;
  }

  showErrorDialog(errorMessage: string) {
    switch (errorMessage) {
      case APP_CONSTANTS.ROLE_ERROR: {
        return this.dialog.open(GameLinkDialogComponent, this.dialogConfig)
      }

      case APP_CONSTANTS.NO_VALID_LICENSES:
      case APP_CONSTANTS.LICENSES_EXPIRED: {
        return this.dialog.open(InfoDialogComponent, this.dialogConfig)
      }
    }

  }

  private isAuthorizedRequest(url): boolean {
    let result = false;
    if (
      url !== this.LOGIN_METHOD_NAME &&
      !_.endsWith(url, this.ACTIVATE_ACCOUNT_METHOD_NAME) &&
      !_.endsWith(url, this.RESET_PASSWORD_METHOD_NAME) &&
      !_.endsWith(url, this.REQUEST_PASSWORD_RESET_METHOD_NAME)
    ) {
      result = true;
    }
    return result;
  }
}
