import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-information-dialog',
    templateUrl: './information-dialog.component.html',
    styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent {
    private dialogRef: MatDialogRef<InformationDialogComponent>;
    public textContent: string;
    public descriptionContent: string;
    public isShowCloseButton = true;
    public isAlignCenterContent = false;
    public buttonContent: string;

    constructor(dialogRef: MatDialogRef<InformationDialogComponent>) {
        this.dialogRef = dialogRef;
    }

    public keyDownFunction(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.dialogRef.close();
        }
    }
}
