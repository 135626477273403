import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { AzureReportConfig } from "../../../domain/azure-report-config";
import { CollectionCache } from "../../../domain/collectioncache";
import { Feature } from "../../../domain/feature";
import { Organization } from "../../../domain/organization";
import { Reports } from "./reports";
import * as _ from "lodash";
import { map as rxjsMap } from "rxjs/operators";

@Injectable()
export class HttpReportsService implements Reports, CollectionCache {
  private httpClient: HttpClient;
  private featureSubject: Subject<Feature[]>;
  private featureRequest: Observable<Feature[]>;
  private loadingSubject: Subject<boolean>;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.featureSubject = new ReplaySubject(1);
    this.loadingSubject = new ReplaySubject(1);
  }

  public clear(): void {
    this.featureRequest = null;
    this.featureSubject.next([]);
  }

  public getFeatures(
    organizationId: string,
    refresh: boolean = false
  ): Observable<Feature[]> {
    if (refresh || !this.featureRequest) {
      let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders.set("apiVersion", "version2");

      this.loadingSubject.next(true);

      this.featureRequest = this.httpClient
        .get(`/Organizations/${organizationId}/features`, {
          headers: httpHeaders,
        })
        .pipe(rxjsMap(this.mapFeatures, this));

      this.featureRequest.subscribe(
        (result) => {
          this.featureSubject.next(result);
          this.loadingSubject.next(false);
        },
        (err) => this.featureSubject.error(err)
        //, () => this.featureSubject.complete()
      );
    }
    return this.featureSubject.asObservable();
  }

  private mapFeatures(response: any): Feature[] {
    return _.map(response, this.toFeature);
  }

  public isLoadingFeatures(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  public getDashboardConfig(
    organizationId: string
  ): Observable<AzureReportConfig> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");
    return this.httpClient
      .get("/Organizations/" + organizationId + "/powerbi/dashboard", {
        headers: httpHeaders,
      })
      .pipe(rxjsMap(this.toAzureReportConfig));
  }

  public getReportsConfig(
    organizationId: string
  ): Observable<AzureReportConfig> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    return this.httpClient
      .get("/Organizations/" + organizationId + "/powerbi/reports", {
        headers: httpHeaders,
      })
      .pipe(rxjsMap(this.toAzureReportConfig));
  }

  private toFeature(feature: any): Feature {
    return new Feature(feature.id, feature.name);
  }

  private toAzureReportConfig(reportConfig: any): AzureReportConfig {
    return new AzureReportConfig(
      reportConfig.id,
      reportConfig.embedToken,
      reportConfig.embedUrl,
      reportConfig.pages
    );
  }
}
