import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-information-dialog',
    templateUrl: './dashboard-guide-dialog.component.html',
    styleUrls: ['./dashboard-guide-dialog.component.scss']
})
export class DashboardGuideDialogComponent implements OnInit {
    private dialogRef: MatDialogRef<DashboardGuideDialogComponent>;
    public isShowCloseButton = false;
    public items = [];

    constructor(dialogRef: MatDialogRef<DashboardGuideDialogComponent>) {
        this.dialogRef = dialogRef;
    }

    ngOnInit() {
        this.dialogRef.keydownEvents().subscribe((event) => {
            if (event.key === 'Escape') {
                this.closeDialog()
            }
        })

        this.dialogRef.backdropClick().subscribe((event) => {
            this.closeDialog()
        })
    }

    public keyDownFunction(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.dialogRef.close();
        }
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }
}
