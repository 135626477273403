import {FormControl} from '@angular/forms';
import {User} from '../../domain/user';
import * as _ from 'lodash';

export function validateResponsibleUser(c: FormControl) {
	let result = null;
	if (!(c.value instanceof User) && c.value !== '' && !_.isNil(c.value)) {
		result = {
			validateResponsibleUser: {
				valid: false
			}
		}
	}
	return result;
}
