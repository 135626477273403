import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { LicenceOverviewHttpService } from "./services/license-overview-http.service";

import { IStatistics } from "app/application/interfaces/licenses.interface";

@Component({
  selector: 'app-license-overview',
  templateUrl: './license-overview.component.html',
  styleUrls: ['./license-overview.component.scss']
})
export class LicenseOverviewComponent implements OnInit, OnDestroy {
  isStatisticsLoading = false

  searchValue: string

  organizationsStats: IStatistics
  statisticssSubscription$: Subscription

  constructor(
    private licenseService: LicenceOverviewHttpService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
      this.searchValue = fragment || ''
    })

    this.getStatistics()
  }

  ngOnDestroy() {
    this.statisticssSubscription$.unsubscribe()
  }

  getStatistics() {
    this.isStatisticsLoading = true

    this.statisticssSubscription$ = this.licenseService.getStatistics().subscribe((statistics) => {
      this.organizationsStats = statistics
      this.isStatisticsLoading = false
    })
  }
}
