<button type="button" mat-icon-button (click)="isNotificationsOpen = !isNotificationsOpen" cdkOverlayOrigin
    #trigger="cdkOverlayOrigin">
    <mat-icon class="notifications-icon" [matBadge]="badgeCounter" [matBadgeHidden]="!badgeCounter" matBadgeSize="small"
        matBadgePosition="below after">
        download
    </mat-icon>
</button>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isNotificationsOpen"
    [cdkConnectedOverlayHasBackdrop]="true" cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    (backdropClick)="isNotificationsOpen = false">
    <app-notifications-scorm-popover [generatedScorms]="generatedScorms"></app-notifications-scorm-popover>
</ng-template>