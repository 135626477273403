<form class="lp-form" [formGroup]="learningPathForm" (ngSubmit)="submitLearningPathForm()">
  <mat-horizontal-stepper *ngIf="!editMode" [linear]="!isWorkingNameFilled" #stepper
    (selectionChange)="onStepChange($event)">
    <mat-step [stepControl]="firstFormGroup">
      <ng-template matStepLabel>General</ng-template>
      <mat-form-field class="lp-form__name">
        <mat-label>{{"learningpaths_module.lp_workingname" | translate }}</mat-label>
        <input matInput formControlName="workingName" />
        <mat-error>
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="lp-form__icon-id">
        <mat-label>Icon Asset Id (optional)</mat-label>
        <mat-select formControlName="iconId">
          <mat-option *ngFor="let categoryAsset of (categoryAssets$ | async)" [value]="categoryAsset.id">
            <mat-icon matPrefix>
              <img [src]="categoryAsset.downloadUri" [alt]="categoryAsset.id" loading="lazy">
            </mat-icon>
            {{ categoryAsset.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <ng-template matStepLabel>Description</ng-template>
      <ng-container *ngFor="let nameGroup of names.controls; let i = index;" formArrayName="names">
        <app-learning-path-names [errorsList]="names.errors" [shouldDisableButton]="names.controls.length === 1"
          [languageNameGroup]="nameGroup" (onDelete)="removeNameForm(i)"></app-learning-path-names>
      </ng-container>
      <app-button class="add-language-button" shape="raised" color="primary" (onButtonClick)="setNamesForm()"
        [disabled]="learningPathForm.disabled">
        <mat-icon class="add-icon">add</mat-icon> {{"learningpaths_module.add_new_language" | translate }}
      </app-button>
    </mat-step>
  </mat-horizontal-stepper>

  <div *ngIf="editMode">
    <div *ngIf="mode==0">
      <mat-form-field class="lp-form__name">
        <mat-label>{{"learningpaths_module.lp_workingname" | translate }}</mat-label>
        <input matInput formControlName="workingName" />
        <mat-error>
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="lp-form__icon-id">
        <mat-label>Icon Asset Id (optional)</mat-label>
        <mat-select formControlName="iconId">
          <mat-option *ngFor="let categoryAsset of (categoryAssets$ | async)" [value]="categoryAsset.id">
            <mat-icon matPrefix>
              <img [src]="categoryAsset.downloadUri" [alt]="categoryAsset.id" loading="lazy">
            </mat-icon>
            {{ categoryAsset.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="mode==1">
      <ng-container *ngFor="let nameGroup of names.controls; let i = index;" formArrayName="names">
        <app-learning-path-names [errorsList]="names.errors" [shouldDisableButton]="names.controls.length === 1"
          [languageNameGroup]="nameGroup" (onDelete)="removeNameForm(i)"></app-learning-path-names>
      </ng-container>
      <app-button class="add-language-button" shape="raised" color="primary" (onButtonClick)="setNamesForm()"
        [disabled]="learningPathForm.disabled">
        <mat-icon class="add-icon">add</mat-icon> {{"learningpaths_module.add_new_language" | translate }}
      </app-button>
    </div>
  </div>
</form>