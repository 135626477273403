import { Component } from "@angular/core";
import { ITooltipAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'user-tooltip',
    templateUrl: 'user-tooltip.component.html',
    styleUrls: ['user-tooltip.component.scss']
})
export class UserTooltipComponent implements ITooltipAngularComp {
    private params: any;
    public data: any;

    constructor() {
        alert('test');
    }
    agInit(params): void {
    
        this.params = params;
        this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
       
    }
}
