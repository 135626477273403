import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {NotificationService} from '../../../../application/notification/notification.service';
import {Group} from '../../../../domain/group';
//import {LoggedInOrganizationService} from '../../../../infrastructure/helper/logged-in-organization.service';
import {HttpGroupsService} from '../../../../infrastructure/http/group/httpgroups.service';
import { DataService } from '../../../../infrastructure/dataservice';

@Component({
    selector: 'app-add-group',
    templateUrl: './add-group.component.html',
    styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {
    public group: Group;
    public addGroupForm: FormGroup;
    private dialogRef: MatDialogRef<AddGroupComponent>;
    private groupService: HttpGroupsService;
    //private loggedInOrganizationService: LoggedInOrganizationService;
    private notificationService: NotificationService;

    public constructor(dialogRef: MatDialogRef<AddGroupComponent>,
                       groupService: HttpGroupsService,
                       private dataService: DataService,
                       notificationService: NotificationService) {
        this.dialogRef = dialogRef;
        this.groupService = groupService;

        this.notificationService = notificationService;

        this.addGroupForm = new FormGroup({
            name: new FormControl('', Validators.required),
            description: new FormControl('')
        });
    }

    public ngOnInit(): void {
        this.group = new Group('', '', '', [], [],[]);
    }

    public addGroup(group: Group) {
        this.groupService.addGroup(this.dataService.organizationID, group).subscribe((addedGroup: Group) => {
            this.notificationService.show('GROUP ADDED');
            this.dialogRef.close(addedGroup);
        });
    }

    public cancel(): void {
        this.dialogRef.close(null);
    }

    public keyDownFunction(event) {
        if (event.keyCode === 13) {
            if (this.addGroupForm.valid) {
                this.addGroup(this.group);
            }
        }
    }
}
