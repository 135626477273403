import { HttpParams } from "@angular/common/http";
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ONE_MONTH_IN_MILLISECONDS } from "app/application/constants";
import { merge, of, Subject, Subscription } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from "rxjs/operators";
import { LicenceOverviewHttpService } from "../../services/license-overview-http.service";
import { LicenseBlockCreateModal } from "../license-block-create-modal/license-block-create-modal.component";
import { LicenseRenewModalComponent } from "../license-renew-modal/license-renew-modal.component";

import { IOrganizationBlock, IQueryParams } from "app/application/interfaces/licenses.interface";
import { IPaginationHeaders, ISort } from "app/application/interfaces/tables.interface";

const paginationKey = 'x-pagination'

@Component({
  selector: 'app-license-overview-table',
  templateUrl: './license-overview-table.component.html',
  styleUrls: ['./license-overview-table.component.scss'],
})
export class LicenseOverviewTableComponent implements OnChanges, AfterViewInit, OnDestroy {
  isDataLoading = true
  isDataRequested = false

  tableData: IOrganizationBlock[] = []

  paginationData: IPaginationHeaders

  displayedColumns: string[] = [
    'name',
    'licenseType',
    'licensesCap',
    'status',
    'creationDate',
    'expirationDate'
  ]

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  @Input() searchValue: string

  searchModel$: Subscription
  searchModelChange = new Subject<string>()

  // this Subject merges with table sort and pagination streams,
  // to watch when make request if one of these actions was fired
  searchChange$ = new Subject<string>()

  private dialogConfig: MatDialogConfig = <MatDialogConfig>{
    height: '618px',
    width: '1000px',
  }

  constructor(
    private licenseService: LicenceOverviewHttpService,
    private dialog: MatDialog,
  ) {}

  ngOnChanges(changes) {
    const { searchValue } = changes
    this.searchModelChange.next(searchValue.currentValue)
  }

  ngAfterViewInit() {
    this.searchModel$ = this.searchModelChange.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.searchValue = value
      this.searchChange$.next(value)
    })

    this.sort?.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0
    })

    const initialAction = this.searchValue || {}

    merge(this.sort?.sortChange, this.paginator?.page, this.searchChange$)
      .pipe(
        startWith(initialAction),
        switchMap((action) => {
          const { query, ...params } = this.handleAction(action)
          this.isDataLoading = true
          return this.getData(query, params)
        }),
        map((response) => {
          const { body, headers } = response
          this.paginationData = JSON.parse(headers.get(paginationKey))
          this.isDataLoading = false
          this.isDataRequested = true
          return body
        }),
        map((blocks) => {
          return blocks.map(({organization, ...rest}) =>
            ({ ...organization, ...rest }))
        }),
        catchError(() => {
          this.isDataLoading = false
          this.isDataRequested = true
          return of([])
        })
      )
      .subscribe((organizationBlocks) => {
        this.tableData = organizationBlocks.length
          ? this.setBlockStatus(organizationBlocks)
          : []
      })
  }

  ngOnDestroy() {
    this.sort.sortChange.unsubscribe()
    this.paginator.page.unsubscribe()
    this.searchChange$.unsubscribe()
    this.searchModel$.unsubscribe()
  }

  clearHandle() {
    this.searchValue = ''
    this.searchModelChange.next('')
  }

  changeHandler(value) {
    this.searchModelChange.next(value)
  }

  getData(
    query: string,
    { pageNumber = 1, pageSize = 10, orderBy = '' }: IQueryParams
  ) {
    const params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('orderBy', orderBy)

    if (query) {
      return this.licenseService.searchOrganizations(query, params)
    }

    return this.licenseService.getOrganizations(params)
  }

  handleAction(action) {
    let query = ''
    let pageNumber = 1
    let pageSize = 10
    let orderBy = ''

    const isSearchAction = typeof action === 'string'
    const actionKeys = (!isSearchAction && Object.keys(action)) || []
    const isSortAction = actionKeys.includes('active')
    const isPaginationAction = actionKeys.includes('pageIndex')

    if (isSearchAction) {
      pageSize = this.paginator.pageSize
      pageNumber = 1
      orderBy = this.getSortAction(action)
      query = action
    }

    if (isSortAction) {
      pageSize = this.paginator.pageSize
      pageNumber = 1
      orderBy = this.getSortAction(action)
      query = this.searchValue
    }

    if (isPaginationAction) {
      pageSize = action.pageSize
      pageNumber = action.pageIndex + 1
      orderBy = this.getSortAction({
        active: this.sort.active,
        direction: this.sort.direction
      })
      query = this.searchValue
    }

    return {
      pageNumber,
      pageSize,
      orderBy,
      query
    }
  }

  getSortAction({ active, direction }: ISort) {
    if (active === 'name' && direction) {
      return `organization.name ${direction}`
    }

    if (direction) {
      return `${active} ${direction}`
    }

    return ''
  }

  setBlockStatus(blocks: IOrganizationBlock[]): IOrganizationBlock[] {
    const currentDate = +new Date()
    return blocks.map(({ expirationDate, ...rest}) => {
      // initial values
      let status = 'Expired'
      let expirationSoon = false

      const expireDate = +new Date(expirationDate)
      const statusResult = expireDate - currentDate

      // if expirationDate is null then status should be Active
      if (statusResult > ONE_MONTH_IN_MILLISECONDS || !expirationDate) {
        status = 'Active'
      } else if (statusResult > 0) {
        status = 'Expires soon'
        expirationSoon = true
      }

      return {
        ...rest,
        expirationDate,
        status,
        expirationSoon
      }
    })
  }

  openRenewModal(organization: IOrganizationBlock) {
    const config = <MatDialogConfig>{
      ...this.dialogConfig,
      panelClass: 'license-renew',
      data: { ...organization }
    }

    const dialogRef = this.dialog.open(LicenseRenewModalComponent, config)
    const onLicensedChange$ = dialogRef.componentInstance.onLicensedChange.subscribe(() => {
      this.changeUnlicenseStatus(organization.organizationId)
    })
    dialogRef.afterClosed().subscribe(() => {
      onLicensedChange$.unsubscribe()
    })
  }

  openCreateBlockModal() {
    const dialogRef = this.dialog.open(LicenseBlockCreateModal)

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchChange$.next('')
      }
    })
  }

  changeUnlicenseStatus(organizationId: string) {
    this.tableData.forEach((organization) => {
      if (organization.organizationId === organizationId) {
        organization.isUnlicensed = !organization.isUnlicensed
      }
    })
  }
}
