import { ICourseInfoUpdateDTO, ICourseTranslation } from './../../../../application/interfaces/courses.interface';
import { transition } from '@angular/animations';
import { noEmptySpaceValidator } from 'app/application/validators/empty-space.validator';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ICourseInfo, ILanguages } from "../../../../application/interfaces/courses.interface";
import { PlayEnvironment } from "app/domain/playEnvironment";
import { DataService } from "app/infrastructure/dataservice";
import { HttpLanguageService } from "app/infrastructure/http/language/httplanguage.service";
import { HttpOrganizationService } from "app/infrastructure/http/organization/httporganization.service";
import { uniqueLanguageValue } from '../../../learning-path/learning-path.validators';

@Component({
  selector: 'app-available-courses-info-form',
  templateUrl: './available-courses-info-form.component.html',
  styleUrls: ['./available-courses-info-form.component.scss']
})
export class AvailableCoursesInfoFormComponent implements OnInit {
  @Input() isFormLoading = false
  @Input() courseInfoFormData: ICourseInfo

  @Output() onSubmit: EventEmitter<ICourseInfoUpdateDTO> = new EventEmitter()

  courseInfoForm: FormGroup
  isTranslationsExpanded  = false;
  languageId: FormControl
  translationsFormArray: FormArray
  isFormDisabled = false
  isLanguagesLoading = false
  languages: ILanguages[] = []

  constructor(
    private languagesService: HttpLanguageService,
    private organizationsService: HttpOrganizationService,
    private dataService: DataService,
  ) {
    this.languageId = new FormControl(null, Validators.required);
    const estimatedTimeControl = new FormControl('')
    this.translationsFormArray = new FormArray([
      new FormGroup({
        languageId: this.languageId,
        description: new FormControl('', [Validators.required, noEmptySpaceValidator]),
        name: new FormControl('', [Validators.required, noEmptySpaceValidator])
      })
    ], uniqueLanguageValue)

    this.courseInfoForm = new FormGroup({

      estimatedTime: estimatedTimeControl,
      translations: this.translationsFormArray
    })
  }

  ngOnInit() {
    this.getLanguages()

    this.formInit()
  }

  formInit() {
    const {
      estimatedTime: estimatedTimeControl,
      translations: translationsFormArray
    } = this.courseInfoForm.controls

    const {
      estimatedTimeInSeconds,
      translations
    } = this.courseInfoFormData

    estimatedTimeControl.setValue(estimatedTimeInSeconds? estimatedTimeInSeconds/60 : undefined);

    translations.forEach(({languageId, description, name}, index) => {
      if (index >= 1) {
        this.addLanguageGroup()
      }

      this.translationsFormArray.controls[index].setValue(
        {
          languageId,
          description,
          name
        }
      )
    })

    this.formDisable()
  }

  addLanguageGroup() {
    const languageId = new FormControl(null, Validators.required)

    const formGroup = new FormGroup({
      languageId,
      description: new FormControl('', [Validators.required, noEmptySpaceValidator]),
      name: new FormControl('', [Validators.required, noEmptySpaceValidator])
    })

    this.translationsFormArray.push(formGroup)
  }
  removeLanguageGroup(index: number) {
    this.translationsFormArray.removeAt(index)
  }

  getFormValue() {
    return this.translationsFormArray.value
  }

  getLanguages() {
    this.isLanguagesLoading = true
    this.languagesService.getLanguages().subscribe((languages) => {
      this.languages = languages
      this.isLanguagesLoading = false
    })
  }

  formDisable() {
    this.isFormDisabled = true
    this.courseInfoForm.disable()
  }

  formEnable() {
    this.isFormDisabled = false
    this.courseInfoForm.enable()
  }

  switchFormState() {
    if (this.isFormDisabled) {
      this.formEnable();
    } else {
      this.formDisable();
    }
  }

  submitCourseInfoForm() {
    const {
        estimatedTime,
        translations

    } = this.courseInfoForm.value;


    const trimmedTranslations = this.trimTranslationFields(translations);

    const payload: ICourseInfoUpdateDTO = {
      estimatedTimeInSeconds: estimatedTime? estimatedTime*60 : undefined,
      translations : trimmedTranslations
    }

    //console.log(JSON.stringify(payload));

    this.onSubmit.emit(payload)
  }

  trimTranslationFields(translations: ICourseTranslation[]): ICourseTranslation[] {
    return translations.map(({ name, description, languageId }) => {
      return {
        languageId,
        name: name?.trim(),
        description: description?.trim()
      }
    })
  }

}
