import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment/moment';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators'
import {Group} from '../../../domain/group';
import {Organization} from '../../../domain/organization';
import {Reminder} from '../../../domain/reminder';
import {User} from '../../../domain/user';
import {Reminders} from './reminders';

@Injectable()
export class HttpReminderService implements Reminders {
    private httpClient: HttpClient;

    public constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    public getNumberOfUsersApplicableForReminder(organizationId: string, receiver: User[] | Group): Observable<number> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('apiVersion', 'version2');

        const body = !_.isNil(receiver) ? (receiver instanceof Group ? {groupIDs: [receiver.id]} : {userIDs: _.map(receiver, 'id')}) : {};
        return this.httpClient
            .post(`/Organizations/${organizationId}/reminderinfo`, body, {headers: httpHeaders})
            .pipe(
                rxjsMap((response: {numberOfApplicableUsers: number}) => {
                    return response.numberOfApplicableUsers;
                })
            );
    }

    public sendReminderForUncompleteCourses(organizationId: string, receiver: User[] | Group): Observable<Object> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('apiVersion', 'version2');

        const body = !_.isNil(receiver) ? (receiver instanceof Group ? {groupIDs: [receiver.id]} : {userIDs: _.map(receiver, 'id')}) : {};
        return this.httpClient
            .post(`/Organizations/${organizationId}/send-reminder`, body, {responseType: 'text', headers: httpHeaders});
    }

    private toReminder(reminder: any): Reminder {
        let reminderObj: Reminder = null;

        if (!_.isNil(reminder)) {
            const momentDate = moment(reminder.dateTimeSentUTC);
            const dateReminder = moment(reminder.dateTimeSentUTC).utc().add(momentDate.utcOffset(), 'm').toDate();
            reminderObj = new Reminder(reminder.id, dateReminder, reminder.amountOfUsersNotified);
        }

        return reminderObj;
    }
}
