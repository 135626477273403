<div class="header-less-tabs">
  <mat-tab-group header-less-tabs (selectedTabChange)="onTabChanged($event)">
    <mat-tab label="{{ 'GROUPS' | translate }}">
      <div class="joyride-users-groups-step" stepPosition="bottom"></div>
      <app-groups [isOpenSelectGroup]="isOpenSelectGroup" [isActive]="groupTabIsActive"></app-groups>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="navigation-guide tooltip-guide" matTooltip="{{ 'on_boarding_module.tooltip_info' | translate }}"
  (click)="startOnboardingNavigation()">
  <mat-icon>help_outline</mat-icon>
  <div class="text-block">
    {{ "on_boarding_module.repeat_guide" | translate }}
  </div>
</div>
