import {Component, ElementRef} from '@angular/core';
import {IHeaderParams} from 'ag-grid-community/main';
import {IAfterGuiAttachedParams} from 'ag-grid-community';
import { IHeaderAngularComp } from 'ag-grid-angular';

@Component({
    templateUrl: 'header-name.component.html'
})
export class HeaderNameComponent implements IHeaderAngularComp {
    public params: IHeaderParams;
    public sorted: string;
    private elementRef: ElementRef;

    constructor(elementRef: ElementRef) {
        this.elementRef = elementRef;
    }

    refresh(params: IHeaderParams): boolean {
       return true;
    }

    public agInit(params: IHeaderParams): void {
        this.params = params;
        this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
        this.onSortChanged();
    }

    onOwnSortRequested(event) {
        switch (this.sorted) {
            case '':
                this.params.setSort('asc', event.shiftKey);
                break;
            case 'asc':
                this.params.setSort('desc', event.shiftKey);
                break;
            case 'desc':
                this.params.setSort(null, event.shiftKey);
        }
    }

    onSortChanged() {
        if (this.params.column.isSortAscending()) {
            this.sorted = 'asc'
        } else if (this.params.column.isSortDescending()) {
            this.sorted = 'desc'
        } else {
            this.sorted = ''
        }
    };

    public afterGuiAttached(params?: IAfterGuiAttachedParams): void {
    }


}
