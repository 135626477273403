import { IPage, Page } from "../../domain/page";
import { Route, Routes } from "@angular/router";
import { CanActivateViaAuthGuard } from "../security/can-activate-via-auth-guard";

export const convertPagesToRoutes = (pages: IPage[]): Routes =>
  pages.map((page: Page) => {
    let route: Route = {
      path: page.path,
      component: page.component,
    };

    if (page.data) {
      route = {
        ...route,
        data: { ...page.data },
      };
    }

    if (page.securedPage) {
      route.canActivate = [CanActivateViaAuthGuard];
    }

    return route;
  });
