<div class="header-less-tabs">
  <mat-tab-group header-less-tabs (selectedTabChange)="onTabChanged($event)">
    <mat-tab label="{{ 'USERS' | translate }}">
      <div class="joyride-users-groups-step" stepPosition="bottom"
     ></div>
      <app-people [isOpenSelect]="isOpenSelect" [isActive]="peopleTabIsActive"></app-people>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="navigation-guide tooltip-guide" matTooltip="{{ 'on_boarding_module.tooltip_info' | translate }}"
  (click)="startOnboardingNavigation()">
  <mat-icon>help_outline</mat-icon>
  <div class="text-block">
    {{ "on_boarding_module.repeat_guide" | translate }}
  </div>
</div>
