import {ChangeDetectorRef, Component, Input} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-group-course-result',
    templateUrl: './group-course-result.component.html',
    styleUrls: ['./group-course-result.component.scss']
})
export class GroupCourseResultComponent {
    public group: any;
    public courseResults: any;
    public showAbsolutes: boolean;

    /**
     *
     */
    constructor(private cdRef: ChangeDetectorRef) {

    }

    public agInit(params: any): void {
        const courseId = params.courseId;
        this.group = params.data;
        this.courseResults = _.find(this.group.courses, (course: any) => {
            return course.id === courseId;
        });
        this.showAbsolutes = params.showAbsolutes();
        // this.cdRef.detectChanges();
    }

    public courseAvailable(): boolean {
        return !_.isNil(this.courseResults);
    }

    public getNumberOfUsersCompleted(): number {
        return this.courseResults.usersCompleted;
    }

    public getPercentNumberOfUsersCompleted(): number {
        let result = 0;
        if (this.courseResults.usersCompleted > 0) {
            result = Math.round(this.courseResults.usersCompleted / this.getNumberOfUsersInGroup() * 100);
        }
        return result;
    }

    public getNumberOfUsersInProgress(): number {
        return this.courseResults.usersInProgress;
    }

    public getPercentNumberOfUsersInProgress(): number {
        let result = 0;
        if (this.courseResults.usersInProgress > 0) {
            result = Math.round(this.courseResults.usersInProgress / this.getNumberOfUsersInGroup() * 100);
        }
        return result;
    }

    public getNumberOfUsersNotStarted(): number {
        return this.courseResults.usersNotPlayed;
    }

    public getPercentNumberOfUsersNotStarted(): number {
        let result = 0;
        if (this.courseResults.usersNotPlayed > 0) {
            result = Math.round(this.courseResults.usersNotPlayed / this.getNumberOfUsersInGroup() * 100);
        }
        return result;
    }

    public getNumberOfUsersExpired(): number {
        return this.courseResults.usersExpired;
    }

    public getPercentNumberOfUsersExpired(): number {
        let result = 0;
        if (this.courseResults.usersExpired > 0) {
            result = Math.round(this.courseResults.usersExpired / this.getNumberOfUsersInGroup() * 100);
        }
        return result;
    }

    public getNumberOfUsersInGroup(): number {
        return this.getNumberOfUsersNotStarted() + this.getNumberOfUsersInProgress() + this.getNumberOfUsersCompleted() + this.getNumberOfUsersExpired();
    }
}
