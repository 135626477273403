import { Component, Input, OnInit } from "@angular/core";
import { IStatistics } from "app/application/interfaces/licenses.interface";

// in degrees
const initialProgressPosition = 45
const fullProgress = 225

// 100% = 180° so: ° = % * 1.8
const transformRatio = 1.8

@Component({
  selector: 'app-license-stats',
  templateUrl: './license-stats.component.html',
  styleUrls: ['./license-stats.component.scss']
})
export class LicenseStatsComponent implements OnInit {

  @Input() stats: IStatistics

  licensesInUsePercent = 0
  licensesLeftPercent = 0
  expireSoonPercent = 0

  licensesInUseDegrees = initialProgressPosition
  licensesLeftDegrees = initialProgressPosition
  expireSoonDegrees = initialProgressPosition

  ngOnInit() {
    const {
      totalAmountOfLicenses,
      licensesInUse,
      licensesLeft,
      expireSoon
    } = this.stats

    this.licensesInUsePercent = this.calculatePercent(totalAmountOfLicenses, licensesInUse)
    this.licensesLeftPercent = this.calculatePercent(totalAmountOfLicenses, licensesLeft)
    this.expireSoonPercent = this.calculatePercent(totalAmountOfLicenses, expireSoon)

    setTimeout(() => {
      this.licensesInUseDegrees = this.calculateDegrees(this.licensesInUsePercent)
      this.licensesLeftDegrees = this.calculateDegrees(this.licensesLeftPercent)
      this.expireSoonDegrees = this.calculateDegrees(this.expireSoonPercent)
    }, 200)
  }

  calculatePercent(totalValue, partValue) {
    if (partValue <= 0) {
      return 0
    }

    return +(partValue / totalValue * 100).toFixed(1)
  }

  calculateDegrees(percent) {
    if (percent <= 0) {
      return initialProgressPosition
    }

    const degreeToRotate = percent * transformRatio + initialProgressPosition

    if (degreeToRotate > fullProgress) {
      return fullProgress
    }

    return degreeToRotate
  }

}
