<div class="license-stats">

  <div class="license-stats__item total-amount">
    <h3 class="license-stats__label">
      {{'license_module.stats_total' | translate}}
    </h3>
    <p class="license-stats__value total-licenses">{{stats.totalAmountOfLicenses}}</p>
  </div>

  <div class="license-stats__item progress">
    <h3 class="license-stats__label">
      {{'license_module.stats_in-use' | translate}}
    </h3>
    <div class="barOverflow">
      <div class="bar" [ngStyle]="{transform: 'rotate(' + licensesInUseDegrees + 'deg)'}"></div>
    </div>
    <div class="license-stats_value-container">
      <p class="license-stats__value">{{stats.licensesInUse}}</p>
      <p class="license-stats__percent">{{licensesInUsePercent}}%</p>
    </div>
  </div>

  <div class="license-stats__item progress">
    <h3 class="license-stats__label">
      {{'license_module.stats_left' | translate}}
    </h3>
    <div class="barOverflow">
      <div class="bar" [ngStyle]="{transform: 'rotate(' + licensesLeftDegrees + 'deg)'}"></div>
    </div>
    <div class="license-stats_value-container">
      <p class="license-stats__value">{{stats.licensesLeft}}</p>
      <p class="license-stats__percent">{{licensesLeftPercent}}%</p>
    </div>
  </div>

  <div class="license-stats__item progress">
    <h3 class="license-stats__label">
      {{'license_module.stats_expire_soon' | translate}}
    </h3>
    <div class="barOverflow">
      <div class="bar" [ngStyle]="{transform: 'rotate(' + expireSoonDegrees + 'deg)'}"></div>
    </div>
    <div class="license-stats_value-container">
      <p class="license-stats__value">{{stats.expireSoon}}</p>
      <p class="license-stats__percent">{{expireSoonPercent}}%</p>
    </div>
  </div>
</div>
