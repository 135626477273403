import { AfterViewInit, Component, ElementRef, Input, OnDestroy, QueryList, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ILicenseBlock } from "app/application/interfaces/licenses.interface";
import { Subscription } from "rxjs";
import { LicenseUsersModalComponent } from "../license-users-modal/license-users-modal.component";

@Component({
  selector: 'app-license-blocks-table',
  templateUrl: './license-blocks-table.component.html',
  styleUrls: ['./license-blocks-table.component.scss'],
})
export class LicenseBlocksTableComponent implements AfterViewInit, OnDestroy {
  @Input() tableData = []

  displayedColumns: string[] = [
    'licenseType',
    'status',
    'creationDate',
    'expirationDate'
  ]

  dataSource: MatTableDataSource<ILicenseBlock>

  @ViewChildren('matrow', {read: ViewContainerRef}) rows: QueryList<ViewContainerRef>
  @ViewChild('tableContainer') tableContainer: ElementRef

  rows$: Subscription

  @ViewChild(MatSort) sort: MatSort

  private dialogConfig: MatDialogConfig = <MatDialogConfig>{
    height: '700px',
    width: '1000px',
    panelClass: 'license-management__users',
  }

  constructor(
    private dialog: MatDialog
  ) {
    this.dataSourceInit()
  }

  ngAfterViewInit() {
    this.rows$ = this.rows.changes.subscribe((list: QueryList<ViewContainerRef>) => {
      this.scrollToRow()
    })

    this.dataSourceInit()
    this.dataSource.sort = this.sort
  }

  ngOnDestroy() {
    this.rows$.unsubscribe()
  }

  scrollToRow() {
    const headerHeight = 56

    const row = this.rows.find((row) => {
      return row.element.nativeElement.classList.contains('highlighted')
    })?.element?.nativeElement

    if (row) {
      const rowOffset = row.offsetTop - headerHeight
      this.tableContainer.nativeElement.scroll({top: rowOffset, behavior: 'smooth'})

      setTimeout(() => {
        row.classList.remove('highlighted')
      }, 5000);
    }
  }

  dataSourceInit() {
    this.dataSource = new MatTableDataSource(this.tableData)
  }

  openUsersModal(licenseBlock: ILicenseBlock) {
    const config = <MatDialogConfig>{
      ...this.dialogConfig,
      data: { ...licenseBlock }
    }

    this.dialog.open(LicenseUsersModalComponent, config)
  }
}
