<section fxLayout="column">
  <header fxLayoutGap="20px">
    <h3 fxFlex>
      <span class="link" routerLink="/results">{{
        "RESULTS" | translate
      }}</span>
      <span *ngIf="canShowGroupDetails()">
        > {{ "GROUP" | translate }} "{{ groupName }}" ({{
          "CATEGORY" | translate
        }}
        "{{ categoryName }}")</span>
    </h3>

    <button mat-flat-button fxLayoutAlign="end center" color="primary" [matMenuTriggerFor]="resultActions"
      [disabled]="!canShowGroupDetails()">
      {{ "common.select_action" | translate }}
      <mat-icon class="fa fa-angle-down group-results-detail-actions" aria-hidden="true"><svg class="select-icon"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path
            d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
        </svg></mat-icon>
    </button>

    <mat-menu #resultActions [overlapTrigger]="false" xPosition="after" yPosition="below">
      <button mat-menu-item (click)="sendReminder()">
        {{ "reminder.send_reminder" | translate }}
      </button>
    </mat-menu>
  </header>

  <div *ngIf="areResultsLoading()" fxFlex fxLayout="column" fxLayoutAlign="center center">
    <p>
      {{
        "general.loading"
          | translate: { attribute: "RESULTS_attribute" | translate}
      }}
    </p>
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
  </div>

  <ag-grid-angular class="ag-theme-material group-results" *ngIf="canShowGroupDetails()" [gridOptions]="gridOptions"
    [rowData]="sortedUsers" enableColResize>
  </ag-grid-angular>
</section>

<app-group-detail-result-legenda *ngIf="canShowGroupDetails()" [title]="'USER_PROGRESS' | translate">
</app-group-detail-result-legenda>

<script src="https://kit.fontawesome.com/99fe3c75dd.js" crossorigin="anonymous"></script>
