<header mat-dialog-title>
  <div class="title">
    <h4 class="label">
      {{editMode ? ('learningpaths_module.edit_learning_path'| translate) : ('learningpaths_module.create_learning_path'
      | translate )}}
    </h4>
    <app-button mat-dialog-close shape="icon">
      <mat-icon>close</mat-icon>
    </app-button>
  </div>

  <mat-tab-group *ngIf="editMode" (selectedTabChange)="toggleShow($event.index)">
    <mat-tab label="General"></mat-tab>
    <mat-tab label="Description"> </mat-tab>
  </mat-tab-group>
</header>

<mat-dialog-content>
  <div *ngIf="isFormInitializing; else formContainer">
    <mat-spinner color="primary" [diameter]="40"></mat-spinner>
  </div>

  <ng-template #formContainer>
    <app-learning-path-form #formComponent [mode]="mode" [isFormLoading]="isFormLoading" [editMode]="editMode"
      [currentStep]="currentStep" (isWorkingNameFilledChange)="onWorkingNameFilledChange($event)"
      (isNamesControlsFilledChange)="onNamesControlsFilled($event)" [learningPathData]="learningPath"
      (onSubmit)="formSubmitHandler($event)" (currentStepChange)="currentStep = $event">
    </app-learning-path-form>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">

  <ng-container *ngIf="currentStep === 0 && !editMode">
    <button fxFlex="25%" mat-button (click)="close()" class="mat-secondary">
      {{'CANCEL' | translate}}
    </button>
    <button fxFlex="25%" mat-flat-button color="primary" [disabled]="!isWorkingNameFilled" (click)="nextButtonClick()">
      {{'common.next' | translate}}
    </button>
  </ng-container>

  <ng-container *ngIf="currentStep === 1 && !editMode">
    <button fxFlex="25%" mat-button (click)="close()" class="mat-secondary">
      {{'CANCEL' | translate}}
    </button>
    <button fxFlex="25%" mat-flat-button color="primary" (click)="previousButtonClick()">
      Previous
    </button>
    <app-button class="create-learning-path-button" shape="raised" color="primary" type="submit"
      [isLoading]="isFormLoading" [disabled]="isFormDisabled || !isNamesControlsFilled" (onButtonClick)="submitForm()">
      {{"learningpaths_module.create_learning_path"|translate }}
    </app-button>
  </ng-container>
  <app-button *ngIf="editMode" class="create-learning-path-button" shape="raised" color="primary" type="submit"
    [isLoading]="isFormLoading" [disabled]="isFormDisabled || !isNamesControlsFilled" (onButtonClick)="submitForm()">
    {{ "learningpaths_module.save_changes" | translate }}
  </app-button>

  <app-button *ngIf="editMode && !isFormInitializing" color="secondary" class="edit-form-button" shape="raised"
    (onButtonClick)="switchFormState()">
    {{isFormDisabled ? ('learningpaths_module.edit' | translate) : ('learningpaths_module.discard' | translate )}}
  </app-button>
</mat-dialog-actions>