import { NgModule } from '@angular/core';
import { MaterialModule } from 'app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { CoursesComponent } from './courses.component';
import { CourseSettingsTabComponent } from './course-settings-tab/course-settings-tab.component';
import { CourseInfoTabComponent } from './course-info-tab/course-info-tab.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { JoyrideModule } from 'ngx-joyride';
import { CoursesGroupsModule } from 'app/features/courses-groups/courses-groups.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule,
    JoyrideModule,
    NgSelectModule,
    CoursesGroupsModule
  ],
  declarations: [
    CoursesComponent,
    CourseSettingsTabComponent,
    CourseInfoTabComponent,
    CourseDetailComponent
  ]
})
export class CoursesModule {}
