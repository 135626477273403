<section class="course-info">

  <div class=" loading-status" *ngIf="isCourseInfoLoading; else courseInfoContent">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>

  <ng-template #courseInfoContent>
    <div class="course-info__properties">
      <div class="course-info__properties-wrapper">
        <div class="course-info__description">
          <h5>Description: </h5>
          <p *ngIf="!courseInfo?.description"><i>N/A</i></p>
          <p *ngIf="courseInfo?.description">{{courseInfo?.description}}</p>
        </div>
        <div class="course-info__estimated-time">
          <h5>Estimated time</h5>
          <p *ngIf="!courseInfo?.estimatedTimeInSeconds"><i>N/A</i></p>
          <p *ngIf="courseInfo?.estimatedTimeInSeconds">
            {{courseInfo.estimatedTimeInSeconds/60}} minutes
          </p>
          <ng-template #empty>
            <p>-</p>
          </ng-template>
        </div>
        <div class="course-info__languages">
          <h5>Supported languages</h5>
          <mat-chip-list class="course-info__chip-list" fxLayoutAlign="start space-around" fxLayoutGap="0.9rem">
            <mat-chip class="mat-chip" fxFlex="10" style="padding: 0.5rem 1rem" *ngIf="!courseInfo?.supportedLanguages">
              <i>N/A</i>
            </mat-chip>
            <mat-chip class="mat-chip" fxFlex="7" *ngFor="let lang of courseInfo?.supportedLanguages">
              {{lang}}
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="course-info__environments">
          <h5>Supported environments</h5>
          <mat-chip-list class="course-info__chip-list" fxLayoutAlign="start space-around" fxLayoutGap="0.9rem">
            <mat-chip class="mat-chip" fxFlex="10" *ngIf="!courseInfo.playEnvironments"><i>N/A</i></mat-chip>
            <mat-chip class="mat-chip" fxFlex="31" *ngFor="let env of courseInfo?.playEnvironments">{{env?.name}}
            </mat-chip>
          </mat-chip-list>
        </div>

      </div>

      <div class="course-info__images">
        <ng-container *ngFor="let image of courseInfo?.images">
          <img class="course-info__image" [src]="image.imageBody">
        </ng-container>
      </div>
    </div>
  </ng-template>

</section>
