<div *ngIf="!initialized; else content" class="pits-loading">
  <img style="width: 20%;" src="../assets/img/logo_kleur.png" />
</div>

<ng-template #content>

  <!-- main container to hold sidenav's-->
  <mat-sidenav-container autosize="true" class="main-container">



    <!-- navigation of the portal-->
    <mat-sidenav [ngClass]="{ 'is-expanded': isNavExpanded }" *ngIf="canShowMenu()" mode="side" opened="true" #sidenav
      class="side-navigation" disableClose="true">

      <!-- inject the navigation component-->
      <app-navigation></app-navigation>

      <!-- double black arrow used to expand the menu-->
      <!--
      <div class="nav-expand">
        <button *ngIf="this.isNavExpanded" (click)="toggleMenu()" class="nav-expand-button material-symbols-outlined">
          keyboard_double_arrow_left
         </button>
         <button *ngIf="!this.isNavExpanded" (click)="toggleMenu()" class="nav-expand-button nav-expand-button-small material-symbols-outlined">
          keyboard_double_arrow_right
         </button>
        </div>
      -->

    </mat-sidenav>

    <!-- main content of the portal-->
    <mat-sidenav-content fxFlex class="main-container">
      <div class="main-content">
        <router-outlet></router-outlet>
        <app-notifications-button *ngIf="!isNotificationsHidden"></app-notifications-button>
        <app-notifications-button-scorm *ngIf="!isNotificationsHidden"></app-notifications-button-scorm>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>
</ng-template>