<div class="report-content">
  <app-upgrade-feature *ngIf="!canShowReports()"></app-upgrade-feature>

  <mat-tab-group *ngIf="canShowTabs()" (selectedTabChange)="selectedTabChanged($event)">
    <mat-tab #matTab [label]="page.displayName | translate" *ngFor="let page of getReportPages()"></mat-tab>
  </mat-tab-group>

  <app-reports-detail *ngIf="canShowReportsDetail()" [azureReportConfig]="getAzureReportConfig()"
    [reportsPageName]="getCurrentReportPageToShow().name"></app-reports-detail>

  <ng-container *ngIf="isShowGuideMark">
    <div class="navigation-guide tooltip-guide" matTooltip="{{ 'on_boarding_module.tooltip_info' | translate }}"
      (click)="startOnboardingNavigation()">
      <mat-icon>help_outline</mat-icon>
      <div class="text-block">
        {{ "on_boarding_module.repeat_guide" | translate }}
      </div>
    </div>
  </ng-container>
</div>
