import {Course} from './course';
import * as _ from 'lodash';

export class Category {
	private _id: string;
	private _name: string;
	private _description: string;
	private _isActive: boolean;
	private _courses?: Array<Course>;

	constructor(id: string, name: string, description: string, isActive: boolean, courses?: Array<Course>) {
		this._id = id;
		this._name = name;
		this._description = description;
		this._isActive = isActive;
		this._courses = courses;
	}

	get id(): string {
		return this._id;
	}

	get name(): string {
		return this._name;
	}

	get description(): string {
		return this._description;
	}

	get isActive(): boolean {
		return this._isActive;
	}

	set isActive(value: boolean) {
		this._isActive = value;
	}

	get courses(): Array<Course> {
		return this._courses;
	}

	set courses(value: Array<Course>) {
		this._courses = value;
	}

	get checked(): boolean {
		return !_.some(this.courses, (course: Course) => {
			return !course.checked;
		});

	}

	set checked(value: boolean) {
		_.each(this.courses, (course: Course) => {
			course.checked = value;
		});
	}
}
