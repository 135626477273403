<div class="license-overview" *ngIf="!isStatisticsLoading; else spinner">

  <div class="license-overview__header">
    <h3 class="license-overview__title">License Overview</h3>
  </div>

  <app-license-stats [stats]="organizationsStats"></app-license-stats>
  <app-license-overview-table [searchValue]="searchValue"></app-license-overview-table>
</div>

<ng-template #spinner>
  <div class="loading-status">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>
