import {
  AbstractControl,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { Directive } from "@angular/core";
import { HttpGroupsService } from "../../infrastructure/http/group/httpgroups.service";
import { DataService } from "../../infrastructure/dataservice";

@Directive({
  selector: "[appValidatorGroupName]",
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: ValidatorGroupNameDirective,
      multi: true,
    },
  ],
})
export class ValidatorGroupNameDirective implements Validator {
  private groupService: HttpGroupsService;

  constructor(
    groupService: HttpGroupsService,
    private dataService: DataService
  ) {
    this.groupService = groupService;
  }

  public validate(c: AbstractControl): Promise<ValidationErrors> {
    const groupName = c.value;

    return new Promise((resolve) => {
      this.groupService
        .isGroupNameAvailable(this.dataService.organizationID, groupName)
        .subscribe(
          (available) => {
            resolve(
              available
                ? null
                : {
                    groupNameDuplicate: true,
                  }
            );
          },
          () => {
            resolve({
              groupNameDuplicate: true,
            });
          }
        );
    });
  }
}
