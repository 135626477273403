import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { itemsLicenseGuide } from "app/application/onboarding/license-management.steps";

@Component({
  selector: 'app-license-guide',
  templateUrl: './license-guide-modal.component.html',
  styleUrls: ['./license-guide-modal.component.scss']
})
export class LicenseGuideModalComponent implements OnInit {
  items = itemsLicenseGuide

  constructor(private dialogRef: MatDialogRef<LicenseGuideModalComponent>) {}

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.closeDialog()
      }
    })

    this.dialogRef.backdropClick().subscribe(() => {
      this.closeDialog()
    })
  }

  keyDownFunction(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.closeDialog()
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }
}
