export const itemsLicenseGuide = [
  {
    title: 'license_guide_module.step1_title',
    description: 'license_guide_module.step1_description',
    src: '/assets/img/guides/licenses/step1.png'
  },
  {
    title: 'license_guide_module.step2_title',
    description: 'license_guide_module.step2_description',
    src: '/assets/img/guides/licenses/step2.png'
  },
  {
    title: 'license_guide_module.step3_title',
    description: 'license_guide_module.step3_description',
    src: '/assets/img/guides/licenses/step3.png'
  },
  {
    title: 'license_guide_module.step4_title',
    description: 'license_guide_module.step4_description',
    src: '/assets/img/guides/licenses/step4.png'
  },
  {
    title: 'license_guide_module.step5_title',
    description: 'license_guide_module.step5_description',
    src: '/assets/img/guides/licenses/step5.png'
  },
  {
    title: 'license_guide_module.step6_title',
    description: 'license_guide_module.step6_description',
    src: '/assets/img/guides/licenses/step6.png'
  },
  {
    title: 'license_guide_module.step7_title',
    description: 'license_guide_module.step7_description',
    src: '/assets/img/guides/licenses/step7.png'
  },
  {
    title: 'license_guide_module.step8_title',
    description: 'license_guide_module.step8_description',
    src: '/assets/img/guides/licenses/step8.png'
  },
  {
    title: 'license_guide_module.step9_title',
    description: 'license_guide_module.step9_description',
    src: '/assets/img/guides/licenses/step9.png'
  },
  {
    title: 'license_guide_module.step10_title',
    description: 'license_guide_module.step10_description',
    src: '/assets/img/guides/licenses/step10.png'
  },
  {
    title: 'license_guide_module.step1_title',
    description: 'license_guide_module.step1_description',
    src: '/assets/img/guides/licenses/step11.png'
  },
  {
    title: 'license_guide_module.step12_title',
    description: 'license_guide_module.step12_description',
    src: '/assets/img/guides/licenses/step12.png'
  },
  {
    title: 'license_guide_module.step13_title',
    description: 'license_guide_module.step13_description',
    src: '/assets/img/guides/licenses/step13.png'
  },
  {
    title: 'license_guide_module.step14_title',
    description: 'license_guide_module.step14_description',
    src: '/assets/img/guides/licenses/step14.png'
  },
  {
    title: 'license_guide_module.step15_title',
    description: 'license_guide_module.step15_description',
    src: '/assets/img/guides/licenses/step15.png'
  }
];
