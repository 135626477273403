import { Component, OnInit, Input } from "@angular/core";
import { Course } from "../../../../domain/course";
import { CourseSetting } from "../../../../domain/courseSetting";
import { CourseOptions } from "../../../../domain/courseOptions";
import { HttpCourseService } from "../../../../infrastructure/http/course/httpcourse.service";
import { FormGroup, FormControl } from "@angular/forms";
import { EXPIRATION_SETTING_VALUES } from "app/application/constants";
import { IStarsSettings } from "app/application/interfaces/course-settings.interface";

@Component({
  selector: "app-available-courses-settings-tab",
  templateUrl: "./available-courses-settings-tab.component.html",
  styleUrls: ["./available-courses-settings-tab.component.scss"],
})
export class AvailableCoursesSettingsTabComponent implements OnInit {
  EXPIRATION_SETTING_VALUES = EXPIRATION_SETTING_VALUES
  @Input() course: Course;
  isCourseSettingsLoading = false
  isExpirationSettingsLoading = false
  isCourseStarsSettingsLoading = false

  settings: CourseSetting[] = [];
  options: CourseOptions[] = [];

  updateSettingsForm: FormGroup
  expirationSettingsForm: FormGroup
  courseStarsSettingsForm: FormGroup

  settingsEditMode: boolean

  initialExpirationSettings
  initialStarsSettings: IStarsSettings

  isCourseSettingsFormEnabled = false
  @Input() isExpirationCourseSettingsEnabled = false

  expirationNumberOptions = Array.from({ length: 100 }, (_, i) => i + 1)
  expirationNumberControl = new FormControl({ value: 1, disabled: true })
  expirationTypeControl = new FormControl({ value: 'year', disabled: true })

  constructor(private courseService: HttpCourseService) {
    this.expirationSettingsForm = new FormGroup({
      courseResults: new FormControl('6')
    });

    this.courseStarsSettingsForm = new FormGroup({
      minimumRequiredStars: new FormControl(1)
    })

    this.courseStarsSettingsForm.disable()
    this.expirationSettingsForm.disable()
  }

  ngOnInit() {
    this.getCourseOptions()
    this.getCourseStars()

    if (this.isExpirationCourseSettingsEnabled) {
      this.initExpirationSettingsForm()
    }
  }

  getCourseStars() {
    this.isCourseStarsSettingsLoading = true

    this.courseService.getCourseStars(this.course).subscribe((courseStarsData) => {
      this.initialStarsSettings = { ...courseStarsData }

      this.courseStarsSettingsForm.controls.minimumRequiredStars.setValue(courseStarsData.minimumRequiredStars || 1)

      this.isCourseStarsSettingsLoading = false
    })
  }

  getCourseOptions() {
    this.isCourseSettingsLoading = true;

    this.courseService.getCourseOptions(this.course).subscribe(
      (options) => {
        if (options) {
          this.options = options;
          this.isCourseSettingsFormEnabled = true;

          this.courseService
            .getCourseSettings(this.course)
            .subscribe((dynamicCourseSettings) => {
              if (dynamicCourseSettings) {
                this.settings = dynamicCourseSettings;
                this.buildSettings()
              } else {
                this.settings = [];
                this.buildSettings()
              }
              this.isCourseSettingsLoading = false;
            });
        } else {
          this.isCourseSettingsFormEnabled = false;
          this.options = [];
          this.settings = [];
          this.isCourseSettingsLoading = false;
        }
      });
  }

  initExpirationSettingsForm() {
    this.isExpirationSettingsLoading = true

    this.courseService.getExpirationCourseSettings(this.course).subscribe((expirationData) => {
      const {
        expirationTerm
      } = expirationData

      const data = this.mapExpiration(expirationTerm)
      this.initialExpirationSettings = { ...data }

      const courseResultsInitialId = data.selectedExpirationId
      const expirationNumberInitial = data.expirationNumber
      const expirationTypeInitial = data.expirationType

      this.expirationSettingsForm.controls.courseResults.setValue(String(courseResultsInitialId))
      this.expirationNumberControl.setValue(expirationNumberInitial)
      this.expirationTypeControl.setValue(expirationTypeInitial)

      this.expirationNumberControl.disable()
      this.expirationTypeControl.disable()

      this.isExpirationSettingsLoading = false
    })
  }



  updateExpirationSettings() {
    const expirationData = {
      ...this.getExpirationTerm()
    }

    this.courseService.setExpirationCourseSettings(this.course.id, expirationData).subscribe(() => {
      this.initialExpirationSettings = { ...expirationData }
    });
  }

  canEditSettings(): boolean {
    const isSettingsLoaded = !this.isCourseSettingsLoading && !this.isExpirationSettingsLoading && !this.isCourseStarsSettingsLoading

    return isSettingsLoaded && !this.settingsEditMode;
  }

  toggleSettingsEditMode(editMode: boolean) {
    this.settingsEditMode = editMode;
    this.toggleCustomInputs()

    if (this.settingsEditMode) {
      this.isExpirationCourseSettingsEnabled && this.expirationSettingsForm.enable()
      this.isCourseSettingsFormEnabled && this.updateSettingsForm.enable();
      this.courseStarsSettingsForm.enable()
    } else {
      this.isExpirationCourseSettingsEnabled && this.expirationSettingsForm.disable()
      this.isCourseSettingsFormEnabled && this.updateSettingsForm.disable();
      this.courseStarsSettingsForm.disable()
    }
  }

  updateSettings() {
    if (this.isCourseSettingsFormEnabled) {
      this.settings.forEach(x => x.applyChanges());
      this.courseService.setCourseSettings(this.course, this.settings);
    }

    if (this.isExpirationCourseSettingsEnabled) {
      this.updateExpirationSettings()
    }

    this.courseService.setCourseStars(this.course, this.courseStarsSettingsForm.value).subscribe(() => {
      this.initialStarsSettings = { ...this.courseStarsSettingsForm.value }
    })

    this.toggleSettingsEditMode(false);
  }

  revertSettings() {
    if (this.isCourseSettingsFormEnabled) {
      this.settings.forEach((x) => x.undoChanges());
    }

    if (this.isExpirationCourseSettingsEnabled) {
      const {
        selectedExpirationId,
        customSettings: {
          expirationNumber,
          expirationType
        }
      } = this.initialExpirationSettings

      if (this.checkExpirationSettingsDirty()) {
        this.expirationSettingsForm.controls.courseResults.setValue(
          String(selectedExpirationId || EXPIRATION_SETTING_VALUES.USE_ORGANIZATION_SETTINGS)
        )
        this.expirationNumberControl.setValue(expirationNumber || '1')
        this.expirationTypeControl.setValue(expirationType || 'Year')
      }
    }

    this.courseStarsSettingsForm.controls.minimumRequiredStars.setValue(this.initialStarsSettings.minimumRequiredStars || 1)

    this.toggleSettingsEditMode(false);
  }

  restoreDefaults() {
    if (this.isCourseSettingsFormEnabled) {
      this.settings.forEach((x) => x.restoreDefaults());
    }

    if (this.isExpirationCourseSettingsEnabled) {
      this.expirationSettingsForm.controls.courseResults.setValue(EXPIRATION_SETTING_VALUES.USE_ORGANIZATION_SETTINGS)
      this.expirationNumberControl.setValue('1')
      this.expirationTypeControl.setValue('Year')

      this.expirationSettingsForm.markAsDirty()
    }

    this.courseStarsSettingsForm.controls.minimumRequiredStars.setValue(1)
    this.courseStarsSettingsForm.markAsDirty()

    this.toggleSettingsEditMode(true);
  }

  hasOnlyDefaults() {
    let isCourseSettingsDefault = true
    let isExpirationFormDefault = true
    let isStarsSettingsDefault = this.courseStarsSettingsForm.controls.minimumRequiredStars.value === 1

    if (this.isCourseSettingsFormEnabled) {
      isCourseSettingsDefault = this.settings.findIndex((x) => !x.isDefault()) < 0;
    }

    if (this.isExpirationCourseSettingsEnabled) {
      const isSelectedExpirationIdDefault = +this.expirationSettingsForm.controls.courseResults.value === +EXPIRATION_SETTING_VALUES.USE_ORGANIZATION_SETTINGS
      const isExpirationNumberDefault = this.expirationNumberControl.value === '1'
      const isExpirationTypeDefault = this.expirationTypeControl.value === 'Year'

      isExpirationFormDefault = isSelectedExpirationIdDefault && isExpirationNumberDefault && isExpirationTypeDefault
    }


    return isCourseSettingsDefault && isExpirationFormDefault && isStarsSettingsDefault
  }

  settingsDirty(): boolean {
    let isExpirationSettingsChanged = false
    let isCourseSettingsChanged = false
    let isStarsSettingsDirty = this.courseStarsSettingsForm.dirty

    if (this.isCourseSettingsFormEnabled) {
      isCourseSettingsChanged = this.settings.findIndex((x) => x.isDirty) >= 0
    }

    if (this.isExpirationCourseSettingsEnabled) {
      isExpirationSettingsChanged = this.checkExpirationSettingsDirty()
    }

    return isCourseSettingsChanged || isExpirationSettingsChanged || isStarsSettingsDirty;
  }

  checkExpirationSettingsDirty() {
    const isExpirationFormDirty = +this.expirationSettingsForm.controls.courseResults.value !== +this.initialExpirationSettings.selectedExpirationId

    return isExpirationFormDirty || this.expirationNumberControl.dirty || this.expirationTypeControl.dirty
  }

  buildSettings() {
    let formData = {};

    this.options.forEach((opt) => {
      let setting = this.settings.find(
        (x) => x.nodeId === opt.nodeId && x.field === opt.field
      );

      if (!setting) {
        setting = new CourseSetting(opt.nodeId, opt.field, opt.defaultValue);
        setting.setOptions(opt, true);
        formData[opt.propertyName] = new FormControl(setting.value);
        this.settings.push(setting);
      } else {
        setting.setOptions(opt, false);
        formData[opt.propertyName] = new FormControl(setting.value);
      }
    })

    this.settings = this.settings.filter((setting) => {
      return setting.hasOptions();
    });

    this.updateSettingsForm = new FormGroup(formData);
    this.updateSettingsForm.disable();
  }

  mapExpiration(expirationTerm) {
    let selectedExpirationId = EXPIRATION_SETTING_VALUES.USE_ORGANIZATION_SETTINGS
    let expirationNumber = '1'
    let expirationType = 'Year'
    if (expirationTerm != null) {
      switch (expirationTerm) {
        case (-1):
          selectedExpirationId = EXPIRATION_SETTING_VALUES.NEVER
          break;
        case (6):
          selectedExpirationId = EXPIRATION_SETTING_VALUES.SIX_MONTH
          break;
        case (12):
          selectedExpirationId = EXPIRATION_SETTING_VALUES.ONE_YEAR
          break;
        case (24):
          selectedExpirationId = EXPIRATION_SETTING_VALUES.TWO_YEARS
          break;
        default:
          selectedExpirationId = EXPIRATION_SETTING_VALUES.CUSTOM
          if (expirationTerm % 12 == 0) {
            expirationType = 'Year'
            expirationNumber = String(expirationTerm / 12)
          }
          else {
            expirationType = 'Month'
            expirationNumber = String(expirationTerm)
          }
      }
    }
    return {
      selectedExpirationId,
      expirationNumber,
      expirationType
    }

  }

  getExpirationTerm() {
    const { courseResults } = this.expirationSettingsForm.value

    let expirationTerm = null

    switch (courseResults) {
      case EXPIRATION_SETTING_VALUES.CUSTOM:
        expirationTerm = Number(this.expirationNumberControl.value)
        if (this.expirationTypeControl.value == 'Year') {
          expirationTerm = expirationTerm * 12
        }
        break;
      case EXPIRATION_SETTING_VALUES.ONE_YEAR:
        expirationTerm = 12;
        break;
      case EXPIRATION_SETTING_VALUES.SIX_MONTH:
        expirationTerm = 6;
        break;
      case EXPIRATION_SETTING_VALUES.TWO_YEARS:
        expirationTerm = 24;
        break;
      case EXPIRATION_SETTING_VALUES.NEVER:
        expirationTerm = -1;
        break;
      case EXPIRATION_SETTING_VALUES.USE_ORGANIZATION_SETTINGS:
        expirationTerm = null;
        break;
    }

    return {
      expirationTerm: expirationTerm,
    }
  }

  toggleCustomInputs() {
    if (this.settingsEditMode) {
      this.expirationNumberControl.enable()
      this.expirationTypeControl.enable()
    } else {
      this.expirationNumberControl.disable()
      this.expirationTypeControl.disable()
    }
  }
}
