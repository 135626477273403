import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as _ from "lodash";
import { ReportType, TokenType } from "ngx-powerbi";
import * as PowerBi from "powerbi-client";
import { AzureReportConfig } from "../../../../domain/azure-report-config";
import { IDashboardEmbedConfiguration, models } from "powerbi-client";
import { PowerBIReportEmbedComponent } from "powerbi-client-angular";
@Component({
  selector: "app-reports-detail",
  templateUrl: "./reports-detail.component.html",
  styleUrls: ["./reports-detail.component.scss"],
})
export class ReportsDetailComponent implements OnInit, OnChanges {
  @Input() public azureReportConfig: AzureReportConfig;
  @Input() public reportsPageName: string;
  @ViewChild("reportContainer") public reportContainer: ElementRef;
  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;
  public optionsForReporting = {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
  };
  private powerBiReportLoaded = false;
  private powerBiReport;
  reportClass = 'report-container hidden';


  public constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (!_.isUndefined(changes.reportsPageName) && !_.isUndefined(this.powerBiReport))
    {

      if (this.powerBiReportLoaded)
      {
        this.setCurrentPage(changes.reportsPageName.currentValue);
      }
    }
  }

  setCurrentPage(pageName: string): void
  {
    console.log("set currentpage");
    const page = this.powerBiReport.page(
      pageName
    );
    page.setActive();

  }

  public onRendered(){
    this.powerBiReportLoaded = true;
    this.reportClass = "report-container";
  }

  public onLoaded() {
    this.powerBiReport = this.reportObj.getReport();
    this.setCurrentPage(this.reportsPageName);
    this.cdr.detectChanges();
  }

  public canShowPowerBiReport(): boolean {
    return this.powerBiReportLoaded;
  }

  public getEmbedConfig(): IDashboardEmbedConfiguration
    {
      const config : IDashboardEmbedConfiguration =
      {
          type: "report",
          id: this.azureReportConfig.id,
          embedUrl: this.azureReportConfig.embedUrl,
          tokenType: models.TokenType.Embed,
          accessToken: this.azureReportConfig.embedToken,
          settings:{
            panes:{
              filters:{
                expanded: false,
                visible: false
              }
            },
            navContentPaneEnabled: false,
            background: models.BackgroundType.Transparent
          }
      }
      return config;
    }

  public getEventHandlers(): Map<string, any>
  {
    return new Map([
      ['loaded', ()=> this.onLoaded()],
      ['rendered', ()=>this.onRendered()]
    ]);
  }
}
