import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import * as _ from "lodash";
import { FileSaverService } from "ngx-filesaver";
import { Course } from "../../../domain/course";
import { HttpGameReportService } from "../../../infrastructure/http/gamereport/httpgamereport.service";
import { ReceiverTypeForReminder } from "../../reminder/receiver-type-for-reminder";
import { SendReminderDialogComponent } from "../../reminder/send-reminder-dialog.component";
import { HeaderNameComponent } from "../users/people/header-name/header-name.component";
import { GroupCourseResultComponent } from "./group-course-result/group-course-result.component";
import { GroupNameResultComponent } from "./group-name/group-name-result.component";
import { HeaderCourseNameComponent } from "./header-course-name/header-course-name.component";
import { DataService } from "../../../infrastructure/dataservice";
import { Subscription } from "rxjs";
import { take, finalize } from "rxjs/operators";
import { OnboardingService } from "../../../infrastructure/http/onboarding/onboarding.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { HttpReportsService } from "../../../infrastructure/http/report/httpreports.service";
import { Feature } from "../../../domain/feature";
import { Router } from "@angular/router";
import { ExportResultsDialogComponent } from "./export-results-dialog/export-results-dialog.component";
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  RefreshCellsParams,
} from "ag-grid-community";

interface CategoryReport {
  id: string;
  name: string;
  groups: any[];
  courses: any[];
}

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.scss"],
})
export class ResultsComponent implements OnInit, OnDestroy {
  @ViewChild("btnTrigger") public btnTrigger: MatMenuTrigger;
  public categoryReports: JSON;
  public gameReportGridOptionsForCategories: {
    categoryName: string;
    columnDefs: ColDef[];
  }[] = [];
  public currentCategory;
  public currentRowData = [];
  public gridOptions: GridOptions;
  public showAbsolutes: boolean;
  private availableCategories: string[] = [];
  private isLoading: boolean;
  private loadResults: boolean = true;
  isExporting = false;
  isDirectUserAssignment = false;
  isExternalLMS = false;
  isOldExcelFormat = false;
  private reportsServiceSubscription : Subscription;

  private guides = [];
  private steps = [
    "GroupProgressStep",
    "ResultsGroupStep",
    "ChooseCategoryStep",
    "SelectActionStep",
    "ExportResultsStep",
    "SendReminderStep",
  ];
  private subscriptionOnboarding: Subscription;

  constructor(
    private gameReportService: HttpGameReportService,
    private reportsService: HttpReportsService,
    private dialog: MatDialog,
    private router: Router,
    private fileSaverService: FileSaverService,
    private onboarding: OnboardingService,
    private dataService: DataService
  ) {}

  public ngOnInit(): void {
    this.gridOptions = <GridOptions>{ 
      rowHeight: 65,
      headerHeight: 65,
      enableSorting: false,
      suppressMovableColumns: true,
      icons: {
        groupExpanded: '<i class="fa-solid fa-angle-down"/>',
        groupContracted: '<i class="fa-solid fa-angle-down"/>',
      },
      onGridReady: (event: GridReadyEvent) => {
        event.api.setColumnDefs(this.getCurrentColumnDefs());
      },
    };
    this.reload();
    this.reportsService.isLoadingFeatures().subscribe((isLoading) => {
      console.debug(`isLoading ${isLoading}`);
      if(isLoading) this.isLoading = isLoading;
    });
  }

  private checkPossibleGuide(): void {
    this.subscriptionOnboarding = this.onboarding
      .getOnboarding()
      .subscribe((res) => {
        for (const key in res) {
          if (
            res[key].name === "Results" &&
            !res[key].isCompleted &&
            this.router.url.includes("results")
          ) {
            this.onboarding
              .openOnboardingDialog(
                "results_guide",
                this.steps,
                this.guides,
                true
              )
              .subscribe(() => {
                this.startOnboardingNavigation();
              });
            this.guides.push(res[key].id);
          }
        }
      });
  }

  public startOnboardingNavigation(): void {
    this.onboarding
      .startOnboardingNavigation(this.steps, "results_guide", this.guides, true)
      .subscribe(
        (res) => {
          if (
            res.name === "SelectActionStep" ||
            res.name === "ExportResultsStep" ||
            res.name === "SendReminderStep"
          ) {
            this.btnTrigger.openMenu();
          } else {
            this.btnTrigger.closeMenu();
          }
        },
        null,
        () => {
          if (this.guides.length) {
            this.btnTrigger.closeMenu();
            this.onboarding.openFinishOnboardingDialog(
              "results_guide",
              this.guides
            );
            this.guides = [];
          }
        }
      );
  }

  public areResultsLoading(): boolean {
    return this.isLoading;
  }

  public noResultsAvailable(): boolean {
    return !this.areResultsLoading() && _.isEmpty(this.categoryReports);
  }

  public noResultsAvailableForCurrentCategory(): boolean {
    return (
      !this.areResultsLoading() &&
      !_.isEmpty(this.categoryReports) &&
      _.isEmpty(this.getCurrentCategoryReport().groups)
    );
  }

  public areResultsAvailable(): boolean {
    return !this.areResultsLoading() && !_.isEmpty(this.categoryReports);
  }

  public reload(): void {
    const { organizationID, language, features } = this.dataService;

    this.isLoading = true;
    this.loadResults = true;

    this.reportsServiceSubscription = this.reportsService
      .getFeatures(organizationID)
      .subscribe((features: Feature[]) => {
          this.loadResults = !features.some((feature) => feature.name === "export_results_disable_sessions");

          features.forEach((feature) => {
            if (feature.name === "export_external_lms") {
              this.isExternalLMS = true;
            }

          if (feature.name === "use_old_excel_format") {
            this.isOldExcelFormat = true;
          }

          if (feature.name === "direct_user_assignments") {
            this.isDirectUserAssignment = true;
          }

          if (feature.name === "export_results_disable_sessions") {
            this.loadResults = false;
          }
        });


        
        //by default do not load the results. clearing of suubject sends empty array.
        if(features.length === 0)
          this.loadResults = false;
        
        if (this.loadResults) {
          this.gameReportService
            .getCategories(organizationID, language)
            .subscribe((categories: JSON) => {
              this.categoryReports = categories;

              _.each(categories, (category: any, key: string) => {
                this.availableCategories.push(category.name);
                if (_.isEqual(key, 0)) {
                  this.currentCategory = category.name;
                  this.currentRowData =
                    this.getCurrentCategoryReport().groups;
                }
                this.gameReportGridOptionsForCategories.push({
                  categoryName: category.name,
                  columnDefs: this.createColumnDefs(category.courses),
                });

                category.groups = category.groups.sort(
                  ({ name: firstName }, { name: secondName }) => {
                    return firstName.toLowerCase() > secondName.toLowerCase()
                      ? 1
                      : -1;
                  }
                );
              });

              this.isLoading = false;
              /*  this.checkPossibleGuide(); */
            });
        } else {
          this.isLoading = false;
          this.categoryReports = null;
        }
      });
  }

  public getAvailableCategories(): string[] {
    return _.reject(this.availableCategories, this.currentCategory); 
  }

  public switchCategory(category: string): void {
    this.currentCategory = category;
    this.currentRowData = this.getCurrentCategoryReport().groups;
    if (!_.isNil(this.gridOptions.api)) {
      this.gridOptions.api.setColumnDefs(this.getCurrentColumnDefs());
    }
  }

  public canShowGridForCurrentCategory(): boolean {
    return !_.isEmpty(this.currentRowData) && this.loadResults;
  }

  public getCurrentCategoryReport(): CategoryReport {
    return <CategoryReport>(
      (<any>_.find(this.categoryReports, ["name", this.currentCategory]))
    );
  }
  public get LoadData(): boolean {
    return this.loadResults; 
  }
  public getCurrentColumnDefs(): ColDef[] {
    return _.find(this.gameReportGridOptionsForCategories, [
      "categoryName",
      this.currentCategory,
    ]).columnDefs;
  }

  public sendReminder(): void {
    const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
      height: "500px",
      width: "1000px",
      data: {
        receiverTypeForReminder: ReceiverTypeForReminder.ALL,
      },
    };
    const dialogRef: MatDialogRef<SendReminderDialogComponent> =
      this.dialog.open(SendReminderDialogComponent, currentDialogConfig);
    dialogRef.afterClosed().subscribe(() => {});
  }

  exportResults() {
    if (this.isExternalLMS) {
      this.isExporting = true;
      return this.gameReportService
        .getDownloadExternalResults(this.dataService.organizationID)
        .subscribe((blob: Blob) => {
          this.isExporting = false;
          const dateString = new DatePipe("en-US").transform(
            Date.now(),
            "dd/MM/yyyy"
          );
          const fileName = "results-" + dateString + ".xlsx";
          this.fileSaverService.save(blob, fileName);
        });
    }

    if (this.isOldExcelFormat) {
      this.isExporting = true;
      return this.gameReportService
        .getDownloadContentCategories(
          this.dataService.organizationID,
          this.dataService.language
        )
        .subscribe((blob: Blob) => {
          this.isExporting = false;
          const dateString = new DatePipe("en-US").transform(
            Date.now(),
            "dd/MM/yyyy"
          );
          const fileName = "results-" + dateString + ".xlsx";
          this.fileSaverService.save(blob, fileName);
        });
    }

    return this.dialog.open(ExportResultsDialogComponent, {
      data: {
        isShowGroupsSelect: !this.isDirectUserAssignment,
      },
    });
  }

  public updateAbsolutes() {
    console.log("updated showAbsolutes:" + this.showAbsolutes);

    if (!_.isNil(this.gridOptions.api)) {
      //this.gridOptions.api.setColumnDefs(this.getCurrentColumnDefs());
      this.gridOptions.api.refreshCells({ force: true });
    }
  }

  private createColumnDefs(courses: Course[]): ColDef[] {
    const colDefs: ColDef[] = [
      <ColDef>{
        headerName: "GROUPS",
        field: "name",
        headerComponentFramework: <new () => HeaderNameComponent>(
          HeaderNameComponent
        ),
        width: 200,
        suppressSizeToFit: true,
        pinned: true,
        cellRendererFramework: GroupNameResultComponent,
        cellRendererParams: {
          getCategoryId: () => {
            return this.getCurrentCategoryReport().id;
          },
        },
      },
    ];

    _.each(
      _.sortBy(courses, (course) => {
        return `${course.level} ${_.lowerCase(course.name)}`;
      }),
      (course) => {
        colDefs.push(<ColDef>{
          headerName: `${course.name}`,
          headerClass: "course-name",
          cellClass: "course-name",
          cellRendererFramework: GroupCourseResultComponent,
          cellRendererParams: {
            courseId: course.id,
            showAbsolutes: () => {
              return this.showAbsolutes;
            },
          },
          sortable: false,
          headerComponentParams: {
            courseLevel: course.level,
          },
          headerComponentFramework: <new () => HeaderCourseNameComponent>(
            HeaderCourseNameComponent
          ),
          width: 300,
        });
      }
    );

    return colDefs;
  }

  public ngOnDestroy() {
    this.subscriptionOnboarding?.unsubscribe();
    this.reportsServiceSubscription?.unsubscribe();
  }
}
