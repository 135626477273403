import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { MaterialModule } from 'app/material.module';

import { ButtonModule } from 'app/shared/app-button/app-button.module';
import { AvailableCoursesComponent } from './available-courses.component';
import { AvailableCoursesListComponent } from './components/available-courses-list/available-courses-list.component';
import { AvailableCoursesDialogComponent } from './components/available-courses-dialog/available-courses-dialog.component';
import { AvailableCoursesInfoTabComponent } from './components/available-courses-info-tab/available-courses-info-tab.component';
import { AvailableCoursesSettingsTabComponent } from './components/available-courses-settings-tab/available-courses-settings-tab.component';
import { TranslateModule } from '@ngx-translate/core';
import { AvailableCoursesInfoFormComponent } from './forms/available-courses-info-form/available-courses-info-form.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    NgSelectModule,
    TranslateModule
  ],
  declarations: [
    AvailableCoursesComponent,
    AvailableCoursesListComponent,
    AvailableCoursesDialogComponent,
    AvailableCoursesInfoTabComponent,
    AvailableCoursesSettingsTabComponent,
    AvailableCoursesInfoFormComponent
  ],
  exports: [AvailableCoursesComponent]
})
export class AvailableCoursesModule {}
