import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login-page/login-page.module').then(m =>  m.LoginPageModule)
  },
  {
    path: 'activate-account',
    loadChildren: () => import('./pages/activate-account-page/activate-account-page.module').then(m => m.ActivateAccountModule)
  },
  // when all pages will be described and loaded here, uncomment this
//   {
//     path: '**',
//     redirectTo: ''
//   }
]

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
