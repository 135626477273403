<mat-list class="course-list">
  <mat-list-item *ngFor="let course of category.courses" class="course-item">
    <div class="course-item-content">
      <div class="course-name" (click)="openDialog(course)">
        <h4 class="course-name-txt">{{ course.level }}. {{ course.name }}</h4>
      </div>
      <div class="edit-icon" (click)="editCourse(course)">
        <mat-icon svgIcon="learning-path-edit"></mat-icon>
      </div>
    </div>
  </mat-list-item>
</mat-list>
