<article class="list-header">
  <div class="header-wrapper">
    <h3>{{ "GROUPS" | translate }}</h3>
    <div class="button-add-group-wrapper" joyrideStep="LinkNewGroupStep" stepPosition="right"
      title="{{ 'courses_guide_module.link_new_group_title' | translate }}"
      text="{{ 'courses_guide_module.link_new_group_hint' | translate }}">
      <button class="header-button mat-basic-button mat-little-button" mat-flat-button color="primary"
        [disabled]="!canAddSelectedCoursesToNewGroup()" (click)="addSelectedCoursesToNewGroup()">
        {{ "LINK NEW GROUP" | translate }}
      </button>
    </div>
  </div>
  <app-filter (filteredTextChanged)="filterGroups($event)" joyrideStep="SearchGroupStep" stepPosition="right"
    title="{{ 'courses_guide_module.search_group_title' | translate }}"
    text="{{ 'courses_guide_module.search_group_hint' | translate }}" placeholder="{{ 'FIND' | translate }}">
  </app-filter>
</article>
<article class="list-content group-list-content" fxFlex>
  <mat-list>
    <virtual-scroller class="group-list-scroller" #scroll [items]="filteredGroups" (vsUpdate)="scrollItems = $event">
      <mat-list-item *ngFor="let group of scrollItems; let indexGroup = index">
        <div mat-line>
          <h5 *ngIf="indexGroup === 0; else notFirstGroupElement" class="group-name antialiased"
            joyrideStep="UserOpenGroupStep" stepPosition="left"
            title="{{ 'courses_guide_module.name_group_title' | translate }}"
            text="{{ 'courses_guide_module.name_group_hint' | translate }}" (click)="showGroupProfile(group)">
            {{ group.name }}</h5>
          <ng-template #notFirstGroupElement>
            <h5 class="group-name antialiased" (click)="showGroupProfile(group)">{{ group.name }}</h5>
          </ng-template>
          <span class="group-details antialiased">{{
            "course_groups.selected_courses_linked"
              | translate
                : {
                    numberOfSelectedCoursesForGroup:
                      getNumberOfSelectedCoursesForGroup(group),
                    numberOfSelectedCourses: getNumberOfSelectedCourses()
                  }
          }}</span>
        </div>
        <div *ngIf="indexGroup === 0; else notFirstGroup" class="group-button-wrapper" joyrideStep="LinkGroupStep"
          stepPosition="left" title="{{ 'courses_guide_module.link_group_title' | translate }}"
          text="{{ 'courses_guide_module.link_group_hint' | translate }}">
          <button mat-icon-button (click)="removeGroupFromSelectedCourses(group)"
            [disabled]="!canRemoveGroupFromSelectedCourses(group)" matTooltip="{{
              'NUMBER OF COURSES TO REMOVE GROUP FROM'
                | translate
                  : {
                      numberOfCoursesToRemoveGroupFrom:
                        getNumberOfCoursesToRemoveGroupFrom(group)
                    }
            }}">
            <mat-icon svgIcon="remove-user"></mat-icon>
          </button>
          <button mat-icon-button [disabled]="!canAddGroupToSelectedCourses(group)"
            (click)="addGroupToSelectedCourses(group)" matTooltip="{{
              'NUMBER OF COURSES TO ADD GROUP TO'
                | translate
                  : {
                      numberOfCoursesToAddGroupTo:
                        getNumberOfCoursesToAddGroupTo(group)
                    }
            }}">
            <mat-icon svgIcon="add-user"></mat-icon>
          </button>
        </div>
        <ng-template #notFirstGroup>
          <button mat-icon-button (click)="removeGroupFromSelectedCourses(group)"
            [disabled]="!canRemoveGroupFromSelectedCourses(group)" matTooltip="{{
              'NUMBER OF COURSES TO REMOVE GROUP FROM'
                | translate
                  : {
                      numberOfCoursesToRemoveGroupFrom:
                        getNumberOfCoursesToRemoveGroupFrom(group)
                    }
            }}">
            <mat-icon svgIcon="remove-user"></mat-icon>
          </button>
          <button mat-icon-button [disabled]="!canAddGroupToSelectedCourses(group)"
            (click)="addGroupToSelectedCourses(group)" matTooltip="{{
              'NUMBER OF COURSES TO ADD GROUP TO'
                | translate
                  : {
                      numberOfCoursesToAddGroupTo:
                        getNumberOfCoursesToAddGroupTo(group)
                    }
            }}">
            <mat-icon svgIcon="add-user"></mat-icon>
          </button>
        </ng-template>
      </mat-list-item>
    </virtual-scroller>
  </mat-list>
</article>
