<section>
	<header class="primary-color">
		<button class="close-button" mat-icon-button type="button" mat-dialog-close>
			<mat-icon class="icon-clear">clear</mat-icon>
		</button>
	</header>
	<mat-dialog-content class="game-link-dialog-container">
		<h5>
			{{ 'login.game_link' | translate }}
			<a href="https://play.playitsafe.eu" target="_blank" rel="noreferrer noopener">
				https://play.playitsafe.eu
			</a>
		</h5>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button color="primary" mat-dialog-close>Ok</button>
	</mat-dialog-actions>
</section>
