import { IDashboardEmbedConfiguration, models } from 'powerbi-client';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as _ from "lodash";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AzureReportConfig } from "../../../domain/azure-report-config";
import { Feature } from "../../../domain/feature";
import { HttpReportsService } from "../../../infrastructure/http/report/httpreports.service";
import { DataService } from "../../../infrastructure/dataservice";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { OnboardingService } from "../../../infrastructure/http/onboarding/onboarding.service";
import { DashboardGuideDialogComponent } from "./dashboard-guide-dialog/dashboard-guide-dialog.component";
import { ReportType, TokenType } from "ngx-powerbi";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild("reportContainer") public reportContainer: ElementRef;
  reportClass = 'dashboard-container';

  private dashboardEnabled = false;
  private azureReportConfig: AzureReportConfig;
  private powerBiReportLoaded;
  private guides = [];
  private subscriptionOnboarding: Subscription;
  private dialogConfigGuide: MatDialogConfig = <MatDialogConfig>{
    height: "560px",
    width: "600px",
  };
  private itemsDashboardGuide = [
    {
      title: "dashboard_guide_module.total_users_title",
      description: "dashboard_guide_module.total_users_hint",
      src: "/assets/img/guides/screen1.png",
    },
    {
      title: "dashboard_guide_module.playing_users_title",
      description: "dashboard_guide_module.playing_users_hint",
      src: "/assets/img/guides/screen2.png",
    },
    {
      title: "dashboard_guide_module.accepted_users_title",
      description: "dashboard_guide_module.accepted_users_hint",
      src: "/assets/img/guides/screen3.png",
    },
    {
      title: "dashboard_guide_module.invited_users_title",
      description: "dashboard_guide_module.invited_users_hint",
      src: "/assets/img/guides/screen4.png",
    },
    {
      title: "dashboard_guide_module.used_platforms_title",
      description: "dashboard_guide_module.used_platforms_hint",
      src: "/assets/img/guides/screen5.png",
    },
    {
      title: "dashboard_guide_module.online_today_title",
      description: "dashboard_guide_module.online_today_hint",
      src: "/assets/img/guides/screen6.png",
    },
    {
      title: "dashboard_guide_module.online_7day_title",
      description: "dashboard_guide_module.online_7day_hint",
      src: "/assets/img/guides/screen7.png",
    },
    {
      title: "dashboard_guide_module.online_30day_title",
      description: "dashboard_guide_module.online_30day_hint",
      src: "/assets/img/guides/screen8.png",
    },
    {
      title: "dashboard_guide_module.online_12months_title",
      description: "dashboard_guide_module.online_12months_hint",
      src: "/assets/img/guides/screen9.png",
    },
    {
      title: "dashboard_guide_module.top5_play_title",
      description: "dashboard_guide_module.top5_play_hint",
      src: "/assets/img/guides/screen10.png",
    },
    {
      title: "dashboard_guide_module.top5_level_title",
      description: "dashboard_guide_module.top5_level_hint",
      src: "/assets/img/guides/screen11.png",
    },
  ];

  public constructor(
    private reportsService: HttpReportsService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private onboarding: OnboardingService,
    private dialog: MatDialog
  ) {}

  public reload(): void {

      this.powerBiReportLoaded = false;
      this.dashboardEnabled = _.some(this.dataService.features, x => x == "reporting_powerbi_dashboard");

      if (this.dashboardEnabled) {
        this.azureReportConfig = null;
        this.reportsService
          .getDashboardConfig(this.dataService.organizationID)
          .subscribe((azureReportConfig: AzureReportConfig) => {
            this.azureReportConfig = azureReportConfig;
          });
      }
  }

  public ngOnInit(): void {
   /*  this.checkOnboarding(); */
    this.reload();
  }

  private checkOnboarding(): void {
    this.subscriptionOnboarding = this.onboarding
      .getOnboarding()
      .subscribe((res) => {
        let isNavigationGuideCompleted = true;
        let isDashboardGuideCompleted = true;
        this.guides = [];

        for (const key in res) {
          if (res[key].name === "Dashboard" && !res[key].isCompleted) {
            this.guides.push(res[key].id);
            isDashboardGuideCompleted = false;
          } else if (res[key].name === "Navigation" && !res[key].isCompleted) {
            isNavigationGuideCompleted = false;
          }
        }

        if (!isDashboardGuideCompleted && isNavigationGuideCompleted) {
          this.onboarding
            .openOnboardingDialog("dashboard_guide", [], this.guides, true)
            .subscribe(() => {
              this.startOnboardingNavigation();
            });
        }
      });
  }

  public startOnboardingNavigation(): void {
    const dialogRef: MatDialogRef<DashboardGuideDialogComponent> =
      this.dialog.open(DashboardGuideDialogComponent, this.dialogConfigGuide);
    dialogRef.componentInstance.items = this.itemsDashboardGuide;
    dialogRef.componentInstance.isShowCloseButton = true;
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(() => {
      if (this.guides.length) {
        this.onboarding.openFinishOnboardingDialog(
          "dashboard_guide",
          this.guides
        );
        this.guides = [];
      }
    });
  }

  public isLoading(): boolean {
    return false;
  }

  public canShowLoader(): boolean{
    return this.dashboardEnabled && !this.canShowPowerBiDashboard();
  }

  public canShowDashboard(): boolean {
    return this.dashboardEnabled;
  }

  public canShowDashboardReport(): boolean {
    return this.canShowDashboard() && !_.isNil(this.azureReportConfig);
  }

  public getAzureReportConfig(): AzureReportConfig {
    return this.azureReportConfig;
  }

  public onLoaded(){
    this.cdr.detectChanges();
    this.powerBiReportLoaded = true;
    //this.reportContainer.nativeElement.classList
    //this.dashClass.style.backgroundColor = "";
  }

  public canShowPowerBiDashboard(): boolean {
    return this.powerBiReportLoaded;
  }

  public getEmbedConfig(): IDashboardEmbedConfiguration
    {
      const config : IDashboardEmbedConfiguration =
      {
          type: "dashboard",
          id: this.azureReportConfig.id,
          embedUrl: this.azureReportConfig.embedUrl,
          tokenType: models.TokenType.Embed,
          accessToken: this.azureReportConfig.embedToken,
          settings: {
            background: models.BackgroundType.Transparent
          }
      }
      return config;
    }

  public getEventHandlers(): Map<string, any>
  {
    return new Map([
      ['loaded', ()=> this.onLoaded()]
    ]);
  }


    ngOnDestroy() {
        this.subscriptionOnboarding?.unsubscribe();
    }

}
