import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { LicencesNotificationsService } from 'app/application/licences-notifications/licences-notifications.service';
import { Subscription } from 'rxjs';

import { INotifications } from 'app/application/interfaces/notifications.interface';

@Component({
  selector: 'app-notifications-popover',
  templateUrl: './notifications-popover.component.html',
  styleUrls: ['./notifications-popover.component.scss']
})
export class NotificationsPopoverComponent implements OnInit, OnDestroy {
  shouldShowSpinner = false
  notifications: INotifications[] = []

  @Output() onCloseEvent = new EventEmitter()

  unreadNumber: number
  unreadNumber$: Subscription

  constructor(
    private notificationsService: LicencesNotificationsService,
  ) {}

  ngOnInit() {
    if (this.notificationsService.isDataLoading) {
      this.shouldShowSpinner = true
    }

    this.notificationsService.notifications$.subscribe((notifications) => {
      this.notifications = this.getUrlFragment(notifications)
      this.shouldShowSpinner = false
    })

    this.unreadNumber$ = this.notificationsService.unreadNumber$.subscribe((value) => {
      this.unreadNumber = value
    })

    this.notifications = this.getUrlFragment(this.notificationsService.notifications)
    this.notificationsService.setUnreadNotificationsNumber()
  }

  ngOnDestroy() {
    this.unreadNumber$.unsubscribe()
  }

  markAsRead(id: string) {
    this.notificationsService.markAsRead(id)
  }

  markAllRead() {
    this.notificationsService.markAllRead()
  }

  deleteNotification(id: string) {
    this.notificationsService.deleteNotification(id)
  }

  closeNotifications() {
    this.onCloseEvent.emit(false)
  }

  getUrlFragment(notifications: INotifications[]): INotifications[] {
    return notifications.map((n) => {
      const { viewDetailsUrl: originUrl } = n

      if (originUrl) {
        const [viewDetailsUrl, fragment] = originUrl?.split('#')
        return {
          ...n,
          viewDetailsUrl,
          fragment
        }
      }

      return n
    })
  }
}
