<section class="courseSettings">
  <header class="settings__header">
    <h4>{{ "COURSESETTINGS" | translate }}</h4>
    <div class="settings__buttons">
      <button color="primary" *ngIf="canEditSettings()" mat-flat-button class="mat-little-button"
        (click)="toggleSettingsEditMode(true)">
        {{ "EDIT" | translate }}
      </button>
      <button *ngIf="settingsEditMode" mat-flat-button mat-button color="primary" class="mat-primary mat-little-button"
        (click)="updateSettings()" [disabled]="!settingsDirty()">
        {{ "SAVE" | translate }}
      </button>
      <button *ngIf="settingsEditMode" mat-flat-button mat-button class="mat-secondary mat-little-button"
        (click)="revertSettings()">
        {{ "DISCARD" | translate }}
      </button>
      <button [disabled]="hasOnlyDefaults()" mat-flat-button mat-button
        class="mat-secondary mat-little-button restore-defaults" (click)="restoreDefaults()">
        {{ "RESTORE DEFAULTS" | translate }}
      </button>
    </div>
  </header>

  <article *ngIf="isCourseSettingsLoading || isExpirationSettingsLoading || isCourseStarsSettingsLoading">
    <mat-spinner class="listspinner" diameter="30"></mat-spinner>
  </article>

  <article *ngIf="!isCourseSettingsLoading && !isExpirationSettingsLoading && !isCourseStarsSettingsLoading"
    class="settings__container">
    <div *ngIf="isCourseSettingsFormEnabled" [formGroup]="updateSettingsForm" class="settings">
      <h5 class="settings__title">
        {{'courses_settings_module.scenario_settings_title' | translate}}
      </h5>
      <mat-list>
        <mat-list-item *ngFor="let opt of settings">
          <div class="input-row" *ngIf="!opt.isFreeForm()">
            <mat-form-field class="full-width">
              <mat-select [(ngModel)]="opt.previewValue" [ngClass]="{ 'setting-dirty': opt.isDirty }"
                [formControlName]="opt.propertyName" [placeholder]="opt.propertyName">
                <mat-option *ngFor="let option of opt.options" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="input-row" *ngIf="opt.isFreeForm()">
            <mat-form-field class="full-width">
              <input matInput [ngClass]="{ 'setting-dirty': opt.isDirty }" [placeholder]="opt.propertyName"
                [formControlName]="opt.propertyName" [(ngModel)]="opt.previewValue" />
            </mat-form-field>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

    <div *ngIf="isExpirationCourseSettingsEnabled" class="expiration-settings" [formGroup]="expirationSettingsForm">
      <div class="expiration-settings__courses">
        <h5 class="expiration-settings__courses-title">
          {{'courses_settings_module.expiration_settings_title' | translate}}
        </h5>

        <mat-radio-group color="primary" class="expiration-settings__options" formControlName="courseResults">
          <mat-radio-button class="expiration-settings__option-button"
            [value]="EXPIRATION_SETTING_VALUES.USE_ORGANIZATION_SETTINGS">
            Use organization settings
          </mat-radio-button>
          <mat-radio-button class="expiration-settings__option-button" [value]="EXPIRATION_SETTING_VALUES.SIX_MONTH">
            6 {{'courses_settings_module.expiration_month_label' | translate}}
          </mat-radio-button>
          <mat-radio-button class="expiration-settings__option-button" [value]="EXPIRATION_SETTING_VALUES.ONE_YEAR">
            1 {{'courses_settings_module.expiration_year_label' | translate}}
          </mat-radio-button>
          <mat-radio-button class="expiration-settings__option-button" [value]="EXPIRATION_SETTING_VALUES.TWO_YEARS">
            2 {{'courses_settings_module.expiration_years_label' | translate}}
          </mat-radio-button>
          <mat-radio-button class="expiration-settings__option-button" [value]="EXPIRATION_SETTING_VALUES.NEVER">
            {{'courses_settings_module.expiration_never_label' | translate}}
          </mat-radio-button>

          <div class="custom-option">
            <mat-radio-button class="expiration-settings__option-button" [value]="EXPIRATION_SETTING_VALUES.CUSTOM">
              {{'courses_settings_module.expiration_custom_label' | translate}}
            </mat-radio-button>

            <div class="custom-option__container" [hidden]="expirationSettingsForm.get('courseResults').value !== '5'">
              <mat-form-field class="input-number">
                <mat-select [formControl]="expirationNumberControl">
                  <mat-option *ngFor="let num of expirationNumberOptions" [value]="num + ''">
                    {{num}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="input-type">
                <mat-select [formControl]="expirationTypeControl">
                  <mat-option value="Year">
                    {{'courses_settings_module.expiration_year_label' | translate}}
                  </mat-option>
                  <mat-option value="Month">
                    {{'courses_settings_module.expiration_month_label' | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
        </mat-radio-group>
      </div>
    </div>

    <div class="expiration-settings" [formGroup]="courseStarsSettingsForm">
      <div class="expiration-settings__stars">
        <h5 class="expiration-settings__stars-title">
          {{'courses_settings_module.stars_settings_title' | translate}}
        </h5>

        <mat-radio-group color="primary" class="expiration-settings__options" formControlName="minimumRequiredStars">
          <mat-radio-button class="expiration-settings__option-button" [value]="1">
            1 {{'courses_settings_module.star_label' | translate}}
          </mat-radio-button>
          <mat-radio-button class="expiration-settings__option-button" [value]="2">
            2 {{'courses_settings_module.stars_label' | translate}}
          </mat-radio-button>
          <mat-radio-button class="expiration-settings__option-button" [value]="3">
            3 {{'courses_settings_module.stars_label' | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </article>
</section>
