import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TOAST_TYPES } from "app/application/constants";
import {
  ILearningModuleCopyData,
  ILearningModuleCreated,
  ILearningModuleInfo,
  ILearningModuleListItem,
  ILearningPathItem
} from "app/application/interfaces/learning-paths.interface";
import { ToastService } from "app/application/toast/toast.service";
import { EventBusService } from "app/core/event-bus.service";
import { generateNewWorkingName } from "app/utils/learning-path.utils";
import { Subscription } from "rxjs";
import { ACTIONS } from "../../learning-path.actions";
import { LearningModulesService } from "../../services/learning-modules.service";

@Component({
  selector: "app-learning-path-list",
  templateUrl: "./learning-path-list.component.html",
  styleUrls: ["./learning-path-list.component.scss"],
})
export class LearningPathListComponent implements OnInit, OnDestroy {
  @Input() learningPathsList: ILearningPathItem[] = [];

  subs$: Subscription[] = [];

  constructor(
    private toastService: ToastService,
    private learningModulesService: LearningModulesService,
    private eventService: EventBusService
  ) {}

  ngOnInit(): void {
    this.subscribeToEvents()
  }

  ngOnDestroy(): void {
    this.eventService.removeSubscriptions(this.subs$)
  }

  subscribeToEvents() {
    const moduleUpdateSub$ = this.eventService.subscribeEvent(
      ACTIONS.LEARNING_MODULE_UPDATE,
      (
        {
          learningPathIndex,
          updatedLearningModule,
          learningModuleIndex
        }: {
          learningPathIndex: number
          updatedLearningModule: ILearningModuleCreated,
          learningModuleIndex: number
        }
      ) => {
        const { id, workingName, orderIndex } = updatedLearningModule
        const learningModuleListItem = { id, workingName, orderIndex };
        const learningModuleItem = {
          ...learningModuleListItem,
          index: learningModuleIndex
        };

        this.updateLearningModuleList(
          learningPathIndex,
          learningModuleItem,
          learningModuleListItem
        );
      }
    )

    const moduleCopySub$ = this.eventService.subscribeEvent(
      ACTIONS.LEARNING_MODULE_COPY,
      (data: ILearningModuleCopyData) => {
        this.copyLearningModule(data);
      }
    );

    const moduleDeleteSub$ = this.eventService.subscribeEvent(
      ACTIONS.LEARNING_MODULE_DELETE,
      (data: ILearningModuleCopyData) => {
        this.deleteLearningModuleItem(data);
      }
    );

    this.subs$.push(moduleCopySub$, moduleDeleteSub$, moduleUpdateSub$)
  }

  setLearningPathItemLoading(learningPathIndex: number, state: boolean) {
    const learningPathCopy = {...this.learningPathsList[learningPathIndex]}
    learningPathCopy.isLoading = state
    learningPathCopy.isExpanded = true
    this.learningPathsList[learningPathIndex] = learningPathCopy
  }

  updateLearningModuleList(
    learningPathIndex: number,
    learningModule: ILearningModuleInfo,
    newLearningModule?: ILearningModuleListItem
  ) {
    const { id, index } = learningModule
    const learningPathCopy = {...this.learningPathsList[learningPathIndex]}

    const foundModuleIndex = learningPathCopy.item.learningModules.findIndex((item) => item.id === id)

    // delete case
    if (foundModuleIndex !== -1 && !newLearningModule) {
      learningPathCopy.item.learningModules.splice(foundModuleIndex, 1);
    }

    if (newLearningModule) {
      if (id === newLearningModule.id) {
        // edit case
        learningPathCopy.item.learningModules.splice(index, 1, newLearningModule)
      } else {
        // copy case
        learningPathCopy.item.learningModules.push(newLearningModule);
      }
    }

    this.learningPathsList[learningPathIndex] = learningPathCopy
  }

  copyLearningModule({
    learningPath,
    learningModule,
  }: ILearningModuleCopyData) {
    const { id: learningPathId, index: learningPathIndex } = learningPath;
    const { id: learningModuleId, workingName: oldWorkingName } = learningModule;
    const { learningModules } = this.learningPathsList[learningPathIndex].item;

    const learningModulesMappedList = learningModules.map((item) => item.workingName.toLowerCase());
    const newWorkingName = generateNewWorkingName(oldWorkingName, learningModulesMappedList);

    this.setLearningPathItemLoading(learningPathIndex, true);
    this.learningModulesService
      .copyLearningModule(learningPathId, learningModuleId, newWorkingName)
      .subscribe(
        (newLearningModule: ILearningModuleCreated) => {
          this.updateLearningModuleList(
            learningPathIndex,
            learningModule,
            newLearningModule
          );
          this.setLearningPathItemLoading(learningPathIndex, false);
          this.toastService.show(`Learning module "${oldWorkingName}" is copied`);
        },
        (error: HttpErrorResponse) => {
          this.setLearningPathItemLoading(learningPathIndex, false);
          this.toastService.show(error.message, TOAST_TYPES.ERROR);
        }
      );
  }

  deleteLearningModuleItem({ learningPath, learningModule }: ILearningModuleCopyData) {
    const { id: learningPathId, index: learningPathIndex } = learningPath;
    const { id: learningModuleId } = learningModule;

    this.setLearningPathItemLoading(learningPathIndex, true);
    this.learningModulesService
      .deleteLearningModule(learningPathId, learningModuleId)
      .subscribe(
        () => {
          this.updateLearningModuleList(learningPathIndex, learningModule);
          this.setLearningPathItemLoading(learningPathIndex, false);
        },
        (error: HttpErrorResponse) => {
          this.setLearningPathItemLoading(learningPathIndex, false);
          this.toastService.show(error.message, TOAST_TYPES.ERROR);
        }
      );
  }
}
