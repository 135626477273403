export const ACTIONS = {
  LEARNING_PATH_DELETE: 'LEARNING_PATH_DELETE',
  LEARNING_PATH_CREATE: 'LEARNING_PATH_CREATE',
  LEARNING_PATH_UPDATE: 'LEARNING_PATH_UPDATE',
  LEARNING_PATH_COPY: 'LEARNING_PATH_COPY',
  LEARNING_PATH_CHECK: 'LEARNING_PATH_CHECK',
  LEARNING_PATH_UNCHECK: 'LEARNING_PATH_UNCHECK',
  LEARNING_MODULE_CREATE: 'LEARNING_MODULE_CREATE',
  LEARNING_MODULE_DELETE: 'LEARNING_MODULE_DELETE',
  LEARNING_MODULE_UPDATE: 'LEARNING_MODULE_UPDATE',
  LEARNING_MODULE_COPY: 'LEARNING_MODULE_COPY',
  LEARNING_MODULE_SCORM_CREATED: 'LEARNING_MODULE_SCORM_CREATED',
  LEARNING_MODULE_SCORM_REMOVED: 'LEARNING_MODULE_SCORM_REMOVED',
}
