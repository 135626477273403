import { DatePipe } from "@angular/common";
import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import * as _ from "lodash";
import { FileSaverService } from "ngx-filesaver";
import { combineLatest, Observable, Subscription,ReplaySubject } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { NotificationService } from "../../../../application/notification/notification.service";
import { validateResponsibleUser } from "../../../../application/validators/responsible-user-validation";
import { Category } from "../../../../domain/category";
import { Group } from "../../../../domain/group";
import { Language } from "../../../../domain/language";
import { Organization } from "../../../../domain/organization";
import { PlayEnvironment } from "../../../../domain/playEnvironment";
import RoleEnum from "../../../../domain/roleEnum";
import StatusEnum from "../../../../domain/statusEnum";
import { User } from "../../../../domain/user";
import { HttpCategoryService } from "../../../../infrastructure/http/category/httpcategory.service";
import { HttpGroupsService } from "../../../../infrastructure/http/group/httpgroups.service";
import { HttpLanguageService } from "../../../../infrastructure/http/language/httplanguage.service";
import { HttpOrganizationService } from "../../../../infrastructure/http/organization/httporganization.service";
import { HttpUserService } from "../../../../infrastructure/http/user/httpuser.service";
import { DataService } from "../../../../infrastructure/dataservice";
import { TranslateService } from "@ngx-translate/core";
import { HttpReportsService } from "../../../../infrastructure/http/report/httpreports.service";
import { OnboardingService } from "../../../../infrastructure/http/onboarding/onboarding.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { EXPIRATION_SETTING_VALUES, SUPPORTED_LANGUAGES } from "app/application/constants";
import { IStarsSettings } from "app/application/interfaces/course-settings.interface";
import { ILearningPath } from '../../../../application/interfaces/learning-paths.interface';
import { LearningPathService } from './../../../../features/learning-path/services/learning-path.service';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { validatePasswordMatch } from "../../../../application/validators/password-match-validation";
import { UserPasswordComponent } from "../password/user-password.component";

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  EXPIRATION_SETTING_VALUES = EXPIRATION_SETTING_VALUES
  private eventEmitterNotifier: EventEmitter<null> = new EventEmitter();
  public user: User;
  public originalUser: User;
  public userEditMode: boolean;
  public groupsOfUser: Group[] = [];
  public categoriesOfUser: Category[] = [];
  expirationYearLabel: any;
  expirationMonthLabel: any;

  get organization(): Organization {
    return this.dataService.CurrentOrganization;
  }

  public organizationEditMode: boolean;
  public users: User[] = [];
  public filteredUsers: Observable<User[]>;
  @ViewChild("logoFileInput") public logoFileInput: ElementRef;
  public updateUserForm: FormGroup;
  public updateOrganizationForm: FormGroup;
  public playEnvironments: PlayEnvironment[] = [];
  public userLanguages: Language[] = [];
  public canDownloadCertificate = false;
  public downloadingCertificate = false;
  public loadinggroups = false;
  public loadingcourses = false;
  public canShowGroupsAndCourses = true;
  public showCompanyTab = false;
  public companyTabIsActive = false;
  public infoTabIsActive = true;
  public isOpenSelectInfo = new ReplaySubject<boolean>();
  public isOpenSelectCompany = new ReplaySubject<boolean>();
  public shouldShowLearningPaths = false;
  public isLearningPathsLoading = false
  public learningPathsList: ILearningPath[]

  private guides = { info: "", company: "" };
  private groupSteps = [
    "EditCompanyStep",
    "InternalEmergencyNumberStep",
    "ExternalEmergencyNumberStep",
    "LanguageCompanyStep",
    "ResponsibleUserStep",
    "EnvironmentStep",
  ];
  private userSteps = [
    "EditInfoStep",
    "FillInfoStep",
    "DownloadCertificateStep",
  ];

  public userRoles=[
    {
      name: "Player",
      value: RoleEnum.player
    },
    {
      name: "Admin",
      value: RoleEnum.admin
    },
    {
      name: "Creator",
      value: RoleEnum.creator
    },
    {
      name: "ServiceAccount",
      value: RoleEnum.serviceAccount
    }
  ]
  private subscriptionOnboarding: Subscription;

  isExpirationSettingsLoading = false
  isStarsSettingsLoading = false
  isExpirationSettingsEnabled = false

  courseStarsSettingsForm: FormGroup
  expirationSettingsForm: FormGroup
  settingsEditMode = false

  initialStarsSettings: IStarsSettings

  expirationNumberOptions = Array.from({ length: 100 }, (_, i) => i + 1)
  expirationNumberControl = new FormControl()
  expirationTypeControl = new FormControl()
  isLoggedInUserSuperUser =false

  constructor(
    private dialogRef: MatDialogRef<UserDetailComponent>,
    private userService: HttpUserService,
    private groupService: HttpGroupsService,
    private categoryService: HttpCategoryService,
    private learningpathsService: LearningPathService,
    private organizationService: HttpOrganizationService,
    private languageService: HttpLanguageService,
    private notificationService: NotificationService,
    private fileSaverService: FileSaverService,
    private dataService: DataService,
    private translateService: TranslateService,
    private reportsService: HttpReportsService,
    @Inject(MAT_DIALOG_DATA) data,
    private onboarding: OnboardingService,
    private userPasswordDialog: MatDialog,
    private cdref: ChangeDetectorRef,
    private  applicationRef: ApplicationRef,
    private ngZone: NgZone
  ) {
    this.userEditMode = false;
    this.originalUser = data.user;
    this.user = _.cloneDeep(data.user);
    this.organizationEditMode = false;
    this.showCompanyTab = data.showCompanyTab || false;
    this.canDownloadCertificate = _.some(this.dataService.features, x=> x== "certifications_export_pdf_user");
    this.isExpirationSettingsEnabled = this.showCompanyTab && _.some(this.dataService.features, x=> x== 'expiration_courses_custom_expirationterm');
    if (this.isExpirationSettingsEnabled)
    {
      this.expirationSettingsFormInit()
    }

    this.courseStarsSettingsForm = new FormGroup({
      minimumRequiredStars: new FormControl(1)
    })
    this.shouldShowLearningPaths = 	_.some(this.dataService.features, x=> x == "learningpaths");
    this.courseStarsSettingsForm.disable()
  }

  ngOnInit() {

    this.isLoggedInUserSuperUser = this.dataService.userRole == 30;
    /* this.checkPossibleGuide(); */
    this.setUserDetails();
    this.getUserGroups();

    if(this.shouldShowLearningPaths)
      this.getUserLearningPaths();
    else
      this.getUserCourses();

    if(this.showCompanyTab)
      this.setOrganizationDetails();

   this.starsSettingsFormInit()

   /*this.updateUserForm
      .get("userVirtualEmail")
      .valueChanges.subscribe((virtualEmail: boolean) => {
        if (virtualEmail) {
          this.updateUserForm.addControl(
            "passwordGroup",
            new FormGroup(
              {
                password: new FormControl("", Validators.required),
                passwordConfirm: new FormControl("", Validators.required),
              },
              validatePasswordMatch
            )
          );
        }
      });*/

      //Fix for translated labels in mat-select causing ExpresionChangedAfterItHasBeenCheckedError
      this.translateService.get(["courses_settings_module.expiration_year_label"]).subscribe(v => {
        this.expirationYearLabel = v;
      });
      this.translateService.get(["courses_settings_module.expiration_month_label"]).subscribe(v => {
        this.expirationMonthLabel = v;
      });
  }


  openPasswordDialog(): void {
    const config = new MatDialogConfig();
    config.minHeight = "392px";
    config.width = "506px";
    config.data = this.originalUser.id
    
    
    const dialogRef = this.userPasswordDialog.open(UserPasswordComponent, config);
    dialogRef.afterClosed().subscribe(result => {});
  }

  starsSettingsFormInit() {
    this.isStarsSettingsLoading = true

    this.organizationService.getOrganizationStarsSettings().subscribe((courseStarsData) => {
      this.initialStarsSettings = { ...courseStarsData }

      this.courseStarsSettingsForm.controls.minimumRequiredStars.setValue(courseStarsData.minimumRequiredStars || 1)

      this.isStarsSettingsLoading = false
    })
  }

  expirationSettingsFormInit() {
    this.isExpirationSettingsLoading = true

    this.organizationService.getOrganizationCourseSettings().subscribe(
      (expirationData) => {
        const {
          expirationTerm
        } = expirationData

        this.isExpirationSettingsLoading = false

        const data = this.mapExpiration(expirationTerm)

        const courseResultsInitialId = data.selectedExpirationId
        const expirationNumberInitial = data.expirationNumber
        const expirationTypeInitial = data.expirationType

        this.expirationSettingsForm = new FormGroup({
          courseResults: new FormControl(String(courseResultsInitialId))
        })

        this.expirationNumberControl.setValue(expirationNumberInitial)
        this.expirationTypeControl.setValue(expirationTypeInitial)

        this.expirationNumberControl.disable()
        this.expirationTypeControl.disable()
        this.expirationSettingsForm.disable()

        this.isExpirationSettingsLoading = false



      })
  }

  toggleSettingsEditMode(): void {
    this.settingsEditMode = !this.settingsEditMode;

    if (this.settingsEditMode) {
      this.isExpirationSettingsEnabled && this.expirationSettingsForm.enable();
      this.courseStarsSettingsForm.enable()
      this.toggleCustomInputs()
    } else {
      this.isExpirationSettingsEnabled && this.expirationSettingsForm.disable();
      this.courseStarsSettingsForm.disable()
      this.toggleCustomInputs()
    }
  }

  updateSettings() {
    this.isStarsSettingsLoading = true

    const $starsSettings = this.organizationService.updateOrganizationStarsSettings(this.courseStarsSettingsForm.value)

    let expirationType = null
    let expirationNumber = null
    let expirationData

    if (this.isExpirationSettingsEnabled) {
      this.isExpirationSettingsLoading = true

      expirationData = this.getExpirationTerm();

      const $expirationSettings = this.organizationService.updateOrganizationExpirationSettings( expirationData )

      combineLatest([$starsSettings, $expirationSettings]).subscribe(() => {
        this.toggleSettingsEditMode()
        this.isStarsSettingsLoading = false
        this.isExpirationSettingsLoading = false
        this.notificationService.show("UPDATED ORGANIZATION")
      })
    } else {
      $starsSettings.subscribe(() => {
        this.toggleSettingsEditMode()
        this.isStarsSettingsLoading = false
        this.notificationService.show("UPDATED ORGANIZATION")
      })
    }
  }

  canUpdateSettings(): boolean {
    return (this.isExpirationSettingsEnabled && this.expirationSettingsForm.dirty)
      || this.courseStarsSettingsForm.dirty
      || this.expirationNumberControl.dirty
      || this.expirationTypeControl.dirty;
  }

  toggleCustomInputs() {
    if (this.settingsEditMode) {
      this.expirationNumberControl.enable()
      this.expirationTypeControl.enable()
    } else {
      this.expirationNumberControl.disable()
      this.expirationTypeControl.disable()
    }
  }

  private checkPossibleGuide(): void {
    this.subscriptionOnboarding = this.onboarding
      .getOnboarding()
      .subscribe((res) => {
        for (const key in res) {
          if (
            res[key].name === "Admin/Info" &&
            this.infoTabIsActive &&
            !res[key].isCompleted
          ) {
            this.guides.info = res[key].id;
            this.onboarding.openOnboardingDialog(
              "admin_info_guide",
              this.userSteps,
              [this.guides.info],true
            ).subscribe(() => this.startOnboardingNavigation());
          } else if (
            res[key].name === "Admin/Company" &&
            this.companyTabIsActive &&
            !res[key].isCompleted
          ) {
            this.guides.company = res[key].id;
            this.onboarding.openOnboardingDialog(
              "admin_company_guide",
              this.groupSteps,
              [this.guides.company],true
            ).subscribe(() => this.startOnboardingNavigation());

          }
        }
      });
  }

  public startOnboardingNavigation(): void {
    if (this.infoTabIsActive) {
      this.onboarding.startOnboardingNavigation(
        this.userSteps,
        "admin_info_guide",
        [this.guides.info],
        true
      )
      .subscribe(
        (res) => {
          if (
            res.name === "EditInfoStep" ||
            res.name === "FillInfoStep" ||
            res.name === "DownloadCertificateStep"
          ) {
            this.isOpenSelectInfo.next(true);
          } else {
            this.isOpenSelectInfo.next(false);
          }
        },
        () => {},
        () => {
          if (this.guides.info) {
            this.onboarding.openFinishOnboardingDialog("admin_info_guide", [
              this.guides.info,
            ]);
            this.guides.info = "";
          }
        }
      );

    } else {
      this.onboarding.startOnboardingNavigation(
        this.groupSteps,
        "admin_company_guide",
        [this.guides.company],
        true
      )
      .subscribe(
        (res) => {
          if (
            res.name === "EditCompanyStep" ||
            res.name === "InternalEmergencyNumberStep" ||
            res.name === "ExternalEmergencyNumberStep" ||
            res.name === "LanguageCompanyStep" ||
            res.name === "ResponsibleUserStep"||
            res.name === "EnvironmentStep"
          ) {
            this.isOpenSelectCompany.next(true);
          } else {
            this.isOpenSelectCompany.next(false);
          }
        },
        () => {},
        () => {
          if (this.guides.company) {
            this.onboarding.openFinishOnboardingDialog("admin_company_guide", [
              this.guides.company,
            ]);
            this.guides.company = "";
          }
        }
      );
    }
  }

  public onTabChanged(event: MatTabChangeEvent) {
    this.infoTabIsActive = event.index === 0;
    this.companyTabIsActive = event.index === 1;
    this.checkPossibleGuide();
  }

  public canEditUser(): boolean {
    return !this.userEditMode && !_.isNil(this.user.email);
  }

  public toggleUserEditMode(): void {
    this.userEditMode = !this.userEditMode;

    if (this.updateUserForm.disabled) {
      this.updateUserForm.enable();
      if(!this.isLoggedInUserSuperUser){
        this.updateUserForm.controls.userRole.disable();
      }
    } else {
      this.updateUserForm.disable();
    }
  }

  public canUpdateUser(): boolean {
    return this.updateUserForm.valid && this.updateUserForm.dirty;
  }

  /*checkUpdateForm(value: MatCheckboxChange) {
    if (!value.checked) {
      this.updateUserForm.removeControl("passwordGroup");
    }
  }*/
  
  public canShowVirtualEmail(): boolean {
    return this.originalUser.virtualEmail;
  }

  public canShowPassword(): boolean {
    return (
      this.updateUserForm.get("userVirtualEmail").value 
    );
  }
  /*
  public canShowErrorForConfirmedPassword(): boolean {
    return (
      this.updateUserForm.get("passwordGroup.passwordConfirm").touched &&
      this.updateUserForm.get("passwordGroup").hasError("validatePasswordMatch")
    );
  }*/

  public updateUser(user: User): void {
    this.toggleUserEditMode();
    const langobj = this.updateUserForm.get("userLanguage").value;

    /*var hasVirtualEmail =  this.updateUserForm.get("userVirtualEmail").value;
    var newpassword = this.updateUserForm.get("passwordGroup.password").value;
    var isValidPassword = this.updateUserForm.get("passwordGroup").valid;*/

    user.update(
      user.id,
      this.updateUserForm.get("userName").value,
      this.updateUserForm.get("userEmail").value,
      this.updateUserForm.get("userJob").value,
      this.updateUserForm.get("userBirthdate").value,
      this.updateUserForm.get("userDepartment").value,
      user.status,
      this.isLoggedInUserSuperUser? this.updateUserForm.get("userRole").value : user.role,
      langobj == null ? null : langobj.id,
      user.organizationID,
      this.updateUserForm.get("userVirtualEmail").value,
      user.licenseId,
      user.blockId
    );

    this.userService
      .updateUser(this.dataService.organizationID, user, this.originalUser)
      .subscribe(
        () => {
          
          this.originalUser = user;

          if (this.user.id === this.dataService.userID) {
            
            this.dataService.AuthenticatedUser = user;
            
            var lang = this.dataService.language.includes("-") ? this.dataService.language.split("-")[0] : this.dataService.language;
            const languageToUse = SUPPORTED_LANGUAGES.includes( lang ) ? lang : "en";
      
            if(this.translateService.currentLang != languageToUse) {
                this.translateService.use(languageToUse);            
            }

            this.notificationService.show("UPDATED USER");
          }
        },
        () => {
          this.toggleUserEditMode();
          this.user = _.cloneDeep(this.originalUser);
        }
      );

      /*if(hasVirtualEmail && newpassword && isValidPassword) {
          this.userService.updatePassword(this.dataService.organizationID, user, newpassword).subscribe( 
            () => console.log("Updated password"),
            () => console.log("Error updating password") 
          );
      }*/

  }

  public toggleOrganizationEditMode(): void {
    this.organizationEditMode = !this.organizationEditMode;

    if (this.updateOrganizationForm.disabled) {
      this.updateOrganizationForm.enable();
    } else {
      this.updateOrganizationForm.disable();
    }
  }

  public canUpdateOrganization(): boolean {
    return this.updateOrganizationForm.valid;
  }

  public updateOrganization(): void {
    this.toggleOrganizationEditMode();
    const organization = this.organization;
    organization.name = this.updateOrganizationForm.get(
      "organizationName"
    ).value;
    organization.internalEmergencyNumber = this.updateOrganizationForm.get(
      "organizationInternalEmergencyNumber"
    ).value;
    organization.externalEmergencyNumber = this.updateOrganizationForm.get(
      "organizationExternalEmergencyNumber"
    ).value;

    if (this.updateOrganizationForm.get("organizationResponsibleUser").value) {
      organization.responsibleUser = this.updateOrganizationForm.get(
        "organizationResponsibleUser"
      ).value.id;
    }

    if (this.updateOrganizationForm.get("organizationLanguage").value) {
      organization.language = this.updateOrganizationForm.get(
        "organizationLanguage"
      ).value.id;
    }

    if (this.updateOrganizationForm.get("organizationPlayEnvironment").value) {
      organization.playEnvironmentId = this.updateOrganizationForm.get(
        "organizationPlayEnvironment"
      ).value.id;
    }

    this.organizationService.updateOrganization(organization).subscribe(() => {
      this.notificationService.show("UPDATED ORGANIZATION");
    });
  }

  public getClassForStatus(status: StatusEnum): string {
    return _.camelCase(status.toString());
  }

  public canShowGroupsOfUser(): boolean {
    return !_.isEmpty(this.groupsOfUser);
  }

  public canShowCategoriesOfUser(): boolean {
    return !_.isEmpty(this.categoriesOfUser);
  }

  public displayNameOfResponsibleUser(user: User) {
    if (user) {
      return user.name;
    }

    return ''
  }

  public organizationDetailsAvailable(): boolean {
    return !_.isNil(this.organization);
  }

  public onFileInput(event): void {
    const file: File = event.currentTarget.files[0];
    const myReader: FileReader = new FileReader();
    myReader.onloadend = () => {
      this.organization.logo = myReader.result as string;
    };
    myReader.readAsDataURL(file);
  }

  public triggerUpload(): void {
    this.logoFileInput.nativeElement.click();
  }

  public downloadCertificate(): void {
    this.downloadingCertificate = true;
    this.userService
      .getUrlToDownloadCertificate(this.organization, this.user)
      .subscribe((url: string) => {
        this.downloadingCertificate = false;
        const dateString = new DatePipe("en-US").transform(
          Date.now(),
          "dd/MM/yyyy"
        );
        const fileName = "Certificate-" + dateString + ".pdf";

        const htmlAnchorElement = document.createElement("a");
        document.body.appendChild(htmlAnchorElement);
        htmlAnchorElement.setAttribute("style", "display: none");
        htmlAnchorElement.href = url;
        htmlAnchorElement.download = fileName;
        htmlAnchorElement.click();
        window.URL.revokeObjectURL(url);
        htmlAnchorElement.remove();
      });
  }

  filterUsers(val): User[] {
    const isString = typeof val === 'string'
    const filterValue = isString
      ? val.toLowerCase()
      : val.name.toLowerCase()

    return this.users.filter(user => user.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private setUserDetails(): void {
    this.updateUserForm = new FormGroup({
      userName: new FormControl(this.user.name, Validators.required),
      userEmail: new FormControl(this.user.email, Validators.required),
      userVirtualEmail: new FormControl(this.user.virtualEmail),
      userJob: new FormControl(this.user.job),
      userBirthdate: new FormControl(this.user.birthdate),
      userDepartment: new FormControl(this.user.department),
      userLanguage: new FormControl(),
      userRole: new FormControl(this.user.role),
    });

    if(this.user.role == 30 && this.isLoggedInUserSuperUser){
      this.updateUserForm.controls.userRole.disable();
    }
    
    this.updateUserForm.disable();

    this.languageService.getLanguages().subscribe((languages: Language[]) => {
      this.userLanguages = languages;

      if (!_.isNil(this.user.language)) {
        const lang: Language = _.find(
          this.userLanguages,
          (language: Language) => {
            return _.isEqual(this.user.language, language.id);
          }
        );
        this.updateUserForm.controls.userLanguage.setValue(lang);
      }
    });
  }

  private getUserGroups():void{
     this.loadinggroups = true;
     this.groupService
      .getGroupsOfUser(this.dataService.organizationID, this.originalUser)
      .subscribe(
        (groups: Group[]) => {
          this.groupsOfUser = groups;
          this.loadinggroups = false;
        },
        () => {
          this.loadinggroups = false;
          this.canShowGroupsAndCourses = false;
        }
      );
  }

  private getUserLearningPaths(): void{
    this.isLearningPathsLoading = true;
    this.learningpathsService.getUserLearningPaths(this.user.id).subscribe((paths)=>{
      this.isLearningPathsLoading = false;
      this.learningPathsList = paths || [];
    }, (error)=>{
      console.error(error);
      this.isLearningPathsLoading = false;
    });
  }

  private getUserCourses():void{
    this.loadingcourses = true;
    this.categoryService
          .getCategoriesOfUser(this.organization, this.originalUser)
          .subscribe(
            (categories: Category[]) => {
              this.categoriesOfUser = categories;
              this.loadingcourses = false;
            },
            () => {
              this.loadingcourses = false;
              this.canShowGroupsAndCourses = false;
            }
      );
  }

  private setOrganizationDetails(): void {
    this.updateOrganizationForm = new FormGroup({
      organizationName: new FormControl("", Validators.required),
      organizationInternalEmergencyNumber: new FormControl(""),
      organizationExternalEmergencyNumber: new FormControl(""),
      organizationLanguage: new FormControl(),
      organizationResponsibleUser: new FormControl(
        '',
        validateResponsibleUser
      ),
      organizationPlayEnvironment: new FormControl(),
    });
    this.updateOrganizationForm.disable();

    this.userService.getUsers().subscribe((users: User[]) => {
      this.users = users;
    });

    this.filteredUsers = this.updateOrganizationForm.controls.organizationResponsibleUser.valueChanges.pipe(
      startWith(''),
      map((name) => {
        return this.filterUsers(name)
      })
    );

    this.userService
      .getUser(
        this.dataService.organizationID,
        this.organization.responsibleUser
      )
      .subscribe((user: User) => {
        this.updateOrganizationForm.controls.organizationResponsibleUser.setValue(user);
      });

    this.updateOrganizationForm.controls.organizationName.setValue(
      this.organization.name
    );
    this.updateOrganizationForm.controls.organizationInternalEmergencyNumber.setValue(
      this.organization.internalEmergencyNumber
    );
    this.updateOrganizationForm.controls.organizationExternalEmergencyNumber.setValue(
      this.organization.externalEmergencyNumber
    );

    this.organizationService
      .getAvailablePlayEnvironments(
        this.dataService.organizationID,
        this.dataService.language
      )
      .subscribe((playEnvironments: PlayEnvironment[]) => {
        this.playEnvironments = playEnvironments;
        const playEnv: PlayEnvironment = _.find(
          playEnvironments,
          (playEnvironment: PlayEnvironment) => {
            return _.isEqual(
              this.organization.playEnvironmentId,
              playEnvironment.id
            );
          }
        );
        this.updateOrganizationForm.controls.organizationPlayEnvironment.setValue(
          playEnv
        );
      });
    this.languageService.getLanguages().subscribe((languages: Language[]) => {
      const lang: Language = _.find(languages, (language: Language) => {
        return _.isEqual(this.organization.language, language.id);
      });
      this.updateOrganizationForm.controls.organizationLanguage.setValue(lang);
    });
  }

  mapExpiration(expirationTerm) {
    let selectedExpirationId = EXPIRATION_SETTING_VALUES.USE_ORGANIZATION_SETTINGS
    let expirationNumber = '1'
    let expirationType = 'Year'
    if (expirationTerm != null) {
      switch (expirationTerm) {
        case (-1):
          selectedExpirationId = EXPIRATION_SETTING_VALUES.NEVER
          break;
        case (6):
          selectedExpirationId = EXPIRATION_SETTING_VALUES.SIX_MONTH
          break;
        case (12):
          selectedExpirationId = EXPIRATION_SETTING_VALUES.ONE_YEAR
          break;
        case (24):
          selectedExpirationId = EXPIRATION_SETTING_VALUES.TWO_YEARS
          break;
        default:
          selectedExpirationId = EXPIRATION_SETTING_VALUES.CUSTOM
          if (expirationTerm % 12 == 0) {
            expirationType = 'Year'
            expirationNumber = String(expirationTerm / 12)
          }
          else {
            expirationType = 'Month'
            expirationNumber = String(expirationTerm)
          }
      }
    }
    return {
      selectedExpirationId,
      expirationNumber,
      expirationType
    }

  }

  getExpirationTerm() {
    const { courseResults } = this.expirationSettingsForm.value

    let expirationTerm = null

    switch (courseResults) {
      case EXPIRATION_SETTING_VALUES.CUSTOM:
        expirationTerm = Number(this.expirationNumberControl.value)
        if (this.expirationTypeControl.value == 'Year') {
          expirationTerm = expirationTerm * 12
        }
        break;
      case EXPIRATION_SETTING_VALUES.ONE_YEAR:
        expirationTerm = 12;
        break;
      case EXPIRATION_SETTING_VALUES.SIX_MONTH:
        expirationTerm = 6;
        break;
      case EXPIRATION_SETTING_VALUES.TWO_YEARS:
        expirationTerm = 24;
        break;
      case EXPIRATION_SETTING_VALUES.NEVER:
        expirationTerm = -1;
        break;
      case EXPIRATION_SETTING_VALUES.USE_ORGANIZATION_SETTINGS:
        expirationTerm = null;
        break;
    }

    return {
      expirationTerm: expirationTerm,
    }
  }


  public ngOnDestroy() {
      this.subscriptionOnboarding?.unsubscribe();
  }
}
