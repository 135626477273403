import { DOCUMENT, Location } from "@angular/common";
import { Component, Inject, Injector, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Organization } from "./domain/organization";
import { Subscription } from "rxjs";
import { SUPPORTED_LANGUAGES } from "app/application/constants";
import { AuthenticationService } from "./infrastructure/http/authentication/authentication.service";
import { NavigationService } from "./infrastructure/navigation/navigation.service";
import { HttpOrganizationService } from "./infrastructure/http/organization/httporganization.service";
import { LoggedInOrganizationService } from "./infrastructure/helper/logged-in-organization.service";
import { DataService } from "./infrastructure/dataservice";
import { HttpOnboardingService } from "./infrastructure/http/onboarding/http-onboarding.service";
import { OnboardingService } from "./infrastructure/http/onboarding/onboarding.service";
import { APP_ROUTES } from "./app-routing.module";
import { ICONS_LIST, ICONS_PATH } from "./utils/icons";

import { LayoutNavService} from './layout.navigation.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  public initialized: boolean;
  private organizationId: string;
  private subscriptionOnboarding: Subscription;
  public isNotificationsHidden = true;
  private keyDownHandler: EventListener;
  private bodyElement: Element;
  public isNavExpanded:boolean;

  get organization(): Organization {
    return this.dataService.CurrentOrganization;
  }
  private anonymouspaths = [
    "/login",
    "/activate-account",
    "/reset-password",
    "/forgot-password",
  ];

  
  toggleExpandContent() {
    this.isNavExpanded = this.layoutNavService.getIsNavExpanded();
  }
  
  clickEventsubscription:Subscription;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private authenticationService: AuthenticationService,
    organizationService: HttpOrganizationService,
    private loggedInOrganizationService: LoggedInOrganizationService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private location: Location,
    private translate: TranslateService,
    private injector: Injector,
    private dataService: DataService,
    private onboardingService: HttpOnboardingService,
    private onboarding: OnboardingService,
    @Inject(LayoutNavService) private layoutNavService: LayoutNavService,
    @Inject(DOCUMENT) private document: Document,
   // private ref: ChangeDetectorRef
  ) {
    this.isNavExpanded = layoutNavService.getIsNavExpanded();
    this.clickEventsubscription = this.layoutNavService.getExpandEvent().subscribe(()=>{ this.toggleExpandContent(); })

    translate.setDefaultLang("en");

    this.initialized = false;

    this.registerIcons(iconRegistry, sanitizer);

    // for onboarding
    this.keyDownHandler = this.closeOnboard.bind(this);
    this.bodyElement = this.document.body;

    this.checkOnBoardingStatus();
  }

  
  ngOnInit() {
    this.router.resetConfig(this.getActualRoutes());

    this.router.events.subscribe((value) => {
      const isCurrentPathAnonymous = this.anonymouspaths.some((url) =>
        this.router.url.includes(url)
      );

      if (
        this.router.url == window.location.href &&
        value instanceof NavigationEnd
      ) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }

      if (
        this.router.url === "/" ||
        this.router.url === "/support" ||
        isCurrentPathAnonymous
      ) {
        this.isNotificationsHidden = true;
      } else {
        this.isNotificationsHidden = false;
      }
    });

    const currentpath = this.location.path();
    const foundPath = this.anonymouspaths.find((path) =>
      currentpath.startsWith(path)
    );
    if (foundPath) {
      // don't use auto login on anonymous paths
      this.initialized = true;
    } else {
      this.autoLogin();
    }
  }

  getActualRoutes() {
    const routes = this.navigationService.getRoutes();
    return [
      ...APP_ROUTES,
      ...routes,
      {
        path: "**",
        redirectTo: "",
      },
    ];
  }

  autoLogin() {
    const currentpath = this.location.path();

    this.authenticationService.TryAutoLogin().subscribe(
      (success) => {
        if (success) {
          this.organizationId =
            this.loggedInOrganizationService.getLoggedInOrganizationId();
          this.checkOnBoarding();

          var language = this.dataService.language.includes("-") ? this.dataService.language.split("-")[0] : this.dataService.language;

          const languageToUse = SUPPORTED_LANGUAGES.includes(
            language
          )
            ? language
            : "en";
          this.translate.use(languageToUse);

          if (currentpath === "") {
            this.router.navigate(["/dashboard"]);
          }
        } else {
          const browserLang = this.translate.getBrowserLang();

          if (currentpath !== "") {
            this.router.navigate([""]);
          }
        }

        this.initialized = true;
      },
      () => {
        this.authenticationService.logout();
        this.router.navigate([""]);

        this.initialized = true;
      }
    );
  }

  checkOnBoardingStatus() {
    const bodyElement = this.document.body;

    this.onboarding.onboardingProgress().subscribe((response) => {
      if (response) {
        bodyElement.addEventListener("keydown", this.keyDownHandler);
      }
    });
  }

  private closeOnboard(event) {
    if (event.key === "Escape") {
      this.onboarding.closeOnboarding();
      this.bodyElement.removeEventListener("keydown", this.keyDownHandler);
    }
  }

  public canShowMenu(): boolean {
    const currentPath = this.location.path();
    return !(
      currentPath === "/login" ||
      currentPath.startsWith("/activate-account") ||
      currentPath.startsWith("/reset-password") ||
      currentPath.startsWith("/forgot-password") ||
      currentPath === "/" ||
      currentPath === ""
    );
  }

  private checkOnBoarding(): void {
    this.subscriptionOnboarding = this.onboardingService
      .getOnboarding(this.organizationId)
      .subscribe((res) => {
        this.onboarding.setOnboarding(res);
      });
  }

  public registerIcons(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ): void {
    ICONS_LIST.forEach(({ fileName, iconLabel }) => {
      iconRegistry.addSvgIcon(
        iconLabel,
        sanitizer.bypassSecurityTrustResourceUrl(ICONS_PATH.concat(fileName))
      );
    });
  }

  public ngOnDestroy() {
    this.subscriptionOnboarding.unsubscribe();
  }
}
