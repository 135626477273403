import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventBusService implements OnDestroy {
  private eventChannels = {}
  private subscriptions$: Set<Subscription> = new Set()

  constructor() {}

  public sendEvent<T> (eventName: string, data?: T) {
    this.registerEvent<T>(eventName);
    (this.eventChannels[eventName] as Subject<T>).next(data)
  }

  public subscribeEvent<T> (eventName: string, cb: (data: T) => any): Subscription {
    this.registerEvent<T>(eventName);
    const subscribe = this.eventChannels[eventName].subscribe(cb)
    this.subscriptions$.add(subscribe)
    return subscribe
  }

  public removeSubscriptions(subs: Subscription[]) {
    subs.forEach((sub) => {
      this.subscriptions$.delete(sub)
      sub.unsubscribe()
    })
  }

  private registerEvent<T> (eventName: string) {
    if (!(eventName in this.eventChannels)) {
      this.eventChannels[eventName] = new Subject<T>()
    }
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((item) => item.unsubscribe())
  }
}
