<button
  type="button"
  mat-icon-button
  (click)="isNotificationsOpen = !isNotificationsOpen"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <mat-icon
    class="notifications-icon"
    [matBadge]="badgeCounter"
    [matBadgeHidden]="!badgeCounter"
    matBadgeSize="small"
    matBadgePosition="below after">
    notifications_active
  </mat-icon>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isNotificationsOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="isNotificationsOpen = false"
>
  <app-notifications-popover [@notifications] (onCloseEvent)="closeNotifications()"></app-notifications-popover>
</ng-template>
