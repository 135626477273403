import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { TOAST_TYPES } from "app/application/constants";
import { ToastService } from "app/application/toast/toast.service";
import { LicenseManagementHttpService } from "app/presentation/pages/license-management/services/license-management-http.service";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { LicenceOverviewHttpService } from "../../services/license-overview-http.service"
import { LicenseConfirmationDialog } from "../license-confirmation-dialog/license-confirmation-dialog";

import { ILicenseBlock, IOrganizationBlock } from "app/application/interfaces/licenses.interface";
import { FileSaverService } from "ngx-filesaver";

const fileKey = 'content-disposition'

@Component({
  selector: 'app-license-renew-modal',
  templateUrl: './license-renew-modal.component.html',
  styleUrls: ['./license-renew-modal.component.scss']
})
export class LicenseRenewModalComponent implements OnInit {
  displayedColumns: string[] = [
    'type',
    'stats',
    'expiration',
    'renew',
    'exceeding',
    'actions'
  ]

  isDataLoading = false
  isRenewLoading = false
  isRevokeLoading = false
  isUnlicenseLoading = false
  isLogsLoading = false

  onLicensedChange: EventEmitter<boolean> = new EventEmitter()

  licenses: ILicenseBlock[] = []

  dialogConfig: MatDialogConfig = <MatDialogConfig>{
    height: '200px',
    width: '500px'
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IOrganizationBlock,
    private licenceService: LicenseManagementHttpService,
    private licenceOverviewService: LicenceOverviewHttpService,
    private toastService: ToastService,
    private fileSaverService: FileSaverService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getLicenses(this.data.organizationId)
  }

  getLicenses(organizationId) {
    this.isDataLoading = true

    this.licenceService.getLicenses(organizationId).subscribe(
      (licenses) => {
        this.licenses = this.setButtonFlags(licenses)
        this.isDataLoading = false
      }
    )
  }

  downloadLogs() {
    if (!this.isLogsLoading) {
      this.isLogsLoading = true

      this.licenceOverviewService.downloadLogs(this.data.organizationId)
        .subscribe(
          ({ body, headers }) => {
            // getting filename from header
            // example of header format: "attachment; filename=20210203100716-license-logs-6ecbb375-62f1-417e-bfc0-ba81ed01bc7a.xlsx;"
            const fileData = headers.get(fileKey).split('; ')[1] || ''
            const filenameStartIndex = fileData.indexOf('=') + 1
            const filteName = fileData.slice(filenameStartIndex)

            this.isLogsLoading = false

            const binaryData = new Blob([body], { type: body.type})
            this.fileSaverService.save(binaryData, filteName || 'Logs.xlsx')
          }
        )
    }
  }

  renewBlock(blockId, index) {
    if (!this.isRenewLoading) {
      const dialogRef = this.dialog.open(LicenseConfirmationDialog, {
        data: {
          textContent: 'Are you sure you want to renew this license block?'
        },
        ...this.dialogConfig,
      })

      dialogRef.afterClosed()
        .pipe(
          switchMap((action) => {
            if (action) {
              this.isRenewLoading = true
              return this.licenceOverviewService.renewLicenseBlock(blockId)
            }
            return of()
          })
        )
        .subscribe((updatedBlock: ILicenseBlock) => {
          this.isRenewLoading = false

          if (updatedBlock) {
            this.licenses =  this.updateAfterToggle(updatedBlock, index)
          }

          this.toastService.show('LicenseBlock was successfully renewed')
        }, () => {
          this.toastService.show('Something went wrong', TOAST_TYPES.ERROR)
        })
    }
  }

  revokeBlock(blockId, index) {
    if (!this.isRevokeLoading) {
      const dialogRef = this.dialog.open(LicenseConfirmationDialog, {
        data: {
          textContent: 'Are you sure you want to revoke license block renewing?'
        },
        ...this.dialogConfig,
      },)

      dialogRef.afterClosed()
        .pipe(
          switchMap((action) => {
            if (action) {
              this.isRevokeLoading = true
              return this.licenceOverviewService.revokeLicenseBlock(blockId)
            }
            return of()
          })
        )
        .subscribe((updatedBlock: ILicenseBlock) => {
          this.isRevokeLoading = false

          if (updatedBlock) {
            this.licenses = this.updateAfterToggle(updatedBlock, index)
          }

          this.toastService.show('Renewing successfully revoked')
        }, () => {
          this.toastService.show('Something went wrong', TOAST_TYPES.ERROR)
        })
    }
  }

  autoRenewHandle({ checked }: MatSlideToggleChange, blockId, index) {
    const params = {
      op: 'replace',
      path: '/enableAutoRenew',
      value: checked
    }

    this.licenceOverviewService.patchLicenseBlock(blockId, params).subscribe(
      (updatedBlock: ILicenseBlock) => {
        if (updatedBlock) {
          this.licenses = this.updateAfterToggle(updatedBlock, index)
        }
        this.toastService.show('Settings were saved')
      },
      () => {
        this.toastService.show('Something went wrong', TOAST_TYPES.ERROR)
      }
    )
  }

  exceedLimitHandle({ checked }: MatSlideToggleChange, blockId, index) {
    const params = {
      op: 'replace',
      path: '/canExceedCap',
      value: checked
    }

    this.licenceOverviewService.patchLicenseBlock(blockId, params).subscribe(
      (updatedBlock: ILicenseBlock) => {
        if (updatedBlock) {
          this.licenses = this.updateAfterToggle(updatedBlock, index)
        }
        this.toastService.show('Settings were saved')
      },
      () => {
        this.toastService.show('Something went wrong', TOAST_TYPES.ERROR)
      }
    )
  }

  switchLicenseState() {
    const confirmationDialogMessage = this.data.isUnlicensed
      ? 'Are you sure you want to add license to this company?'
      : 'Are you sure you want to make this company unlicensed?'

    const toastSuccessMessage = this.data.isUnlicensed
      ? 'Now company has license'
      : 'Now company is unlicensed'

    if (!this.isUnlicenseLoading) {
      const dialogRef = this.dialog.open(LicenseConfirmationDialog, {
        data: {
          textContent: confirmationDialogMessage
        },
        ...this.dialogConfig,
      },)

      dialogRef.afterClosed()
        .pipe(
          switchMap((action) => {
            if (action) {
              this.isUnlicenseLoading = true
              return this.licenceOverviewService.switchLicenseState(this.data.organizationId, !this.data.isUnlicensed)
            }
            return of()
          })
        )
        .subscribe(() => {
          this.data.isUnlicensed = !this.data.isUnlicensed
          this.onLicensedChange.emit(!this.data.isUnlicensed)

          this.isUnlicenseLoading = false
          this.toastService.show(toastSuccessMessage)
        }, () => {
          this.toastService.show('Something went wrong', TOAST_TYPES.ERROR)
        })
    }
  }

  updateAfterToggle(newBlock: ILicenseBlock, index) {
    this.licenses.splice(index, 1, newBlock)
    return this.setButtonFlags(this.licenses)
  }

  setButtonFlags(licenses: ILicenseBlock[]): ILicenseBlock[] {
    const currentDate = +new Date()

    return licenses.map((license) => {
      const expirationDate = +new Date(license.expirationDate)
      const shouldDisableRevoke = expirationDate < currentDate
      const shouldDisableRenew = license.licenseType === 'Demo'

      return {
        ...license,
        shouldDisableRevoke,
        shouldDisableRenew
      }
    })
  }
}
