import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { JoyrideModule } from 'ngx-joyride';
import { TranslateModule } from '@ngx-translate/core';
import { DeactivateUsersDialogComponent } from './deactivate-users-dialog/deactivate-users-dialog.component';
import { MaterialModule } from 'app/material.module';


@NgModule({
  imports: [
    CommonModule,
    JoyrideModule,
    MaterialModule,
    TranslateModule,
    FlexLayoutModule
  ],
  declarations: [
    DeactivateUsersDialogComponent
  ],
  exports: [
    DeactivateUsersDialogComponent
  ]
})
export class UsersModule { }
