import { Component, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { Category } from "../../../domain/category";
import { Course } from "../../../domain/course";
import { HttpCategoryService } from "../../../infrastructure/http/category/httpcategory.service";
import { HttpCourseService } from "../../../infrastructure/http/course/httpcourse.service";
import { OnboardingService } from "../../../infrastructure/http/onboarding/onboarding.service";
import { Subscription } from "rxjs";
import { HttpReportsService } from "app/infrastructure/http/report/httpreports.service";
import { DataService } from "app/infrastructure/dataservice";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CourseDetailComponent } from "./course-detail/course-detail.component";
import RoleEnum from "app/domain/roleEnum";
import { CoursesGroupsComponent } from "app/features/courses-groups/courses-groups.component";
import { AvailableCoursesDialogComponent } from "../../../features/available-courses/components/available-courses-dialog/available-courses-dialog.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-courses",
  templateUrl: "./courses.component.html",
  styleUrls: ["./courses.component.scss"],
})
export class CoursesComponent implements OnInit, OnDestroy {
  @ViewChild(CoursesGroupsComponent, { static: false })
  coursesGroupComponent: CoursesGroupsComponent;

  @Output() public categories: Array<Category> = [];
  @Output() public selectedCourses: Array<Course> = [];
  public editCourses: boolean;
  private guides = [];

  private guideStepsSecond = [
    "UserBlockAtGroupDialog",
    "CoursesBlockAtGroupDialog",
    "EnvironmentsBlockAtGroupDialog",
  ];

  private guidesStepWithoutGroups = [
    "CheckCourseStep",
    "SelectCategoryStep",
    "EnableCourseStep"
  ]

  private guideStepsWithGroups = [
    "CheckCourseStep",
    "SelectCategoryStep",
    "EnableCourseStep",
    "SearchGroupStep",
    "LinkNewGroupStep",
    "LinkGroupStep",
    "UserOpenGroupStep",
  ];

  private subscriptionOnboarding: Subscription;

  isFeaturesLoading = false
  isDirectUserAssignments = false
  isExpirationCourseSettings = false

  shouldShowEditButton = false

  constructor(
    private categoryService: HttpCategoryService,
    private onboarding: OnboardingService,
    private courseService: HttpCourseService,
    private reportService: HttpReportsService,
    private dataService: DataService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
   /*  this.checkOnboarding(); */

    this.isFeaturesLoading = true
    this.reportService
      .getFeatures(this.dataService.organizationID)
      .subscribe((features) => {
        features.forEach(({name}) => {
          if (name === 'direct_user_assignments') {
            this.isDirectUserAssignments = true
          }

          if (name === 'expiration_courses_custom_expirationterm') {
            this.isExpirationCourseSettings = true
          }
        })

        this.isFeaturesLoading = false
      })

    this.UpdateCategories();

    this.translateService.onLangChange.subscribe(() => {
      this.UpdateCategories();
    });

    this.shouldShowEditButton = this.dataService.userRole === RoleEnum.superuser
  }

  private UpdateCategories() {
    this.categoryService
      .getCategories(true)
      .subscribe((categories: Array<Category>) => {
        this.ngZone.run(() => {
          this.categories = categories;
        this.updateSelectedCourses();
        });
        
      });
  }

  openEditModal(course: Course) {

    const data: {
      course: Course
      isExpirationSettingsEnabled: boolean
    } = {
      course,
      isExpirationSettingsEnabled: this.isExpirationCourseSettings
    };

		this.dialog.open(AvailableCoursesDialogComponent,  {     height: '675px',
    width: '1029px',data: data });
  }

  showCourseDetails(course: Course): void {
    const isExpirationCourseSettingsEnabled = this.isExpirationCourseSettings

		const config = <MatDialogConfig>{
      height: '675px',
		  width: '1029px',
      data: {
        course,
        isExpirationCourseSettingsEnabled
      }
    };

		this.dialog.open(CourseDetailComponent, config);
	}

  private checkOnboarding(): void {
    this.subscriptionOnboarding = this.onboarding
      .getOnboarding()
      .subscribe((res) => {
        const guideSteps = this.isDirectUserAssignments
          ? this.guidesStepWithoutGroups
          : this.guideStepsWithGroups
          
        for (const key in res) {
          if (res[key].name === "Courses/Main" && !res[key].isCompleted) {
            this.onboarding
              .openOnboardingDialog(
                "courses_guide",
                guideSteps,
                this.guides,
                true
              )
              .subscribe(() => this.startOnboardingNavigation());
              this.guides.push(res[key].id);
          }
        }
      });
  }

  public startOnboardingNavigation(): void {
    const guideSteps = this.isDirectUserAssignments
      ? this.guidesStepWithoutGroups
      : this.guideStepsWithGroups

    const isReturn = !this.isDirectUserAssignments

    let isLastStep = false;

    this.onboarding
      .startOnboardingNavigation(
        guideSteps,
        "courses_guide",
        this.guides,
        isReturn
      )
      .subscribe(
        (res) => {
          if (res.name === "UserOpenGroupStep") {
            isLastStep = true;
          } else if (res.name === "LinkGroupStep") {
            isLastStep = false;
          }
        },
        () => {},
        () => {
          if (this.guides.length || isLastStep) {
            this.coursesGroupComponent.openDialogForGuide();
            this.onboarding.openFinishOnboardingDialog(
              "courses_guide",
              this.guides
            );
            this.guides = [];
          }
         /*  if (!this.isDirectUserAssignments) {
            if (this.guides.length || isLastStep) {
              this.coursesGroupComponent.openDialogForGuide();
              setTimeout(() => {
                this.onboarding.startOnboardingNavigation(
                  this.guideStepsSecond,
                  "courses_guide",
                  this.guides,
                );
                this.guides = [];
              }, 300);
            }
          }  */
        }
      );
  }

  public updateSelectedCourses() {
    const courses = _.reduce(
      this.categories,
      (result: Course[], category: Category) => {
        return result.concat(category.courses);
      },
      []
    );
    this.selectedCourses = _.filter(courses, ["checked", true]);
  }

  public onCategoryIsActiveChanged(category: Category): void {
    this.categoryService.updateCategory(category);
  }

  public onCourseIsActiveChanged(course: Course): void {
    this.courseService.updateCourse(course);
  }

  public ngOnDestroy() {
    this.subscriptionOnboarding?.unsubscribe();
  }
}
