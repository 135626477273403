import {Component} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GroupInCategory} from '../group-in-category';

@Component({
    templateUrl: './group-name-result.component.html',
    styleUrls: ['./group-name-result.component.scss']
})
export class GroupNameResultComponent {
    public groupInCategory: GroupInCategory;
    private categoryId: string;
    private dialog: MatDialog;
    private router: Router;

    public constructor(dialog: MatDialog, router: Router) {
        this.dialog = dialog;
        this.router = router;
    }

    public agInit(params: any): void {
        this.groupInCategory = params.data;
        this.categoryId = params.getCategoryId();
    }

    public showGroupDetails(): void {
        this.router.navigateByUrl(`results/category/${this.categoryId}/group/${this.groupInCategory.id}`);
    }

}
