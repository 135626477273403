<div class="support-flex">
	<section>
		<h3>{{'SUPPORT' | translate}}</h3>
		<h4>{{'GOT A PROBLEM OR A QUESTION' | translate}}</h4>
		<p>{{'WE ARE HAPPY TO ANSWER THEM' | translate}}</p>
		<ul>
			<li [innerHTML]="'SUPPORT_PAGE.SUPPORT_LINE_ADMIN' | translate"></li>
			<li [innerHTML]="'SUPPORT_PAGE.SUPPORT_LINE_MANUAL' | translate"></li>
			<li [innerHTML]="'SUPPORT_PAGE.SUPPORT_LINE_FAQ' | translate"></li>
			<li [innerHTML]="'SUPPORT_PAGE.SUPPORT_LINE_LIST_COURSES' | translate"></li>
			<li [innerHTML]="'SUPPORT_PAGE.CONTACT_US' | translate"></li>
		</ul>
	</section>

	<section>
		<h4>{{'SUPPORT_PAGE.BLOCKED_LINKS.TITLE' | translate}}</h4>
		<p>{{'SUPPORT_PAGE.BLOCKED_LINKS.SUBTITLE' | translate}}</p>

		<h4>{{'SUPPORT_PAGE.BLOCKED_LINKS.GAME' | translate}}</h4>
		<ul>
			<li>https://playitsafe.eu</li>
			<li>https://cdn.playitsafe.eu</li>
			<li>https://assets.playitsafe.eu</li>
			<li>https://api.playitsafe.eu</li>
			<li>https://analytics.playitsafe.eu</li>
			<li>https://pits-game-api-prod.azurewebsites.net</li>
			<li>https://pits-api-prd.azurewebsites.net</li>
			<li>https://pits-analytics-eventhub-prod.servicebus.windows.net</li>
			<li>https://pitsassetmanager.azurewebsites.net</li>
			<li>https://cdp.cloud.unity3d.com</li>
			<li>https://config.uca.cloud.unity3d.com</li>
			<li>https://pitsassetsprdstorage.blob.core.windows.net/</li>
		</ul>

		<h4>{{'SUPPORT_PAGE.BLOCKED_LINKS.PORTAL' | translate}}</h4>

		<ul>
			<li>https://portal.playitsafe.eu</li>
			<li>https://editor.playitsafe.eu</li>
			<li>https://api.playitsafe.eu</li>
			<li>https://pits-api-prd.azurewebsites.net</li>
			<li>https://app.powerbi.com</li>
			<li>https://wabi-europe-north-b-redirect.analysis.windows.net</li>
			<li>https://dc.services.visualstudio.com</li>
		</ul>
	</section>
</div>
