import { Directive, Input } from "@angular/core";
import {
  AbstractControl,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { HttpUserService } from "../../infrastructure/http/user/httpuser.service";
import * as _ from "lodash";
import { debounce } from "lodash";

@Directive({
  selector: "[appValidatorUserEmail]",
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: ValidatorUserEmailDirective,
      multi: true,
    },
  ],
})
export class ValidatorUserEmailDirective implements Validator {
  @Input("appValidatorUserEmail") public initialValue: string;

  constructor(private userService: HttpUserService) {
    this.debounceValidate = debounce(this.debounceValidate.bind(this), 500);
  }

  private debounceValidate(control, resolve) {
    const userEmail = control.value;
    if (_.isEqual(userEmail, this.initialValue)) {
      resolve(null);
    } else {
      this.userService.isUserEmailAvailable(userEmail).subscribe(
        (available) => {
          resolve(
            available
              ? null
              : {
                  userEmailDuplicate: true,
                }
          );
        },
        () => {
          resolve({
            userEmailDuplicate: true,
          });
        }
      );
    }
  }

  public validate(control: AbstractControl): Promise<ValidationErrors> {
    return new Promise((resolve) => {
      this.debounceValidate(control, resolve);
    });
  }
}
