import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Group } from "../../../../../domain/group";
import { User } from "../../../../../domain/user";
import { Category } from "../../../../../domain/category";
import { HttpUserService } from "../../../../../infrastructure/http/user/httpuser.service";
import { HttpCategoryService } from "../../../../../infrastructure/http/category/httpcategory.service";
import * as _ from "lodash";
import { DataService } from "../../../../../infrastructure/dataservice";
import { HttpReportsService } from "app/infrastructure/http/report/httpreports.service";
import { ILearningPath } from "app/application/interfaces/learning-paths.interface";
import { HttpGroupsService } from "app/infrastructure/http/group/httpgroups.service";

@Component({
  selector: "app-group-detail",
  templateUrl: "./group-detail.component.html",
  styleUrls: ["./group-detail.component.scss"],
})
export class GroupDetailComponent implements OnInit {
  public group: Group;
  public coursesLength = 0;
  public usersOfGroup: User[] = [];
  public categoriesOfGroup: Category[] = [];

  public loadingusers: boolean;
  public loadingcourses: boolean;

  isFeaturesLoading = false
  isDirectUserAssignments = false

  shouldShowLearningPaths = false
  isLearningPathsLoading = false
  learningPathsList: ILearningPath[]

  constructor(
    private dialogRef: MatDialogRef<GroupDetailComponent>,
    private userService: HttpUserService,
    private categoryService: HttpCategoryService,
    private groupService: HttpGroupsService,
    private dataService: DataService,
    private reportService: HttpReportsService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.group = data.group;
    this.shouldShowLearningPaths = 	_.some(this.dataService.features, x=> x == "learningpaths");
  }

  ngOnInit() {
    this.loadingusers = true;
    this.loadingcourses = true;
    this.isFeaturesLoading = true

    this.reportService
      .getFeatures(this.dataService.organizationID)
      .subscribe((features) => {
        const externalFeature = features.find((feature) => feature.name === 'direct_user_assignments')

        this.isDirectUserAssignments = externalFeature && true
        this.isFeaturesLoading = false
      })

    this.userService
      .getUsersOfGroup(this.dataService.organizationID, this.group)
      .subscribe((users: User[]) => {
        this.usersOfGroup = users;
        this.loadingusers = false;
      });

    if (this.shouldShowLearningPaths) {
      this.getGroupLearningPaths()
    }
    else{
      this.categoryService
      .getCategoriesOfGroup(this.group)
      .subscribe((categories: Category[]) => {
        this.categoriesOfGroup = categories;
        categories.map((category) => {
          this.coursesLength += category.courses.length;
        });
        this.loadingcourses = false;
      });
    }
  }

  getGroupLearningPaths() {
    this.isLearningPathsLoading = true;
    this.groupService
      .getLinkedLearningPaths(this.group.id)
      .subscribe(
        (learningPathList) => {
          this.learningPathsList = learningPathList || [];
          this.isLearningPathsLoading = false;
        },
        (error) => {
          console.error(error)
        }
      )
  }

  public canShowUsersOfGroup(): boolean {
    return !_.isEmpty(this.usersOfGroup);
  }

  public canShowCategoriesOfGroup(): boolean {
    return !_.isEmpty(this.categoriesOfGroup);
  }
}
