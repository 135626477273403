<section fxFlex fxLayout="column" class="user-detail__container">
  <header mat-dialog-title class="primary-color">
    <button class="close-button" mat-icon-button mat-dialog-close>
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>

    <h3>
      <span class="antialiased">{{ user.name }}</span>
      <div style="margin-right:2rem;">
        <span class="{{ getClassForStatus(user.status) }}"><span>{{ user.status }}</span></span>
        <button *ngIf="canDownloadCertificate" [disabled]="downloadingCertificate" mat-button
          class="mat-secondary mat-little-button download-certificate" (click)="downloadCertificate()"
          joyrideStep="DownloadCertificateStep" stepPosition="bottom" title="{{
          'admin_info_guide_module.download_certificate_title' | translate
        }}" text="{{
          'admin_info_guide_module.download_certificate_hint' | translate
        }}">
          <mat-spinner *ngIf="downloadingCertificate; else downloadLogsContent" [diameter]="18"></mat-spinner>
          <ng-template #downloadLogsContent>
            {{ "admin_info_guide_module.download_certificate_title" | translate }}
          </ng-template>
        </button>
      </div>
    </h3>
    <p class="job antialiased">{{ user.job }}</p>
  </header>

  <mat-dialog-content fxFlex class="user-detail-container">
    <mat-tab-group fxFlex (selectedTabChange)="onTabChanged($event)">

      <mat-tab label="Info">
        <section fxLayout="row" fxLayoutGap="38px" class="info-tab__wrapper">
          <section class="user-details" fxFlex fxLayout="column">
            <header class="data-tab__header">
              <h4>{{ "DATA" | translate }}</h4>
              <button *ngIf="canEditUser()" mat-button class="mat-secondary mat-little-button"
                (click)="toggleUserEditMode()" joyrideStep="EditInfoStep" stepPosition="bottom" title="{{
                  'admin_info_guide_module.edit_info_title' | translate
                }}" text="{{
                  'admin_info_guide_module.edit_info_hint' | translate
                }}">
                {{ "EDIT" | translate }}
              </button>
              <button *ngIf="userEditMode" mat-flat-button mat-button color="primary"
                class="mat-primary mat-little-button" (click)="updateUser(user)" [disabled]="!canUpdateUser()">
                {{ "SAVE" | translate }}
              </button>
            </header>
            <article fxFlex>
              <form [formGroup]="updateUserForm" *ngIf="updateUserForm">
                <div class="input-row" joyrideStep="FillInfoStep" stepPosition="bottom" title="{{
                    'admin_info_guide_module.fill_info_title' | translate
                  }}" text="{{
                    'admin_info_guide_module.fill_info_hint' | translate
                  }}">
                  <mat-form-field class="full-width">
                    <input matInput autocomplete="off" id="srch-username" aria-label="name" placeholder="{{ 'NAME' | translate }}"
                      formControlName="userName" />
                  </mat-form-field>
                </div>
                <div class="input-row">
                  <mat-form-field class="full-width">
                    <mat-error *ngIf="
                        updateUserForm.controls.userEmail.hasError('email') ||
                        updateUserForm.controls.userEmail.hasError('required')
                      ">
                      {{ "PROVIDE A VALID EMAIL" | translate }}
                    </mat-error>
                    <mat-error *ngIf="
                        updateUserForm.controls.userEmail.hasError(
                          'userEmailDuplicate'
                        )
                      ">{{ "common.email_duplicate" | translate }}</mat-error>
                    <input matInput aria-label="email" placeholder="{{ 'EMAIL' | translate }}" email
                      formControlName="userEmail"  [appValidatorUserEmail]="user.email" />
                  </mat-form-field>
                </div>
                <div class="input-row" *ngIf="canShowVirtualEmail()">
                  <div class="full-width">
                    <mat-checkbox formControlName="userVirtualEmail" (change)="checkUpdateForm($event)">{{
                      "VIRTUAL_EMAIL" | translate
                    }}</mat-checkbox>
                  </div>
                </div>
                <div class="input-row" *ngIf="canShowPassword()">
                  <div class="full-width">
                    <button [disabled]="updateUserForm.disabled" mat-flat-button mat-button color="primary" (click)="openPasswordDialog()" >Change Password</button >
                  </div>
                </div>
                <!--div formGroupName="passwordGroup" *ngIf="canShowPassword()">
                  <div class="input-row password">
                    <mat-form-field class="full-width">
                      <mat-error>{{'PASSWORD GUIDE' | translate}}</mat-error>
                      <input matInput type="password" id="srch-pw1" placeholder="{{'PASSWORD' | translate}}"
                        pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})" formControlName="password">
                    </mat-form-field>
          
                  </div>
                  <div class="input-row password">
                    <mat-form-field class="full-width" [ngClass]="{'mat-input-invalid': canShowErrorForConfirmedPassword()}">
                      <mat-hint *ngIf="canShowErrorForConfirmedPassword()">{{'REPEAT PASSWORD ERROR' | translate}}</mat-hint>
                      <input matInput type="password" id="srch-pw2"  placeholder="{{'REPEAT PASSWORD' | translate}}"
                        formControlName="passwordConfirm">
                    </mat-form-field>
                  </div>
                </div-->
                <div class="input-row">
                  <mat-form-field class="full-width">
                    <input autocomplete="off" id="srch-job" matInput aria-label="job" placeholder="{{ 'JOB' | translate }}" formControlName="userJob" />
                  </mat-form-field>
                </div>
                <div class="input-row">
                  <mat-form-field class="full-width">
                    <input matInput readonly aria-label="birthday" [matDatepicker]="birthdaypicker"
                      formControlName="userBirthdate" placeholder="{{ 'BIRTHDATE' | translate }}" />
                    <mat-datepicker-toggle matSuffix [for]="birthdaypicker" [disabled]="!userEditMode">
                    </mat-datepicker-toggle>
                    <mat-datepicker #birthdaypicker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="input-row">
                  <mat-form-field class="full-width">
                    <input autocomplete="off" id="srch-dpt"  matInput aria-label="department" placeholder="{{ 'DEPARTMENT' | translate }}"
                      formControlName="userDepartment" />
                  </mat-form-field>
                </div>
                <div class="input-row">
                  <div class="full-width">
                    <mat-form-field>
                      <mat-select formControlName="userLanguage" placeholder="{{ 'common.language' | translate }}">
                        <mat-option *ngFor="let language of userLanguages" [value]="language">{{ language.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="input-row">
                  <div class="full-width">
                    <mat-form-field>
                      <mat-select formControlName="userRole" placeholder="{{ 'ROLE' | translate }}">
                        <mat-option *ngFor="let role of userRoles" [value]="role.value">{{ role.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </article>
          </section>
          <section class="user-groups" fxFlex fxLayout="column">
            <header>
              <h4>{{ "GROUPS" | translate }}</h4>
            </header>
            <article fxFlex>
              <mat-spinner class="listspinner" strokeWidth="5" diameter="30" *ngIf="loadinggroups"></mat-spinner>
              <mat-list *ngIf="!loadinggroups && canShowGroupsOfUser()">
                <mat-list-item *ngFor="let group of groupsOfUser">
                  <div mat-line>
                    <span class="group-name antialiased">{{ group.name }}</span>
                  </div>
                </mat-list-item>
              </mat-list>
              <mat-list *ngIf="!loadinggroups && !canShowGroupsOfUser()">
                <mat-list-item>
                  <div mat-line>
                    <span class="antialiased">{{
                      "NO GROUPS AVAILABLE" | translate
                    }}</span>
                  </div>
                </mat-list-item>
              </mat-list>
            </article>
          </section>
          <section *ngIf="!shouldShowLearningPaths" class="user-courses" fxFlex fxLayout="column">
            <header>
              <h4>{{ "COURSES" | translate }}</h4>
            </header>
            <article fxFlex>
              <mat-spinner class="listspinner" strokeWidth="5" diameter="30" *ngIf="loadingcourses"></mat-spinner>
              <mat-accordion [multi]="true" *ngIf="!loadingcourses && canShowCategoriesOfUser()">
                <mat-expansion-panel #panel *ngFor="let category of categoriesOfUser" [hideToggle]="true">
                  <mat-expansion-panel-header fxLayout="row">
                    <div class="accordion-title" fxFlex>
                      <mat-icon>
                        <svg *ngIf="panel.expanded == true"  xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/></svg>
                        <svg  *ngIf="panel.expanded == false" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
                      </mat-icon>
                      <span [matTooltip]="!category.isActive ? category.name : ''" class="course-name"
                        [ngClass]="{ 'not-active': !category.isActive }">{{ category.name }}</span>
                      <span class="information-active antialiased" *ngIf="!category.isActive"
                        [ngClass]="{ 'not-active': !category.isActive }">({{ "NOT ACTIVE" | translate }})</span>
                    </div>
                  </mat-expansion-panel-header>
                  <mat-list>
                    <mat-list-item *ngFor="let course of category.courses">
                      <span [matTooltip]="
                          !category.isActive || !course.isActive
                            ? course.name
                            : ''
                        " class="course-name" [ngClass]="{
                          'not-active': !category.isActive || !course.isActive
                        }">{{ course.level }}. {{ course.name }}</span>
                      <span class="information-active antialiased" *ngIf="!category.isActive || !course.isActive"
                        [ngClass]="{
                          'not-active': !category.isActive || !course.isActive
                        }">({{ "NOT ACTIVE" | translate }})</span>
                    </mat-list-item>
                  </mat-list>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-list *ngIf="!loadingcourses && !canShowCategoriesOfUser()">
                <mat-list-item>
                  <div mat-line>
                    <span class="antialiased">{{
                      "NO COURSES AVAILABLE" | translate
                    }}</span>
                  </div>
                </mat-list-item>
              </mat-list>
            </article>
          </section>
          <section *ngIf="shouldShowLearningPaths" class="user-courses" fxFlex fxLayout="column">
            <header fxLayoutAlign="left center">
              <h4>{{"learningpaths_module.learning_paths" | translate}}</h4>
            </header>

            <article fxFlex>
              <mat-spinner class="listspinner" strokeWidth="5" diameter="30"
                *ngIf="isLearningPathsLoading; else learningPathContent"></mat-spinner>

              <ng-template #learningPathContent>
                <mat-list>
                  <mat-list-item *ngIf="learningPathsList.length === 0; else pathList">
                    <p mat-line>{{ "NO PATHS AVAILABLE" | translate }}
                  </p>
                  </mat-list-item>

                  <ng-template #pathList>
                    <mat-list-item *ngFor="let learningPath of learningPathsList">
                      <p mat-line>{{ learningPath.workingName }}</p>
                    </mat-list-item>
                  </ng-template>
                </mat-list>
              </ng-template>
            </article>
          </section>
        </section>
      </mat-tab>

      <mat-tab *ngIf="showCompanyTab" label="{{ 'COMPANYINFORMATION' | translate }}">
        <div class="company-tab__wrapper">
          <section class="organization-details company-tab__settings">
            <header class="data-tab__header">
              <h4>{{ "DATA" | translate }}</h4>
              <button *ngIf="!organizationEditMode" mat-button class="mat-secondary mat-little-button"
                (click)="toggleOrganizationEditMode()" joyrideStep="EditCompanyStep" stepPosition="bottom" title="{{
                  'admin_company_guide_module.edit_info_title' | translate
                }}" text="{{
                  'admin_company_guide_module.edit_info_hint' | translate
                }}">
                {{ "EDIT" | translate }}
              </button>
              <button *ngIf="organizationEditMode" mat-flat-button mat-button color="primary"
                class="mat-primary mat-little-button" (click)="updateOrganization()"
                [disabled]="!canUpdateOrganization()">
                {{ "SAVE" | translate }}
              </button>
            </header>
            <article>
              <form [formGroup]="updateOrganizationForm" *ngIf="organizationDetailsAvailable()">
                <div class="input-row">
                  <mat-form-field class="full-width">
                    <input matInput aria-label="name" placeholder="{{ 'NAME' | translate }}"
                      formControlName="organizationName" />
                  </mat-form-field>
                </div>
                <div class="input-row" joyrideStep="InternalEmergencyNumberStep" stepPosition="top" title="{{
                    'admin_company_guide_module.internal_emergency_number_title'
                      | translate
                  }}" text="{{
                    'admin_company_guide_module.internal_emergency_number_hint'
                      | translate
                  }}">
                  <mat-form-field class="full-width">
                    <input matInput aria-label="internal emergency number" type="text" digitsOnly
                      placeholder="{{ 'INTERNAL EMERGENCY NUMBER' | translate }}"
                      formControlName="organizationInternalEmergencyNumber" />
                  </mat-form-field>
                </div>
                <div class="input-row" joyrideStep="ExternalEmergencyNumberStep" stepPosition="top" title="{{
                    'admin_company_guide_module.external_emergency_number_title'
                      | translate
                  }}" text="{{
                    'admin_company_guide_module.external_emergency_number_hint'
                      | translate
                  }}">
                  <mat-form-field class="full-width">
                    <input matInput aria-label="external emergency number"  type="text" digitsOnly
                      placeholder="{{ 'EXTERNAL EMERGENCY NUMBER' | translate }}"
                      formControlName="organizationExternalEmergencyNumber" />
                  </mat-form-field>
                </div>
                <div class="input-row" joyrideStep="LanguageCompanyStep" stepPosition="top" title="{{
                    'admin_company_guide_module.language_title' | translate
                  }}" text="{{
                    'admin_company_guide_module.language_hint' | translate
                  }}">
                  <mat-form-field class="full-width">
                    <mat-select formControlName="organizationLanguage" [value]="
                        updateOrganizationForm.get('organizationLanguage').value
                      " placeholder="{{ 'common.language' | translate }}">
                      <mat-option *ngFor="let language of userLanguages" [value]="language">{{ language.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="input-row" joyrideStep="ResponsibleUserStep" stepPosition="top" title="{{
                    'admin_company_guide_module.responsible_user_title'
                      | translate
                  }}" text="{{
                    'admin_company_guide_module.responsible_user_hint' | translate
                  }}">
                  <mat-form-field class="full-width">
                    <mat-error>{{
                      "USER MUST BE SELECTED" | translate
                    }}</mat-error>
                    <input matInput placeholder="{{ 'RESPONSIBLE USER' | translate }}" aria-label="responsible user"
                      [matAutocomplete]="usersAutoComplete" formControlName="organizationResponsibleUser" />
                  </mat-form-field>
                  <mat-autocomplete #usersAutoComplete [displayWith]="displayNameOfResponsibleUser">
                    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                      {{ user.name }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="input-row" joyrideStep="EnvironmentStep" stepPosition="top" title="{{
                    'admin_company_guide_module.environment_title' | translate
                  }}" text="{{
                    'admin_company_guide_module.environment_hint' | translate
                  }}">
                  <mat-form-field class="full-width">
                    <mat-select formControlName="organizationPlayEnvironment"
                      placeholder="{{ 'common.environment' | translate }}">
                      <mat-option *ngFor="let playEnvironment of playEnvironments" [value]="playEnvironment">
                        {{ playEnvironment.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="input-row">
                  <div class="full-width">
                    <input type="file" #logoFileInput id="logo-file-input" accept="image/*"
                      (change)="onFileInput($event)" />
                  </div>
                  <div fxLayoutAlign="center center" class="logo-container"
                    [ngClass]="{ 'placeholder-logo': !organization.logo }">
                    <div class="overlay" fxLayoutAlign="center center" *ngIf="organizationEditMode"
                      (click)="triggerUpload()">
                      <div class="text">{{ "UPLOAD LOGO" | translate }}</div>
                    </div>
                    <img alt="logo organization" *ngIf="organization.logo" [src]="organization.logo"
                      class="logo-file-image" />
                    <img alt="logo organization" *ngIf="!organization.logo && !organizationEditMode"
                      src="./assets/img/picture_placeholder.png" class="logo-file-image" />
                    <img alt="logo organization" *ngIf="!organization.logo && organizationEditMode"
                      src="./assets/img/picture_placeholder.png" class="logo-file-image upload-file"
                      (click)="triggerUpload()" />
                  </div>
                </div>
              </form>
            </article>
          </section>

          <section class="organization-details company-tab__settings">
            <header class="data-tab__header">
              <h4>{{'COURSESETTINGS' | translate}}</h4>
              <button *ngIf="!settingsEditMode" mat-button class="mat-secondary mat-little-button"
                (click)="toggleSettingsEditMode()">
                {{ "EDIT" | translate }}
              </button>
              <button *ngIf="settingsEditMode" mat-flat-button mat-button color="primary"
                class="mat-primary mat-little-button" (click)="updateSettings()" [disabled]="!canUpdateSettings()">
                {{ "SAVE" | translate }}
              </button>
            </header>
            <article class="settings__content"
              *ngIf="!isExpirationSettingsLoading && !isStarsSettingsLoading; else settingsSpinner">
              <form *ngIf="isExpirationSettingsEnabled" class="expiration-settings"
                [formGroup]="expirationSettingsForm">
                <div class="expiration-settings__courses">
                  <h5 class="expiration-settings__courses-title">
                    {{'courses_settings_module.expiration_settings_title' | translate}}
                  </h5>

                  <mat-radio-group color="primary" class="expiration-settings__options" formControlName="courseResults">
                    <mat-radio-button class="expiration-settings__option-button"
                      [value]="EXPIRATION_SETTING_VALUES.SIX_MONTH">
                      6 {{'courses_settings_module.expiration_month_label' | translate}}
                    </mat-radio-button>
                    <mat-radio-button class="expiration-settings__option-button"
                      [value]="EXPIRATION_SETTING_VALUES.ONE_YEAR">
                      1 {{'courses_settings_module.expiration_year_label' | translate}}
                    </mat-radio-button>
                    <mat-radio-button class="expiration-settings__option-button"
                      [value]="EXPIRATION_SETTING_VALUES.TWO_YEARS">
                      2 {{'courses_settings_module.expiration_years_label' | translate}}
                    </mat-radio-button>
                    <mat-radio-button class="expiration-settings__option-button"
                      [value]="EXPIRATION_SETTING_VALUES.NEVER">
                      {{'courses_settings_module.expiration_never_label' | translate}}
                    </mat-radio-button>

                    <div class="custom-option">
                      <mat-radio-button class="expiration-settings__option-button"
                        [value]="EXPIRATION_SETTING_VALUES.CUSTOM">
                        {{'courses_settings_module.expiration_custom_label' | translate}}
                      </mat-radio-button>

                      <div class="custom-option__container"
                        [hidden]="expirationSettingsForm.get('courseResults').value !== '5'">
                        <mat-form-field class="input-number">
                          <mat-select [formControl]="expirationNumberControl">
                            <mat-option *ngFor="let num of expirationNumberOptions" [value]="num + ''">
                              {{num}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field class="input-type">
                          <mat-select [formControl]="expirationTypeControl">
                            <mat-option value="Year">
                              {{expirationYearLabel}}
                            </mat-option>
                            <mat-option value="Month">
                              {{expirationMonthLabel}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                    </div>
                  </mat-radio-group>
                </div>
              </form>

              <form class="expiration-settings" [formGroup]="courseStarsSettingsForm">
                <div class="expiration-settings__stars">
                  <h5 class="expiration-settings__stars-title">
                    {{'courses_settings_module.stars_settings_title' | translate}}
                  </h5>

                  <mat-radio-group color="primary" class="expiration-settings__options"
                    formControlName="minimumRequiredStars">
                    <mat-radio-button class="expiration-settings__option-button" [value]="1">
                      1 {{'courses_settings_module.star_label' | translate}}
                    </mat-radio-button>
                    <mat-radio-button class="expiration-settings__option-button" [value]="2">
                      2 {{'courses_settings_module.stars_label' | translate}}
                    </mat-radio-button>
                    <mat-radio-button class="expiration-settings__option-button" [value]="3">
                      3 {{'courses_settings_module.stars_label' | translate}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </form>
            </article>

            <ng-template #settingsSpinner>
              <mat-spinner class="listspinner" strokeWidth="5" diameter="30"></mat-spinner>
            </ng-template>
          </section>
        </div>
      </mat-tab>

    </mat-tab-group>
  </mat-dialog-content>

  <div class="user-detail-guide tooltip-guide" matTooltip="{{ 'on_boarding_module.tooltip_info' | translate }}"
    (click)="startOnboardingNavigation()">
    <mat-icon>help_outline</mat-icon>
    <div class="text-block">
      {{ "on_boarding_module.repeat_guide" | translate }}
    </div>
  </div>
</section>

<script src="https://kit.fontawesome.com/99fe3c75dd.js" crossorigin="anonymous"></script>
