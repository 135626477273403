import { Component, OnInit } from "@angular/core";
import { Category } from "app/domain/category";
import { DataService } from "app/infrastructure/dataservice";
import { HttpCategoryService } from "app/infrastructure/http/category/httpcategory.service";
import { HttpReportsService } from "app/infrastructure/http/report/httpreports.service";

interface ICategoryItem {
  category: Category
  isExpand: boolean
}

@Component({
  selector: 'app-available-courses',
  templateUrl: './available-courses.component.html',
  styleUrls: ['./available-courses.component.scss']
})
export class AvailableCoursesComponent implements OnInit {
  isCategoriesLoading = false
  categories: ICategoryItem[] = []

  isExpirationSettingsEnabled = false

  constructor(
    private categoryService: HttpCategoryService,
    private reportService: HttpReportsService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.getCategories()
    this.getFeatures()
  }

  getFeatures() {
    const { organizationID } = this.dataService

    this.reportService.getFeatures(organizationID).subscribe(
      (features) => {
        this.isExpirationSettingsEnabled = features.some(({ name }) => {
          return name === 'expiration_courses_custom_expirationterm'
        })
      }
    )
  }

  getCategories() {
    this.isCategoriesLoading = true
    this.categoryService.getCategories().subscribe(
      (categories: Category[]) => {
        this.categories = this.mapCategories(categories);
        this.isCategoriesLoading = false
      }
    )
  }

  mapCategories(categories: Category[]): ICategoryItem[] {
    return categories.map((category, index) => {
      let isExpand = index === 0
      return { category, isExpand }
    })
  }
}
