<section class="antialiased course-detail">

  <header mat-dialog-title class="primary-color">
    <button class="close-button" mat-icon-button mat-dialog-close>
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>
    <h4>{{ course.name }}</h4>
    <p class="courseid">id: {{courseId}}</p>
  </header>

  <mat-tab-group class="course-detail__tabs">
    <mat-tab label="Info">
      <app-course-info-tab [courseId]="courseId" [courseName]="course.name" [courseDescription]="course.description"></app-course-info-tab>
    </mat-tab>
    <mat-tab label="Settings">
      <app-course-settings-tab [isExpirationCourseSettingsEnabled]="isExpirationCourseSettingsEnabled"
        [course]="course"></app-course-settings-tab>
    </mat-tab>
  </mat-tab-group>

</section>
