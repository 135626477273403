import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TOAST_TYPES } from "app/application/constants";
import { IUserLicense } from "app/application/interfaces/licenses.interface";
import { ToastService } from "app/application/toast/toast.service";
import { User } from "app/domain/user";
import { HttpOrganizationService } from "app/infrastructure/http/organization/httporganization.service";
import { LicenseManagementHttpService } from "app/presentation/pages/license-management/services/license-management-http.service";
import { LicenseConfirmationDialog } from "app/presentation/pages/license-overview/components/license-confirmation-dialog/license-confirmation-dialog";
import * as moment from "moment";
import { DataService } from "../../../../../infrastructure/dataservice";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
  selector: 'app-user-licenses',
  templateUrl: './user-licenses-modal.component.html',
  styleUrls: ['./user-licenses-modal.component.scss']
})
export class UserLicensesComponent implements OnInit {
  licenseId: string
  blockId: string
  license: IUserLicense
  licenseType = ''
  unlicensedOrganization = false
  isDataLoading = false
  isRenewLoading = false

  disabledReason = 'You can\'t renew this user, because expiration date is binded to the license block'

  constructor(
    @Inject(MAT_DIALOG_DATA) public user: User,
    private dialog: MatDialog,
    private toastService: ToastService,
    private licenseService: LicenseManagementHttpService,
    private organizationService: HttpOrganizationService,
    private dataService: DataService
  ) {
    this.licenseId = user.licenseId;
    this.blockId = user.blockId;
    this.unlicensedOrganization = this.dataService.CurrentOrganization.isUnlicensed
  }

  ngOnInit() {
    if (this.licenseId !== '00000000-0000-0000-0000-000000000000') {
      this.getLicenseType()
      
    }
  }

  getLicenseType() {
    this.isDataLoading = true
    this.licenseService.getLicense(this.blockId).subscribe((licenseBlock) => {
      this.licenseType = licenseBlock.licenseType
      this.organizationService.getUserLicense(this.blockId, this.licenseId).subscribe((license: IUserLicense) => {
        this.license = license
        this.isDataLoading = false
      });
    })
  }

  renewLicense(blockId, licenseId) {
    if (!this.isRenewLoading) {
      const dialogConfig = { height: '200px', width: '500px' }
      const dialogRef = this.dialog.open(LicenseConfirmationDialog, {
        data: {
          textContent: 'Are you sure you want to renew this license?'
        },
        ...dialogConfig
      })

      dialogRef
        .afterClosed()
        .pipe(
          switchMap((action) => {
            if (action) {
              this.isRenewLoading = true
              return this.organizationService.renewUserLicense(blockId, licenseId)
            }
            return of()
          })
        )
        .subscribe(() => {

          //this.license.expirationDate = moment(this.license.expirationDate).add(1, 'year').toString()

          this.toastService.show('License was successfully renewed')
          this.isRenewLoading = false
        }, () => {
          this.toastService.show('Something went wrong', TOAST_TYPES.ERROR)
        })
    }
  }

  get IsUnlicensedOrganization() {
    return this.unlicensedOrganization;
  }
}
