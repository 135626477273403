<script src="https://kit.fontawesome.com/99fe3c75dd.js" crossorigin="anonymous"></script>

<div class="container-list">
  <article class="list-header" fxLayout="column">
    <div class="header-flex" fxLayout="row">
      <div class="header-title">
        <h3>{{ "OVERVIEW USERS" | translate }}</h3>
        <span *ngIf="hasSelectedUsers()" class="selection-indication antialiased">
          {{
          "user_overview.number_of_users_selected"
          | translate: { numberOfUsers: getNumberOfSelectedUsers() }
          }}
        </span>
      </div>
      <div class="select-wrapper" joyrideStep="SelectActionStep" stepPosition="right"
        title="{{ 'user_guide_module.select_action_title' | translate }}"
        text="{{ 'user_guide_module.select_action_hint' | translate }}" fxLayoutAlign="end center">
        <button mat-flat-button color="primary" class="select" flex="none" #btnTrigger="matMenuTrigger"
          [matMenuTriggerFor]="userActions">
          {{ "common.select_action" | translate }}
          <mat-icon><svg class="select-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path
                d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
            </svg></mat-icon>
        </button>

        <mat-menu #userActions [overlapTrigger]="false" xPosition="after" yPosition="below">
          <div *ngIf="!isOrganizationUnlicensed">
            <button mat-menu-item [disabled]="!hasSelectedUsers()" (click)="deactivateSelectedUsers()">
              Deactivate users
            </button>
            <button mat-menu-item [disabled]="!hasSelectedUsers()" (click)="reactivateUsers()">
              Reactivate users
            </button>
            <button mat-menu-item [disabled]="!hasSelectedUsers()" (click)="activateNewLicense()">
              Activate new license
            </button>
          </div>
          <button mat-menu-item [disabled]="!hasSelectedUsers()" (click)="removeSelectedUsers()"
            joyrideStep="RemoveUsersStep" stepPosition="right"
            title="{{ 'user_guide_module.remove_users_title' | translate }}"
            text="{{ 'user_guide_module.remove_users_hint' | translate }}">
            {{ "user_overview.remove_users" | translate }}
          </button>
          <button mat-menu-item [disabled]="!hasSelectedUsers()" (click)="sendReminder()" joyrideStep="SendReminderStep"
            stepPosition="right" title="{{ 'user_guide_module.send_reminder_title' | translate }}"
            text="{{ 'user_guide_module.send_reminder_hint' | translate }}">
            {{ "reminder.send_reminder" | translate }}
          </button>
          <button mat-menu-item [matMenuTriggerFor]="bulk_actions">
            {{ "bulk_actions.menu" | translate }}
          </button>
          <button mat-menu-item (click)="addUser()" joyrideStep="AddUsersStep" stepPosition="right"
            title="{{ 'user_guide_module.add_users_title' | translate }}"
            text="{{ 'user_guide_module.add_users_hint' | translate }}">
            {{ "ADD USER" | translate }}
          </button>
        </mat-menu>
        <mat-menu #bulk_actions="matMenu">
          <button mat-menu-item (click)="importUsers()" joyrideStep="ImportUsersStep" stepPosition="right"
            title="{{ 'user_guide_module.import_users_title' | translate }}"
            text="{{ 'user_guide_module.import_users_hint' | translate }}">
            {{ "user_overview.import_users" | translate }}
          </button>
          <button mat-menu-item (click)="deactivateUsers()" joyrideStep="DeactivateUsersStep" stepPosition="right"
            title="{{ 'user_guide_module.deactivate_users_title' | translate }}"
            text="{{ 'user_guide_module.deactivate_users_hint' | translate }}">
            {{ "users_deactivate.menu" | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
    <app-filter (filteredTextChanged)="filterUsers($event)" joyrideStep="SearchUserStep" stepPosition="top"
      title="{{ 'user_guide_module.search_user_title' | translate }}"
      text="{{ 'user_guide_module.search_user_hint' | translate }}" placeholder="{{ 'FIND USER' | translate }}">
    </app-filter>
  </article>

  <article class="list-content">
    <div class="select-user-joyride" joyrideStep="SelectUserStep" stepPosition="right"
      title="{{ 'user_guide_module.select_user_title' | translate }}"
      text="{{ 'user_guide_module.select_user_hint' | translate }}"></div>
    <div class="user-status-joyride" joyrideStep="UserStatusStep" stepPosition="left"
      title="{{ 'user_guide_module.user_status_title' | translate }}"
      text="{{ 'user_guide_module.user_status_hint' | translate }}"></div>
    <div class="select-users-joyride" joyrideStep="SelectUsersStep" stepPosition="right"
      title="{{ 'user_guide_module.select_users_title' | translate }}"
      text="{{ 'user_guide_module.select_users_hint' | translate }}"></div>

    <ag-grid-angular class="ag-theme-material" style="width: 100%; height: 100%;" [gridOptions]="peopleGridOptions"
      [rowData]="users" (gridReady)="onGridReady($event)"></ag-grid-angular>

    <div *ngIf="isLoading() | async" class="loader-container">
      <p>
        {{
        "general.loading"
        | translate: { attribute: "USERS_attribute" | translate }
        }}...
      </p>
      <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
    </div>
  </article>
</div>

<app-users-groups (onGroupsUpdate)="onGroupsUpdateHandler()" [selectedUsers]="selectedUsers" [isActive]="isActive">
</app-users-groups>