<form class="course-form" [formGroup]="courseInfoForm" (ngSubmit)="submitCourseInfoForm()">
  <mat-expansion-panel class="course-info-translations cit"
    (expandedChange)="isTranslationsExpanded = !isTranslationsExpanded" [expanded]="isTranslationsExpanded"
    [hideToggle]="true">
    <mat-expansion-panel-header>
      <div class="cit__header">
        <app-button shape="icon">
          <mat-icon class="cit__icon" [ngClass]="{ expanded: isTranslationsExpanded }">
            expand_more
          </mat-icon>
        </app-button>

        <h3 class="cit__title">Translations</h3>
      </div>
    </mat-expansion-panel-header>

    <div class="cit__form">
      <ng-container *ngFor="let formGroup of translationsFormArray.controls; let i = index">
        <div [formGroup]="formGroup">
          <div class="cit__translations">
            <div class="cit__select-wrapper">
              <ng-select class="cit__select-language" [items]="languages" [loading]="isLanguagesLoading"
                [searchable]="false" [ngClass]="{
              'custom-error':
                translationsFormArray.hasError('uniqueLanguages') &&
                translationsFormArray.errors.uniqueLanguages.includes(
                  formGroup.get('languageId').value
                )
            }" appendTo="body" bindLabel="name" bindValue="id" placeholder="Choose the language"
                formControlName="languageId" [(ngModel)]="formGroup.get('languageId').value">
              </ng-select>
              <div *ngIf="
              formGroup.get('languageId').hasError('required') &&
              formGroup.get('languageId').touched
            " class="cit__select-error">
                Language is <strong>required</strong>
              </div>
              <div *ngIf="
              translationsFormArray.hasError('uniqueLanguages') &&
              translationsFormArray.errors.uniqueLanguages.includes(
                formGroup.get('languageId').value
              )
            " class="cit__select-error">
                Language should be <strong>unique</strong>
              </div>
            </div>
            <mat-form-field class="cit__name">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" />
              <mat-error> Name is <strong>required</strong> </mat-error>
            </mat-form-field>
            <mat-form-field class="cit__description">
              <mat-label>Description</mat-label>
              <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
              <mat-error> Description is <strong>required</strong> </mat-error>
            </mat-form-field>

            <app-button *ngIf="translationsFormArray.length > 1" class="cit__button-delete" shape="icon"
              (onButtonClick)="removeLanguageGroup(i)" [disabled]="translationsFormArray.disabled">
              <mat-icon class="cit__button-delete-icon" svgIcon="learning-path-delete"></mat-icon>
            </app-button>
          </div>
        </div>
      </ng-container>

      <app-button class="cit__button" shape="raised" color="primary" (onButtonClick)="addLanguageGroup()"
        [disabled]="translationsFormArray.disabled">
        <mat-icon class="cit__button-icon">add</mat-icon> add new language
      </app-button>
    </div>
  </mat-expansion-panel>


  <h3 class="course-form__field-title estimated-time">Estimated time</h3>
  <mat-form-field appearance="standard" class="course-form__estimated-time">
    <mat-label>Estimated time</mat-label>
    <input formControlName="estimatedTime" matInput type="number" min="0" max="300" autocomplete="off" />
  </mat-form-field>

  <h3 class="course-form__field-title">Upload screenshot</h3>
  <div class="course-form__upload">
    <app-button class="course-form__upload-button" shape="raised" color="primary" [disabled]="true">
      Upload screenshot
    </app-button>
    <p class="course-form__upload-note">
      *up to 3 images can be uploaded with 500kb maximum size
    </p>
  </div>

  <div class="course-form__actions">
    <app-button class="course-form__save-button" shape="raised" color="primary" type="submit"
      [isLoading]="isFormLoading" [disabled]="isFormDisabled">
      Save
    </app-button>
  </div>
</form>
<app-button color="secondary" class="course-form__edit-button" (onButtonClick)="switchFormState()">
  {{isFormDisabled ? 'edit' : 'discard'}}
</app-button>
