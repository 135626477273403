import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import * as _ from "lodash";
import { NotificationService } from "../../../../application/notification/notification.service";
import { validatePasswordMatch } from "../../../../application/validators/password-match-validation";
import RoleEnum from "../../../../domain/roleEnum";
import StatusEnum from "../../../../domain/statusEnum";
import { User } from "../../../../domain/user";
import { LoggedInOrganizationService } from "../../../../infrastructure/helper/logged-in-organization.service";
import { HttpUserService } from "../../../../infrastructure/http/user/httpuser.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastService } from "app/application/toast/toast.service";
import { TOAST_TYPES } from "app/application/constants";
import { HttpReportsService } from "app/infrastructure/http/report/httpreports.service";
import { DataService } from "app/infrastructure/dataservice";
import { Feature } from "app/domain/feature";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  public user: User;
  public addUserForm: FormGroup;

  isSkipAutoInvite = false

  constructor(
    private dialogRef: MatDialogRef<AddUserComponent>,
    private userService: HttpUserService,
    private notificationService: NotificationService,
    private loggedInOrganizationService: LoggedInOrganizationService,
    private toastService: ToastService,
    private dataService: DataService,
    private reportService: HttpReportsService
  ) {}

  ngOnInit() {
    this.user = new User({
      id: "",
      name: "",
      email: "",
      job: "",
      birthdate: null,
      department: "",
      status: StatusEnum.invited,
      role: RoleEnum.player,
      language: null,
      organizationID: null,
      virtualEmail: false,
      licenseId: null,
      blockId: null,
    });

    this.addUserForm = new FormGroup({
      name: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      virtualEmail: new FormControl(false),
    });

    this.addUserForm
      .get("virtualEmail")
      .valueChanges.subscribe((virtualEmail: boolean) => {
        if (virtualEmail) {
          this.addUserForm.addControl(
            "passwordGroup",
            new FormGroup(
              {
                password: new FormControl("", Validators.required),
                passwordConfirm: new FormControl("", Validators.required),
              },
              validatePasswordMatch
            )
          );
        }
      });

    this.reportService
      .getFeatures(this.dataService.organizationID)
      .subscribe((features: Feature[]) => {
        const externalFeature = features.find((feature) =>  feature.name === 'skip_auto_invite')

        this.isSkipAutoInvite = externalFeature && true
      })
  }

  public canShowPassword(): boolean {
    return (
      this.addUserForm.get("virtualEmail").value &&
      !_.isNil(this.addUserForm.get("passwordGroup"))
    );
  }

  checkUpdateForm(value: MatCheckboxChange) {
    if (!value.checked) {
      this.addUserForm.removeControl("passwordGroup");
    }
  }

  public canShowErrorForConfirmedPassword(): boolean {
    return (
      this.addUserForm.get("passwordGroup.passwordConfirm").touched &&
      this.addUserForm.get("passwordGroup").hasError("validatePasswordMatch")
    );
  }

  public registerUser(user: User) {
    const limitUserResponse = "no_licenses_left";
    const organizationId: string = this.loggedInOrganizationService.getLoggedInOrganizationId();
    const password: string = user.virtualEmail
      ? this.addUserForm.get("passwordGroup.password").value
      : null;

    this.userService.createUser(organizationId, user, password).subscribe(
      (registeredUser: User) => {
        if (!this.isSkipAutoInvite && !registeredUser.virtualEmail) {
          this.userService
            .sendInvitation(organizationId, registeredUser)
            .subscribe(() => {
              this.notificationService.show("USER ADDED");
              this.dialogRef.close(registeredUser);
            });
        } else {
          this.notificationService.show("USER ADDED");
          this.dialogRef.close(registeredUser);
        }
      },
      (errorResponse: HttpErrorResponse) => {
        const { error, status } = errorResponse

        if (typeof error === 'string') {
          if (status === 400 && error === limitUserResponse) {
            this.toastService.show('Oops! You can not add a user because you exceed limit cap!', TOAST_TYPES.ERROR)
          } else {
            this.toastService.show('Something went wrong', TOAST_TYPES.ERROR)
          }
        } else {
          this.toastService.show(error.Error.Message, TOAST_TYPES.ERROR)
        }
      }
    );
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public keyDownFunction(event) {
    if (event.keyCode === 13) {
      if (this.addUserForm.valid) {
        this.registerUser(this.user);
      }
    }
  }
}
