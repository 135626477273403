import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { concatMap, map as rxjsMap, mergeMap } from "rxjs/operators";
import { Organization } from "../../../domain/organization";
import { User } from "../../../domain/user";
import { PersistentStoreService } from "../../helper/persistentstore.service";
import { HttpCategoryService } from "../category/httpcategory.service";
import { HttpGroupsService } from "../group/httpgroups.service";
import { HttpOrganizationService } from "../organization/httporganization.service";
import { HttpReportsService } from "../report/httpreports.service";
import { HttpUserService } from "../user/httpuser.service";
import { Authentication } from "./authentication";
import { DataService } from "../../dataservice";
import RoleEnum from "../../../domain/roleEnum";
import { OnboardingService } from "../onboarding/onboarding.service";
import { Feature } from "../../../domain/feature";
import { concat } from "lodash";

@Injectable()
export class AuthenticationService implements Authentication {
  public constructor(
    private httpClient: HttpClient,
    private dataService: DataService,
    private persistentstoreService: PersistentStoreService,
    private userService: HttpUserService,
    private groupsService: HttpGroupsService,
    private categoryService: HttpCategoryService,
    private organizationService: HttpOrganizationService,
    private reportsService: HttpReportsService,
    private onboarding: OnboardingService
  ) { }

  public TryAutoLogin(): Observable<boolean> {
    const authToken = this.dataService.authToken || ''

    if (authToken) {

      const orgID = this.dataService.organizationID;
      const userID = this.dataService.userID;

      return forkJoin([
        this.organizationService.getOrganization(orgID),
        this.organizationService.getFeatures(orgID),
        this.userService.getUser(orgID, userID)
      ]
      ).pipe(rxjsMap((resp: any[]) => {
        this.dataService.setOrganization(resp[0]);
        this.dataService.setFeatures(resp[1].map((feature: Feature) => { return feature.name; }));
        let user: User = resp[2];
        this.persistentstoreService.set("userRole", String(user.role));
        if (
          user.role === RoleEnum.admin ||
          user.role === RoleEnum.superuser
        ) {
          this.dataService.organizationID = orgID;
          this.dataService.userID = userID;
          this.dataService.AuthenticatedUser = user;
          this.dataService.userRole = user.role
          return true;
        } else {
          this.logout();
          return false;
        }
      }));
    } else {
      return of(false)
    }
  }

  public login(email: string, password: string): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");
    return this.handleLoginResponse(
      this.httpClient.post(
        "/Authenticate",
        {
          email: email,
          password: password,
          requestedRole: 15,
        },
        {
          headers: httpHeaders,
        }
      )
    );
  }

  private handleLoginResponse(
    httpRequest: Observable<Object>
  ): Observable<any> {
    return httpRequest.pipe(
      concatMap((responseDTO: any) => {
        if (
          responseDTO.organizationIDs === undefined ||
          responseDTO.organizationIDs.length === 0
        ) {
          return [{ success: false, errorcode: "no_orgs_found" }];
        }

        let orgID = responseDTO.organizationIDs[0];
        if (responseDTO.organizationIDs.includes(this.persistentstoreService.get("loggedInOrganizationId")))
          orgID = this.persistentstoreService.get("loggedInOrganizationId");

        const userID = responseDTO.userID;
        this.storeAuthData(responseDTO);
        return forkJoin([
          this.organizationService.getOrganization(orgID),
          this.organizationService.getFeatures(orgID),
          this.userService.getUser(orgID, userID)
        ]
        ).pipe(rxjsMap((resp: any[]) => {
          this.dataService.setOrganization(resp[0]);
          this.dataService.setFeatures(resp[1].map((feature: Feature) => { return feature.name; }));
          let user: User = resp[2];
          this.persistentstoreService.set("userRole", String(user.role));
          if (
            user.role === RoleEnum.admin ||
            user.role === RoleEnum.superuser
          ) {
            this.dataService.organizationID = orgID;
            this.dataService.userID = userID;
            this.dataService.AuthenticatedUser = user;
            this.dataService.userRole = user.role
            return { success: true, errorcode: null };
          } else {
            this.logout();
            return { success: false, errorcode: "user_not_admin" };
          }
        }));
      })
    );
  }

  public logout(): void {
    this.clearDataCache();
    this.onboarding.deleteOnboarding();
    this.persistentstoreService.remove("jwtToken");
    this.persistentstoreService.remove("loggedInUserId");
    //this.persistentstoreService.remove("loggedInOrganizationId");
    this.persistentstoreService.remove("authExpiration");
    this.persistentstoreService.remove('userRole');
    this.persistentstoreService.remove('features');
    this.dataService.clearData();
  }

  public isLoggedIn(): boolean {
    return !!this.persistentstoreService.get("jwtToken");
  }

  public activateAccount(id: string, password: string): Observable<any> {
    const httpHeaders = new HttpHeaders().set("apiVersion", "version2");
    return this.httpClient.post(
      "/ActivateAccount/" + id,
      {
        userId: id,
        Password: password,
      },
      { headers: httpHeaders }
    );
  }

  public resetAccount(
    id: string,
    token: string,
    password: string
  ): Observable<Object> {
    const httpHeaders = new HttpHeaders().set("apiVersion", "version2");
    return this.httpClient.put<any>(
      `/RecoverAccount/${id}`,
      {
        token: token,
        newPassword: password,
      },
      { headers: httpHeaders }
    );
  }

  public requestResetPassword(email: string): Observable<Object> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");
    return this.httpClient.post(
      "/RecoverAccount",
      {
        email: email,
      },
      { responseType: "text", headers: httpHeaders }
    );
  }

  public verifyToken(id: string, token: string): Observable<any> {
    const httpHeaders = new HttpHeaders().set("apiVersion", "version2");
    return this.httpClient.get<any>(
      `/RecoverAccount/validate?userId=${id}&token=${token}`,
      { headers: httpHeaders }
    );
  }

  private clearDataCache(): void {
    this.userService.clear();
    this.groupsService.clear();
    this.categoryService.clear();
    this.reportsService.clear();
  }

  private storeAuthData(responseDTO: any) {
    this.persistentstoreService.set(
      "jwtToken",
      responseDTO.jwt.token,
      responseDTO.jwt.expiresUTC
    );

    this.persistentstoreService.set(
      "loggedInUserId",
      responseDTO.userID,
      responseDTO.jwt.expiresUTC
    );

    if (responseDTO.organizationIDs.Length > 0) {
      this.persistentstoreService.set(
        "loggedInOrganizationId",
        responseDTO.organizationIDs[0],
        responseDTO.jwt.expiresUTC
      );
    }

    this.persistentstoreService.set(
      "authExpiration",
      responseDTO.jwt.expiresUTC,
      responseDTO.jwt.expiresUTC
    );
    this.dataService.authexpiration = responseDTO.jwt.expiresUTC;
  }
}
