import { ILearningModuleTranslation } from './../../../../application/interfaces/learning-paths.interface';
import { ICourseTranslation } from './../../../../application/interfaces/courses.interface';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ILanguages } from "app/application/interfaces/courses.interface";
import { noEmptySpaceValidator } from "app/application/validators/empty-space.validator";
import { HttpLanguageService } from "app/infrastructure/http/language/httplanguage.service";
import { uniqueLanguageValue } from "../../learning-path.validators";


@Component({
  selector: 'app-learning-module-translations',
  templateUrl: './learning-module-translations.component.html',
  styleUrls: ['./learning-module-translations.component.scss']
})


export class LearningModuleTranslationsComponent implements OnInit, OnDestroy {
  isPanelExpanded = false

  languageId: FormControl
  translationsFormArray: FormArray
  isLanguagesLoading = false
  languages: ILanguages[] = []
  constructor(
    private languagesService: HttpLanguageService,
  ) {
    this.languageId = new FormControl(null, Validators.required)
    this.translationsFormArray = new FormArray([
      new FormGroup({
        languageId: this.languageId,
        description: new FormControl('', [Validators.required, noEmptySpaceValidator]),
        name: new FormControl('', [Validators.required, noEmptySpaceValidator])
      })
    ], uniqueLanguageValue)
  }

  ngOnInit(): void {
    this.getLanguages()
  }

  setTranslations(translations: ILearningModuleTranslation[]){
    while(this.translationsFormArray.length > translations.length){
    this.translationsFormArray.removeAt(0);
    }
    while(this.translationsFormArray.length < translations.length){
      this.addLanguageGroup();
    }
    translations.forEach(({languageId, description, name}, index) => {
      this.translationsFormArray.controls[index].setValue(
        {
          languageId,
          description,
          name
        }
      )
    })
  }

  getLanguages() {
    this.isLanguagesLoading = true
    this.languagesService.getLanguages().subscribe((languages) => {
      this.languages = languages
      this.isLanguagesLoading = false
    })
  }

  addLanguageGroup() {
    const languageId = new FormControl(null, Validators.required)

    const formGroup = new FormGroup({
      languageId,
      description: new FormControl('', [Validators.required, noEmptySpaceValidator]),
      name: new FormControl('', [Validators.required, noEmptySpaceValidator])
    })

    this.translationsFormArray.push(formGroup)
  }

  removeLanguageGroup(index: number) {
    this.translationsFormArray.removeAt(index)
  }

  getFormValue() {
    return this.translationsFormArray.value
  }

  ngOnDestroy() {}
}
