<div class="learning-module-create lmc">

  <form class="lmc__form lmc-form" style="position: relative;" [formGroup]="learningModuleForm"
    (ngSubmit)="submitLearningModuleForm()">
    <mat-horizontal-stepper *ngIf="!editMode" [linear]="!isWorkingNameFilled" #stepper
      (selectionChange)="onStepChange($event)">
      <mat-step [stepControl]="firstFormGroup">
        <ng-template matStepLabel>General</ng-template>
        <div class="lmc-form__courses-wrapper">
          <ng-select class="lmc-form__courses" formControlName="courseId" [items]="coursesList" [searchable]="true"
            [loading]="isCoursesLoading" (change)="onCourseSelectHandler($event)" appendTo="body" bindLabel="name"
            bindValue="id" placeholder='{{"learningpaths_module.lm_selectcourse" | translate }}'>
          </ng-select>

          <div *ngIf="
          learningModuleForm.get('courseId').hasError('required') &&
          learningModuleForm.get('courseId').touched
        " class="lmc-form__courses-error">
            Course is <strong>required</strong>
          </div>
        </div>
        <mat-form-field class="lmc-form__name">
          <mat-label>{{"learningpaths_module.lm_workingname" | translate }}</mat-label>
          <input matInput formControlName="workingName" />
          <mat-error *ngIf="learningModuleForm.controls.workingName.getError('required')">
            Working Name is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="learningModuleForm.controls.workingName.getError('notUnique')">
            Module with this name already <strong>exists</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="lmc-form__time">
          <mat-label>{{"learningpaths_module.lm_est_duration" | translate }}</mat-label>
          <input matInput formControlName="estimatedTimeInSeconds" type="number" min="0" max="10000"
            autocomplete="off" />
          <mat-error>Duration should be greater than 0</mat-error>
        </mat-form-field>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <ng-template matStepLabel>Description</ng-template>
        <app-learning-module-translations (click)="onTranslationsFormChange()" #translationsFormArray>
        </app-learning-module-translations>
      </mat-step>
    </mat-horizontal-stepper>

    <div *ngIf="editMode">
      <div [hidden]="mode!=0" class="lmc-form__courses-wrapper">
        <ng-select class="lmc-form__courses" formControlName="courseId" [items]="coursesList" [searchable]="true"
          [loading]="isCoursesLoading" (change)="onCourseSelectHandler($event)" appendTo="body" bindLabel="name"
          bindValue="id" placeholder='{{"learningpaths_module.lm_selectcourse" | translate }}'>
        </ng-select>
        <div *ngIf="
          learningModuleForm.get('courseId').hasError('required') &&
          learningModuleForm.get('courseId').touched
        " class="lmc-form__courses-error">
          Course is <strong>required</strong>
        </div>
      </div>
      <mat-form-field [hidden]="mode!=0" class="lmc-form__name">
        <mat-label>{{"learningpaths_module.lm_workingname" | translate }}</mat-label>
        <input matInput formControlName="workingName" />
        <mat-error *ngIf="learningModuleForm.controls.workingName.getError('required')">
          Working Name is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="learningModuleForm.controls.workingName.getError('notUnique')">
          Module with this name already <strong>exists</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field [hidden]="mode!=0" class="lmc-form__time">
        <mat-label>{{"learningpaths_module.lm_est_duration" | translate }}</mat-label>
        <input matInput formControlName="estimatedTimeInSeconds" type="number" min="0" max="10000" autocomplete="off" />
        <mat-error>Duration should be greater than 0</mat-error>
      </mat-form-field>
      <div>
        <app-learning-module-translations [hidden]="mode!=1" #translationsFormArray>
        </app-learning-module-translations>
      </div>

      <!-- The code provided is currently commented out, so it is not being utilized.  -->

      <!-- <div [hidden]="mode!=2" class="lmc-form__date-wrapper">
        <mat-form-field class="lmc-form__date-start">
          <mat-label>{{"learningpaths_module.lm_start_date" | translate }}</mat-label>
          <input matInput formControlName="start" [matDatepicker]="startPicker" />
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker [startAt]="startDate"></mat-datepicker>
          <mat-error>Invalid format</mat-error>
        </mat-form-field>
        <mat-form-field class="lmc-form__date-end">
          <mat-label>{{"learningpaths_module.lm_end_date" | translate }}</mat-label>
          <input matInput formControlName="end" [matDatepicker]="endPicker" />
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker [startAt]="startDate"></mat-datepicker>
          <mat-error>Invalid format</mat-error>
        </mat-form-field>
      </div>
      <ng-select [hidden]="mode!=2" formControlName="lockedBy" class="lmc-form__modules"
        placeholder='{{"learningpaths_module.lm_lockedbymodule" | translate }}' bindLabel="workingName" bindValue="id"
        appendTo="body" [items]="lockedByModuleList" [multiple]="false">
      </ng-select>
      <mat-checkbox [hidden]="mode!=2" class="lmc-form__mandatory" matInput formControlName="mandatory">{{
        "learningpaths_module.lm_mandatory" | translate }}
      </mat-checkbox> -->
    </div>
  </form>
</div>