import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { DataService } from "app/infrastructure/dataservice";
import * as _ from "lodash";
import { AuthenticationService } from "../../infrastructure/http/authentication/authentication.service";

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private dataService: DataService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url = state.url;
    return this.checkPermissions(route, url);
  }

  checkPermissions(route: ActivatedRouteSnapshot, url: string): boolean {
    if (this.authenticationService.isLoggedIn()) {
      const userRole = this.dataService.userRole;
      const orgFeatures = this.dataService.features;
      if (route.data.role && route.data.role !== userRole) {
        this.router.navigate([""]);
        return false;
      }

      if(orgFeatures && route.data.feature)
      {
        let hasFeature = _.some(orgFeatures, x=> x == route.data.feature);
        let hideOnFeature = !!route.data.hideOnFeature;
        if(hasFeature == hideOnFeature)
        {
          this.router.navigate([""]);
          return false;
        }
      }

      return true;
    }

    this.router.navigate([""]);
    return false;
  }
}
