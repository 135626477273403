import {FormArray} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable, of as observableOf, Subject, ReplaySubject} from 'rxjs';
import {ConvertToOrganization} from '../../../application/command/convertToOrganization';
import {Organization} from '../../../domain/organization';
import {PlayEnvironment} from '../../../domain/playEnvironment';
import {ImportUsersSpeadsheetValidationResult} from '../../../domain/import-users-spreadsheet-validation-result';
import {DeactivateUsersSpeadsheetValidationResult} from '../../../domain/deactivate-users-spreadsheet-validation-result';
import {Organizations} from './organizations';
import {map as rxjsMap, catchError} from 'rxjs/operators';
import {DataService} from 'app/infrastructure/dataservice';
import {IExpirationSettings, IStarsSettings} from 'app/application/interfaces/course-settings.interface';
import {Feature} from '../../../domain/feature';

@Injectable()
export class HttpOrganizationService implements Organizations {
  private playEnvironmentsSubject: Subject<PlayEnvironment[]>;
  private playEnvironmentsRequest: Observable<PlayEnvironment[]>;
  private currentEnvLangId: string;

  headers = new HttpHeaders({
    apiVersion: 'version2',
  });

  constructor(
    private httpClient: HttpClient,
    private dataService: DataService
  ) {
    this.playEnvironmentsSubject = new ReplaySubject(1);
  }

  public getOrganization(organizationID: string): Observable<Organization> {
    return this.httpClient
      .get('/Organizations/' + organizationID, {headers: this.headers})
      .pipe(rxjsMap(ConvertToOrganization, this));
  }

  public clear() {
    this.playEnvironmentsSubject.next([]);
    this.playEnvironmentsRequest = null;
  }

  public updateOrganization(organization: Organization): Observable<Object> {
    return this.httpClient.put(
      '/Organizations/' + organization.id,
      {
        id: organization.id,
        name: organization.name,
        responsibleUserID: organization.responsibleUser
          ? organization.responsibleUser
          : null,
        externalEmergencyNumber: organization.externalEmergencyNumber,
        internalEmergencyNumber: organization.internalEmergencyNumber,
        logo: organization.logo,
        languageID: organization.language,
        environmentID: organization.playEnvironmentId,
      },
      {responseType: 'text', headers: this.headers}
    );
  }

  getOrganizationCourseSettings() {
    const organizationId = this.dataService.CurrentOrganization.id;

    return this.httpClient.get<IExpirationSettings>(
      `/v2/Organizations/${organizationId}/expiration`,
      {headers: this.headers}
    );
  }

  updateOrganizationExpirationSettings(settings) {
    const organizationId = this.dataService.CurrentOrganization.id;

    return this.httpClient.put(
      `/v2/Organizations/${organizationId}/expiration`,
      settings,
      {headers: this.headers}
    );
  }

  getOrganizationStarsSettings(): Observable<IStarsSettings> {
    const organizationId = this.dataService.CurrentOrganization.id;
    return this.httpClient.get<IStarsSettings>(
      `/v2/Organizations/${organizationId}/stars`,
      {headers: this.headers}
    );
  }

  updateOrganizationStarsSettings(settings: IStarsSettings) {
    const organizationId = this.dataService.CurrentOrganization.id;

    return this.httpClient.put(
      `/v2/Organizations/${organizationId}/stars`,
      settings,
      {headers: this.headers}
    );
  }

  public getAvailableOrganizations(): Observable<Organization[]> {
    return this.httpClient
      .get('/Organizations', {headers: this.headers})
      .pipe(rxjsMap(this.mapOrganizations, this));
  }

  getUserLicenses() {
    const requestUrl = `/v2/Licenses/Users/${this.dataService.userID}/UserLicenses`;
    return this.httpClient.get(requestUrl, {headers: this.headers});
  }
  getUserLicense(blockId:string, licenseId) {
    const organizationId = this.dataService.CurrentOrganization.id;
    const requestUrl = `/v2/organizations/${organizationId}/licenses/blocks/${blockId}/userLicenses/${licenseId}`;
    return this.httpClient.get(requestUrl, {headers: this.headers});
  }
  renewUserLicense(blockId, licenseId) {
    const organizationId = this.dataService.organizationID;
    const requestUrl = `/v2/Organizations/${organizationId}/Licenses/Blocks/${blockId}/UserLicenses/${licenseId}`;
    return this.httpClient.patch(requestUrl, null, {headers: this.headers});
  }

  public getAvailablePlayEnvironments(
    orgId: string,
    languageId: string
  ): Observable<PlayEnvironment[]> {
    if (!this.playEnvironmentsRequest || this.currentEnvLangId != languageId) {
      this.clear();
      this.currentEnvLangId = languageId;
      this.playEnvironmentsRequest = this.httpClient
        .get('/Organizations/' + orgId + '/Environments?lang=' + languageId, {
          headers: this.headers,
        })
        .pipe(rxjsMap(this.mapPlayEnvironments, this));

      this.playEnvironmentsRequest.subscribe((playenvironments) => {
        this.playEnvironmentsSubject.next(playenvironments);
      });
    }
    return this.playEnvironmentsSubject.asObservable();
  }

  public getFeatures(
    organizationId: string
  ): Observable<Feature[]> {

    return this.httpClient.get(`/Organizations/${organizationId}/features`, {
      headers: this.headers
    })
      .pipe(rxjsMap(this.mapFeatures, this));
  }

  public getTemplateUrlForImportingUsersForOrganization(
    organizationId: string
  ): Observable<string> {
    return this.httpClient.get<string>(
      '/Organizations/' + organizationId + '/import/template',
      {headers: this.headers}
    );
  }

  public deactivateUsers(
    organizationId: string,
    fileData
  ): Observable<DeactivateUsersSpeadsheetValidationResult> {
    let httpHeaders = new HttpHeaders().set('apiVersion', 'version2');
    httpHeaders = httpHeaders.set('disableContentType', 'true');
    return this.httpClient
      .post<DeactivateUsersSpeadsheetValidationResult>(`/v2/Organizations/${organizationId}/import/spreadsheet/deactivate-users`,
        fileData,
        {headers: httpHeaders}).pipe(catchError(
          (error: {  error: DeactivateUsersSpeadsheetValidationResult }) =>  {
            return observableOf(error.error);
        }));
  }

  public validateImportSpreadSheet(
    organizationId: string,
    fileData,
    includeExistingUsers: boolean
  ): Observable<ImportUsersSpeadsheetValidationResult> {

    let httpHeaders = new HttpHeaders().set('apiVersion', 'version2');
    httpHeaders = httpHeaders.set('disableContentType', 'true');

    return this.httpClient
      .post(
        '/Organizations/' + organizationId + '/import/validatespreadsheet?IncludeExistingUsers=' + includeExistingUsers,
        fileData,
        {headers: httpHeaders}
      )
      .pipe(
        rxjsMap(
          (feedback: {
            isValid: boolean;
            countNewUsers: number,
            countOldUsers: number,
            users: number,
            countLicensesNewUsers: number,
            countLicensesOldUsers: number,
            countLicensesTotal: number;
            warnings: string[];
            errors: string[];

          }) => {
            return new ImportUsersSpeadsheetValidationResult(
              feedback.isValid,
              feedback.warnings,
              feedback.errors,
              feedback.users,
              feedback.countOldUsers,
              feedback.countNewUsers,
              feedback.countLicensesTotal,
              feedback.countLicensesOldUsers,
              feedback.countLicensesNewUsers
            );
          }
        )
      )
      .pipe(
        catchError(
          (error: {
            error: {
              isValid: boolean;
              countNewUsers: number,
              countOldUsers: number,
              users: number,
              countLicensesNewUsers: number,
              countLicensesOldUsers: number,
              countLicensesTotal: number;
              warnings: string[];
              errors: string[];
            };
          }) => {
            const feedback = error.error;
            return observableOf(
              new ImportUsersSpeadsheetValidationResult(
                feedback.isValid,
                feedback.warnings,
                feedback.errors,
                feedback.users,
                feedback.countOldUsers,
                feedback.countNewUsers,
                feedback.countLicensesTotal,
                feedback.countLicensesOldUsers,
                feedback.countLicensesNewUsers
              )
            );
          }
        )
      );
  }

  public importUsers(organizationId: string, fileData, expireResults: boolean, includeExistingUser: boolean): Observable<Object> {
    let httpHeaders = new HttpHeaders().set('apiVersion', 'version2');
    httpHeaders = httpHeaders.set('disableContentType', 'true');

    return this.httpClient.post(
      '/Organizations/' + organizationId + '/import/spreadsheet?ExpireResults='
      + expireResults + '&IncludeExistingUsers=' + includeExistingUser,
      fileData,
      {headers: httpHeaders}
    );
  }

  private mapOrganizations(response: any): Organization[] {
    return _.sortBy(
      response.map(ConvertToOrganization),
      (organization: Organization) => {
        return _.lowerCase(organization.name);
      }
    );
  }

  private mapPlayEnvironments(response: any): PlayEnvironment[] {
    return _.sortBy(
      response.map(PlayEnvironment.fromArray),
      (playEnvironment: PlayEnvironment) => {
        return _.lowerCase(playEnvironment.name);
      }
    );
  }

  private mapFeatures(response: any): Feature[] {
    return response.map(resp => {
      return new Feature(resp.id, resp.name)
    });
  }
}
