import { Observable } from "rxjs";

export interface IOnBoardingItem {
  id: string;
  name: string;
  isCompleted: boolean;
}
export interface Onboarding {
  getOnboarding(organizationID: string): Observable<IOnBoardingItem[]>;

  setOnboarding(
    organizationID: string,
    completedGuides: string[]
  ): Observable<Object>;
}
