<div
  *ngIf="!canShowPowerBiReport()"
  class="report-content"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <p>
    {{
      "general.loading"
        | translate: { attribute: "REPORTS_attribute" | translate }
    }}
  </p>
  <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<powerbi-report
[embedConfig] = "getEmbedConfig()"
[cssClassName]= "reportClass"
[eventHandlers] = "getEventHandlers()"
></powerbi-report>
