import { Component,  Input, OnInit } from "@angular/core";
import { ICourseInfo } from "app/application/interfaces/courses.interface";
import { ToastService } from "app/application/toast/toast.service";
import * as _ from "lodash";
import { CourseInfoService } from "../../../../infrastructure/course-info/course-info.service";

@Component({
  selector: 'app-course-info-tab',
  templateUrl: './course-info-tab.component.html',
  styleUrls: ['./course-info-tab.component.scss']
})
export class CourseInfoTabComponent implements OnInit {
  @Input() courseId = ''
  @Input() courseName = ''
  @Input() courseDescription = ''

  isCourseInfoLoading = false
  courseInfo: ICourseInfo

  constructor(
    private courseInfoService: CourseInfoService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.isCourseInfoLoading = true

    this.courseInfoService.getCourseInfo(this.courseId).subscribe(
      (courseInfo) => {
        this.courseInfo = courseInfo;
        this.isCourseInfoLoading = false;
      }//,
      // (error) => {
      //   this.toastService.show('Something went wrong', 'error')
      //   this.isCourseInfoLoading = false
      // }
    )
  }
}

