export type ButtonSize = 'extraSmall' | 'small' | 'medium' | 'large'

export type ElementType = 'link' | 'button'

export type TButtonColors =
  '' |
  'primary' |
  'accent' |
  'warn'

export const BUTTON_SIZE_MAP = {
  extraSmall: 'extra-small-button',
  small: 'small-button',
  medium: 'medium-button',
  large: 'large-button'
}

export const BUTTON_SHAPE_MAP = {
  raised: 'mat-flat-button',
  stroked: 'mat-stroked-button',
  flat: 'mat-flat-button',
  icon: 'mat-icon-button',
}

export const BUTTON_ALIGN_MAP = {
  left: 'align-left',
  center: 'align-center',
  right: 'align-right'
}

export const SPINNER_SIZE_MAP = {
  extraSmall: '18',
  small: '18',
  medium: '26',
  large: '26'
}
