import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { NotificationService } from '../../../../../application/notification/notification.service';
import { Group } from '../../../../../domain/group';
import { User } from '../../../../../domain/user';
import { LoggedInOrganizationService } from '../../../../../infrastructure/helper/logged-in-organization.service';
import { HttpGroupsService } from '../../../../../infrastructure/http/group/httpgroups.service';
import { HttpUserService } from '../../../../../infrastructure/http/user/httpuser.service';
import { ConfirmationDialogComponent } from '../../../../confirmation-dialog/confirmation-dialog.component';
import { AddUserComponent } from '../../add-user/add-user.component';
import { UserDetailComponent } from '../../detail/user-detail.component';
import { DataService } from '../../../../../infrastructure/dataservice';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';

@Component({
    selector: 'app-group-users',
    templateUrl: './group-users.component.html',
    styleUrls: ['./group-users.component.scss'],
    // encapsulation: ViewEncapsulation.None
})
export class GroupUsersComponent implements OnChanges, OnInit {
    public users: User[] = [];
    public filteredUsers: User[] = [];
    @Input() public selectedGroup: Group;
    @ViewChild(VirtualScrollerComponent) public virtualScroll: VirtualScrollerComponent;
    @Input() public isActive: boolean;
    public filteredText = '';
    public scrollItems: User[] = [];
    @Output() public change: EventEmitter<string> = new EventEmitter<string>();

    private userNumbersMap: Map<string, { isUserInGroup: boolean }>;

    private dialogConfig: MatDialogConfig = <MatDialogConfig>{
        height: '250px', width: '506px'
    };
    private organizationId: string;

    public constructor(
        private userService: HttpUserService,
        private groupService: HttpGroupsService,
        private dialog: MatDialog,
        private notificationService: NotificationService,
        private loggedInOrganizationService: LoggedInOrganizationService,
        private dataService: DataService) {

    }


    public ngOnInit(): void {
       
        this.reload();
        this.organizationId = this.dataService.organizationID;
    }


    public ngOnChanges(changes: SimpleChanges): void {
        if (this.users) {
            this.createUserGridMap();
        }

        this.filteredUsers = this.users;
        //console.log("filteredUsers group-users.component.ts: " + this.filteredUsers);
        if (changes.isActive && changes.isActive.currentValue) {
            this.refreshList();
        }
        this.filterUsers(this.filteredText);
        //console.log("filteredUsers group-users.component.ts: " + this.filteredUsers);
    }

    public filterUsers(event: string): void {
        this.filteredText = event;
        if (_.isEmpty(this.filteredText)) {
            this.filteredUsers = this.users;
        } else {
            this.filteredUsers = _.filter(this.users, (user: User) => {
                let result = false;
                _.forOwn(user, (value: string, key) => {
                    if (key !== '_id' && _.includes(_.lowerCase(value), _.lowerCase(this.filteredText))) {
                        result = true;
                    }
                });
                return result;
            });
        }
        if (this.selectedGroup) {
            this.sortUsersBySelectedGroup();
        }
    }

    public canRemoveUserFromSelectedGroup(user: User): boolean {
        let result = false;
        if (this.selectedGroup) {
            result = this.getUsersMapValue(user).isUserInGroup;
        }
        return result;
    }

    public removeUserFromSelectedGroup(user: User): void {
        const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
            width: this.dialogConfig.width,
            data: {
                textContent: 'group_users.remove_user_from_group_confirmation',
                confirmText: 'common.remove',
                cancelText: 'common.do_not_remove'
            }
        };
        const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.groupService.removeUsersFromGroup(this.organizationId, this.selectedGroup, [user.id]).subscribe(() => {
                    this.selectedGroup.userIDs.splice(this.selectedGroup.userIDs.indexOf(user.id), 1);
                    const userGrid = this.getUsersMapValue(user);
                    userGrid.isUserInGroup = false;
                    this.notificationService.show('REMOVED SELECTED USER FROM GROUP');
                    this.change.emit('usersInGroup');
                });
            }
        });
    }

    public canAddUserToSelectedGroup(user: User): boolean {
        let result = false;
        if (this.selectedGroup) {
            result = !this.getUsersMapValue(user).isUserInGroup;
        }
        return result;
    }

    public addUserToSelectedGroup(user: User, confirmation: boolean): void {
        if (confirmation) {
            const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
                width: this.dialogConfig.width,
                data: {
                    textContent: 'group_users.add_users_to_group_confirmation',
                    confirmText: 'ADD',
                    cancelText: 'common.do_not_add'
                }
            };
            const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.groupService.addUsersToGroup(this.organizationId, this.selectedGroup, [user]).subscribe(() => {
                        this.selectedGroup.userIDs.push(user.id);
                        const userGrid = this.getUsersMapValue(user);
                        userGrid.isUserInGroup = true;
                        this.notificationService.show('ADDED SELECTED USER TO GROUP');
                        this.change.emit('usersInGroup');
                    });
                }

            });
        } else {
            this.groupService.addUsersToGroup(this.organizationId, this.selectedGroup, [user]).subscribe(() => {
                this.selectedGroup.userIDs.push(user.id);
                this.change.emit('usersInGroup');
                this.sortUsersBySelectedGroup();
            });
        }

    }

    public canAddNewUserToSelectedGroup(): boolean {
        return !_.isNil(this.selectedGroup);
    }

    public addNewUserToSelectedGroup(): void {
        const config = new MatDialogConfig();
        config.minHeight = '392px';
        config.width = '506px';
        const dialogRef: MatDialogRef<AddUserComponent> = this.dialog.open(AddUserComponent, config);
        dialogRef.afterClosed().subscribe((addedUser: User) => {
            if (!_.isNil(addedUser)) {
                this.users.push(addedUser);
                this.addUserToSelectedGroup(addedUser, false);
                this.userNumbersMap.set(addedUser.id, { isUserInGroup: true });

                this.filterUsers(this.filteredText);
            }
        });
    }

    public showUserProfile(user: User): void {
        const config = new MatDialogConfig();
        config.height = '832px';
        config.width = '1240px';
        config.data = {
            user: user
        };
        this.dialog.open(UserDetailComponent, config);
    }

    private getUsersMapValue(user: User): { isUserInGroup: boolean } {
        return this.userNumbersMap.get(user.id) || { isUserInGroup: false };
    }

    private reload():
        void {
        this.userService.getUsers().subscribe((users: User[]) => {
            this.users = users;
            this.filteredUsers = users;
            
            this.createUserGridMap();

            if (this.isActive) {
             //   this.refreshList();
            }
        });
    }

    private sortUsersBySelectedGroup(): void {
        this.filteredUsers = _.sortBy(_.sortBy(this.filteredUsers, (user: User) => {
            return _.lowerCase(user.name);
        }), (user: User) => {
            return !_.includes(this.selectedGroup.userIDs, user.id);
        });
    }

    private createUserGridMap(): void {
        this.userNumbersMap = new Map<string, { isUserInGroup: boolean }>();
        _.each(this.users, (user: User) => {
            this.userNumbersMap.set(user.id, {
                isUserInGroup: this.selectedGroup ? _.includes(this.selectedGroup.userIDs, user.id) : false
            });
        });
    }

    private refreshList(): void {
            this.virtualScroll?.refresh();
    }
}
