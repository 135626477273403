
<div class="container-list">
  <article class="list-header">
    <div class="header-flex">
      <div>
        <h3>{{ "OVERVIEW GROUPS" | translate }}</h3>
      </div>
      <div class="select-action-wrapper" joyrideStep="SelectActionGroupStep" stepPosition="right" title="{{
          'groups_guide_module.select_action_group_title' | translate
        }}" text="{{ 'groups_guide_module.select_action_group_hint' | translate }}">
        <button mat-flat-button color="primary" class="group-actions" #btnGroupTrigger="matMenuTrigger"
          [matMenuTriggerFor]="groupActions">
          {{ "common.select_action" | translate }}
          <mat-icon><svg class="select-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path
                d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
            </svg></mat-icon>
        </button>

        <mat-menu #groupActions [overlapTrigger]="false" xPosition="after" yPosition="below">
          <button mat-menu-item [disabled]="!hasSelectedGroup()" (click)="sendMessage()" joyrideStep="SendGroupStep"
            stepPosition="right" title="{{ 'groups_guide_module.send_message_title' | translate }}"
            text="{{ 'groups_guide_module.send_message_hint' | translate }}">
            {{ "SEND MESSAGE" | translate }}
          </button>
          <button mat-menu-item [disabled]="!hasSelectedGroup()" (click)="removeSelectedGroup()"
            joyrideStep="RemoveGroupStep" stepPosition="right"
            title="{{ 'groups_guide_module.remove_group_title' | translate }}"
            text="{{ 'groups_guide_module.remove_group_hint' | translate }}">
            {{ "group_overview.remove_group" | translate }}
          </button>
          <button mat-menu-item [disabled]="!hasSelectedGroup()" (click)="renameSelectedGroup()"
            joyrideStep="RenameGroupStep" stepPosition="right"
            title="{{ 'groups_guide_module.rename_group_title' | translate }}"
            text="{{ 'groups_guide_module.rename_group_hint' | translate }}">
            {{ "group_overview.rename_group" | translate }}
          </button>
          <button mat-menu-item [disabled]="!hasSelectedGroup()" (click)="sendReminder()"
            joyrideStep="SendReminderGroupStep" stepPosition="right" title="{{
              'groups_guide_module.send_reminder_group_title' | translate
            }}" text="{{
              'groups_guide_module.send_reminder_group_hint' | translate
            }}">
            {{ "reminder.send_reminder" | translate }}
          </button>
          <button mat-menu-item (click)="addGroup()" joyrideStep="AddGroupsStep" stepPosition="right"
            title="{{ 'groups_guide_module.add_group_title' | translate }}"
            text="{{ 'groups_guide_module.add_group_hint' | translate }}">
            {{ "ADD GROUP" | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
    <app-filter (filteredTextChanged)="filterGroups($event)" joyrideStep="SearchGroupStep" stepPosition="top"
      title="{{ 'groups_guide_module.search_group_title' | translate }}"
      text="{{ 'groups_guide_module.search_group_hint' | translate }}" placeholder="{{ 'FIND GROUP' | translate }}">
    </app-filter>
  </article>


  <div *ngIf="(isLoading() | async) === true">
    <p>
      {{
        "general.loading"
          | translate: { attribute: "GROUPS_attribute" | translate }
      }}
    </p>
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
  </div>

  <article class="list-content" *ngIf="(isLoading() | async) === false">
    <div class="select-group-joyride" joyrideStep="SelectGroupStep" stepPosition="right"
      title="{{ 'groups_guide_module.select_group_title' | translate }}"
      text="{{ 'groups_guide_module.select_group_hint' | translate }}"></div>
    <div class="group-status-joyride" joyrideStep="GroupStatusStep" stepPosition="left"
      title="{{ 'groups_guide_module.number_group_title' | translate }}"
      text="{{ 'groups_guide_module.number_group_hint' | translate }}"></div>
    <ag-grid-angular class="ag-theme-material" style="width: 100%; height: 100%;" [gridOptions]="groupGridOptions"
      [rowData]="groups" (gridReady)="onGridReady($event)"></ag-grid-angular>
  </article>
</div>

<app-group-users [selectedGroup]="selectedGroup" [isActive]="isActive" (change)="peopleGroupChange($event)">
</app-group-users>
