import { CourseOptions } from './courseOptions';

export class CourseSetting {

    private _nodeId: string;
    private _field: string;
    private _value: object;
    private _courseOptions: CourseOptions = null;
    private _previewValue: object;
    

    constructor(nodeId: string, field: string, value: object)
    {
        this._nodeId = nodeId;
        this._field = field;
        this._value = value;
        this._previewValue = value;
    }

    

    public get nodeId(): string {
        return this._nodeId;
    }
    public set nodeId(value: string) {
        this._nodeId = value;
    }

    public get field(): string {
        return this._field;
    }
    public set field(value: string) {
        this._field = value;
    }

    public get value(): object
    {
        return this._value;
    }

    public set value(value: object) {

        this._value = value;
    }


    public get previewValue(): object {
        return this._previewValue;
    }

    public set previewValue(value: object) {
        this._previewValue = value;


    }

    public get isDirty(): boolean {
        return (this._previewValue !== this.value);
    }

    public get propertyName(): string {
        return this._courseOptions.propertyName;
    }

    public get options(): object[] {
        return this._courseOptions.options;
    }

    public isFreeForm(): boolean {
        return this._courseOptions.freeForm;
    }

    public hasOptions(): boolean {
        return this._courseOptions !== null;
    }
    public isDefault(): boolean {
        return this._courseOptions.defaultValue === this._value;
    }
    public setOptions(options: CourseOptions, overwriteValue: boolean)
    {
        this._nodeId = options.nodeId;
        this._field = options.field;
        this._courseOptions = options;
        if (overwriteValue) {
            this._value = options.defaultValue;
            this._previewValue = this._value;
        }

    }
    public applyChanges() {
        if (this.isDirty) {
            this._value = this.previewValue;
        }
    }
    public undoChanges() {
        this.previewValue = this._value;
    }
    public restoreDefaults() {
        this._previewValue = this._courseOptions.defaultValue;
    }
}
