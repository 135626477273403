import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TEventType } from "./toast.types";

@Injectable({ providedIn: "root" })
export class ToastService {
  constructor(private snackBar: MatSnackBar) {}

  show(message: string, eventType: TEventType = 'success', buttonText: string | null = null ): void {
    this.snackBar.open(message, buttonText, <MatSnackBarConfig>{
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['pits-toast', `pits-toast-${eventType}`]
    });
  }
}
