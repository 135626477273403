<section fxLayout="column" fxLayoutAlign="center center">
	<section class="content" fxLayout="row">
		<article id="information-container" fxFlex fxLayoutAlign="center center">
			<app-language-component></app-language-component>
			<img id="information-container__logo" src="../../../../assets/img/logo_verticaal.png" alt="logo PlayIt verticaal">
		</article>
		<article id="reset-password-container" fxFlex fxLayout="column" fxLayoutAlign="center center">

			<header fxLayout="column" fxLayoutAlign="center center">
				<p *ngIf="isFormSubmitted() == false">{{'ENTER EMAIL TO RESET' | translate}}</p>
			</header>
			<article>
				<mat-icon-button class="button-back" (click)="back()"><span class="material-symbols-outlined">
						arrow_back
					</span></mat-icon-button>
				<form [formGroup]="requestPasswordForm" (ngSubmit)="submitForm(requestPasswordForm.value)"
					*ngIf="canShowRequestPasswordForm()">
					<div class="input-row">

						<p class="error-email" *ngIf="isFormError()">{{'forgot_password.error_email' | translate}}</p>

						<mat-form-field class="full-width">
							<mat-error>{{'PROVIDE A VALID EMAIL' | translate}}
							</mat-error>
							<input matInput email placeholder="{{'EMAIL' | translate}}" formControlName="email">
						</mat-form-field>

					</div>
					<div class="input-row">
						<button type="submit" mat-flat-button color="primary" [disabled]="!requestPasswordForm.valid">
							{{'forgot_password.send_password_reset_request' | translate}}
						</button>
					</div>
					<div class="input-row">
						<p>&copy; Play It. All rights reserved {{currentYear}}. </p>
					</div>
				</form>

				<div *ngIf="isFormSubmitted()" class="form-submitted">
					<p>
						{{'forgot_password.password_request_reset_send' | translate}}<br>
					</p>
					<mat-icon class="completed-icon" svgIcon="completed"></mat-icon>

				
				</div>
			</article>
		</article>
	</section>
</section>
