<article class="list-header">
  <div class="header-controls">
    <div class="header-flex">
      <h3>{{ "USERS" | translate }}</h3>
      <button mat-button joyrideStep="NewUserGroupStep" stepPosition="right"
        title="{{ 'groups_guide_module.new_user_group_title' | translate }}"
        text="{{ 'groups_guide_module.new_user_group_hint' | translate }}"
        class="mat-secondary header-button mat-little-button" (click)="addNewUserToSelectedGroup()"
        [disabled]="!canAddNewUserToSelectedGroup()">
        {{ "ADD NEW USER" | translate }}
      </button>
    </div>
    <app-filter (filteredTextChanged)="filterUsers($event)" joyrideStep="SearchUserGroupStep" stepPosition="right"
      title="{{ 'groups_guide_module.search_user_title' | translate }}"
      text="{{ 'groups_guide_module.search_user_hint' | translate }}" placeholder="{{ 'FIND USER' | translate }}">
    </app-filter>
  </div>
</article>
<article class="list-content user-list-content">
  <mat-list>
    <virtual-scroller #scroll class="user-list-scroll" [items]="filteredUsers" (vsUpdate)="scrollItems = $event">
      <ng-container *ngIf="isActive">
        <mat-list-item *ngFor="let user of scrollItems; let i = index">
          <h5 class="user-name" mat-line (click)="showUserProfile(user)">
            {{ user.name }}
          </h5>
          <span mat-line> {{ user.job }}</span>
          <div *ngIf="i === 0; else showButtonsWithoutGuide" class="action-groups-wrapper-guide"
            joyrideStep="LinkGroupStep" stepPosition="left"
            title="{{ 'groups_guide_module.link_user_title' | translate }}"
            text="{{ 'groups_guide_module.link_user_hint' | translate }}">
            <button class="remove-user" mat-icon-button matTooltip="{{ 'REMOVE USER FROM GROUP' | translate }}"
              [disabled]="!canRemoveUserFromSelectedGroup(user)" (click)="removeUserFromSelectedGroup(user)">
              <mat-icon svgIcon="remove-user"></mat-icon>
            </button>
            <button class="add-user" mat-icon-button matTooltip="{{ 'ADD USER TO GROUP' | translate }}"
              [disabled]="!canAddUserToSelectedGroup(user)" (click)="addUserToSelectedGroup(user, true)">
              <mat-icon svgIcon="add-user"></mat-icon>
            </button>
          </div>
          <ng-template #showButtonsWithoutGuide>
            <button class="remove-user" mat-icon-button matTooltip="{{ 'REMOVE USER FROM GROUP' | translate }}"
              [disabled]="!canRemoveUserFromSelectedGroup(user)" (click)="removeUserFromSelectedGroup(user)">
              <mat-icon svgIcon="remove-user"></mat-icon>
            </button>
            <button class="add-user" mat-icon-button matTooltip="{{ 'ADD USER TO GROUP' | translate }}"
              [disabled]="!canAddUserToSelectedGroup(user)" (click)="addUserToSelectedGroup(user, true)">
              <mat-icon svgIcon="add-user"></mat-icon>
            </button>
          </ng-template>
        </mat-list-item>
      </ng-container>
    </virtual-scroller>
  </mat-list>
</article>
