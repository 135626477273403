import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { TButtonColors, ButtonSize, BUTTON_SHAPE_MAP, BUTTON_SIZE_MAP, SPINNER_SIZE_MAP, BUTTON_ALIGN_MAP } from "./app-button.types";

@Component({
  selector: "app-button",
  templateUrl: "./app-button.component.html",
  styleUrls: ["./app-button.component.scss"],
})
export class ButtonComponent implements OnInit {
  resultClass = []
  spinnerSize: string
  buttonRippleColor = '#fff'

  @Output() onButtonClick = new EventEmitter()

  @Input() isLoading = false
  @Input() color: TButtonColors = ''
  @Input() shape = ''
  @Input() size: ButtonSize
  @Input() classNames: string[] = ['']
  @Input() disabled = false
  @Input() type = 'button'
  @Input() align = 'center'

  constructor() {}

  ngOnInit() {
    this.spinnerSize = this.setSpinnerSize(this.size)
    const buttonSize = this.getSize(this.size)
    const buttonShape = this.getShape(this.shape)
    const buttonAlign = this.getAlign(this.align)

    this.resultClass = [
      ...this.classNames,
      buttonSize,
      buttonShape,
      buttonAlign
    ]
  }

  fireButtonClick() {
    this.onButtonClick.emit()
  }

  getAlign(align) {
    return BUTTON_ALIGN_MAP[align] || BUTTON_ALIGN_MAP.center
  }

  getSize(size): string {
    return BUTTON_SIZE_MAP[size] || BUTTON_SIZE_MAP.medium
  }

  getShape(shape): string {
    // return BUTTON_SHAPE_MAP

    return BUTTON_SHAPE_MAP[shape] || ''
  }

  setSpinnerSize(size) {
    return SPINNER_SIZE_MAP[size] || '26'
  }
}
