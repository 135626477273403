import {Group} from './group';

export class Course {
    private _id: string;
    private _name: string;
    private _description: string;
    private _isActive: boolean;
    private _checked: boolean;
    private _level: number;

    public constructor(id: string, name: string, description: string, isActive: boolean, level: number) {
        this._id = id;
        this._name = name;
        this._description = description;
        this._isActive = isActive;
        this._level = level;
    }

    public get id(): string {
        return this._id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    public get description(): string {
        return this._description;
    }

    public set description(value: string) {
        this._description = value;
    }

    public get isActive(): boolean {
        return this._isActive;
    }

    public set isActive(value: boolean) {
        this._isActive = value;
    }

    public get checked(): boolean {
        return this._checked;
    }

    public set checked(value: boolean) {
        this._checked = value;
    }

    public get level(): number {
        return this._level;
    }

    public set level(value: number) {
        this._level = value;
    }
}
