export class Language {
	private _id: string;
	private _name: string;
	private _countryCode: string;
	private _languageCode: string;
	private _priority: number;

	constructor(id: string, name: string, countryCode:string, languageCode:string, priority:number) {
		this._id = id;
		this._name = name;
		this._countryCode = countryCode;
		this._languageCode = languageCode;
		this._priority = priority;
	}

	get id(): string {
		return this._id;
	}

	set id(value: string) {
		this._id = value;
	}

	get name(): string {
		return this._name;
	}

	set name(value: string) {
		this._name = value;
	}

	get countryCode(): string {
		return this._countryCode;
	}

	set countryCode(value: string) {
		this._countryCode = value;
	}

	get languageCode(): string {
		return this._languageCode;
	}

	set languageCode(value: string) {
		this._languageCode = value;
	}

	get priority(): number {
		return this._priority;
	}

	set priority(value: number) {
		this._priority = value;
	}

}
