import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { GroupsnavComponent } from './groupsnav.component';
import { UsersComponent } from './users.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { AddUserComponent } from './add-user/add-user.component';
import { UserDetailComponent } from './detail/user-detail.component';
import { GroupDetailComponent } from './groups/group-detail/group-detail.component';
import { GroupPlayEnvironmentsComponent } from './groups/group-play-environments/group-play-environments.component';
import { GroupUsersComponent } from './groups/group-users/group-users.component';
import { GroupNameComponent } from './groups/name/group-name.component';
import { RenameGroupComponent } from './groups/rename-group/rename-group.component';
import { UsersGroupsModule } from 'app/features/users-groups/users-groups.module';
import { JoyrideModule } from 'ngx-joyride';
import { PeopleComponent } from './people/people.component';
import { GroupsComponent } from './groups/groups.component';
import { FilterModule } from 'app/shared/app-filter/app-filter.module';
import { AgGridModule } from 'ag-grid-angular';
import { UserTooltipComponent } from './people/user-tooltip/user-tooltip.component';
import { UserLicensesComponent } from './people/user-licenses-modal/user-licenses-modal.component';
import { UserStatusComponent } from './people/status/user-status.component';
import { UserNameComponent } from './people/name/user-name.component';
import { ImportUsersComponent } from './people/import-users/import-users.component';
import { HeaderNameComponent } from './people/header-name/header-name.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidatorUserEmailDirective } from 'app/application/validators/user-email-validation';
import { SharedModule } from 'app/shared/shared.module';
import { UserPasswordComponent } from './password/user-password.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    UsersGroupsModule,
    JoyrideModule,
    FilterModule,
    VirtualScrollerModule,
    AgGridModule,
    FlexLayoutModule,
    SharedModule
  ],
  declarations: [
    GroupsnavComponent,
    UsersComponent,
    PeopleComponent,
    GroupsComponent,
    AddGroupComponent,
    AddUserComponent,
    UserDetailComponent,
    GroupDetailComponent,
    GroupPlayEnvironmentsComponent,
    GroupUsersComponent,
    GroupNameComponent,
    RenameGroupComponent,
    UserTooltipComponent,
    UserLicensesComponent,
    UserStatusComponent,
    UserNameComponent,
    ImportUsersComponent,
    HeaderNameComponent,
    ValidatorUserEmailDirective,
    UserPasswordComponent
  ],
})
export class UsersModule {}
