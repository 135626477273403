import { Routes } from "@angular/router";
import { convertPagesToRoutes } from "../../application/command/convertPageToRoute";
import { IPage, Page } from "../../domain/page";
import { CoursesComponent } from "../../presentation/pages/courses/courses.component";
import { DashboardComponent } from "../../presentation/pages/dashboard/dashboard.component";
import { ForgotPasswordComponent } from "../../presentation/pages/forgot-password/forgot-password.component";
import { ReportsComponent } from "../../presentation/pages/reports/reports.component";
import { ResetPasswordComponent } from "../../presentation/pages/reset-password/reset-password.component";
import { GroupDetailResultComponent } from "../../presentation/pages/results/group-detail-result/group-detail-result.component";
import { ResultsComponent } from "../../presentation/pages/results/results.component";
import { SupportComponent } from "../../presentation/pages/support/support.component";
import { UsersComponent } from "../../presentation/pages/users/users.component";
import { GroupsnavComponent } from "../../presentation/pages/users/groupsnav.component";
import { AvailableCoursesComponent } from "../../features/available-courses/available-courses.component";
import { Injectable } from "@angular/core";
import { LicenseManagementComponent } from "../../presentation/pages/license-management/license-management.component";
import { LicenseOverviewComponent } from "../../presentation/pages/license-overview/license-overview.component";
import { LearningPathsPageComponent } from "../../pages/learning-paths-page/learning-paths-page.component";

import { DataService } from "../dataservice";
import RoleEnum from "../../domain/roleEnum";
import * as _ from "lodash";
import { LearningPathFormComponent } from "app/features/learning-path/forms/learning-path-form/learning-path-form.component";
import { UserComponentFactory } from "ag-grid-community";

@Injectable()
export class NavigationService {
  private pages: IPage[] = [
    new Page({
      name: "DASHBOARD",
      icon: "dashboard",
      path: "dashboard",
      component: DashboardComponent,
      isDefault: false,
      showInMenu: true,
      securedPage: true,
    }),
    new Page({
      name: "USERS",
      icon: "users",
      path: "users",
      component: UsersComponent,
      isDefault: false,
      showInMenu: true,
      securedPage: true,
    }),
    new Page({
      name: "GROUPS",
      icon: "groups",
      path: "groups",
      component: GroupsnavComponent,
      isDefault: false,
      showInMenu: true,
      securedPage: true,
    }),
    new Page({
      name: 'learningpaths_module.learning_paths',
      icon: 'learning-paths',
      path: 'learning-paths',
      component: LearningPathsPageComponent,
      data: {
        feature: "learningpaths"
      },
      isDefault: false,
      showInMenu: true,
      securedPage: true,
    }),
    new Page({
      name: "COURSES",
      icon: "courses",
      path: "courses",
      component: CoursesComponent,
      data: {
        feature: "learningpaths",
        hideOnFeature: true,
      },
      isDefault: false,
      showInMenu: true,
      securedPage: true,
    }),
    new Page({
      name: "RESULTS",
      icon: "results",
      path: "results",
      component: ResultsComponent,
      isDefault: false,
      showInMenu: true,
      securedPage: true,
    }),
    new Page({
      name: "GroupResults",
      icon: "",
      path: "results/category/:categoryId/group/:groupId",
      component: GroupDetailResultComponent,
      isDefault: false,
      showInMenu: false,
      securedPage: true,
    }),
    new Page({
      name: "REPORTS",
      icon: "statistics",
      path: "reports",
      component: ReportsComponent,
      isDefault: false,
      showInMenu: true,
      securedPage: true,
    }),
    new Page({
      name: "LICENSE MANAGEMENT",
      icon: "management",
      path: "license-management",
      component: LicenseManagementComponent,
      data: {
        licenseStatus: true
      },
      isDefault: false,
      showInMenu: true,
      securedPage: true,
    }),
    new Page({
      name: "LICENSE OVERVIEW",
      icon: "overview",
      path: "license-overview",
      component: LicenseOverviewComponent,
      data: {
        role: 30,
      },
      isDefault: false,
      showInMenu: true,
      securedPage: true,
    }),
    new Page({
      name: "Reset Password",
      icon: "reset",
      path: "reset-password",
      component: ResetPasswordComponent,
      isDefault: false,
      showInMenu: false,
      securedPage: false,
    }),
    new Page({
      name: "Forgot Password",
      icon: "forgot",
      path: "forgot-password",
      component: ForgotPasswordComponent,
      isDefault: false,
      showInMenu: false,
      securedPage: false,
    }),
  ];

  private routes: Routes = [];

  public constructor(
    private dataService: DataService
  ) {
    this.routes = convertPagesToRoutes(this.pages);
  }

  public getMenuPages(): IPage[] {
    const userRole = this.dataService.AuthenticatedUser?.role
    const isOrganizationUnlicensed = this.dataService.CurrentOrganization?.isUnlicensed
    const organizationFeatures = this.dataService.features;
    const isManager = userRole !== RoleEnum.superuser

    // Temporary for working with learning path, till all routes won't in routing modules
    let pagesCopy = this.pages.slice();
    const filteredPages = pagesCopy.filter((page) => {
      if(page.data?.feature)
      {
        let hasFeature = _.some(organizationFeatures, x=> x == page.data.feature);
        let hideOnFeature = page.data.hideOnFeature?? false;
        if(hasFeature == hideOnFeature){
          return false;
        }
      }
      if (page.showInMenu) {
        if (page.data?.role && page.data.role !== userRole) {
          return false
        }

        if (isManager && page.data?.licenseStatus && isOrganizationUnlicensed) {
          return false
        }

        return true
      }

      return false;
    });

    return filteredPages
  }

  public getRoutes(): Routes {
    return this.routes;
  }

  private getDefaultPage(): IPage {
    return this.pages.filter((page) => page.isDefault)[0]
  }
}
