import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { ONE_MONTH_IN_MILLISECONDS } from "app/application/constants";
import { ILicenseBlock, IStatistics } from "app/application/interfaces/licenses.interface";
import { OnboardingService } from "app/infrastructure/http/onboarding/onboarding.service";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { LicenseContactsModalComponent } from "./components/license-contacts/license-contacts.component";
import { LicenseGuideModalComponent } from "./components/license-guide-modal/license-guide-modal.component";
import { LicenseManagementHttpService } from "./services/license-management-http.service";

@Component({
  selector: 'app-license-management',
  templateUrl: './license-management.component.html',
  styleUrls: ['./license-management.component.scss']
})
export class LicenseManagementComponent implements OnInit {
  isLicensesLoading = false

  licenseStats: IStatistics = {
    totalAmountOfLicenses: 0,
    licensesInUse: 0,
    licensesLeft: 0,
    expireSoon: 0
  }

  licenseBlocks: ILicenseBlock[] = []
  highlightedElement: string

  guides = [];
  subscriptionOnboarding: Subscription;

  constructor(
    private licenseService: LicenseManagementHttpService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private onboarding: OnboardingService
  ) {
    this.setLicenseStatus = this.setLicenseStatus.bind(this)
  }

  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
      this.highlightedElement = fragment
    })

    this.getLicenses()
   /*  this.checkOnboarding() */
  }

  checkOnboarding() {
    this.subscriptionOnboarding = this.onboarding.getOnboarding().subscribe(onboardingData => {
      const licenseOnboardingData = onboardingData.find(({ name }) => name === 'Licenses')

      if (licenseOnboardingData && !licenseOnboardingData.isCompleted) {
        this.guides.push(licenseOnboardingData.id)

        this.onboarding.openOnboardingDialog('license_guide', [], this.guides, true).subscribe(() => {
          this.startOnboardingNavigation();
        });
      }
    });
}

  startOnboardingNavigation() {
    const dialogConfig: MatDialogConfig = { height: '560px', width: '600px' }
    const dialogRef = this.dialog.open(LicenseGuideModalComponent, dialogConfig);

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(() => {
      if (this.guides.length) {
        this.onboarding.openFinishOnboardingDialog('license_guide', this.guides);
        this.guides = [];
      }
    });
}

  getLicenses() {
    this.isLicensesLoading = true

    this.licenseService.getLicenses()
      .pipe(
        map(this.setLicenseStatus)
      )
      .subscribe(([licenses, stats]) => {
        this.licenseBlocks = licenses
        this.licenseStats = {...stats}
        this.isLicensesLoading = false
      })
  }

  setLicenseStatus(licenses: ILicenseBlock[]): [ILicenseBlock[], IStatistics] {
    const currentDate = +new Date()
    let totalAmountOfLicenses = 0
    let licensesInUse = 0
    let licensesLeftInOrganization = 0
    let expireSoon = 0

    const processedLicenses = licenses.map(({
      id,
      status,
      licensesCap,
      licensesUsed,
      expirationDate,
      expirationSoon,
      shouldHighlight,
      ...rest
    }) => {
      // calculate how much licenses left
      let licensesLeftInBlock = licensesCap - licensesUsed > 0 ? licensesCap - licensesUsed : 0
      const expireDate = +new Date(expirationDate)
      const statusResult = expireDate - currentDate

      if (statusResult > ONE_MONTH_IN_MILLISECONDS || !expirationDate) {
        status = 'Active'
      } else if (statusResult > 0) {
        status = 'Expires soon'
        expirationSoon = true
        expireSoon++
      } else {
        status = 'Expired'
        licensesLeftInBlock = 0
      }

      if (id === this.highlightedElement) {
        shouldHighlight = true
      }

      totalAmountOfLicenses += licensesCap
      licensesInUse += licensesUsed
      licensesLeftInOrganization += licensesLeftInBlock

      return {
        ...rest,
        id,
        status,
        licensesCap,
        licensesUsed,
        expirationDate,
        expirationSoon,
        shouldHighlight,
      }
    })

    return [processedLicenses, { licensesInUse, totalAmountOfLicenses, expireSoon, licensesLeft: licensesLeftInOrganization}]
  }

  openContactsModal() {
    const dialogConfig: MatDialogConfig = <MatDialogConfig>{
      height: '200px',
      width: '700px',
      panelClass: 'license-management__contacts'
    }

    this.dialog.open(LicenseContactsModalComponent, dialogConfig)
  }
}
