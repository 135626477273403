<mat-tab-group>
  <mat-tab label="{{ 'DASHBOARD' | translate }}">
    <div
      *ngIf="canShowLoader() === true"
      class="report-content"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <p>
        {{
          "general.loading"
            | translate: { attribute: "DASHBOARD" | translate | lowercase }
        }}
      </p>
      <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
    </div>

    <div
      [hidden]="canShowLoader()"
      [class.report-content]="canShowPowerBiDashboard()"
      [class.upgrade-content]="!canShowDashboard()"
    >
      <app-upgrade-feature
        *ngIf="canShowLoader() === false && !canShowDashboard()"
      ></app-upgrade-feature>
      <div
        *ngIf="isLoading() === false"
        [class.report-content]="canShowPowerBiDashboard()"
      >

      <powerbi-dashboard
      *ngIf="canShowDashboardReport()"
      [embedConfig] = "getEmbedConfig()"
      [cssClassName]= "reportClass"
      [eventHandlers] = "getEventHandlers()">

      </powerbi-dashboard>
        <!-- <ngx-powerbi-component
          type="dashboard"
          [id]="getAzureReportConfig().id"
          [embedUrl]="getAzureReportConfig().embedUrl"
          tokenType="Embed"
          #reportContainer
          (embedded)="onEmbedded()"
          *ngIf="getAzureReportConfig()"
          [hidden]="!canShowPowerBiDashboard()"
          [accessToken]="getAzureReportConfig().embedToken"
        >
        </ngx-powerbi-component> -->
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<div
  class="navigation-guide tooltip-guide"
  matTooltip="{{ 'on_boarding_module.tooltip_info' | translate }}"
  (click)="startOnboardingNavigation()"
>
  <mat-icon>help_outline</mat-icon>
  <div class="text-block">
    {{ "on_boarding_module.repeat_guide" | translate }}
  </div>
</div>
