import { Language } from "./language";
import { PlayEnvironment } from "./playEnvironment";
import { User } from "./user";

export class Organization {
  private _id: string;
  private _name: string;
  private _internalEmergencyNumber: string;
  private _externalEmergencyNumber: string;
  private _responsibleUserId: string;
  private _logo: string;
  private _langugeId: string;
  private _playEnvironmentId: string;
  private _isUnlicensed: boolean
  private _isDisabled?: boolean
  private _disableReason?: string

  public constructor(params) {
    const {
      id,
      name,
      internalEmergencyNumber,
      externalEmergencyNumber,
      responsibleUserId,
      logo,
      language,
      playEnvironmentId,
      isUnlicensed
    } = params;

    this._id = id;
    this._name = name;
    this._isUnlicensed = isUnlicensed
    this._internalEmergencyNumber = internalEmergencyNumber;
    this._externalEmergencyNumber = externalEmergencyNumber;
    this._responsibleUserId = responsibleUserId;
    this._logo = logo;
    this._langugeId = language;
    this._playEnvironmentId = playEnvironmentId;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public set isUnlicensed(value: boolean) {
    this._isUnlicensed = value
  }

  public get isUnlicensed(): boolean {
    return this._isUnlicensed
  }

  public set isDisabled(value: boolean) {
    this._isDisabled = value
  }

  public get isDisabled(): boolean {
    return this._isDisabled
  }

  public set disableReason(value: string) {
    this._disableReason = value
  }

  public get disableReason(): string {
    return this._disableReason
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get internalEmergencyNumber(): string {
    return this._internalEmergencyNumber;
  }

  public set internalEmergencyNumber(value: string) {
    this._internalEmergencyNumber = value;
  }

  public get externalEmergencyNumber(): string {
    return this._externalEmergencyNumber;
  }

  public set externalEmergencyNumber(value: string) {
    this._externalEmergencyNumber = value;
  }

  public get responsibleUser(): string {
    return this._responsibleUserId;
  }

  public set responsibleUser(value: string) {
    this._responsibleUserId = value;
  }

  public get logo(): string {
    return this._logo;
  }

  public set logo(value: string) {
    this._logo = value;
  }

  public get language(): string {
    return this._langugeId;
  }

  public set language(value: string) {
    this._langugeId = value;
  }

  public get playEnvironmentId(): string {
    return this._playEnvironmentId;
  }

  public set playEnvironmentId(value: string) {
    this._playEnvironmentId = value;
  }
}
