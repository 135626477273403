import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit, Inject, ViewChild, ChangeDetectorRef } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UserDetailComponent } from "app/presentation/pages/users/detail/user-detail.component";
import * as _ from "lodash";
import { Organization } from "../../../domain/organization";
import { IPage, Page } from "../../../domain/page";
import { User } from "../../../domain/user";
import { AuthenticationService } from "../../../infrastructure/http/authentication/authentication.service";
import { HttpCategoryService } from "../../../infrastructure/http/category/httpcategory.service";
import { HttpGroupsService } from "../../../infrastructure/http/group/httpgroups.service";
import { HttpOrganizationService } from "../../../infrastructure/http/organization/httporganization.service";
import { HttpReportsService } from "../../../infrastructure/http/report/httpreports.service";
import { HttpUserService } from "../../../infrastructure/http/user/httpuser.service";
import { NavigationService } from "../../../infrastructure/navigation/navigation.service";
import { SwitchOrganizationDialogComponent } from "../../switch-organization-dialog/switch-organization-dialog.component";
import { DataService } from "../../../infrastructure/dataservice";
import { Subscription } from "rxjs";
import { OnboardingService } from "../../../infrastructure/http/onboarding/onboarding.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { TranslateService } from "@ngx-translate/core";
import { PersistentStoreService } from "../../../infrastructure/helper/persistentstore.service";
import { Feature } from "../../../domain/feature";
import { Reference } from "@angular/compiler/src/render3/r3_ast";
import { LayoutNavService } from "../../../layout.navigation.service";

//import { AppComponent } from "../../../app.component";
// import isNavExpanded from "../../../app.component";



@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})


export class NavigationComponent implements OnInit, OnDestroy {
  public pages: IPage[];
  public isNavExpanded: boolean;

  @ViewChild("appTrigger") public appTrigger: MatMenuTrigger;

  get loggedInUser(): User {
    return this.dataService.AuthenticatedUser;
  }

  get loggedInOrganization(): Organization {
    return this.dataService.CurrentOrganization;
  }

  private guides = [];
  private steps = [
    "1Step",
    "2Step",
    "3Step",
    "4Step",
    "5Step",
    "6Step",
    "accountStep",
    "profileStep",
    "logoutStep",
  ];
  private availableOrganizations: Array<Organization>;
  private subscriptionOnboarding: Subscription;

  constructor(
    //private mainApp: AppComponent,
    private navigationService: NavigationService,
    private location: Location,
    private authenticationService: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private userService: HttpUserService,
    private groupsService: HttpGroupsService,
    private categoryService: HttpCategoryService,
    private organizationService: HttpOrganizationService,
    private reportsService: HttpReportsService,
    private persistentstoreService: PersistentStoreService,
    private dialog: MatDialog,
    private dataService: DataService,
    private onboarding: OnboardingService,
    @Inject(LayoutNavService) public LayoutNavService: LayoutNavService,
    private ref: ChangeDetectorRef
  ) {

    this.isNavExpanded = LayoutNavService.getIsNavExpanded();

    if (this.authenticationService.isLoggedIn()) {
      this.organizationService
        .getAvailableOrganizations()
        .subscribe((organizations: Array<Organization>) => {
          this.availableOrganizations = organizations;
        });
    }
  }

  private setLanguage(): void {
    const currentLanguage = this.persistentstoreService.get("language");
    if (currentLanguage === undefined) {
      return;
    }
    this.translate.setDefaultLang(currentLanguage);
    this.translate.use(currentLanguage);
    this.dataService.PreferredLanguage = currentLanguage;
  }

  refresh() {
    this.pages = this.navigationService.getMenuPages();
    /*  this.subscriptionOnboarding = this.onboarding
       .getOnboarding()
       .subscribe((res) => {
         this.guides = [];
         for (const key in res) {
           if (res[key].name === "Navigation" && !res[key].isCompleted) {
             this.onboarding
               .openOnboardingDialog("on_boarding", [], [], true)
               .subscribe(() => {
                 this.startOnboardingNavigation();
               });
             this.guides.push(res[key].id);
           }
         }
       }); */
  }

  ngOnInit() {

    this.dataService.organizationChangedEvent.subscribe(() => {
      this.refresh();
    })
    this.refresh();
  }


  toggleMenu() {
    this.isNavExpanded = this.LayoutNavService.toggleSetExpanded();
    this.LayoutNavService.sendExpandEvent();
  }

  public startOnboardingNavigation(): void {
    this.onboarding
      .startOnboardingNavigation(this.steps, "on_boarding", this.guides, true)
      .subscribe(
        (res) => {
          if (res.name === "accountStep") {

            this.appTrigger.openMenu();
          }
        },
        null,
        () => {
          if (this.guides.length) {
            this.onboarding.openFinishOnboardingDialog(
              "on_boarding",
              this.guides,
              true
            );
            this.guides = [];
          }
        }
      );
  }

  public onFinishGuide(): void {
    this.appTrigger.closeMenu();
    if (this.guides.length) {
      this.onboarding.openFinishOnboardingDialog(
        "on_boarding",
        this.guides,
        true
      );
      this.guides = [];
    }
  }

  public isActive(path: string) {
    return _.startsWith(this.location.path(), "/" + path);
  }

  public logout(): void {
    this.authenticationService.logout();
    this.organizationService.clear();
    this.router.navigate([""]);
  }

  public showUserProfile(): void {
    const config = new MatDialogConfig();
    config.height = "832px";
    config.width = "1240px";
    config.data = {
      user: this.dataService.AuthenticatedUser,
      showCompanyTab: true
    };
    this.dialog.open(UserDetailComponent, config);
  }

  public canSwitchOrganization(): boolean {
    if (!_.isNil(this.loggedInUser)) {
      return (
        !_.isNil(this.availableOrganizations) &&
        this.availableOrganizations.length > 1
      );
    } else {
      return false;
    }
  }

  public switchOrganization(): void {
    const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
      height: "70%",
      width: "500px",
    };

    const dialogRef: MatDialogRef<SwitchOrganizationDialogComponent> = this.dialog.open(
      SwitchOrganizationDialogComponent,
      {
        ...currentDialogConfig,
        data: {
          availableOrganizations: this.availableOrganizations
        }
      }
    );

    dialogRef.afterClosed().subscribe((chosenOrganization: Organization) => {
      if (chosenOrganization && chosenOrganization.id !== this.dataService.organizationID) {
        this.dataService.CurrentOrganization = chosenOrganization;

        this.userService.clear();
        this.groupsService.clear();
        this.reportsService.clear();
        this.categoryService.clear();
        this.organizationService.clear();


        this.organizationService.getFeatures(chosenOrganization.id).subscribe(features => {

          this.dataService.setOrganizationWithFeatures(chosenOrganization, features.map((feature: Feature) => { return feature.name; }))
          this.groupsService.getGroups(chosenOrganization.id);
          this.categoryService.getCategories();
          this.redirectTo("/dashboard");

        });

      }
    });
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  ngOnDestroy() {
    this.subscriptionOnboarding?.unsubscribe();
  }
}
