<div class="license-confirmation__container">

  <div class="license-confirmation__header">
    <button type="button" class="close-button" (click)="cancel()" mat-icon-button mat-dialog-close>
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>
  </div>

  <div class="license-confirmation__content">
    <p class="license-confirmation__text">{{textContent}}</p>
  </div>

  <div class="license-confirmation__controls">
    <button mat-button class="mat-secondary cancel-button" (click)="cancel()">
      No
    </button>

    <button mat-flat-button color="primary" class="confirm-button mat-flat-button" (click)="confirm()">
      Yes
    </button>
  </div>
</div>
