
  <header mat-dialog-title class="primary-color">
    <button class="close-button" mat-icon-button (click)="close()">
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>

    <h3>{{'user_overview.import_users' | translate}}</h3>
  </header>

  <mat-dialog-content class="import-users-container" >

    <mat-horizontal-stepper #uploadFileWizard linear>
      <mat-step [completed]="isValidated || isValidating">
        <article fxLayout="column" fxLayoutGap="26px">
          <p class="hint">
            <a [href]="templateLocation" [download]="downloadFileName">
              {{'user_import.download_example' | translate}}
            </a>
            {{'user_import.hint' | translate}}
          </p>

          <p fxFlex>
            <mat-form-field fxFlex>
              <mat-label>{{'user_import.select_import_action' | translate}}</mat-label>
              <mat-select [(value)]="importAction">
                <mat-option value="skip">{{'user_import.import_action_skip' | translate}}</mat-option>
                <mat-option value="update">{{'user_import.import_action_update' | translate}}</mat-option>
                <mat-option value="expire">{{'user_import.import_action_update_and_expire' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </p>

          <p fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
            <input #fileUpload type="file" onclick="value = null" style="display:none" single
                   (change)="selectFile($event)"
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">

            <span fxFlex>{{filename}}</span>

            <button fxFlex="none" mat-flat-button color="primary" (click)="fileUpload.click()">
              {{'user_import.select_list_of_users' | translate}}
            </button>
          </p>

          <p *ngIf="isValidating" fxLayout="column" class="uploading-spreadsheet"
             fxLayoutAlign="center center" fxLayoutGap="16px">
            {{'general.uploading' | translate: {attribute: 'user_import.list_of_users' | translate | lowercase} }}
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </p>
        </article>
      </mat-step>

      <mat-step>
        <ng-container  *ngIf="isValidated" [ngSwitch]="isValid">
          <article  *ngSwitchCase="true" [ngSwitch]="hasWarnings" fxLayout="column" fxLayoutGap="26px">
              <header *ngSwitchCase="true">
                {{'user_import.valid_warnings' | translate: validationResults }}
              </header>
              <header *ngSwitchCase="false" class="hint">
                {{'user_import.valid' | translate: validationResults}}
              </header>

              <p *ngIf="hasWarnings" fxLayout="row" fxLayoutGap="16px" >
                <mat-icon class="icon-warning">warning</mat-icon>
                <mat-list fxLayout="column" class="feedback-list" fxFlex>
                  <mat-list-item *ngFor="let warning of warnings">{{warning}}</mat-list-item>
                </mat-list>
              </p>

              <p *ngIf="isImporting">{{'user_import.processing' | translate}}</p>
          </article>

          <article  *ngSwitchCase="false"  fxLayout="column" fxLayoutGap="26px">
            <header>
              {{'user_import.errors' | translate}}
            </header>

            <p *ngIf="hasWarnings" fxLayout="row" fxLayoutGap="16px">
              <mat-icon class="icon-warning">warning</mat-icon>
              <mat-list fxLayout="column" class="feedback-list" fxFlex>
                <mat-list-item *ngFor="let warning of warnings">{{warning}}</mat-list-item>
              </mat-list>
            </p>

            <p *ngIf="hasErrors" fxLayout="row" fxLayoutGap="16px">
              <mat-icon class="icon-error">error</mat-icon>
              <mat-list fxLayout="column" class="feedback-list" fxFlex>
                <mat-list-item *ngFor="let error of errors">{{error}}</mat-list-item>
              </mat-list>
            </p>
          </article>
        </ng-container>

      </mat-step>
    </mat-horizontal-stepper>

  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row"  fxLayoutGap="10px" fxLayoutAlign="end center">

    <ng-container *ngIf="uploadFileWizard.selectedIndex == 0">
      <button fxFlex="25%" mat-button (click)="close()" class="mat-secondary">
        {{'CANCEL' | translate}}
      </button>
      <button  fxFlex="25%" mat-flat-button color="primary" [disabled]="!isSelected" (click)="validateFile(uploadFileWizard)">
        {{'common.next' | translate}}
      </button>
    </ng-container>

    <ng-container *ngIf="uploadFileWizard.selectedIndex == 1" >
      <button fxFlex="25%" *ngIf="!(isImporting || isImported)" mat-button (click)="close()" class="mat-secondary">
        {{'CANCEL' | translate}}
      </button>

      <button fxFlex="25%" mat-button *ngIf="(isImporting || isImported)" (click)="close()" class="mat-secondary">
        {{'CLOSE' | translate}}
      </button>

      <button fxFlex="25%"
              mat-flat-button color="primary"  (click)="uploadFileWizard.previous()">
        Previous
      </button>
      <button fxFlex="25%"
              mat-flat-button color="primary" [disabled]="!isValid || validationResults.numberOfUsers == 0 || isImported" (click)="importFile()">
        {{'common.import' | translate}}
      </button>
    </ng-container>

  </mat-dialog-actions>


