<section (keypress)="keyDownFunction($event)">
  <button class="close-button" mat-icon-button type="button" mat-dialog-close>
    <mat-icon class="icon-clear">clear</mat-icon>
  </button>

  <mat-dialog-content class="information-dialog-container">
    <carousel (guideFinished)="closeDialog()">
      <ng-container *ngFor="let item of items">
        <ng-container *carouselItem>
          <div class="item">
            <h5>{{ item.title | translate }}</h5>
            <p>{{ item.description | translate }}</p>
            <div class="image-wrapper">
              <img [src]="item.src" alt="screen" />
            </div>
          </div>
        </ng-container>
      </ng-container>
    </carousel>
  </mat-dialog-content>
</section>
