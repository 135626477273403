import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/material.module';
import { UsersGroupsComponent } from './users-groups.component'
import { TranslateModule } from '@ngx-translate/core';
import { FilterModule } from 'app/shared/app-filter/app-filter.module';
import { JoyrideModule } from 'ngx-joyride';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';



@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    FilterModule,
    VirtualScrollerModule,
    JoyrideModule
  ],
  declarations: [UsersGroupsComponent],
  exports: [UsersGroupsComponent]
})
export class UsersGroupsModule {}
