<div class="license-users__container">

  <div class="license-users__header">

    <div class="license-users__title-container">
      <h2 class="license-users__title">
        {{'license_module.users_modal_title' | translate}}
      </h2>
      <button type="button" mat-icon-button mat-dialog-close>
        <mat-icon class="icon-clear">clear</mat-icon>
      </button>
    </div>
    <p class="license-users__block-id">License block ID: {{data.id}}</p>
  </div>

  <div class="license-users__content" *ngIf="!isDataLoading; else spinner">
    <app-license-users-table [tableData]="licenseUsers" [licensesUsed]="data.licensesUsed"
      [licensesLeft]="licensesLeft">
    </app-license-users-table>
  </div>

  <ng-template #spinner>
    <div class="loading-status">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </ng-template>
</div>
