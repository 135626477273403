import { Component } from "@angular/core";
import { User } from "../../../../../domain/user";
import { MatDialog } from "@angular/material/dialog";
import { UserLicensesComponent } from "../user-licenses-modal/user-licenses-modal.component";

@Component({
  selector: "app-user-status",
  templateUrl: "./user-status.component.html",
  styleUrls: ["./user-status.component.scss"],
})
export class UserStatusComponent {
  private params: any;
  public user: User;

	constructor(private dialog: MatDialog) {}

  agInit(params: any): void {
    this.params = params;
    this.user = params.data;
  }

	openUserLicensesModal() {
    const config = { data: this.user }
		this.dialog.open(UserLicensesComponent, config)
	}
}
