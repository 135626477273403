import { Component, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { ActivatedRoute, Params } from "@angular/router";
import * as _ from "lodash";
import { Group } from "../../../../domain/group";
import { HttpGameReportService } from "../../../../infrastructure/http/gamereport/httpgamereport.service";
import { ReceiverTypeForReminder } from "../../../reminder/receiver-type-for-reminder";
import { SendReminderDialogComponent } from "../../../reminder/send-reminder-dialog.component";
import { HeaderNameComponent } from "../../users/people/header-name/header-name.component";
import { GroupInCategory } from "../group-in-category";
import { HeaderCourseNameComponent } from "../header-course-name/header-course-name.component";
import { UserCourseResultComponent } from "../user-course/user-course-result.component/user-course-result.component";
import { DataService } from "../../../../infrastructure/dataservice";
import { ColDef, GridOptions, GridReadyEvent } from "ag-grid-community";

@Component({
  templateUrl: "./group-detail-result.component.html",
  styleUrls: ["./group-detail-result.component.scss"],
})
export class GroupDetailResultComponent implements OnInit {
  public groupName: string;
  public categoryName: string;
  public gridOptions: GridOptions;
  private urlParams: Params;
  private groupInCategory: GroupInCategory;
  private isLoading: boolean = true;

  sortedUsers = []

  constructor(
    activatedRoute: ActivatedRoute,
    private gameReportService: HttpGameReportService,
    private dialog: MatDialog,
    private dataService: DataService
  ) {
    this.urlParams = activatedRoute.snapshot.params;
  }

  ngOnInit(): void {
    this.gridOptions = <GridOptions>{
      rowHeight: 65,
      headerHeight: 65,
      suppressMovableColumns: true,
      onGridReady: (event: GridReadyEvent) => {
        event.api.setColumnDefs(this.createColumnDefs())
        event.columnApi.autoSizeAllColumns()
      }
    };

    this.reload();
  }

  public canShowGroupDetails(): boolean {
    return !this.isLoading;
  }

  public areResultsLoading(): boolean {
    return this.isLoading;
  }

  public sendReminder(): void {
    const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
      height: "500px",
      width: "1000px",
      data: {
        receiver: new Group(
          this.urlParams.groupId,
          this.groupName,
          null,
          [],
          [],
          []
        ),
        receiverTypeForReminder: ReceiverTypeForReminder.GROUP,
      },
    };
    const dialogRef: MatDialogRef<SendReminderDialogComponent> =
      this.dialog.open(SendReminderDialogComponent, currentDialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  private reload(): void {
    const { categoryId, groupId } = this.urlParams
    const { organizationID } = this.dataService

    this.isLoading = true;

    this.gameReportService
      .getGroupDetailsForCategoryAndGroup(organizationID, categoryId, groupId)
      .subscribe((groupInCategory: GroupInCategory) => {
        this.groupInCategory = groupInCategory;
        this.groupName = groupInCategory.name;
        this.categoryName = groupInCategory.categoryName;

        this.sortedUsers = this.groupInCategory.users.sort(({name: firstName}, {name: secondName}) => {
          return firstName.toLowerCase() > secondName.toLowerCase() ? 1 : -1
        })

        this.isLoading = false;
      });
  }

  private createColumnDefs() {
    const colDefs: ColDef[] = [
      {
        headerName: "User",
        cellClass: 'user-cell',
        field: "name",
        suppressSizeToFit: true,
        minWidth: 200,
        headerComponentFramework: HeaderNameComponent,
        pinned: true,
      },
    ];

    _.each(
      _.sortBy(this.groupInCategory.courses, (course) => {
        return `${course.level} ${course.name.toLowerCase()}`;
      }),
      (course) => {
        colDefs.push({
          headerName: `${course.name}`,
          cellClass: 'course-level-cell',
          headerComponentParams: { courseLevel: course.level },
          headerComponentFramework: HeaderCourseNameComponent,
          cellRendererFramework: UserCourseResultComponent,
          cellRendererParams: { courseId: course.id },
          minWidth: 200,
        });
      }
    );

    return colDefs
  }
}
