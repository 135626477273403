<article class="list-header">
  <div class="header-controls">
    <h3>{{ "GROUPS" | translate }}</h3>
    <button mat-button joyrideStep="AddUserToGroupStep" stepPosition="left"
      title="{{ 'user_guide_module.add_to_group_title' | translate }}"
      text="{{ 'user_guide_module.add_to_group_hint' | translate }}"
      class="header-button mat-basic-button mat-little-button" (click)="addSelectedUsersToNewGroup()"
      [disabled]="!canAddNewGroup()">
      {{ "ADD TO NEW GROUP" | translate }}
    </button>
    <app-filter class="header-filter" (filteredTextChanged)="filtertextchanged($event)"
      joyrideStep="SearchGroupUserStep" stepPosition="left"
      title="{{ 'user_guide_module.search_group_title' | translate }}"
      text="{{ 'user_guide_module.search_group_hint' | translate }}" placeholder="{{ 'FIND GROUP' | translate }}">
    </app-filter>
  </div>
</article>

<article class="list-content group-list-content" fxFlex>
  <mat-spinner class="listspinner" strokeWidth="5" diameter="30" *ngIf="loadingGroups; else groupsContent">
  </mat-spinner>

  <ng-template #groupsContent>
    <mat-list [hidden]="!isActive">
      <virtual-scroller class="user-groups-scroller" #scroll [items]="filteredGroups" (vsUpdate)="scrollItems = $event">
        <mat-list-item *ngFor="let group of scrollItems; let i = index">
          <h5 (click)="showGroupProfile(group)" class="group-name" mat-line>
            {{ group.name }}
          </h5>
          <span mat-line>
            {{
              "NUMBER OF SELECTED USERS"
                | translate
                  : {
                      numberOfSelectedUsersInGroup: getNumberOfSelectedUsersInGroup(group),
                      selectedUsers: group.userIDs.length
                    }
            }}
          </span>
          <div *ngIf="i === 0; else showButtonsWithoutGuide" class="action-groups-wrapper-guide"
            joyrideStep="LinkUserStep" stepPosition="left" title="{{ 'user_guide_module.link_user_title' | translate }}"
            text="{{ 'user_guide_module.link_user_hint' | translate }}">
            <button class="remove-user" [disabled]="!canRemoveSelectedUsersFromGroup(group)" mat-icon-button
              (click)="removeSelectedUsersFromGroup(group)" matTooltip="{{
                'NUMBER OF SELECTED USERS TO REMOVE'
                  | translate
                    : {
                        numberOfUsersToRemoveFromGroup: getNumberOfUsersToRemoveFromGroup(
                          group
                        )
                      }
              }}">
              <mat-icon svgIcon="remove-user"></mat-icon>
            </button>
            <button class="add-user" [disabled]="!canAddSelectedUsersToGroup(group)" mat-icon-button
              (click)="addSelectedUsersToGroup(group)" matTooltip="{{
                'NUMBER OF SELECTED USERS TO ADD'
                  | translate
                    : {
                        numberOfUsersToAddToGroup: getNumberOfUsersToAddToGroup(
                          group
                        )
                      }
              }}">
              <mat-icon svgIcon="add-user"></mat-icon>
            </button>
          </div>
          <ng-template #showButtonsWithoutGuide>
            <button class="remove-user" [disabled]="!canRemoveSelectedUsersFromGroup(group)" mat-icon-button
              (click)="removeSelectedUsersFromGroup(group)" matTooltip="{{
                'NUMBER OF SELECTED USERS TO REMOVE'
                  | translate
                    : {
                        numberOfUsersToRemoveFromGroup: getNumberOfUsersToRemoveFromGroup(
                          group
                        )
                      }
              }}">
              <mat-icon svgIcon="remove-user"></mat-icon>
            </button>
            <button class="add-user" [disabled]="!canAddSelectedUsersToGroup(group)" mat-icon-button
              (click)="addSelectedUsersToGroup(group)" matTooltip="{{
                'NUMBER OF SELECTED USERS TO ADD'
                  | translate
                    : {
                        numberOfUsersToAddToGroup: getNumberOfUsersToAddToGroup(
                          group
                        )
                      }
              }}">
              <mat-icon svgIcon="add-user"></mat-icon>
            </button>
          </ng-template>
        </mat-list-item>
      </virtual-scroller>
    </mat-list>
  </ng-template>
</article>
