import { Component } from "@angular/core";

@Component({
  selector: "app-learning-paths-page",
  templateUrl: "./learning-paths-page.component.html",
  styleUrls: ["./learning-paths-page.component.scss"],
})
export class LearningPathsPageComponent {
  selectedPathList: string[] = []

  selectHandler(id) {
    if (this.selectedPathList.includes(id)) {
      return
    }

    this.selectedPathList = this.selectedPathList.concat([id]);
  }

  unselectHandler(id) {
    this.selectedPathList = this.selectedPathList.filter((selectedId) => selectedId !== id)
  }
}
