import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
    public textContent: string;
    public summaryContent: string;
    public confirmText: string;
    public cancelText: string;
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>;

    public constructor(dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
        this.dialogRef = dialogRef;
        this.textContent = data.textContent;
        this.summaryContent = data.summaryContent;
        this.confirmText = data.confirmText ? data.confirmText : 'OK';
        this.cancelText = data.cancelText ? data.cancelText : 'Cancel';
    }

    public canShowSummaryContent(): boolean {
        return !_.isNil(this.summaryContent);
    }

    public confirm() {
        this.dialogRef.close(true);
    }

    public cancel() {
        this.dialogRef.close(false);
    }

    public keyPressed(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.confirm();
        }
    }
}
