import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-learning-path-delete-dialog',
  templateUrl: './learning-path-delete-dialog.component.html',
  styleUrls: ['./learning-path-delete-dialog.component.scss']
})
export class LearningPathDeleteDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<LearningPathDeleteDialogComponent>
  ) {}

  confirmDelete() {
    this.dialogRef.close(true)
  }
}
