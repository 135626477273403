import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import * as _ from "lodash";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { StringComparator } from "../../../../application/command/stringcomparator";
import { Group } from "../../../../domain/group";
import { LoggedInOrganizationService } from "../../../../infrastructure/helper/logged-in-organization.service";
import { HttpGroupsService } from "../../../../infrastructure/http/group/httpgroups.service";
import { ConfirmationDialogComponent } from "../../../confirmation-dialog/confirmation-dialog.component";
import { ReceiverTypeForReminder } from "../../../reminder/receiver-type-for-reminder";
import { SendReminderDialogComponent } from "../../../reminder/send-reminder-dialog.component";
import { SendMessageDialogComponent } from "../../../send-message/send-message.component";
import { AddGroupComponent } from "../add-group/add-group.component";
import { HeaderNameComponent } from "../people/header-name/header-name.component";
import { GroupNameComponent } from "./name/group-name.component";
import { RenameGroupComponent } from "./rename-group/rename-group.component";
import { DataService } from "../../../../infrastructure/dataservice";
import { MatMenuTrigger } from "@angular/material/menu";
import { ColDef, GridOptions } from "ag-grid-community";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.scss"],
})
export class GroupsComponent implements OnInit, OnDestroy {
  private gridApi;

  public groups: Array<Group> = null;
  public groupGridOptions: GridOptions = <GridOptions>{};
  public selectedGroup: Group = null;
  @ViewChild("btnGroupTrigger") public btnGroupTrigger: MatMenuTrigger;
  @Input() isActive: boolean;
  @Input() isOpenSelectGroup: ReplaySubject<boolean>;

  private dialogConfig: MatDialogConfig = <MatDialogConfig>{
    height: "250px",
    width: "506px",
  };
  private organizationId: string;
  private openSelectSubscription: Subscription;

  constructor(
    private groupService: HttpGroupsService,
    private dialog: MatDialog,
    private loggedInOrganizationService: LoggedInOrganizationService,
    private dataService: DataService
  ) {
    this.groupGridOptions = <GridOptions>{
      rowSelection: "single",
      rowHeight: 65,
      headerHeight: 65,
      defaultColDef: {
        sortable: true,
      },
      onGridSizeChanged: () => this.resizeGrid(),
      onSelectionChanged: () => this.onSelectionChanged(),
      icons: {
        sortAscending: '',
        sortDescending: '',
      },
      columnDefs: [
        <ColDef>{
          headerName: "",
          width: 20,
          minWidth: 20,
          maxWidth: 20,
          checkboxSelection: false,
          sortable: false,
          suppressMenu: true,
          pinned: true,
          headerCheckboxSelection: false,
          headerCheckboxSelectionFilteredOnly: false,
        },
        <ColDef>{
          headerName: "",
          width: 40,
          minWidth: 40,
          maxWidth: 40,
          checkboxSelection: true,
          sortable: false,
          suppressMenu: true,
          pinned: true,
          // cellRenderer: () => ('<div class="ngSelectionCell"><input name="selected" type="radio"></div>'),
        },
        <ColDef>{
          headerName: "GROUP NAME",
          field: "name",
          width: 300,
          minWidth: 150,
          maxWidth: 300,
          cellRendererFramework: GroupNameComponent,
          comparator: StringComparator,
          headerComponentFramework: <new () => HeaderNameComponent>(
            HeaderNameComponent
          ),
        },
        <ColDef>{
          headerName: "DESCRIPTION",
          field: "description",
          minWidth: 150,
          comparator: StringComparator,
          headerComponentFramework: <new () => HeaderNameComponent>(
            HeaderNameComponent
          ),
        },
        <ColDef>{
          headerName: "USERS",
          field: "userIDs.length",
          width: 85,
          minWidth: 85,
          maxWidth: 85,
          cellStyle: { "text-align": "center" },
          headerComponentFramework: <new () => HeaderNameComponent>(
            HeaderNameComponent
          ),
        },
      ],
    };
  }

  ngOnInit() {
    this.reload();
    this.organizationId = this.loggedInOrganizationService.getLoggedInOrganizationId();
    this.openSelectSubscription = this.isOpenSelectGroup
      .asObservable()
      .subscribe((res) => {
        if (res === true) {
          this.btnGroupTrigger.openMenu();
        } else {
          this.btnGroupTrigger.closeMenu();
        }
      });
  }

  onGridReady(params) {
    this.gridApi = params.api
  }

  public isLoading(): Observable<boolean> {
    return this.groupService.isLoadingGroups();
  }

  public peopleGroupChange(event: string): void {
    if (event === "usersInGroup") {
      this.gridApi.redrawRows();
    }
  }

  public filterGroups(event: string): void {
    this.gridApi.setQuickFilter(event);
  }

  public reload(refresh: boolean = false): void {
    this.groupService
      .getGroups(this.dataService.organizationID, refresh)
      .subscribe((groups: Array<Group>) => {
        this.groups = groups;
      });
  }

  public hasSelectedGroup(): boolean {
    return !_.isNil(this.selectedGroup);
  }

  public sendMessage(): void {
    const config = new MatDialogConfig();
    config.height = "450px";
    config.width = "600px";
    config.data = {
      group: this.selectedGroup,
    };
    this.dialog.open(SendMessageDialogComponent, config);
  }

  public sendReminder(): void {
    const config = new MatDialogConfig();
    config.height = "450px";
    config.data = {
      receiver: this.selectedGroup,
      receiverTypeForReminder: ReceiverTypeForReminder.GROUP,
    };
    this.dialog.open(SendReminderDialogComponent, config);
  }

  public removeSelectedGroup(): void {
    const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
      height: this.dialogConfig.height,
      width: this.dialogConfig.width,
      data: {
        textContent: "group_overview.remove_selected_group_confirmation",
        confirmText: "common.remove",
        cancelText: "DO NOT REMOVE",
      },
    };

    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(
      ConfirmationDialogComponent,
      currentDialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.groupService
          .deleteGroup(this.organizationId, this.selectedGroup)
          .subscribe(() => {
            this.reload(true);
            this.selectedGroup = null;
          });
      }
    });
  }

  public renameSelectedGroup(): void {
    const config = new MatDialogConfig();
    config.height = "392px";
    config.width = "506px";
    config.data = {
      group: this.selectedGroup,
    };
    const dialogRef: MatDialogRef<RenameGroupComponent> = this.dialog.open(
      RenameGroupComponent,
      config
    );
    dialogRef.afterClosed().subscribe(() => {});
  }

  public addGroup(): void {
    const config = new MatDialogConfig();
    config.height = "392px";
    config.width = "506px";
    const dialogRef: MatDialogRef<AddGroupComponent> = this.dialog.open(
      AddGroupComponent,
      config
    );
    dialogRef.afterClosed().subscribe(() => this.reload(true));
  }

  private resizeGrid(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  private onSelectionChanged(): void {
    this.selectedGroup = this.gridApi.getSelectedRows()[0];
  }

  ngOnDestroy() {
    this.openSelectSubscription.unsubscribe();
  }
}
