import { FormControl } from "@angular/forms";

export function noEmptySpaceValidator(control: FormControl) {
  const value = control?.value || ''

  if (value) {
    const isWhiteSpace = value.trim().length === 0
    return isWhiteSpace && { whitespace: true }
  }

  return null
}
