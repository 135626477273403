<div mat-dialog-title>
  <h2 class="title">Delete the Learning Module?</h2>
  <app-button
    mat-dialog-close
    shape="icon"
  >
    <mat-icon>close</mat-icon>
  </app-button>

</div>
<mat-dialog-content class="dialog-content">
  <p class="sure-text">Are you sure you want to delete the Learning Module?</p>

  <app-button
    class="confirm-delete-button"
    shape="raised"
    color="primary"
    (onButtonClick)="confirmDelete()"
  >
    Confirm
  </app-button>
</mat-dialog-content>
