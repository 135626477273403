<section *ngIf="!isFeaturesLoading; else spinner" fxFlex fxLayout="row" class="courses-wrapper">
  <section class="courses-content" fxFlex="70" fxLayout="column">
    <h3 class="antialiased">{{ "COURSES" | translate }}</h3>
    <div class="toggle-description antialiased">{{ "OFF ON" | translate }}</div>
    <article fxFlex>
      <mat-accordion [multi]="true" fxFlex>
        <mat-expansion-panel #panel *ngFor="let category of categories; let i = index" [expanded]="i === 0"
          (opened)="category.isExpanded = true" (closed)="category.isExpanded = false" class="available-courses-panel"
          [hideToggle]="true">
          <mat-expansion-panel-header fxLayout="row">
            <div class="category-wrapper" fxFlex>
              <app-button shape="icon">
                <mat-icon class="expand-icon" [ngClass]="{ expanded: category.isExpanded }">
                  expand_more
                </mat-icon>
              </app-button>
              <p *ngIf="isDirectUserAssignments; else coursesWithCheckbox" class="course-name"
                joyrideStep="CheckCourseStep" stepPosition="right" title="{{
                  'courses_guide_module.select_category_title' | translate
                }}" text="{{
                  'courses_guide_module.select_category_hint' | translate
                }}">
                {{ category.name }}
              </p>

              <ng-template #coursesWithCheckbox>
                <mat-checkbox fxFlex [(ngModel)]="category.checked" *ngIf="i === 0; else notFirst"
                  (change)="updateSelectedCourses()" color="primary" (click)="$event.stopPropagation()"
                  joyrideStep="CheckCourseStep" stepPosition="right" title="{{
                    'courses_guide_module.select_category_title' | translate
                  }}" text="{{
                    'courses_guide_module.select_category_hint' | translate
                  }}">
                  <span class="course-name" [ngClass]="{ 'not-active': !category.isActive }">
                    {{ category.name }}
                  </span>
                </mat-checkbox>

                <ng-template #notFirst>
                  <mat-checkbox fxFlex [(ngModel)]="category.checked" (change)="updateSelectedCourses()" color="primary"
                    (click)="$event.stopPropagation()">
                    <span class="course-name" [ngClass]="{ 'not-active': !category.isActive }">
                      {{ category.name }}
                    </span>
                  </mat-checkbox>
                </ng-template>
              </ng-template>
            </div>
            <div class="mat-accordion-end">
              <mat-slide-toggle (click)="$event.stopPropagation()" color="primary"
                *ngIf="i === 0; else firstToggleCourse" joyrideStep="EnableCourseStep" stepPosition="right" title="{{
                  'courses_guide_module.enable_course_title' | translate
                }}" text="{{
                  'courses_guide_module.enable_course_hint' | translate
                }}" (change)="onCategoryIsActiveChanged(category)" [(ngModel)]="category.isActive">
              </mat-slide-toggle>
              <ng-template #firstToggleCourse>
                <mat-slide-toggle (click)="$event.stopPropagation()" color="primary"
                  (change)="onCategoryIsActiveChanged(category)" [(ngModel)]="category.isActive">
                </mat-slide-toggle>
              </ng-template>
            </div>
          </mat-expansion-panel-header>

          <mat-list>
            <mat-list-item *ngFor="let course of category.courses; let indexCourse = index"
              [ngClass]="{ 'selected-course': course.checked }">
              <ng-container *ngIf="isDirectUserAssignments; else subcoursesWithCheckbox">
                <div *ngIf="indexCourse === 0 && i === 0; else notFirstSubCourse" class="subcourse-container"
                  joyrideStep="SelectCategoryStep" stepPosition="right" title="{{
                    'courses_guide_module.select_course_title' | translate
                  }}" text="{{
                    'courses_guide_module.select_course_hint' | translate
                  }}">
                  <span class="coursename" (click)="$event.preventDefault(); showCourseDetails(course)">
                    {{ course.level }}. {{ course.name }}
                  </span>
                </div>

                <ng-template #notFirstSubCourse>
                  <div class="subcourse-container">
                    <span class="coursename" (click)="
                        $event.preventDefault(); showCourseDetails(course)
                      ">
                      {{ course.level }}. {{ course.name }}
                    </span>
                  </div>
                </ng-template>
              </ng-container>

              <ng-template #subcoursesWithCheckbox>
                <mat-checkbox mat-line [(ngModel)]="course.checked"
                  *ngIf="indexCourse === 0 && i === 0; else firstCourse" joyrideStep="SelectCategoryStep"
                  stepPosition="right" title="{{
                    'courses_guide_module.select_course_title' | translate
                  }}" text="{{
                    'courses_guide_module.select_course_hint' | translate
                  }}" (change)="updateSelectedCourses()" color="primary">
                  <span class="coursename" (click)="$event.preventDefault(); showCourseDetails(course)">
                    {{ course.level }}. {{ course.name }}
                  </span>
                </mat-checkbox>
                <ng-template #firstCourse>
                  <mat-checkbox mat-line [(ngModel)]="course.checked" (change)="updateSelectedCourses()"
                    color="primary">
                    <span class="coursename" (click)="
                        $event.preventDefault(); showCourseDetails(course)
                      ">
                      {{ course.level }}. {{ course.name }}
                    </span>
                  </mat-checkbox>
                </ng-template>
              </ng-template>

              <div class="accordion-end">
                <button *ngIf="shouldShowEditButton" mat-icon-button class="edit-icon__container"
                  (click)="openEditModal(course)">
                  <mat-icon>edit</mat-icon>
                </button>

                <mat-slide-toggle color="primary" [disabled]="!category.isActive"
                  (change)="onCourseIsActiveChanged(course)" [(ngModel)]="course.isActive">
                </mat-slide-toggle>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-expansion-panel>
      </mat-accordion>
    </article>
  </section>

  <section *ngIf="!isDirectUserAssignments; else notice" fxFlex="30" fxLayout="column" class="groups-content">
    <article class="groupsOfCourse" fxFlex>
      <app-courses-groups [selectedCourses]="selectedCourses" fxLayout="column"></app-courses-groups>
    </article>
  </section>

  <ng-template #notice>
    <div class="notice">
      <h4 class="notice__title">
        {{ "courses_module.notice_title" | translate }}
      </h4>
      <p class="notice__description">
        {{ "courses_module.notice_description" | translate }}
      </p>
    </div>
  </ng-template>

  <div class="courses-guide tooltip-guide" matTooltip="{{ 'on_boarding_module.tooltip_info' | translate }}"
    (click)="startOnboardingNavigation()">
    <mat-icon>help_outline</mat-icon>
    <div class="text-block">
      {{ "on_boarding_module.repeat_guide" | translate }}
    </div>
  </div>
</section>

<ng-template #spinner>
  <div class="loading-status">
    <p>
      {{
        "general.loading"
          | translate: { attribute: "COURSES_attribute" | translate }
      }}
    </p>
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
  </div>
</ng-template>
