import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {NotificationService} from '../../../../../application/notification/notification.service';
import {Group} from '../../../../../domain/group';
import {LoggedInOrganizationService} from '../../../../../infrastructure/helper/logged-in-organization.service';
import {HttpGroupsService} from '../../../../../infrastructure/http/group/httpgroups.service';
import * as _ from 'lodash';

@Component({
    templateUrl: './rename-group.component.html',
    styleUrls: ['./rename-group.component.scss']
})
export class RenameGroupComponent {
    public group: Group;
    public renameGroupForm: FormGroup;
    private dialogRef: MatDialogRef<RenameGroupComponent>;
    private groupService: HttpGroupsService;
    private notificationService: NotificationService;
    private loggedInOrganizationService: LoggedInOrganizationService;
    private orginalGroup: Group;

    public constructor(dialogRef: MatDialogRef<RenameGroupComponent>,
                       groupService: HttpGroupsService,
                       loggedInOrganizationService: LoggedInOrganizationService,
                       notificationService: NotificationService,
                       @Inject(MAT_DIALOG_DATA) data) {
        this.dialogRef = dialogRef;
        this.groupService = groupService;
        this.loggedInOrganizationService = loggedInOrganizationService;
        this.notificationService = notificationService;
        this.orginalGroup = data.group;
        this.group = _.cloneDeep(this.orginalGroup);

        this.renameGroupForm = new FormGroup({
            name: new FormControl(this.group.name, Validators.required)
        });
    }

    public renameGroup(group: Group) {
        this.groupService.renameGroup(this.loggedInOrganizationService.getLoggedInOrganizationId(), group).subscribe(() => {
            this.orginalGroup.name = this.group.name;
            this.notificationService.show('GROUP RENAMED');
            this.dialogRef.close();
        });
    }

    public cancel(): void {
        this.dialogRef.close(null);
    }

    public keyDownFunction(event) {
        if (event.keyCode === 13) {
            if (this.renameGroupForm.valid) {
                this.renameGroup(this.group);
            }
        }
    }
}
