export class Scorm {
    private _pathId: string;
    private _status: number;
    private _downloadUri: string;
    private _workingName: string;

    constructor(pathId: string, status: number, downloadUri: string, workingName: string) {
        this._pathId = pathId;
        this._status = status
        this._downloadUri = downloadUri;
        this._workingName = workingName;
    }
    get pathId(): string {
        return this._pathId;
    }
    get status(): number {
        return this._status;
    }
    get downloadUri(): string {
        return this._downloadUri;
    }
    get workingName(): string {
        return this._workingName;
    }

    set workingName(workingName: string) {
        this._workingName = workingName;
    }
}