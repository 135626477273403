import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TOAST_TYPES } from "app/application/constants";
import {
  IEditPathData,
  ILearningPath,
  ILearningPathData,
} from "app/application/interfaces/learning-paths.interface";
import { ToastService } from "app/application/toast/toast.service";
import { EventBusService } from "app/core/event-bus.service";
import { LearningPathFormComponent } from "../../forms/learning-path-form/learning-path-form.component";
import { ACTIONS } from "../../learning-path.actions";
import { LearningPathService } from "../../services/learning-path.service";


@Component({
  selector: "app-learning-path-create-dialog",
  templateUrl: "./learning-path-create-dialog.component.html",
  styleUrls: ["./learning-path-create-dialog.component.scss"],
})
export class LearningPathCreateDialogComponent implements OnInit {


  // initilizing is used for pass data to the form after getting it from server
  isFormInitializing = false;

  // loading is used for making request after form submit
  isFormLoading = false;
  mode = 0;
  editMode = false;
  learningPathId = "";
  learningPathIndex = 0;
  isFormDisabled = false;
  currentStep = 0;
  isWorkingNameFilled = false;
  isNamesControlsFilled = false;

  learningPath: ILearningPath;

  @ViewChild("formComponent") pathForm: LearningPathFormComponent;

  constructor(
    private learningPathsService: LearningPathService,
    private eventService: EventBusService,
    private dialogRef: MatDialogRef<LearningPathCreateDialogComponent>,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: IEditPathData,
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.editMode = this.data.editMode;
      this.learningPathId = this.data.learningPathId;
      this.learningPathIndex = this.data.learningPathIndex;
    }

    if (this.editMode) {
      this.isFormDisabled = true;
      this.getLearningPath();
    }

  }
  toggleShow(mode: number) {
    this.mode = (mode);
  }

  public close(): void {
    this.dialogRef.close(true);
  }

  getLearningPath() {
    this.isFormInitializing = true;

    this.learningPathsService
      .getLearningPathDetails(this.learningPathId)
      .subscribe((learningPath) => {
        this.learningPath = learningPath;
        this.isFormInitializing = false;
      });
  }

  onWorkingNameFilledChange(value: boolean) {
    this.isWorkingNameFilled = value
  }

  onNamesControlsFilled(value: boolean) {
    this.isNamesControlsFilled = value;
  }

  nextButtonClick() {
    if (this.pathForm.stepper) {
      this.pathForm.stepper.next();
    }
  }
  previousButtonClick() {
    if (this.pathForm.stepper) {
      this.pathForm.stepper.previous();
    }
  }

  submitForm() {
    this.pathForm.submitLearningPathForm();
  }

  disableFormState() {
    this.isFormDisabled = true;
    this.pathForm.formDisable();
  }

  enableFormState() {
    this.isFormDisabled = false;
    this.pathForm.formEnable();
  }

  switchFormState() {
    if (this.isFormDisabled) {
      this.enableFormState();
    } else {
      this.disableFormState();
    }
  }

  formSubmitHandler(formData: ILearningPathData) {
    if (this.editMode) {
      const updatedPath: ILearningPath = { ...this.learningPath, ...formData };
      this.updateLearningPath(updatedPath);
    } else {
      this.createLearningPath(formData);
    }
  }

  updateLearningPath(learningPath: ILearningPath) {
    this.isFormLoading = true;
    this.learningPathsService
      .updateLearningPath(this.learningPathId, learningPath)
      .subscribe(
        (updatedLearningPath) => {
          /**
           * TODO: FIX BACKEND RESPONSE
           *
           * We retrieving empty learning modules list from backend, when updating a learning path
           * here we can save list by passing an old one into updated learning path object
           */
          const updatedPathWithModules: ILearningPath = {
            ...updatedLearningPath,
            learningModules: learningPath.learningModules,
          };

          this.isFormLoading = false;
          this.eventService.sendEvent(ACTIONS.LEARNING_PATH_UPDATE, {
            learningPath: updatedPathWithModules,
            index: this.learningPathIndex,
          });
          this.toastService.show("Learning path successfully updated!");
          this.disableFormState();
        },
        (error: HttpErrorResponse) => {
          this.isFormLoading = false;
          this.toastService.show(error.message, TOAST_TYPES.ERROR);
        },
      );
  }

  createLearningPath(data: ILearningPathData) {
    this.isFormLoading = true;
    this.learningPathsService.createLearningPath(data).subscribe(
      (newLearningPath) => {
        this.isFormLoading = false;
        this.eventService.sendEvent(
          ACTIONS.LEARNING_PATH_CREATE,
          newLearningPath,
        );
        this.toastService.show("Learning path successfully created!");
        this.dialogRef.close(true);
      },
      (error: HttpErrorResponse) => {
        this.isFormLoading = false;
        this.toastService.show(error.message, TOAST_TYPES.ERROR);
      },
    );
  }
}
