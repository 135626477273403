import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ILanguages } from 'app/application/interfaces/courses.interface';
import { HttpLanguageService } from 'app/infrastructure/http/language/httplanguage.service';

@Component({
  selector: 'app-learning-path-names',
  templateUrl: './learning-path-names.component.html',
  styleUrls: ['./learning-path-names.component.scss']
})
export class LearningPathNamesComponent implements OnInit {
  @Input() shouldDisableButton = true
  @Input() errorsList: { [key: string]: boolean | string[] } = {}
  @Input() languageNameGroup: FormGroup
  @Output() onDelete: EventEmitter<boolean> = new EventEmitter()

  isLanguagesLoading = false
  languages: ILanguages[] = []

  get languageId(): FormControl {
    return this.languageNameGroup.get('languageId') as FormControl
  }

  get isUniqueError(): boolean {
    const { value, touched } = this.languageId
    const uniqueLanguages = this.errorsList?.uniqueLanguages || []

    let isEqual = false

    if (Array.isArray(uniqueLanguages)) {
      isEqual = value && uniqueLanguages && uniqueLanguages.includes(value)
    }

    return !!isEqual && touched
  }

  constructor(private languagesService: HttpLanguageService) {}

  ngOnInit(): void {
    this.getLanguages()
  }

  onDeleteHandler() {
    this.onDelete.emit(true)
  }

  getLanguages() {
    this.isLanguagesLoading = true
    this.languagesService.getLanguages().subscribe((languages) => {
      this.languages = languages
      this.isLanguagesLoading = false
    })
  }
}
