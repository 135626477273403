import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[digitsOnly]'
})
export class DigitsOnlyDirective {
    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste',
    ];

    inputElement: HTMLInputElement;

    constructor(private el: ElementRef) {
        console.log('DigitsOnlyDirective:constructor');
        this.inputElement = el.nativeElement;
    }

    @HostListener('beforeinput', ['$event'])
    onBeforeInput(e: InputEvent): any {
      if (isNaN(Number(e.data))) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

    @HostListener('keydown', ['$event']) 
    onKeyDown(event) {
        let e = <KeyboardEvent>event;
        if (
            // Allow: Delete, Backspace, Tab, Escape, Enter, etc
            this.navigationKeys.indexOf(e.key) > -1 ||
            (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
            (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
            (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
            (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
            (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
            (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
            (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
            (e.key === 'x' && e.metaKey === true) // Cmd+X (Mac)
        ) {
            return;  // let it happen, don't do anything
        }

        // Ensure that it is a number and stop the keypress
        if (e.key === ' ' || isNaN(Number(e.key))) {
            e.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();

        // get a digit-only string
        const pastedInput: string = event.clipboardData.getData('text/plain').replace(/\D/g, ''); 

        const selection = window.getSelection();

        if (!selection.rangeCount) 
            return;

        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(pastedInput));
        selection.collapseToEnd();
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
        event.preventDefault();

        const textData = event.dataTransfer.getData('text/plain').replace(/\D/g, '');

        this.inputElement.focus();

        const selection = window.getSelection();

        if (!selection.rangeCount) 
            return;

        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(textData));
        selection.collapseToEnd();
    }
}