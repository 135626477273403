import { Component, Input } from '@angular/core';
import { EventBusService } from 'app/core/event-bus.service';
import { Scorm } from 'app/domain/scorm';
import { ACTIONS } from 'app/features/learning-path/learning-path.actions';

@Component({
  selector: 'app-notifications-scorm-popover',
  templateUrl: './notifications-scorm-popover.component.html',
  styleUrls: ['./notifications-scorm-popover.component.scss']
})
export class NotificationsScormPopoverComponent {
  @Input() generatedScorms: Scorm[] = [];

  constructor(private eventService: EventBusService) { }

  downloadScorm(downloadUri: string) {
    window.open(downloadUri, '_self');
  }

  deleteNotificationScorm(id: string) {
    const scorm = this.generatedScorms.find(scorm => scorm.pathId === id);
    if (scorm !== undefined) {
      
      const idx = this.generatedScorms.findIndex(scorm => scorm.pathId === id);
      this.generatedScorms.splice(idx, 1);

      this.eventService.sendEvent(ACTIONS.LEARNING_MODULE_SCORM_REMOVED, scorm);
    }
  }
  
}
