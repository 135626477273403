import { Component, ElementRef } from "@angular/core";
import { IHeaderAngularComp } from "ag-grid-angular";
import { IAfterGuiAttachedParams, IHeaderParams } from "ag-grid-community";
import { HeaderCourseNameParams } from "./header-course-name-params";

@Component({
  templateUrl: "header-course-name.component.html",
  styleUrls: ["./header-course-name.component.scss"],
})
export class HeaderCourseNameComponent implements IHeaderAngularComp {
  public params: HeaderCourseNameParams;
  public courseLevel: number;
  public sorted: string;
  private elementRef: ElementRef;

  public constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  refresh(params: IHeaderParams): boolean {
    return true;
    //throw new Error("Method not implemented.");
  }

  public agInit(params: HeaderCourseNameParams): void {
    this.params = params;
    this.courseLevel = params.courseLevel;
  }

  public afterGuiAttached(params?: IAfterGuiAttachedParams): void {}
}
