import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TOAST_TYPES } from "../../../../application/constants";
import {
  IEditModuleData,
  ILearningModuleCopyData,
  ILearningModuleCreated,
  ILearningModuleCreationData,
  ILearningModuleListItem,
} from "../../../../application/interfaces/learning-paths.interface";
import { ToastService } from "../../../../application/toast/toast.service";
import { EventBusService } from "../../../../core/event-bus.service";
import { LearningModuleFormComponent } from "../../forms/learning-module-form/learning-module-form.component";
import { ACTIONS } from "../../learning-path.actions";
import { LearningModulesService } from "../../services/learning-modules.service";
import { LearningModuleDeleteDialogComponent } from "../learning-module-delete-dialog/learning-module-delete-dialog.component";

@Component({
  selector: "app-learning-module-create-dialog",
  templateUrl: "./learning-module-create-dialog.component.html",
  styleUrls: ["./learning-module-create-dialog.component.scss"],
})
export class LearningModuleCreateDialogComponent implements OnInit {
  // initilizing is used for pass data to the form after getting it from server
  isFormInitializing = false;

  // loading is used for making request after form submit
  isFormLoading = false;

  editMode = false;
  learningPathId = "";
  learningPathIndex = 0;
  learningModuleId = "";
  learningModuleIndex = 0;
  lockedByModuleList: ILearningModuleListItem[] = []
  mode = 0;
  isFormDisabled = false;
  currentStep = 0;
  isWorkingNameFilled = false;
  courseIdSelected = '';
  estimatedTimeInSecondsFilled = 0;
  translationsFormInvalid: boolean = false;

  learningModule: ILearningModuleCreated;

  @ViewChild("formComponent") moduleForm: LearningModuleFormComponent;

  constructor(
    private learningModulesService: LearningModulesService,
    private eventService: EventBusService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<LearningModuleCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEditModuleData
  ) { }

  ngOnInit() {
    if (this.data) {
      this.editMode = this.data.editMode;
      this.learningPathId = this.data.learningPathId;
      this.learningModuleId = this.data.learningModuleId;
      this.learningPathIndex = this.data.learningPathIndex;
      this.learningModuleIndex = this.data.learningModuleIndex;
      this.lockedByModuleList = this.data.lockedByModuleList;
    }

    if (this.editMode) {
      this.isFormDisabled = true;
      this.getLearningModule();
    }
  }

  handleInvalidStatusChanged(invalid: boolean) {
    this.translationsFormInvalid = invalid;
  }

  onWorkingNameFilledChange(value: boolean) {
    this.isWorkingNameFilled = value
  }

  onCourseIdChange(courseId: string) {
    this.courseIdSelected = courseId;
  }

  onEstimatedTimeInSecondsChange(estimatedTimeInSeconds: number) {
    this.estimatedTimeInSecondsFilled = estimatedTimeInSeconds;
  }

  public close(): void {
    this.dialogRef.close(true);
  }

  nextButtonClick() {
    if (this.moduleForm.stepper) {
      this.moduleForm.stepper.next();
    }
  }
  previousButtonClick() {
    if (this.moduleForm.stepper) {
      this.moduleForm.stepper.previous();
    }
  }

  submitForm() {
    this.moduleForm.submitLearningModuleForm()
  }

  toggleShow(mode: number) {
    this.mode = (mode);
  }
  disableFormState() {
    this.isFormDisabled = true;
    this.moduleForm.formDisable();
  }

  enableFormState() {
    this.isFormDisabled = false;
    this.moduleForm.formEnable();
  }

  switchFormState() {
    if (this.isFormDisabled) {
      this.enableFormState();
    } else {
      this.disableFormState();
    }
  }

  formSumbitHandler(formData) {
    if (this.editMode) {
      const updatedModule = { ...this.learningModule, ...formData };
      this.updateLearningModule(updatedModule);
    } else {
      this.createLearningModule(formData);
    }
  }

  getLearningModule() {
    this.isFormInitializing = true;

    this.learningModulesService
      .getLearningModuleDetails(this.learningPathId, this.learningModuleId)
      .subscribe((learningModule: ILearningModuleCreated) => {
        this.learningModule = learningModule;
        this.isFormInitializing = false;
      });
  }

  updateLearningModule(data: ILearningModuleCreated) {
    this.isFormLoading = true;
    this.learningModulesService
      .updateLearningModule(this.learningPathId, this.learningModuleId, data)
      .subscribe(
        (updatedLearningModule) => {
          this.isFormLoading = false;
          this.learningModule = updatedLearningModule;
          this.eventService.sendEvent(
            ACTIONS.LEARNING_MODULE_UPDATE,
            {
              updatedLearningModule,
              learningModuleIndex: this.learningModuleIndex,
              learningPathIndex: this.learningPathIndex
            }
          );
          this.toastService.show("Learning module successfully updated!");
          this.disableFormState();
        },
        (error: HttpErrorResponse) => {
          if (error?.error === 'learning_module_already_exists') {
            this.toastService.show('Learning module already exists', TOAST_TYPES.ERROR);
            this.setError('workingName', { notUnique: true })
          } else {
            this.toastService.show(error.message, TOAST_TYPES.ERROR);
          }
          this.isFormLoading = false;
        }
      );
  }

  createLearningModule(data: ILearningModuleCreationData) {
    this.isFormLoading = true;
    this.learningModulesService.createLearningModule(this.learningPathId, data).subscribe(
      (newLearningModule) => {
        this.isFormLoading = false;
        this.eventService.sendEvent(
          ACTIONS.LEARNING_MODULE_CREATE,
          {
            newLearningModule,
            learningPathIndex: this.learningPathIndex
          }
        );
        this.toastService.show("Learning module successfully created!");
        this.dialogRef.close(true);
      },
      (error: HttpErrorResponse) => {
        this.isFormLoading = false;
        this.toastService.show(error.message, TOAST_TYPES.ERROR);
      }
    );
  }

  openDeleteDialog() {
    const dialogRef = this.dialog.open(LearningModuleDeleteDialogComponent)
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.deleteLearningModule()
        this.dialogRef.close()
      }
    })
  }

  deleteLearningModule() {
    const { workingName, id, orderIndex } = this.learningModule

    const learningPath = {
      id: this.learningPathId,
      index: this.learningPathIndex
    }

    const learningModule = {
      id,
      workingName,
      orderIndex,
      index: this.learningModuleIndex
    }

    const data: ILearningModuleCopyData = { learningPath, learningModule }
    this.eventService.sendEvent(ACTIONS.LEARNING_MODULE_DELETE, data)
  }

  setError(fieldName: string, error: { [key: string]: boolean }) {
    this.moduleForm.learningModuleForm.controls[fieldName].setErrors(error)
  }
}