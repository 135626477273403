import { Component, Inject, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { DataService } from "app/infrastructure/dataservice";
import { HttpUserService } from "app/infrastructure/http/user/httpuser.service";


function passwordMatchValidator(
    control: AbstractControl
): { [key: string]: boolean } | null {
    const newPassword = control.get("newPassword");
    const confirmPassword = control.get("confirmPassword");

    if (newPassword.value !== confirmPassword.value) {
        confirmPassword.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
    }

    return null;
}

@Component({
    selector: "app-user-password",
    styleUrls: ["./user-password.component.scss"],
    templateUrl: "user-password.component.html",
})
export class UserPasswordComponent implements OnInit {
    passwordForm: FormGroup;

    passwordChanged: boolean = false;
    passwordChangeError: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<UserPasswordComponent>,
        private fb: FormBuilder,
        private dataService: DataService,
        private userService: HttpUserService,
        @Inject(MAT_DIALOG_DATA) private userId: string
    ) { }

    ngOnInit() {
        this.passwordForm = this.fb.group(
            {
                newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/) ]] ,
                confirmPassword: ['', Validators.required],
            },
            { validator: passwordMatchValidator }
        );
    }

    cancel() {
        this.dialogRef.close();
    }

    submitForm() {
        const submitButton = document.querySelector('button[type="submit"]') as HTMLButtonElement;
        submitButton.click();
    }

    public onSubmit() {
        if (this.passwordForm.invalid)
            return;

        const newPassword = this.passwordForm.get("newPassword").value;

        this.userService
            .updatePassword(this.dataService.organizationID, this.userId, newPassword)
            .subscribe(
                () => {
                    this.passwordChanged = true;
                    this.passwordChangeError = false;
                },
                () => {
                    this.passwordChanged = false;
                    this.passwordChangeError = true;
                }
            );
    }
}
