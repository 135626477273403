import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { TOAST_TYPES } from "app/application/constants";
import { NotificationService } from "app/application/notification/notification.service";
import { ToastService } from "app/application/toast/toast.service";
import { Course } from "app/domain/course";
import { Group } from "./../../domain/group";
import { DataService } from "app/infrastructure/dataservice";
import { HttpCourseService } from "app/infrastructure/http/course/httpcourse.service";
import { HttpGroupsService } from "./../../infrastructure/http/group/httpgroups.service";
import { ConfirmationDialogComponent } from "app/presentation/confirmation-dialog/confirmation-dialog.component";
import { AddGroupComponent } from "app/presentation/pages/users/add-group/add-group.component";
import { GroupDetailComponent } from "app/presentation/pages/users/groups/group-detail/group-detail.component";
import * as _ from "lodash";
import { filter, switchMap } from "rxjs/operators";
import { ILearningPathGroupLink } from "./../../application/interfaces/learning-paths.interface";

@Component({
  selector: "app-courses-groups",
  templateUrl: "./courses-groups.component.html",
  styleUrls: ["./courses-groups.component.scss"],
})
export class CoursesGroupsComponent implements OnInit, OnChanges {
  @Input() selectedLearningPaths: string[] = [];
  @Input() learningPathMode = false;

  @Input() selectedCourses: Course[] = [];
  
  public groups: Group[] = [];
  public filteredGroups: Group[];
  public scrollItems: Group[] = [];
  public filteredText = "";
  public learingPathGroupLinks: ILearningPathGroupLink[] = [];
  public loadingGroups: false;
  private dialogConfig: MatDialogConfig = {
    height: "250px",
    width: "506px",
  };

  constructor(
    private groupService: HttpGroupsService,
    private courseService: HttpCourseService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedCourses &&
      changes.selectedCourses.currentValue?.length !==
        changes.selectedCourses.previousValue?.length
    ) {
      this.sortGroupsBySelectedCourses();
    }
    if(changes.selectedLearningPaths &&
      changes.selectedLearningPaths.currentValue?.length !==
        changes.selectedLearningPaths.previousValue?.length)
      {
        this.sortGroupsBySelectedCourses();
      }
  }

  public filterGroups(event: string): void {
    this.filteredText = event;
    if (_.isEmpty(this.filteredText)) {
      this.filteredGroups = this.groups;
    } else {
      this.filteredGroups = _.filter(this.groups, (group: Group) => {
        let result = false;
        _.forOwn(group, (value: string, key) => {
          if (
            key !== "_id" &&
            _.includes(_.lowerCase(value), _.lowerCase(this.filteredText))
          ) {
            result = true;
          }
        });
        return result;
      });
    }
  }

  public openDialogForGuide(): void {
    const config = new MatDialogConfig();
    config.height = "675px";
    config.width = "1029px";
    config.data = {
      group: this.scrollItems[0],
      shouldShowLearningPaths: this.learningPathMode,
    };
    this.dialog.open(GroupDetailComponent, config);
  }

  public getNumberOfSelectedCourses(){
    if(this.learningPathMode){
      return this.selectedLearningPaths.length;
    }
    return this.selectedCourses.length;
  }

  public getNumberOfSelectedCoursesForGroup(group: Group): number {
    if(this.learningPathMode){
      return _.size(
        _.filter(this.selectedLearningPaths, (lp: string) => {
          return this.learingPathGroupLinks.find(link=>link.groupId == group.id && link.learningPathId == lp)
        })
      );
    }
    return _.size(
      _.filter(this.selectedCourses, (course: Course) => {
        return _.includes(group.courseIDs, course.id);
      })
    );
  }

  public canAddGroupToSelectedCourses(group: Group): boolean {
    if (this.learningPathMode) {
      return this.getSelectedLearningPathsToAdd(group, this.selectedLearningPaths)?.length !== 0
    }

    return (
      this.getSelectedCoursesToAdd(group, this.selectedCourses)?.length !== 0
    );
  }

  linkPathsToGroup(group: Group) {
    const dialogData: MatDialogConfig = {
      width: this.dialogConfig.width,
      data: {
        textContent: `Link selected learning paths to group ${group.name}?`,
        confirmText: "LINK",
        cancelText: "CANCEL",
      },
    };
    const selection = this.selectedLearningPaths.slice();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogData);
    dialogRef
      .afterClosed()
      .pipe(
        filter((result) => result),
        switchMap(() =>
          this.groupService.linkToGroup(group.id, selection)
        )
      )
      .subscribe(
        () => {
          this.toastService.show(
            "Learningpaths were succesfully linked to the group"
          );
          this.learingPathGroupLinks = this.learingPathGroupLinks.concat(selection.map(x=>{
            const link : ILearningPathGroupLink = {
              learningPathId : x,
              groupId : group.id
            }
            return link}))
        },
        (error: HttpErrorResponse) => {
          this.toastService.show(error.message, TOAST_TYPES.ERROR);
        }
      );
  }

  unlinkPathsFromGroup(group: Group) {
    const dialogData: MatDialogConfig = {
      width: this.dialogConfig.width,
      data: {
        textContent: `Unlink selected learning paths from group ${group.name}?`,
        confirmText: "UNLINK",
        cancelText: "CANCEL",
      },
    };
    const selection = this.selectedLearningPaths.slice();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogData);
    dialogRef
      .afterClosed()
      .pipe(
        filter((result) => result),
        switchMap(() =>
          this.groupService.unlinkFromGroup(
            group.id,
            selection
          )
        )
      )
      .subscribe(
        () => {
          console.log("before: " + JSON.stringify(this.learingPathGroupLinks));
          this.learingPathGroupLinks = this.learingPathGroupLinks.filter((link: ILearningPathGroupLink) =>
          {
            return link.groupId != group.id || !selection.some(s=>s == link.learningPathId);
          });
          console.log("after: " + JSON.stringify(this.learingPathGroupLinks));

          this.toastService.show(
            "Learning paths was succesfully unlinked from the group"
          );


        },
        (error: HttpErrorResponse) => {
          this.toastService.show(error.message, TOAST_TYPES.ERROR);
        }
      );
  }

  public addGroupToSelectedCourses(group: Group): void {
    if (this.learningPathMode) {
      this.linkPathsToGroup(group);
    } else {
      const selectedCoursesToAdd: Course[] = this.getSelectedCoursesToAdd(
        group,
        this.selectedCourses
      );
      this.translate
        .get("ADD GROUP TO SELECTED COURSES", { groupName: group.name })
        .subscribe((translation: string) => {
          const currentDialogConfig: MatDialogConfig = {
            width: this.dialogConfig.width,
            data: {
              textContent: translation,
              summaryContent: _.join(
                _.map(selectedCoursesToAdd, (course: Course) => course.name),
                "\n"
              ),
              confirmText: "ADD",
              cancelText: "DO NOT ADD",
            },
          };
          const dialogRef: MatDialogRef<ConfirmationDialogComponent> =
            this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
          dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
              this.courseService
                .addGroupToCourses(group, selectedCoursesToAdd)
                .subscribe((courseIds: string[]) => {
                  _.each(selectedCoursesToAdd, (course: Course) => {
                    group.courseIDs.push(course.id);
                  });
                  this.notificationService.show(
                    "ADDED GROUP TO SELECTED COURSES"
                  );
                });
            }
          });
        });
    }
  }

  public getNumberOfCoursesToRemoveGroupFrom(group: Group): number
  {
    if(this.learningPathMode){
      return _.size(this.getSelectedLearningPathsToRemove(group, this.selectedLearningPaths));
    }
    return _.size(this.getSelectedCoursesToRemove(group, this.selectedCourses));
  }

  public getNumberOfCoursesToAddGroupTo(group: Group): number {
    if(this.learningPathMode){
      return _.size(this.getSelectedLearningPathsToAdd(group, this.selectedLearningPaths));
    }
    return _.size(this.getSelectedCoursesToAdd(group, this.selectedCourses));
  }

  public canRemoveGroupFromSelectedCourses(group: Group): boolean {
    if (this.learningPathMode) {
      return this.getSelectedLearningPathsToRemove(group, this.selectedLearningPaths)?.length !== 0
    }

    return (
      this.getSelectedCoursesToRemove(group, this.selectedCourses)?.length !== 0
    );
  }

  public removeGroupFromSelectedCourses(group: Group): void {
    if (this.learningPathMode) {
      this.unlinkPathsFromGroup(group);
    } else {
      const selectedCoursesToRemove: Course[] = this.getSelectedCoursesToRemove(
        group,
        this.selectedCourses
      );
      this.translate
        .get("REMOVE GROUP FROM SELECTED COURSES", { groupName: group.name })
        .subscribe((translation: string) => {
          const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
            width: this.dialogConfig.width,
            data: {
              textContent: translation,
              summaryContent: _.join(
                _.map(selectedCoursesToRemove, (course: Course) => course.name),
                "\n"
              ),
              confirmText: "REMOVE",
              cancelText: "DO NOT REMOVE",
            },
          };
          const dialogRef: MatDialogRef<ConfirmationDialogComponent> =
            this.dialog.open(ConfirmationDialogComponent, currentDialogConfig);
          dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
              this.courseService
                .removeGroupFromCourses(group, selectedCoursesToRemove)
                .subscribe(() => {
                  _.each(selectedCoursesToRemove, (course: Course) => {
                    group.courseIDs.splice(
                      group.courseIDs.indexOf(course.id),
                      1
                    );
                  });
                  this.notificationService.show(
                    "REMOVED GROUP FROM SELECTED COURSES"
                  );
                });
            }
          });
        });
    }
  }

  public showGroupProfile(group: Group): void {
    const config = new MatDialogConfig();
    config.height = "675px";
    config.width = "1029px";
    config.data = {
      group: group,
      shouldShowLearningPaths: this.learningPathMode,
    };
    this.dialog.open(GroupDetailComponent, config);
  }

  public canAddSelectedCoursesToNewGroup(): boolean {
    return !_.isEmpty(this.selectedCourses);
  }

  public addSelectedCoursesToNewGroup(): void {
    const config = new MatDialogConfig();
    config.height = "392px";
    config.width = "506px";
    const dialogRef: MatDialogRef<AddGroupComponent> = this.dialog.open(
      AddGroupComponent,
      config
    );
    dialogRef.afterClosed().subscribe((addedGroup: Group) => {
      if (!_.isNil(addedGroup)) {
        this.courseService
          .addGroupToCourses(
            addedGroup,
            this.getSelectedCoursesToAdd(addedGroup, this.selectedCourses)
          )
          .subscribe(() => {
            this.reload(true);
            _.each(this.selectedCourses, (course: Course) => {
              addedGroup.courseIDs.push(course.id);
            });
          });
      }
    });
  }

  private reload(refresh: boolean = false): void {

    this.groupService
      .getGroups(this.dataService.organizationID, refresh)
      .subscribe((groups: Group[]) => {
        this.groups = groups;
        this.filteredGroups = groups;
      });
    this.groupService
    .getLearningPathGroupLinks()
    .subscribe((grouplinks: ILearningPathGroupLink[])=>{
      this.learingPathGroupLinks = grouplinks;
    });
  }

  private getSelectedLearningPathsToAdd(group: Group, selectedLearningpaths: string[]): string[]{
    return selectedLearningpaths.filter(x=> !this.learingPathGroupLinks.find(link=>link.groupId == group.id && link.learningPathId == x));
  }
  private getSelectedLearningPathsToRemove(group: Group, selectedLearningpaths: string[]): string[]{
    return selectedLearningpaths.filter(x=> this.learingPathGroupLinks.find(link=>link.groupId == group.id && link.learningPathId == x));
  }
  private getSelectedCoursesToAdd(
    group: Group,
    selectedCourses: Course[]
  ): Course[] {
    return selectedCourses.filter(
      (course: Course) => !group.courseIDs?.includes(course.id)
    );
  }

  private getSelectedCoursesToRemove(
    group: Group,
    selectedCourses: Course[]
  ): Course[] {
    return _.filter(selectedCourses, (course: Course) => {
      return _.includes(group.courseIDs, course.id);
    });
  }

  private sortGroupsBySelectedCourses(): void {
    if(this.learningPathMode){
      this.filteredGroups = _.sortBy(this.filteredGroups, (group: Group)=>{
        return this.getSelectedLearningPathsToAdd(group, this.selectedLearningPaths).length !== 0;
      })
    }
    else{
      this.filteredGroups = _.sortBy(this.filteredGroups, (group: Group) => {
        return (
          this.getSelectedCoursesToAdd(group, this.selectedCourses).length !== 0
        );
      });
    }
  }
}
