import {User} from './user';

export class Group {
    private _id: string;
    private _name: string;
    private _description: string;
    private _userIDs: string[];
    private _users: User[];
    private _usersNotPlayed: number;
    private _usersInProgress: number;
    private _usersCompleted: number;
    private _playEnvironmentIDs: string[];
    private _courseIds: string[];

    constructor(id: string, name: string, description: string, userIDs: string[], playEnvironmentIDs: string[], courseIds: string[]) {
        this._id = id;
        this._name = name;
        this._description = description;
        this._userIDs = userIDs;
        this._playEnvironmentIDs = playEnvironmentIDs;
        this._courseIds = courseIds;
        if(!this._courseIds){
          this._courseIds = [];
        }
        if(!this._userIDs){
          this._userIDs = [];
        }
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get userIDs(): Array<string> {
        return this._userIDs;
    }

    set userIDs(value: Array<string>) {
        this._userIDs = value;
    }

    get courseIDs(): Array<string> {
        return this._courseIds;
    }

    set courseIDs(value: Array<string>) {
        this.courseIDs = value;
    }

    get users(): User[] {
        return this._users;
    }

    set users(value: User[]) {
        this._users = value;
    }


    get usersNotPlayed(): number {
        return this._usersNotPlayed;
    }

    set usersNotPlayed(value: number) {
        this._usersNotPlayed = value;
    }

    get usersInProgress(): number {
        return this._usersInProgress;
    }

    set usersInProgress(value: number) {
        this._usersInProgress = value;
    }

    get usersCompleted(): number {
        return this._usersCompleted;
    }

    set usersCompleted(value: number) {
        this._usersCompleted = value;
    }


    get playEnvironmentIDs(): string[] {
        return this._playEnvironmentIDs;
    }

    set playEnvironmentIDs(value: string[]) {
        this._playEnvironmentIDs = value;
    }
}
