import {Injectable} from '@angular/core';
import { DataService } from '../dataservice';

@Injectable()
export class LoggedInOrganizationService {


    public constructor(private dataService: DataService) {
       
    }

    public getLoggedInOrganizationId(): string {
        return this.dataService.organizationID;
    }
}
