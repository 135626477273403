<section fxFlex fxLayout="row" fxLayoutAlign="space-around center">
    <article  fxLayout="column" fxLayoutAlign="space-around center" class="popup" fxLayoutGap="39px">
        <img src="./assets/img/upgrade-dashboard.png"/>
        <h4>{{'feature.title' | translate}}</h4>
        <p [innerHTML]="'feature.subtitle' | translate"></p>

        <a mat-flat-button color="primary" href="mailto:support@playitsafe.eu?subject=I want an upgrade&body=Dear Sir,%0D%0A%0D%0A
I would like to request more information about possible upgrades for our company">{{'feature.upgrade' | translate}}</a>
    </article>

</section>
