<article class="legenda" fxLayoutAlign="end" fxLayout="row" fxLayoutGap="16px">
	<div fxLayout="column" fxLayoutGap="16px">
		<div id="legenda-progress" class="antialiased" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="20px">
			<span class="legenda-title">{{title}}</span>
			<div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="space-around center">
				<div class="user-progress not-started"></div>
				<span>{{'NOT STARTED' | translate}}</span>
			</div>
			<div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="space-around center">
				<div class="user-progress in-progress"></div>
				<span>{{'IN PROGRESS' | translate}}</span>
			</div>
			<div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="space-around center">
				<div class="user-progress completed"></div>
				<span>{{'COMPLETED' | translate}}</span>
			</div>
			<div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="space-around center">
				<div class="user-progress expired"></div>
				<span>{{'EXPIRED' | translate}}</span>
			</div>
		</div>
	</div>
</article>
