import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';

import { LoggedInOrganizationService } from '../../../infrastructure/helper/logged-in-organization.service';
import { HttpOrganizationService } from 'app/infrastructure/http/organization/httporganization.service';
import { DeactivateUsersSpeadsheetValidationResult } from 'app/domain/deactivate-users-spreadsheet-validation-result';

@Component({
  templateUrl: './deactivate-users-dialog.component.html',
  styleUrls: ['./deactivate-users-dialog.component.scss']
})
export class DeactivateUsersDialogComponent implements OnInit {
  private _organizationId: string;
  private _selectedFile: File;
  private _templateLocation: any;
  private _isImporting: boolean;
  private _isImported: boolean;
  private _validationResults: DeactivateUsersSpeadsheetValidationResult;
  private _isValidated: boolean;

  constructor(
    public dialogRef: MatDialogRef<DeactivateUsersDialogComponent>,
    private loggedInOrganizationService: LoggedInOrganizationService,
    private organizationService: HttpOrganizationService,
    private translate: TranslateService) { }

  async ngOnInit(): Promise<void> {
    this._organizationId = this.loggedInOrganizationService.getLoggedInOrganizationId();
    this._templateLocation = await this.organizationService
                                          .getTemplateUrlForImportingUsersForOrganization(this._organizationId)
                                          .toPromise();
  }

  public selectFile(event): void {
    const files: FileList = event.currentTarget.files;
    if (_.size(files) === 1) {
      this._selectedFile = _.first(files);
    }
  }

  public importFile(): void {
    this._isImporting = true;

    const formData: FormData = new FormData();
    formData.append('file', this._selectedFile, this._selectedFile.name);


    this.organizationService.deactivateUsers(this._organizationId, formData)
      .subscribe((validationResults: DeactivateUsersSpeadsheetValidationResult) => {
        this._isImporting = false;
        this._validationResults = validationResults;
        this._isImported = this._validationResults.isValid;
      });
  }

  public close(): void {
    if (this._isImported) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
  }

  public get templateLocation(): string {
    return this._templateLocation;
  }

  public get downloadFileName(): string {
    const dateString = new DatePipe('en-US').transform(Date.now(), 'dd/MM/yyyy');
    const fileName = 'SpreadSheetTemplate-' + dateString + '.xlsx';
    return fileName;
  }

  public get selectedFilename(): string {
    return (!_.isNil(this._selectedFile))
              ? this._selectedFile.name
              : this.translate.instant('users_deactivate.no_spreadsheet_selected');
}

  public get isSelected(): boolean {
    return !_.isNil(this._selectedFile);
  }

  public get validationResults() {
    return this._validationResults;
  }

  public get warnings(): string[] {
    return this._validationResults.warnings || [];
  }

  public get hasWarnings(): boolean {
    return !_.isEmpty(this._validationResults.warnings);
  }

  public get errors(): string[] {
    return this._validationResults.errors;
  }

  public get hasErrors(): boolean {
    return !_.isEmpty(this._validationResults.errors);
  }

  public get isImporting(): boolean {
    return this._isImporting;
  }

  public get isValidated(): boolean {
    return !_.isNil(this._validationResults);
  }

  public get isValid(): boolean {
    return this.isValidated && this._validationResults.isValid;
  }
  public get isImported(): boolean {
    return this._isImported;
  }
}
