<div class="lmt__form">
  <ng-container *ngFor="let formGroup of translationsFormArray.controls; let i = index">
    <div [formGroup]="formGroup">
      <div class="lmt__descriptions">
        <div class="lmt__flex">
          <div class="lmt__select-wrapper">
            <ng-select class="lmt__select-language" [items]="languages" [loading]="isLanguagesLoading"
              [searchable]="false" [ngClass]="{
                'custom-error':
                  translationsFormArray.hasError('uniqueLanguages') &&
                  translationsFormArray.errors.uniqueLanguages.includes(
                    formGroup.get('languageId').value
                  )
              }" appendTo="body" bindLabel="name" bindValue="id" placeholder="Choose the language"
              formControlName="languageId" [(ngModel)]="formGroup.get('languageId').value">
            </ng-select>
            <div *ngIf="
                formGroup.get('languageId').hasError('required') &&
                formGroup.get('languageId').touched
              " class="lmt__select-error">
              Language is <strong>required</strong>
            </div>
            <div *ngIf="
                translationsFormArray.hasError('uniqueLanguages') &&
                translationsFormArray.errors.uniqueLanguages.includes(
                  formGroup.get('languageId').value
                )
              " class="lmt__select-error">
              Language should be <strong>unique</strong>
            </div>
          </div>

          <mat-form-field class="lmt__name">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error> Name is <strong>required</strong> </mat-error>
          </mat-form-field>
          <mat-form-field class="lmt__description">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="4"
              cdkAutosizeMaxRows="15"></textarea>
            <mat-error> Description is <strong>required</strong> </mat-error>
          </mat-form-field>
        </div>


        <app-button *ngIf="translationsFormArray.length > 1" class="lmt__button-delete" shape="icon"
          (onButtonClick)="removeLanguageGroup(i)" [disabled]="translationsFormArray.disabled">
          <mat-icon class="lmt__button-delete-icon" svgIcon="learning-path-delete"></mat-icon>
        </app-button>
      </div>
    </div>
  </ng-container>

  <app-button class="lmt__button" shape="raised" color="primary" (onButtonClick)="addLanguageGroup()"
    [disabled]="translationsFormArray.disabled">
    <mat-icon class="lmt__button-icon">add</mat-icon> add new language
  </app-button>
</div>
