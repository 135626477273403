<section fxFlex fxLayout="column" xmlns="http://www.w3.org/1999/html">
  <header mat-dialog-title class="primary-color">
    <button class="close-button" mat-icon-button (click)="close()">
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>

    <h3>{{'users_deactivate.dialog_title' | translate}}</h3>
  </header>
  <mat-dialog-content fxLayout="column" fxLayoutGap="26px" class="import-users-container">
    <article fxLayout="column" fxLayoutGap="26px">
      <p >
        <a [href]="templateLocation" [download]="downloadFileName">
          {{'users_deactivate.template_location' | translate}}
        </a>
        {{'users_deactivate.template_hint' | translate}}
      </p>

      <p fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
        <input #fileUpload type="file" onclick="value = null" style="display:none"
               (change)="selectFile($event)"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">

        <span fxFlex>{{selectedFilename}}</span>

        <button  fxFlex="none" mat-flat-button color="primary" (click)="fileUpload.click()">
          {{'users_deactivate.select_file' | translate}}
        </button>
      </p>

      <p *ngIf="isImporting" fxLayout="column" class="uploading-spreadsheet"
         fxLayoutAlign="center center" fxLayoutGap="16px">
        {{'general.uploading' | translate: {attribute: 'users_deactivate.selected_file' | translate | lowercase} }}
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </p>

      <article *ngIf="isValidated"  fxLayout="columns" fxLayoutGap="16px">
        <header *ngIf="!isValid">
          {{'users_deactivate.errors' | translate}}
        </header>
        <header *ngIf="isValid && hasWarnings">
          {{'users_deactivate.valid_warnings' | translate: validationResults }}
        </header>
        <header *ngIf="isValid && !hasWarnings">
          {{'users_deactivate.valid' | translate: validationResults }}
        </header>

        <p *ngIf="hasWarnings" fxLayout="row" fxLayoutGap="16px">
          <mat-icon class="icon-warning">warning</mat-icon>
          <mat-list fxLayout="column" class="feedback-list" fxFlex>
            <mat-list-item *ngFor="let warning of warnings">{{warning}}</mat-list-item>
          </mat-list>
        </p>

        <p *ngIf="hasErrors" fxLayout="row" fxLayoutGap="16px">
          <mat-icon class="icon-error">error</mat-icon>
          <mat-list fxLayout="column" class="feedback-list" fxFlex>
            <mat-list-item *ngFor="let error of errors">{{error}}</mat-list-item>
          </mat-list>
        </p>
      </article>

    </article>

  </mat-dialog-content>

  <mat-dialog-actions fxLayoutGap="10px" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
    <button fxFlex="25%" *ngIf="!isImported" mat-button (click)="close()" class="mat-secondary">
      {{'CANCEL' | translate}}
    </button>

    <button fxFlex="25%" mat-button *ngIf="isImported" [disabled]="isImporting" (click)="close()" class="mat-secondary">
      {{'CLOSE' | translate}}
    </button>

    <button fxFlex="25%" mat-flat-button
            color="primary" [disabled]="isImported || isImporting || ! isSelected" (click)="importFile()">
      {{'common.import' | translate}}
    </button>
  </mat-dialog-actions>

</section>
