<div mat-dialog-title>
  <h2 class="title">{{course.name}}</h2>
  <app-button
    mat-dialog-close
    shape="icon"
  >
    <mat-icon>close</mat-icon>
  </app-button>
</div>

<mat-dialog-content class="dialog-content">
  <mat-tab-group class="courses-dialog__tabs">

    <mat-tab label="Info">
      <app-available-courses-info-tab [courseId]="courseId"></app-available-courses-info-tab>
    </mat-tab>

    <mat-tab label="Settings">
      <app-available-courses-settings-tab
        [isExpirationCourseSettingsEnabled]="isExpirationSettingsEnabled"
        [course]="course"
      ></app-available-courses-settings-tab>
    </mat-tab>

  </mat-tab-group>
</mat-dialog-content>
