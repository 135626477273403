import { AfterViewInit, Component, Input, OnChanges, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TProcessedUser } from "app/application/interfaces/licenses.interface";

@Component({
  selector: 'app-license-users-table',
  templateUrl: './license-users-table.component.html',
  styleUrls: ['./license-users-table.component.scss']
})
export class LicenseUsersTableComponent implements OnChanges, AfterViewInit {

  @Input() tableData: TProcessedUser[] = []
  @Input() licensesUsed = 0
  @Input() licensesLeft = 0

  defaultColumns: string[] = [
    'name',
    'job',
    'email',
  ]

  displayedColumns: string[] = []

  shouldDisplayExpiration = false

  dataSource: MatTableDataSource<TProcessedUser>

  @ViewChild(MatSort) sort: MatSort

  constructor() {
    this.dataSourceInit()
  }

  ngOnChanges() {
    this.dataSourceInit()
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort
  }

  dataSourceInit() {
    this.displayedColumns = this.defaultColumns

    if (this.tableData[0]?.expirationDate) {
      this.shouldDisplayExpiration = true
      this.displayedColumns = [...this.displayedColumns, 'expirationDate']
    }

    this.dataSource = new MatTableDataSource(this.tableData)
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
