import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
	selector: 'app-filter',
	templateUrl: './app-filter.component.html',
	styleUrls: ['./app-filter.component.scss']
})
export class FilterComponent {
	public filteredText = '';
	@Output() filteredTextChanged: EventEmitter<string> = new EventEmitter();
	@Input() placeholder = 'Find by';


	public filterChanged(newValue: string): void {
		this.filteredText = newValue;
		this.filteredTextChanged.emit(this.filteredText);
	}

	public clear(): void {
	    this.filterChanged('');
    }

}
