import { PersistentStoreService } from './helper/persistentstore.service';
import { Organization } from "../domain/organization";
import { User } from "../domain/user";
import { EventEmitter, Injectable } from "@angular/core";

@Injectable()
export class DataService {
  public userID: string = "";
  public userRole: number = 0
  public organizationID: string = "";
  public organizationName: string = "";
  public authexpiration: string;
  public CurrentOrganization: Organization;
  public AuthenticatedUser: User;
  public PreferredLanguage: string;
  public features: string[];

  public organizationChangedEvent: EventEmitter<void>;

  constructor(private persistentstoreService: PersistentStoreService) {
    this.userID = this.persistentstoreService.get("loggedInUserId");
    this.organizationID = this.persistentstoreService.get("loggedInOrganizationId");
    this.organizationName = this.persistentstoreService.get("organizationName");
    this.authexpiration = this.persistentstoreService.get("authExpiration");
    this.userRole = +this.persistentstoreService.get('userRole');
    const featuresStr = this.persistentstoreService.get('features');
    this.features = featuresStr ? JSON.parse(featuresStr) : [];
    this.organizationChangedEvent = new EventEmitter<void>();
  }

  public setOrganizationWithFeatures(organization: Organization, features: string[]) {
    this.setOrganization(organization);
    this.setFeatures(features);
    this.organizationChangedEvent.emit();
  }

  public setOrganization(organization: Organization) {
    this.CurrentOrganization = organization;
    this.organizationID = organization.id;
    this.persistentstoreService.set(
      "loggedInOrganizationId",
      organization.id,
      this.authexpiration
    );
    this.persistentstoreService.set(
      "organizationName",
      organization.name,
      this.authexpiration
    );
  }

  public setFeatures(features: string[]) {
    this.features = features;
    const featuresStr = JSON.stringify(features);
    this.persistentstoreService.set('features', featuresStr);
  }

  get authToken(): string {
    return this.persistentstoreService.get("jwtToken");
  }

  get language(): string {
    if (this.AuthenticatedUser?.language) {
      return this.AuthenticatedUser.language
    }

    if (this.CurrentOrganization?.language) {
      return this.CurrentOrganization.language
    }

    if (this.PreferredLanguage) {
      return this.PreferredLanguage;
    }

    return null;
  }

  public clearData() {
    this.AuthenticatedUser = null;
    this.CurrentOrganization = null;
    this.userID = "";
    this.organizationID = "";
    this.userRole = 0;
    this.features = [];
  }
}
