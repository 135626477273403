<div class="user-licenses__container">

  <div class="user-licenses__header">
    <h2 class="user-licenses__title">License details</h2>
    <button type="button" mat-icon-button mat-dialog-close>
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>
  </div>

  <div *ngIf="!isDataLoading; else spinner" class="user-licenses__content">

    <div *ngIf="license; else noLicense" class="user-licenses__blocks">

      <div class="user-licenses__block">
        <div class="user-licenses__block-info">
          <h4 class="user-licenses__block-title">{{licenseType}} license</h4>
          <p class="user-licenses__block-text">
            Expiration date:
            <span class="bold">
              {{license.expirationDate ? (license.expirationDate | date:'dd.MM.yyyy') : (license.effectiveExpirationDate | date:'dd.MM.yyyy')}}
            </span>
          </p>
        </div>

        <div class="user-licenses__block-controls" [matTooltip]="disabledReason" matTooltipPosition="left"
          [matTooltipDisabled]="license.expirationDate">
          <button mat-flat-button type="button" color="primary" class="user-licenses__block-button"
            (click)="renewLicense(license.blockId, license.id)" [disabled]="!license.expirationDate">
            <mat-spinner *ngIf="isRenewLoading; else renewText" [diameter]="18"></mat-spinner>
            <ng-template #renewText>Renew</ng-template>
          </button>
        </div>
      </div>

    </div>

    <ng-template #noLicense>
      <p [hidden]="IsUnlicensedOrganization">That user has no license</p>
      <p [hidden]="!IsUnlicensedOrganization">Organization is not using license blocks.</p>
    </ng-template>
  </div>

  <ng-template #spinner>
    <div class="loading-status">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </ng-template>

</div>
