<section fxFlex fxLayout="column" (keypress)="keyDownFunction($event)">
	<header *ngIf="isShowCloseButton" class="primary-color">
		<button class="close-button" mat-icon-button type="button" mat-dialog-close>
			<mat-icon class="icon-clear">clear</mat-icon>
		</button>
	</header>
	<mat-dialog-content fxFlex fxLayout="column" class="information-dialog-container">
		<h5>{{textContent | translate}}</h5>
		<p [ngClass]="{'centered-content': isAlignCenterContent}">{{descriptionContent | translate}}</p>
	</mat-dialog-content>
	<mat-dialog-actions fxLayoutGap="10px">
		<button fxFlex mat-flat-button color="primary" mat-dialog-close>{{(buttonContent | translate) || 'OK'}}
		</button>
	</mat-dialog-actions>
</section>
