import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as _ from "lodash";
import { NotificationService } from "../../application/notification/notification.service";
import { Group } from "../../domain/group";
import { User } from "../../domain/user";
import { LoggedInOrganizationService } from "../../infrastructure/helper/logged-in-organization.service";
import { HttpReminderService } from "../../infrastructure/http/reminder/httpreminder.service";
import { ReceiverTypeForReminder } from "./receiver-type-for-reminder";

@Component({
  templateUrl: "./send-reminder-dialog.component.html",
  styleUrls: ["./send-reminder-dialog.component.scss"],
})
export class SendReminderDialogComponent implements OnInit {
  public numberOfUsersApplicableForReminder = 0;
  public counto;
  public receiver: Group | User[];
  receiverTypeForReminder: ReceiverTypeForReminder;
  private dialogRef: MatDialogRef<SendReminderDialogComponent>;
  private reminderService: HttpReminderService;
  private notificationService: NotificationService;
  private loggedInOrganizationService: LoggedInOrganizationService;
  private organizationId: string;

  receiverGroupName

  public constructor(
    dialogRef: MatDialogRef<SendReminderDialogComponent>,
    reminderService: HttpReminderService,
    notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) data,
    loggedInOrganizationService: LoggedInOrganizationService
  ) {
    this.dialogRef = dialogRef;
    this.reminderService = reminderService;
    this.notificationService = notificationService;
    this.loggedInOrganizationService = loggedInOrganizationService;

    this.receiverTypeForReminder = data.receiverTypeForReminder;
    this.receiver = data.receiver;
    this.receiverGroupName = (this.receiver as Group).name
  }

  public isReceiverTypeOfGroup(): boolean {
    return this.receiverTypeForReminder === ReceiverTypeForReminder.GROUP
  }

  public isReceiverTypeOfAll(): boolean {
    return _.isEqual(this.receiverTypeForReminder, ReceiverTypeForReminder.ALL);
  }

  public isReceiverTypeOfUser(): boolean {
    return _.isEqual(
      this.receiverTypeForReminder,
      ReceiverTypeForReminder.USER
    );
  }

  public ngOnInit(): void {
    this.organizationId =
      this.loggedInOrganizationService.getLoggedInOrganizationId();

    this.reminderService
      .getNumberOfUsersApplicableForReminder(this.organizationId, this.receiver)
      .subscribe((numberOfUsersApplicableForReminder: number) => {
        this.numberOfUsersApplicableForReminder = <number>(
          numberOfUsersApplicableForReminder
        );
      });
  }

  public canSendReminder(): boolean {
    return !_.isEqual(this.numberOfUsersApplicableForReminder, 0);
  }

  public confirm(): void {
    this.reminderService
      .sendReminderForUncompleteCourses(this.organizationId, this.receiver)
      .subscribe(() => {
        this.notificationService.show("reminder.sent_notification");
        this.dialogRef.close(true);
      });
  }

  public cancel() {
    this.dialogRef.close(false);
  }

  public keyPressed(event: KeyboardEvent) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.confirm();
    }
  }
}
