<div class="loading-status" *ngIf="isCourseInfoLoading; else formContainer">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>

<ng-template #formContainer>
  <app-available-courses-info-form
    #form
    [isFormLoading]="isFormLoading"
    [courseInfoFormData]="courseInfo"
    (onSubmit)="formSubmitHandler($event)"
  ></app-available-courses-info-form>
</ng-template>
