<section fxFlex fxLayout="column">
	<header mat-dialog-title class="primary-color">
		<button class="close-button" mat-icon-button (click)="cancel()">
			<mat-icon class="icon-clear">clear</mat-icon>
		</button>

		<h3>{{'RENAME GROUP' | translate}}</h3>
	</header>
	<mat-dialog-content fxFlex fxLayout="column" fxLayoutAlign="center center" class="add-group-container">
		<form [formGroup]="renameGroupForm" (keydown)="keyDownFunction($event)">
			<div class="input-row">
				<mat-form-field class="full-width">
					<mat-error *ngIf="renameGroupForm.controls.name.hasError('required')">{{'NAME IS REQUIRED' | translate}}
					</mat-error>
					<mat-error *ngIf="renameGroupForm.controls.name.hasError('groupNameDuplicate')">
						{{'add_new_group.name_duplicate' | translate}}</mat-error>
					<input matInput placeholder="{{'NAME' | translate}}" [(ngModel)]="group.name" formControlName="name"
						class="add-group-name" appValidatorGroupName>
				</mat-form-field>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions fxLayoutGap="10px">
		<button fxFlex mat-button (click)="cancel()" class="mat-secondary">{{'CANCEL' | translate}}</button>
		<button fxFlex mat-flat-button color="primary" (click)="renameGroup(group)"
			[disabled]="!renameGroupForm.valid">{{'group_overview.rename_group' | translate}}
		</button>
	</mat-dialog-actions>
</section>
