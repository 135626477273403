import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { GroupsComponent } from "./groups/groups.component";
import { PeopleComponent } from "./people/people.component";
import { UsersComponent } from "./users.component";
import { ReplaySubject, Subscription } from "rxjs";
import { OnboardingService } from "../../../infrastructure/http/onboarding/onboarding.service";


@Component({
  selector: "app-users",
  templateUrl: "./groupsnav.component.html",
  styleUrls: ["./users.component.scss"],
})
export class GroupsnavComponent implements OnInit, OnDestroy {
  public groupTabIsActive = true;
  public peopleTabIsActive = false;
  public isOpenSelect = new ReplaySubject<boolean>();
  public isOpenSelectGroup = new ReplaySubject<boolean>();

  private guides = { users: "", groups: "" };

  private userSteps = [
    "UsersGroups1Step",
    "SelectUsersStep",
    "SelectUserStep",
    "SearchUserStep",
    "UserStatusStep",
    "SelectActionStep",
    "AddUsersStep",
    "ImportUsersStep",
    "SendReminderStep",
    "AddUserToGroupStep",
    "SearchGroupUserStep",
    "LinkUserStep",
  ];

  private groupSteps = [
    "UsersGroups2Step",
    "SelectGroupStep",
    "SearchGroupStep",
    "GroupStatusStep",
    "SelectActionGroupStep",
    "AddGroupsStep",
    "SendReminderGroupStep",
    "RenameGroupStep",
    "RemoveGroupStep",
    "SendGroupStep",
    "NewUserGroupStep",
    "SearchUserGroupStep",
    "LinkGroupStep",
  ];

  private subscriptionOnboarding: Subscription;

  public constructor(private onboarding: OnboardingService) {}

  ngOnInit() {
/*    this.checkPossibleGuide(); */
  }

  private checkPossibleGuide() {
    this.subscriptionOnboarding = this.onboarding
      .getOnboarding()
      .subscribe((res) => {
        for (const key in res) {
          if (
            res[key].name === "Users" &&
            this.peopleTabIsActive &&
            !res[key].isCompleted
          ) {
            this.guides.users = res[key].id;
            this.onboarding
              .openOnboardingDialog("user_guide", [], [], true)
              .subscribe(() => this.startOnboardingNavigation());
          } else if (
            res[key].name === "Groups" &&
            this.groupTabIsActive &&
            !res[key].isCompleted
          ) {
            this.guides.groups = res[key].id;
            this.onboarding
              .openOnboardingDialog("groups_guide", [], [], true)
              .subscribe(() => this.startOnboardingNavigation());
          }
        }
      });
  }

  public onTabChanged(event: MatTabChangeEvent) {
    this.peopleTabIsActive = event.index === 0;
    this.groupTabIsActive = event.index === 1;
   // this.checkPossibleGuide();
  }

  public startOnboardingNavigation(): void {
    if (this.peopleTabIsActive) {
      this.onboarding
        .startOnboardingNavigation(
          this.userSteps,
          "user_guide",
          [this.guides.users],
          true
        )
        .subscribe(
          (res) => {
            if (
              res.name === "AddUsersStep" ||
              res.name === "SendReminderStep" ||
              res.name === "AddUserToGroupStep" ||
              res.name === "SelectActionStep" ||
              res.name === "ImportUsersStep" 
            ) {
             
              this.isOpenSelect.next(true);
            } else {
              this.isOpenSelect.next(false);
            }
          },
          () => {},
          () => {
            if (this.guides.users) {
              this.onboarding.openFinishOnboardingDialog("user_guide", [
                this.guides.users,
              ]);
              this.guides.users = "";
            }
          }
        );
    } else {
      this.onboarding
        .startOnboardingNavigation(
          this.groupSteps,
          "groups_guide",
          [this.guides.groups],
          true
        )
        .subscribe(
          (res) => {
            if (
              res.name === "SelectActionGroupStep" ||
              res.name === "AddGroupsStep" ||
              res.name === "SendReminderGroupStep" ||
              res.name === "RenameGroupStep" ||
              res.name === "RemoveGroupStep" ||
              res.name === "SendGroupStep" ||
              res.name === "NewUserGroupStep" ||
              res.name === "SearchUserStep"
            ) {
              this.isOpenSelectGroup.next(true);
            } else {
              this.isOpenSelectGroup.next(false);
            }
          },
          () => {},
          () => {
            if (this.guides.groups) {
              this.onboarding.openFinishOnboardingDialog("groups_guide", [
                this.guides.groups,
              ]);
              this.guides.groups = "";
            }
          }
        );
    }
  }

  ngOnDestroy() {
    this.subscriptionOnboarding?.unsubscribe();
  }
}
