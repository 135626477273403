import { Injectable } from "@angular/core";
import { CookieOptions, CookieService } from "ngx-cookie";
import { PersistentStore } from "./persistentstore";

@Injectable()
export class PersistentStoreService implements PersistentStore {

	constructor(private cookieService: CookieService) {}

  public get(key: string): string {
    return this.cookieService.get(key);
  }

  public set(key: string, value: string, expires?: string): void {
    const options: CookieOptions = {};

		if (expires) {
      options.expires = expires;
    }

    this.cookieService.put(key, value, options);
  }

  public remove(key: string): void {
    this.cookieService.remove(key);
  }

  public getAll(): Object {
    return this.cookieService.getAll();
  }
}
