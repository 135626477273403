import {Component} from '@angular/core';
import {Group} from '../../../../../domain/group';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {GroupDetailComponent} from '../group-detail/group-detail.component';
import * as _ from "lodash";

@Component({
	selector: 'app-group-name',
	templateUrl: './group-name.component.html',
	styleUrls: ['./group-name.component.scss']
})
export class GroupNameComponent {

	private params: any;
	public group: Group;
	private dialog: MatDialog;

	constructor(dialog: MatDialog) {
		this.dialog = dialog;
	}

	agInit(params: any): void {
		this.params = params;
		this.group = params.data;
	}

	public showGroupProfile(group: Group): void {


		const config = new MatDialogConfig();
		config.height = '675px';
		config.width = '1029px';
		config.data = {
			group: group
		};
		this.dialog.open(GroupDetailComponent, config);
	}

}
