import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { map as rxjsMap } from "rxjs/operators";
import { CollectionCache } from "../../../domain/collectioncache";
import { Group } from "../../../domain/group";
import { PlayEnvironment } from "../../../domain/playEnvironment";
import { User } from "../../../domain/user";
import { Groups } from "./groups";
import { DataService } from "../../dataservice";
import { ILearningPath, ILearningPathGroupLink } from "./../../../application/interfaces/learning-paths.interface";

@Injectable()
export class HttpGroupsService implements Groups, CollectionCache {
  private httpClient: HttpClient;
  private groupsSubject: Subject<Group[]>;
  private groupsRequest: Observable<Group[]>;
  private loadingSubject: Subject<boolean>;

  headers = new HttpHeaders().set("apiVersion", "version2");

  constructor(httpClient: HttpClient, private dataService: DataService) {
    this.httpClient = httpClient;
    this.groupsSubject = new ReplaySubject(1);
    this.loadingSubject = new ReplaySubject(1);
  }

  public clear(): void {
    this.groupsRequest = null;
    this.groupsSubject.next([]);
  }

  public getGroups(
    organizationId: string,
    refresh: boolean = false
  ): Observable<Group[]> {
    if (refresh || !this.groupsRequest) {
      let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders.set("apiVersion", "version2");

      this.loadingSubject.next(true);
      this.groupsRequest = this.httpClient
        .get(
          "/Organizations/" +
            organizationId +
            "/Groups?expand=courseids,userids",
          { headers: httpHeaders }
        )
        .pipe(rxjsMap(this.mapGroups, this));
      this.groupsRequest.subscribe(
        (result) => {
          this.groupsSubject.next(result);
          this.loadingSubject.next(false);
        },
        (err) => this.groupsSubject.error(err)
      );
    }
    return this.groupsSubject.asObservable();
  }

  public isLoadingGroups(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  public getGroupsOfUser(
    organizationId: string,
    user: User
  ): Observable<Group[]> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");
    return this.httpClient
      .get(`/Organizations/${organizationId}/Users/${user.id}/Groups`, {
        headers: httpHeaders,
      })
      .pipe(rxjsMap(this.mapGroups, this));
  }

  public removeUsersFromGroup(
    organizationId: string,
    group: Group,
    userIDs: string[]
  ): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");
    return this.httpClient.post(
      `/Organizations/${organizationId}/groups/${group.id}/remove-users`,
      userIDs,
      { responseType: "text", headers: httpHeaders }
    );
  }

  public addUsersToGroup(
    organizationId: string,
    group: Group,
    users: User[]
  ): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    const userIDs = _.map(users, "id");
    return this.httpClient.post(
      `/Organizations/${organizationId}/groups/${group.id}/add-users`,
      userIDs,
      { responseType: "text", headers: httpHeaders }
    );
  }

  public getPlayEnvironmentsFromGroup(
    organizationId: string,
    group: Group
  ): Observable<string[]> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");
    return this.httpClient.get<string[]>(
      "/Organizations/" +
        organizationId +
        "/Groups/" +
        group.id +
        "/environments",
      { headers: httpHeaders }
    );
  }

  public addPlayEnvironmentsToGroup(
    organizationId: string,
    group: Group,
    playEnvironments: PlayEnvironment[]
  ): Observable<any> {
    const playEnvironmentsIDs = _.map(playEnvironments, "id");
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    return this.httpClient.post(
      "/Organizations/" +
        organizationId +
        "/Groups/" +
        group.id +
        "/environments",
      playEnvironmentsIDs,
      { responseType: "text", headers: httpHeaders }
    );
  }

  public removePlayEnvironmentFromGroup(
    organizationId: string,
    group: Group,
    environment: PlayEnvironment
  ): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    return this.httpClient.delete(
      "/Organizations/" +
        organizationId +
        "/Groups/" +
        group.id +
        "/environments/" +
        environment.id,
      { headers: httpHeaders, responseType: "text" }
    );
  }

  public addGroup(organizationId: string, group: Group): Observable<Group> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    return this.httpClient
      .post(
        `/Organizations/${organizationId}/groups`,
        {
          name: group.name,
          description: group.description,
        },
        { headers: httpHeaders }
      )
      .pipe(rxjsMap(this.toGroup));
  }

  public deleteGroup(organizationId: string, group: Group): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    return this.httpClient.delete(
      `/Organizations/${organizationId}/groups/${group.id}`,
      { responseType: "text", headers: httpHeaders }
    );
  }

  public renameGroup(organizationId: string, group: Group): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    return this.httpClient.patch(
      `/Organizations/${organizationId}/groups/${group.id}`,
      [
        {
          value: group.name,
          path: "name",
          op: "replace",
        },
      ],
      { headers: httpHeaders }
    );
  }

  public isGroupNameAvailable(
    organizationId: string,
    name: string
  ): Observable<boolean> {
    let httpHeaders = new HttpHeaders().set("apiVersion", "version2");
    return this.httpClient.get<boolean>(
      `/Organizations/${organizationId}/groups/available?name=${name}`,
      { headers: httpHeaders }
    );
  }

  private mapGroups(response: any): Group[] {
    return _.sortBy(
      _.map(response, (groupJSON) => {
        return this.toGroup(groupJSON);
      }),
      (group: Group) => {
        return _.lowerCase(group.name);
      }
    );
  }

  private toGroup(group: any): Group {
    let newGroup = new Group(
      group.id,
      group.name,
      group.description,
      group.userIds,
      group.environmentIDs,
      group.courseIds
    );
    return newGroup;
  }

  getLearningPathGroupLinks(){
    const { organizationID } = this.dataService;
    return this.httpClient.get<ILearningPathGroupLink[]>(
      `/v2/Organizations/${organizationID}/Groups/LearningPaths`,
      { headers: this.headers }
    );
  }

  getLinkedLearningPaths(groupId: string) {
    const { organizationID } = this.dataService;
    return this.httpClient.get<ILearningPath[]>(
      `/v2/Organizations/${organizationID}/Groups/${groupId}/LearningPaths`,
      { headers: this.headers }
    );
  }

  linkToGroup(groupId: string, learningPathIds: string[]) {
    const { organizationID } = this.dataService;
    return this.httpClient.post(
      `/v2/Organizations/${organizationID}/Groups/${groupId}/link-learning-paths`,
      { learningPathIds },
      { headers: this.headers }
    );
  }

  unlinkFromGroup(groupId: string, learningPathIds: string[]) {
    const { organizationID } = this.dataService;
    return this.httpClient.post(
      `/v2/Organizations/${organizationID}/Groups/${groupId}/unlink-learning-paths`,
      { learningPathIds },
      { headers: this.headers }
    );
  }
}
