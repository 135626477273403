<section class="switch-organization-dialog">

  <header class="switch-organization-dialog__header primary-color">
    <h5 class="switch-organization-dialog__title">
      {{ "switch_organization.switch" | translate }}
    </h5>

    <button class="close-button" mat-icon-button type="button" (click)="cancel()">
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>

    <form class="filter-form">
      <mat-form-field class="filter-full-width" appearance="fill">
        <mat-label>Filter organization</mat-label>
        <input matInput #filterOrg (keyup)="applyFilter(filterOrg.value)" placeholder="Organization name...">
      </mat-form-field>
    </form>

  </header>

  <mat-dialog-content class="switch-organization-dialog__container">

    <mat-spinner *ngIf="isDataLoading; else orgList" [diameter]="45"></mat-spinner>

    <ng-template #orgList>
      

      <mat-list class="switch-organization-dialog__list">
        <mat-list-item *ngFor="let organization of organizationsList">

          <div [matTooltip]="organization.disableReason | translate" matTooltipPosition="left"
            [matTooltipDisabled]="!organization.isDisabled" class="switch-organization-dialog__organization-wrapper">
            <app-button class="switch-organization-dialog__organization" [disabled]="organization.isDisabled"
              align="left" (onButtonClick)="switchToOrganization(organization)">
              {{ organization.name }}
            </app-button>
          </div>

        </mat-list-item>
      </mat-list>

    </ng-template>

  </mat-dialog-content>

</section>
