<div
  *ngIf="!learningPathsList?.length; else listWrapper"
  class="empty-list"
>
  <h4 class="empty-list__title">{{'learningpaths_module.none_in_list' | translate }}</h4>
  <p class="empty-list__subtitle">{{'learningpaths_module.create_new_path' | translate }}</p>
</div>

<ng-template #listWrapper>
  <div class="list-wrapper">
    <p class="on-off">Off/On</p>
    <div>
      <app-learning-path-item
        *ngFor="let item of learningPathsList; let i = index"
        [item]="item"
        [itemIndex]="i"
      ></app-learning-path-item>
    </div>
  </div>

</ng-template>
