import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { ToastService } from "app/application/toast/toast.service";
import { TOAST_TYPES } from "app/application/constants";
import { VirtualScrollerComponent } from "ngx-virtual-scroller";
import { Group } from "app/domain/group";
import { User } from "app/domain/user";
import { HttpGroupsService } from "app/infrastructure/http/group/httpgroups.service";
import { NotificationService } from "app/application/notification/notification.service";
import { LoggedInOrganizationService } from "app/infrastructure/helper/logged-in-organization.service";
import { DataService } from "app/infrastructure/dataservice";
import { ConfirmationDialogComponent } from "app/presentation/confirmation-dialog/confirmation-dialog.component";
import { AddGroupComponent } from "app/presentation/pages/users/add-group/add-group.component";
import { GroupDetailComponent } from "app/presentation/pages/users/groups/group-detail/group-detail.component";
import { forEach } from "lodash";

export interface IGroupNumbers {
  numberOfSelectedUsers: number
  numberOfUsersToRemove: number
  numberOfUsersToAdd: number
}

export interface IGroupNumbersMap {
  [key: string]: IGroupNumbers
}

@Component({
  selector: "app-users-groups",
  templateUrl: "./users-groups.component.html",
  styleUrls: ["./users-groups.component.scss"],
})
export class UsersGroupsComponent implements OnInit, OnChanges {
  public groups: Group[] = [];
  public filteredGroups: Group[];
  @ViewChild(VirtualScrollerComponent)
  public virtualScroll: VirtualScrollerComponent;
  @Input() selectedUsers: Array<User> = [];
  public filteredText = "";
  public scrollItems: Group[] = [];
  @Input() public isActive: boolean;

  @Output() onGroupsUpdate: EventEmitter<string> = new EventEmitter()

  private dialogConfig: MatDialogConfig = <MatDialogConfig>{
    height: "223px",
    width: "506px",
  };

  private groupNumbersMap: IGroupNumbersMap = {}

  private organizationId: string;

  public loadingGroups: boolean;

  constructor(
    private groupService: HttpGroupsService,
    private dialog: MatDialog,
    private notification: NotificationService,
    private loggedInOrganizationService: LoggedInOrganizationService,
    private translate: TranslateService,
    private dataService: DataService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.reload();
    this.organizationId = this.loggedInOrganizationService.getLoggedInOrganizationId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.groups.length) {
      this.refreshList();
      //this.sortGroupsBySelectedUsers();
      //console.log("ngOnChanges: FILTER");
      this.createGroupGridMap();
    }
  }

  public getNumberOfSelectedUsersInGroup(group: Group): number {
    return this.getGroupNumbersMapValue(group).numberOfSelectedUsers;
  }

  public getNumberOfUsersToRemoveFromGroup(group: Group): number {
    return this.getGroupNumbersMapValue(group).numberOfUsersToRemove;
  }

  public getNumberOfUsersToAddToGroup(group: Group): number {
    return this.getGroupNumbersMapValue(group).numberOfUsersToAdd;
  }

  public filtertextchanged(event: string): void {
    this.filteredText = event;
    this.filterGroups();
    //console.log("filtertextchanged: FILTER");
    //this.sortGroupsBySelectedUsers();
  }

  public filterGroups(): void {
    const filterValue = this.filteredText.toLowerCase()

    if (!filterValue) {
      this.filteredGroups = this.groups;
      for(let ig = 0; ig < this.filteredGroups.length; ig++)
      {
        //console.log("filteredGroups groups-users.component.ts: " + JSON.stringify(this.filteredGroups[ig]['_name']));
      }

    } else {
      //console.log("filteredGroups groups-users.component.ts: ELSE");
      this.filteredGroups = this.groups.filter((group: Group) => {
        const filteredName = group.name && group.name.toLowerCase().includes(filterValue)
        const filteredDescription = group.description && group.description.toLowerCase().includes(filterValue)
        return Boolean(filteredName || filteredDescription)
      });
    }
  }

  public canAddSelectedUsersToGroup(group: Group): boolean {
    return this.getGroupNumbersMapValue(group).numberOfUsersToAdd > 0;
  }

  public addSelectedUsersToGroup(group: Group): void {
    this.translate
      .get("ADD SELECTED USERS TO GROUP", { groupName: group.name })
      .subscribe((translation: string) => {
        const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
          width: this.dialogConfig.width,
          data: {
            textContent: translation,
            summaryContent: _.join(
              _.map(this.selectedUsers, (user: User) => user.name),
              "\n"
            ),
            confirmText: "ADD",
            cancelText: "DO NOT ADD",
          },
        };

        const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(
          ConfirmationDialogComponent,
          currentDialogConfig
        );

        dialogRef.afterClosed().subscribe((addSelectedUsers) => {
          if (addSelectedUsers) {
            this.loadingGroups = true

            this.groupService
              .addUsersToGroup(this.organizationId, group, this.selectedUsers)
              .subscribe(() => {
                const userIDsAdded = this.selectedUsers.map((user) => user.id);
                group.userIDs = [...new Set([...group.userIDs, ...userIDsAdded])];

                this.loadingGroups = false
                this.onGroupsUpdate.emit('Users added')
                this.notification.show("SELECTED USERS ADDED TO GROUP");
              }, (error) => {
                console.error(error)
                this.loadingGroups = false
                this.toastService.show('Something went wrong', TOAST_TYPES.ERROR)
              });
          }
        });
      });
  }

  public getTooltipForAddUsersToGroup(group: Group): string {
    return (
      "Add " +
      this.getGroupNumbersMapValue(group).numberOfUsersToAdd +
      " user(s) to group"
    );
  }

  public canRemoveSelectedUsersFromGroup(group: Group): boolean {
    return this.getGroupNumbersMapValue(group).numberOfUsersToRemove > 0;
  }

  public removeSelectedUsersFromGroup(group: Group): void {
    this.translate
      .get("REMOVE SELECTED USERS FROM GROUP", { groupName: group.name })
      .subscribe((translation: string) => {
        const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
          width: this.dialogConfig.width,
          data: {
            textContent: translation,
            summaryContent: _.join(
              _.map(this.selectedUsers, (user: User) => user.name),
              "\n"
            ),
            confirmText: "REMOVE",
            cancelText: "DO NOT REMOVE",
          },
        };
        const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(
          ConfirmationDialogComponent,
          currentDialogConfig
        );
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const selectedUsersToRemove = this.getSelectedUsersToRemove(group);
            const userIds = selectedUsersToRemove.map((user) => user.id)

            this.loadingGroups = true

            this.groupService
              .removeUsersFromGroup(
                this.organizationId,
                group,
                userIds
              )
              .subscribe(() => {
                group.userIDs = group.userIDs.filter((userId) => {
                  return !userIds.includes(userId)
                })

                this.loadingGroups = false
                this.onGroupsUpdate.emit('Users removed')

                this.notification.show("REMOVED SELECTED USERS OF GROUP");
              }, (error) => {
                console.error(error)
                this.loadingGroups = false
                this.toastService.show('Something went wrong', TOAST_TYPES.ERROR)
              });
          }
        });
      });
  }

  public getTooltipForRemoveUsersOfGroup(group: Group): string {
    return (
      "Remove " +
      this.getGroupNumbersMapValue(group).numberOfUsersToRemove +
      " user(s) from group"
    );
  }

  public addSelectedUsersToNewGroup(): void {
    const config = new MatDialogConfig();
    config.height = "392px";
    config.width = "506px";
    const dialogRef: MatDialogRef<AddGroupComponent> = this.dialog.open(
      AddGroupComponent,
      config
    );

    dialogRef.afterClosed().subscribe((addedGroup) => {
      if (addedGroup) {
        this.loadingGroups = true

        this.groupService
          .addUsersToGroup(this.organizationId, addedGroup, this.selectedUsers)
          .subscribe(() => {
            this.loadingGroups = false

            this.onGroupsUpdate.emit('Users added')
            this.reload(true);
          });
      }
    });
  }

  public reload(refresh: boolean = false): void {
    this.loadingGroups = true;
    this.groupService
      .getGroups(this.dataService.organizationID, refresh)
      .subscribe((groups: Array<Group>) => {
        this.groups = groups;
        this.filterGroups();
        this.createGroupGridMap();
        //this.sortGroupsBySelectedUsers();
        //console.log("reload: FILTER");
        if (this.isActive) {
          this.refreshList();
        }
        this.loadingGroups = false;
      });
  }

  public canAddNewGroup(): boolean {
    return !_.isEmpty(this.selectedUsers);
  }

  public showGroupProfile(group: Group): void {
    const config = new MatDialogConfig();
    config.height = "675px";
    config.width = "1029px";
    config.data = {
      group: group,
    };
    this.dialog.open(GroupDetailComponent, config);
  }

  private getGroupNumbersMapValue(group: Group): IGroupNumbers {
    return this.groupNumbersMap[group.id]
    || {
      numberOfSelectedUsers: 0,
      numberOfUsersToRemove: 0,
      numberOfUsersToAdd: 0,
    }
  }

  private getSelectedUsersToRemove(group: Group): User[] {
    return this.selectedUsers.filter((user: User) => group.userIDs.includes(user.id));
  }

  private sortGroupsBySelectedUsers(): void {
    console.log("DO CRAZY FILTER");
    this.filteredGroups.sort((groupA: Group, groupB: Group) => {
      const nameA = groupA.name.toLowerCase()
      const nameB = groupB.name.toLowerCase()

      const userIdInGroupA = groupA.userIDs.length
      const userIdInGroupB = groupB.userIDs.length

      if (userIdInGroupA !== 0 && userIdInGroupB === 0) {
        return -1
      }

      if (userIdInGroupA === 0 && userIdInGroupB !== 0) {
        return 1
      }

      if (userIdInGroupA && userIdInGroupB) {
        return userIdInGroupB - userIdInGroupA
      }

      if (nameA > nameB) {
        return 1
      }

      if (nameA < nameB) {
        return -1
      }

      return 0
    })
  }

  private createGroupGridMap(): void {
    this.groups.forEach((group: Group) => {
      const numberOfUsers = this.getSelectedUsersToRemove(group).length
      const numberOfUsersToAdd = this.selectedUsers.length - numberOfUsers

      this.groupNumbersMap[group.id] = {
        numberOfSelectedUsers: numberOfUsers,
        numberOfUsersToRemove: numberOfUsers,
        numberOfUsersToAdd: numberOfUsersToAdd,
      }
    });
  }

  private refreshList(): void {
    if (this.virtualScroll) {
      this.virtualScroll.refresh();
    }
  }
}
