import {ErrorHandler, Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor() {
	}

	public handleError(error) {
		throw error;
	}

}
