import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { StringComparator } from "../../../../application/command/stringcomparator";
import { User } from "../../../../domain/user";
import { HttpUserService } from "../../../../infrastructure/http/user/httpuser.service";
import { ConfirmationDialogComponent } from "../../../confirmation-dialog/confirmation-dialog.component";
import { ReceiverTypeForReminder } from "../../../reminder/receiver-type-for-reminder";
import { SendReminderDialogComponent } from "../../../reminder/send-reminder-dialog.component";
import { AddUserComponent } from "../add-user/add-user.component";
import { HeaderNameComponent } from "./header-name/header-name.component";
import { ImportUsersComponent } from "./import-users/import-users.component";
import { UserNameComponent } from "./name/user-name.component";
import { UserStatusComponent } from "./status/user-status.component";
import { DataService } from "../../../../infrastructure/dataservice";
import { MatMenuTrigger } from "@angular/material/menu";
import StatusEnum from "app/domain/statusEnum";
import { ToastService } from "app/application/toast/toast.service";
import { HttpErrorResponse } from "@angular/common/http";
import { TOAST_TYPES } from "app/application/constants";
import { GridOptions, ColDef, stringToArray, GridReadyEvent, GridApi } from "ag-grid-community";
import { DeactivateUsersDialogComponent } from "app/features/users/deactivate-users-dialog/deactivate-users-dialog.component";

@Component({
  selector: "app-people",
  templateUrl: "./people.component.html",
  styleUrls: ["./people.component.scss"],
})
export class PeopleComponent implements OnInit, OnDestroy {
  private gridApi : GridApi;

  columnDefs: ColDef[]
  rowData: any[]

  public users: User[] = null;
  public peopleGridOptions: GridOptions;
  public selectedUsers: User[] = [];

  @ViewChild("btnTrigger") public btnTrigger: MatMenuTrigger;
  @Input() isActive: boolean;
  @Input() isOpenSelect: ReplaySubject<boolean>;

  private dialogConfig: MatDialogConfig = <MatDialogConfig>{
    height: "250px",
    width: "506px",
  };

  private openSelectSubscription: Subscription;

  isOrganizationUnlicensed = false
  isActivateButtonsDisabled = false

  constructor(
    private userService: HttpUserService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private dataService: DataService,
    private toastService: ToastService
  ) {
    this.peopleGridOptions = <GridOptions>{
      rowSelection: "multiple",
      rowHeight: 65,
      headerHeight: 65,
      defaultColDef: {
        sortable: true,
        lockPosition: true,
      },
      onGridSizeChanged: () => this.resizeGrid(),
      onSelectionChanged: () => this.onSelectionChanged(),
      getRowId:(params)=> params.data.id,
      icons: {
        sortAscending: '',
        sortDescending: '',
      },

      columnDefs: [
        <ColDef>{
          headerName: "",
          width: 20,
          minWidth: 20,
          maxWidth: 20,
          checkboxSelection: false,
          sortable: false,
          suppressMenu: true,
          pinned: true,
          headerCheckboxSelection: false,
          headerCheckboxSelectionFilteredOnly: false,
        },
        <ColDef>{
          headerName: "",
          width: 40,
          minWidth: 40,
          maxWidth: 40,
          checkboxSelection: true,
          sortable: false,
          suppressMenu: true,
          pinned: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        <ColDef>{
          headerName: "NAME",
          field: "name",
          cellRendererFramework: UserNameComponent,
          comparator: StringComparator,
          headerComponentFramework: <new () => HeaderNameComponent>(
            HeaderNameComponent
          ),
        },
        <ColDef>{
          headerName: "JOB DESCRIPTION",
          field: "job",
          comparator: StringComparator,
          headerComponentFramework: <new () => HeaderNameComponent>(
            HeaderNameComponent
          ),
        },
        <ColDef>{
          headerName: "STATUS",
          field: "status",
          cellRendererFramework: UserStatusComponent,
          width: 150,
          minWidth: 150,
          maxWidth: 150,
          headerComponentFramework: <new () => HeaderNameComponent>(
            HeaderNameComponent
          ),
        },
        <ColDef>{
          headerName: "E-mail",
          field: "email",
          hide: true,
        },
        <ColDef>{
          headerName: "Department",
          field: "department",
          hide: true,
        },
        <ColDef>{
          headerName: "Role",
          field: "role_name",
          hide: true,
        }
      ],
      overlayNoRowsTemplate: "",
    };
  }


  ngOnInit() {
    this.isOrganizationUnlicensed = this.dataService.CurrentOrganization.isUnlicensed

    this.reload();
    this.openSelectSubscription = this.isOpenSelect
      .asObservable()
      .subscribe((res) => {
        if (res === true) {
          this.btnTrigger.openMenu();
        } else {
          this.btnTrigger.closeMenu();
        }
      });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
  }

  public isLoading(): Observable<boolean> {
    return this.userService.loadingSubject;
  }

  reload(refresh: boolean = false): void {
    this.users = [];
    this.userService.getUsers(refresh).subscribe((users: Array<User>) => {

      let selectedIds : Set<string> = new Set<string>();
      if(this.selectedUsers.length > 0)
      {
          (this.selectedUsers).forEach(element => {
            selectedIds.add(element.id);
          });
      }

      this.users = users;
      if(selectedIds.size>0)
      {
        this.gridApi.forEachNode(x=>x.setSelected(selectedIds.has(x.id!)));
      }
    });
  }

  onGroupsUpdateHandler() {
    this.selectedUsers = this.getSelectedRows();
  }

  getSelectedRows() {
    return this.gridApi.getSelectedRows() || []
  }

  reactivateUsers() {
    const summaryContent = this.selectedUsers
      .map((user) => user.name)
      .join('\n')

    const dialogConfig: MatDialogConfig = {
      width: this.dialogConfig.width,
      panelClass: 'confirmation-dialog',
      data: {
        textContent: `Are you sure you want to reactivate ${this.selectedUsers.length} user(s)?`,
        summaryContent,
        confirmText: 'Reactivate',
        cancelText: 'Cancel'
      }
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const usersId = this.selectedUsers.map((user) => user.id)
        this.userService.reactivateUsers(usersId)
          .subscribe(() => {
            this.selectedUsers.forEach((user) => user.status = StatusEnum.activated)
            this.gridApi.deselectAll()
          })
      }
    })
  }

  deactivateSelectedUsers() {
    const summaryContent = this.selectedUsers
      .map((user) => user.name)
      .join('\n')

    const dialogConfig: MatDialogConfig = {
      width: this.dialogConfig.width,
      panelClass: 'confirmation-dialog',
      data: {
        textContent: `Are you sure you want to deactivate ${this.selectedUsers.length} user(s)?`,
        summaryContent,
        confirmText: 'Deactivate',
        cancelText: 'Cancel'
      }
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const usersId = this.selectedUsers.map((user) => user.id)
        this.userService.deactivateUsers(usersId)
          .subscribe(() => {
            this.selectedUsers.forEach((user) => user.status = StatusEnum.deactivated)
            this.gridApi.deselectAll()
          })
      }
    })
  }

  activateNewLicense() {
    const summaryContent = this.selectedUsers
      .map((user) => user.name)
      .join('\n')

    const dialogConfig: MatDialogConfig = {
      width: this.dialogConfig.width,
      panelClass: 'confirmation-dialog',
      data: {
        textContent: `Are you sure you want to activate a new licenses for next user(s)?`,
        summaryContent,
        confirmText: 'Activate',
        cancelText: 'Cancel'
      }
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const usersId = this.selectedUsers.map((user) => user.id)
        this.userService.activateNewLicense(usersId)
          .subscribe(() => {
            this.toastService.show('License successfully activated')
            this.gridApi.deselectAll()
          }, (error: HttpErrorResponse) => {
            if (error.status === 400 && error.error === 'reached_license_cap') {
              this.toastService.show('You can not activate new license because you exceeded limit cap', TOAST_TYPES.ERROR)
            }
          })
      }
    })
  }

  public addUser(): void {
    const config = new MatDialogConfig();
    config.minHeight = "392px";
    config.width = "506px";
    const dialogRef: MatDialogRef<AddUserComponent> = this.dialog.open(
      AddUserComponent,
      config
    );

    dialogRef.afterClosed().subscribe(() => this.reload(true));
  }

  public sendReminder(): void {
    const config = new MatDialogConfig();
    config.height = "450px";
    config.data = {
      receiver: this.selectedUsers,
      receiverTypeForReminder: ReceiverTypeForReminder.USER,
    };
    this.dialog.open(SendReminderDialogComponent, config);
  }

  public filterUsers(event: string): void {
    this.gridApi.setQuickFilter(event);
  }

  public hasSelectedUsers(): boolean {
    return this.selectedUsers.length > 0;
  }

  public getNumberOfSelectedUsers(): number {
    return this.selectedUsers.length;
  }

  public removeSelectedUsers(): void {
    this.translate
      .get("user_overview.remove_selected_users_confirmation", {
        numberOfUsersToRemove: this.selectedUsers.length,
      })
      .subscribe((translation) => {
        const summaryContent = this.selectedUsers
          .map((user) => user.name)
          .join('\n')

        const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
          width: this.dialogConfig.width,
          panelClass: "confirmation-dialog",
          data: {
            textContent: translation,
            summaryContent,
            confirmText: "common.remove",
            cancelText: "DO NOT REMOVE",
          },
        };

        const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(
          ConfirmationDialogComponent,
          currentDialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.userService
              .deleteUsers(this.dataService.organizationID, this.selectedUsers)
              .subscribe(() => {
                this.selectedUsers = [];
                this.reload(true);
              });
          }
        });
      });
  }

  public importUsers(): void {
    const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
      width: "636px",
      maxHeight: "75%",
    };

    const dialogRef: MatDialogRef<ImportUsersComponent> = this.dialog.open(
      ImportUsersComponent,
      currentDialogConfig
    );
    dialogRef.afterClosed().subscribe((hasImportedNewUsers: boolean) => {
      if (hasImportedNewUsers) {
        this.reload(true);
      }
    });
  }

  public deactivateUsers(): void {
    const currentDialogConfig: MatDialogConfig = <MatDialogConfig>{
      width: "636px",
      maxHeight: "75%",
    };

    const dialogRef: MatDialogRef<DeactivateUsersDialogComponent> = this.dialog.open(
      DeactivateUsersDialogComponent,
      currentDialogConfig
    );
    dialogRef.afterClosed().subscribe((hasDeactivatedUsers: boolean) => {
      if (hasDeactivatedUsers) {
        this.reload(true);
      }
    });
  }

  private resizeGrid(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  private onSelectionChanged(): void {
    this.selectedUsers = this.getSelectedRows()
    this.isActivateButtonsDisabled = this.shouldDisableActivateButtons()
  }

  shouldDisableActivateButtons() {
    return this.selectedUsers.some((user) => (
      user.status !== StatusEnum.activated && user.status !== StatusEnum.deactivated
    ))
  }

  ngOnDestroy() {
    this.openSelectSubscription.unsubscribe();
  }
}
