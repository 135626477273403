import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Onboarding, IOnBoardingItem } from "./onboarding";
import { Observable } from "rxjs";
import { DataService } from "app/infrastructure/dataservice";

@Injectable()
export class HttpOnboardingService implements Onboarding {
  constructor(
    private httpClient: HttpClient,
    private dataService: DataService
  ) {}

  public getOnboarding(organizationID?: string): Observable<IOnBoardingItem[]> {
    const userID = this.dataService.userID;
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");
    return this.httpClient.get<IOnBoardingItem[]>(
      `/v2/Organizations/${organizationID}/Users/${userID}/Walkthroughs`,
      { headers: httpHeaders }
    );
  }

  public setOnboarding(
    organizationID: string,
    completedGuides: string[]
  ): Observable<Object> {
    const userID = this.dataService.userID;
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("apiVersion", "version2");

    return this.httpClient
    .post(`/v2/Organizations/${organizationID}/Users/${userID}/Walkthroughs/completed`, completedGuides, {headers: httpHeaders});
  }
}
