import { IToastTypes } from "./toast/toast.types"

export const APP_CONSTANTS = {
  EMAIL_NOT_FOUND: 'EmailNotFound',
  INCORRECT_PASSWORD: 'IncorrectPassword',
  AUTH_FAILED: 'Authentication failed',
  ROLE_ERROR: 'Invalid role',
  NO_VALID_LICENSES: 'valid_license_not_found',
  LICENSES_EXPIRED: 'license_expired',
  USER_ALREADY_ACCEPTED: 'User already has been activated.'
}

export const TOAST_TYPES: IToastTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export const SUPPORTED_LANGUAGES = ["en", "nl", "fr", "de", "es"]

export const ONE_MONTH_IN_MILLISECONDS = 2629800000

export const EXPIRATION_SETTING_VALUES = {
  USE_ORGANIZATION_SETTINGS: '6',
  SIX_MONTH: '1',
  ONE_YEAR: '2',
  TWO_YEARS: '3',
  NEVER: '4',
  CUSTOM: '5'
}
