<section fxFlex fxLayout="column" class="antialiased">
  <header mat-dialog-title class="primary-color">
    <button class="close-button" mat-icon-button mat-dialog-close>
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>
    <h4>{{ group.name }}</h4>
    <span>{{ usersOfGroup.length }} users, {{ coursesLength }} courses</span>
  </header>
  <mat-dialog-content fxFlex fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="center center"
    class="group-detail-container">
    <section class="group-users" fxFlex fxLayout="column" joyrideStep="UserBlockAtGroupDialog" stepPosition="left"
      title="{{ 'courses_guide_module.dialog_courses_user_title' | translate }}"
      text="{{ 'courses_guide_module.dialog_courses_user_hint' | translate }}">
      <header fxLayoutAlign="left center">
        <h4>{{ "USERS" | translate }}</h4>
      </header>

      <article fxFlex>

        <mat-spinner class="listspinner" strokeWidth="5" diameter="30" *ngIf="loadingusers"></mat-spinner>

        <mat-list *ngIf="!loadingusers && canShowUsersOfGroup()">
          <mat-list-item *ngFor="let user of usersOfGroup">
            <p mat-line>{{ user.name }}</p>
          </mat-list-item>
        </mat-list>

        <mat-list *ngIf="!loadingusers && !canShowUsersOfGroup()">
          <mat-list-item>
            <p mat-line>{{ "NO USERS AVAILABLE" | translate }}</p>
          </mat-list-item>
        </mat-list>
      </article>
    </section>

    <section *ngIf="!isDirectUserAssignments && !shouldShowLearningPaths" class="group-courses" fxFlex fxLayout="column"
      joyrideStep="CoursesBlockAtGroupDialog" stepPosition="left" title="{{
        'courses_guide_module.dialog_courses_group_title' | translate
      }}" text="{{ 'courses_guide_module.dialog_courses_group_hint' | translate }}">
      <header fxLayoutAlign="left center">
        <h4>{{ "COURSES" | translate }}</h4>
      </header>
      <article fxFlex>
        <mat-spinner class="listspinner" strokeWidth="5" diameter="30" *ngIf="loadingcourses"></mat-spinner>
        <mat-accordion [multi]="true" *ngIf="!loadingcourses && canShowCategoriesOfGroup()">
          <mat-expansion-panel #panel *ngFor="let category of categoriesOfGroup" [hideToggle]="true">
            <mat-expansion-panel-header fxLayout="row">
              <div class="accordion-title" fxFlex>
                <mat-icon>
                  <svg *ngIf="panel.expanded == true"  xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/></svg>
                  <svg  *ngIf="panel.expanded == false" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
                </mat-icon>
                <span class="course-name" [ngClass]="{ 'not-active': !category.isActive }">{{ category.name }}</span>
                <span class="information-active antialiased" *ngIf="!category.isActive"
                  [ngClass]="{ 'not-active': !category.isActive }">({{ "NOT ACTIVE" | translate }})</span>
              </div>
            </mat-expansion-panel-header>
            <mat-list>
              <mat-list-item *ngFor="let course of category.courses">
                <span [matTooltip]="
                    !category.isActive || !course.isActive ? course.name : ''
                  " class="course-name" [ngClass]="{
                    'not-active': !category.isActive || !course.isActive
                  }">{{ course.level }}. {{ course.name }}
                </span>
                <span class="information-active antialiased" *ngIf="!category.isActive || !course.isActive" [ngClass]="{
                    'not-active': !category.isActive || !course.isActive
                  }">({{ "NOT ACTIVE" | translate }})</span>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-list *ngIf="!loadingcourses && !canShowCategoriesOfGroup()">
          <mat-list-item>
            <p mat-line>{{ "NO COURSES AVAILABLE" | translate }}</p>
          </mat-list-item>
        </mat-list>
      </article>
    </section>

    <section *ngIf="shouldShowLearningPaths" class="group-users" fxFlex fxLayout="column">
      <header fxLayoutAlign="left center">
        <h4>{{"learningpaths_module.learning_paths" | translate}}</h4>
      </header>

      <article fxFlex>
        <mat-spinner class="listspinner" strokeWidth="5" diameter="30"
          *ngIf="isLearningPathsLoading; else learningPathContent"></mat-spinner>

        <ng-template #learningPathContent>
          <mat-list>
            <mat-list-item *ngIf="learningPathsList.length === 0; else pathList">
              <p mat-line>{{ "NO PATHS AVAILABLE" | translate }}</p>
            </mat-list-item>

            <ng-template #pathList>
              <mat-list-item *ngFor="let learningPath of learningPathsList">
                <p mat-line>{{ learningPath.workingName }}</p>
              </mat-list-item>
            </ng-template>
          </mat-list>
        </ng-template>
      </article>
    </section>

    <section class="group-playenvironments" fxFlex fxLayout="row">
      <app-group-play-environments [selectedGroup]="group" fxLayout="column" fxFill></app-group-play-environments>
    </section>
  </mat-dialog-content>
</section>

<script src="https://kit.fontawesome.com/99fe3c75dd.js" crossorigin="anonymous"></script>
