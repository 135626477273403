<div class="ag-cell-label-container" role="presentation">
  <div ref="eLabel" class="ag-header-cell-label" role="presentation">
    <div class="course-level">
			<mat-icon class="course-level-icon" svgIcon="course-level"></mat-icon>
			<span class="course-level__number">{{ courseLevel }}</span>
			<span
				ref="eText"
				class="ag-header-cell-text"
				role="columnheader"
				[matTooltip]="params.displayName"
			>
				{{ params.displayName | translate }}
			</span>
  	</div>
	</div>
</div>
