enum StatusEnum {
	invited = <any> 'Invited',
	problem = <any> 'Problem',
	accepted = <any> 'Accepted',
	activated = <any> 'Activated',
	playing = <any> 'Playing',
	deactivated = <any> 'Deactivated'
}

export default StatusEnum;
