<h3 class="license-table__title">
  {{'license_module.blocks_table_title' | translate}}
</h3>

<div class="license-table__container" *ngIf="tableData.length; else noData" #tableContainer>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="mat-elevation-z8"
  >

    <ng-container matColumnDef="licenseType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'license_module.blocks_table_column_type' | translate}}
      </th>
      <td mat-cell *matCellDef="let element"> {{element.licenseType}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'license_module.blocks_table_column_status' | translate}}
      </th>
      <td mat-cell *matCellDef="let element">
        <div [class]="element.status | lowercase">
          {{element.status | translate}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'license_module.blocks_table_column_purchase' | translate}}
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.creationDate | date:'dd.MM.yyyy'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="expirationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'license_module.blocks_table_column_expiration' | translate}}
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.expirationDate ? (element.expirationDate | date:'dd.MM.yyyy') : '-'}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      #matrow
      mat-row
      *matRowDef="let row; columns: displayedColumns;"
      (click)="openUsersModal(row)"
      [ngClass]="{expiration: row.expirationSoon, highlighted: row.shouldHighlight}"
    ></tr>

  </table>
</div>

<ng-template #noData>
  <p class="license-table__no-data">
    {{'license_module.blocks_table_empty' | translate}}
  </p>
</ng-template>
