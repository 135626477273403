import { HttpErrorResponse } from "@angular/common/http";
import { Component,  Input, OnInit, ViewChild } from "@angular/core";
import { TOAST_TYPES } from "app/application/constants";
import { ICourseInfo } from "app/application/interfaces/courses.interface";
import { ToastService } from "app/application/toast/toast.service";
import { HttpCourseService } from "app/infrastructure/http/course/httpcourse.service";
import { AvailableCoursesInfoFormComponent } from "../../forms/available-courses-info-form/available-courses-info-form.component";

@Component({
  selector: 'app-available-courses-info-tab',
  templateUrl: './available-courses-info-tab.component.html',
  styleUrls: ['./available-courses-info-tab.component.scss']
})
export class AvailableCoursesInfoTabComponent implements OnInit {
  @Input() courseId = ''

  @ViewChild ('form') courseInfoForm: AvailableCoursesInfoFormComponent

  isCourseInfoLoading = false
  courseInfo: ICourseInfo

  isFormLoading = false

  constructor(
    private courseService: HttpCourseService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.isCourseInfoLoading = true
    this.courseService.getCourseInfo(this.courseId).subscribe(
      (courseInfo) => {
        this.courseInfo = courseInfo
        this.isCourseInfoLoading = false
      },
      (error: HttpErrorResponse) => {
        this.toastService.show(error.message, TOAST_TYPES.ERROR)
        this.isCourseInfoLoading = false
      }
    )
  }

  formSubmitHandler(payload: ICourseInfo) {
    this.isFormLoading = true
    this.courseService.saveCourseInfo(this.courseId, payload).subscribe(
      () => {
        this.courseInfoForm.formDisable()
        this.toastService.show('Course info updated successfully')
        this.isFormLoading = false
      },
      (error: HttpErrorResponse) => {
        this.isFormLoading = false
        this.toastService.show(error.message, TOAST_TYPES.ERROR)
      }
    )
  }
}
