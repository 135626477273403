import {Injectable} from '@angular/core';
import {User} from '../../domain/user';
import { DataService } from '../dataservice';


@Injectable()
export class LoggedInUserService {
   

    public constructor(private dataService: DataService) {
    }

    public getLoggedInUser(): User {
      
        return this.dataService.AuthenticatedUser;
    }
}
