import {FormGroup} from '@angular/forms';

export function validatePasswordMatch(g: FormGroup) {
	let result = null;
	if (g.get('password').value !== g.get('passwordConfirm').value) {
		result = {
			validatePasswordMatch: {
				valid: false
			}
		};
	}
	return result;
}
