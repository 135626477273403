<div class="learning-path-item">
  <mat-expansion-panel #expandPanel (expandedChange)="switchPanelState()" class="learning-path-item__panel"
    [hideToggle]="true">
    <mat-expansion-panel-header>
      <div class="learning-path-item__actions">
        <app-button *ngIf="learningPath.learningModules.length !== 0" shape="icon">
          <mat-icon class="learning-path-item__expand-icon" [ngClass]="{ expanded: isExpanded }">
            expand_more
          </mat-icon>
        </app-button>

        <mat-checkbox class="learning-path-item__checkbox" color="primary" [(ngModel)]="item.checked"
          (click)="onCheckHandler($event)"></mat-checkbox>
      </div>

      <h4 class="learning-path-item__name" (click)="openEditDialog($event)">{{learningPath.workingName}}</h4>

      <div class="learning-path-item__actions learning-path-item__actions--end">
        <mat-slide-toggle class="learning-path-item__slide" color="primary" [checked]="learningPath.isEnabled"
          (click)="changeLearningPathState($event)"></mat-slide-toggle>

        <app-button class="more-button" shape="icon" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"
          (menuOpened)="onMenuOpen()">
          <mat-icon>more_horiz</mat-icon>
        </app-button>

        <mat-menu class="learning-path-item__menu" #menu="matMenu" xPosition="before" yPosition="above">
          <app-button shape="icon" (onButtonClick)="openCreateLearningModuleDialog()" title="Create module">
            <mat-icon svgIcon="learning-path-add"></mat-icon>
          </app-button>

          <app-button shape="icon" (onButtonClick)="openEditDialog()" title="Edit">
            <mat-icon svgIcon="learning-path-edit"></mat-icon>
          </app-button>

          <app-button  shape="icon" (onButtonClick)="onScormGenerate()" title="'Generate SCORM'" *ngIf="canGenerateScorm()">
            <mat-icon svgIcon="learning-path-generate"></mat-icon>
          </app-button>

          <app-button  shape="icon" (onButtonClick)="onScormDownload()" title="'Download SCORM'" *ngIf="scormReady">
            <mat-icon svgIcon="learning-path-download"></mat-icon>
          </app-button>

          <app-button shape="icon" title="Copy" (onButtonClick)="copyLearningPath()">
            <mat-icon svgIcon="learning-path-copy"></mat-icon>
          </app-button>

          <app-button shape="icon" (onButtonClick)="openDeleteDialog()" title="Delete">
            <mat-icon svgIcon="learning-path-delete"></mat-icon>
          </app-button>
        </mat-menu>
      </div>
    </mat-expansion-panel-header>


    <div class="loading-status" *ngIf="isListLoading; else content">
      <mat-spinner [color]="'primary'" [diameter]="25"></mat-spinner>
    </div>

    <ng-template #content>
      <mat-progress-bar *ngIf="shouldShowProgressBar && isExpanded" mode="indeterminate"></mat-progress-bar>
      <app-learning-module-list [learningPath]="{ id: learningPath.id, index: itemIndex }"
        [learningModulesList]="learningPath.learningModules"></app-learning-module-list>
    </ng-template>
  </mat-expansion-panel>
</div>
<mat-progress-bar *ngIf="shouldShowProgressBar && !isExpanded" mode="indeterminate"></mat-progress-bar>