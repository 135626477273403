<ng-container *ngIf="tableData.length; else noData">
  <div class="license-table__controls">

    <div class="license-table__stats">
      <p class="license-table__text">
        {{'license_module.users_modal_licenses_in-use' | translate}}: {{licensesUsed}}
      </p>
      <p class="license-table__text">
        {{'license_module.users_modal_licenses_left' | translate}}: {{licensesLeft}}
      </p>
    </div>

    <mat-form-field>
      <mat-label>
        {{'license_module.users_modal_search' | translate}}
      </mat-label>
      <mat-icon class="search-icon" matPrefix>search</mat-icon>
      <input matInput (keyup)="applyFilter($event)" #input>
    </mat-form-field>
  </div>

  <div class="license-table__container">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8"
    >

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'license_module.users_table_column_name' | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.name || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="job">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'license_module.users_table_column_job' | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.job || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'license_module.users_table_column_email' | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.email || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="expirationDate" *ngIf="shouldDisplayExpiration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'license_module.users_table_column_expiration' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.expirationDate | date:'dd.MM.yyyy'}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>
</ng-container>

<ng-template #noData>
  <p class="license-table__no-data">
    {{'license_module.users_table_empty' | translate}}
  </p>
</ng-template>
