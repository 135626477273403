import { Language } from "./language";
import StatusEnum from "./statusEnum";
import { IUserLicense } from "app/application/interfaces/licenses.interface";
import RoleEnum from "./roleEnum";

interface IUserConstructor {
  id: string,
  name: string,
  email: string,
  job: string,
  birthdate: Date,
  department: string,
  status: StatusEnum,
  role: RoleEnum,
  language: string,
  organizationID: string,
  virtualEmail: boolean,
  licenseId?: string,
  blockId?: string
}

export class User {
  private _id: string;
  private _name: string;
  private _email: string;
  private _job: string;
  private _birthdate: Date;
  private _department: string;
  private _status: StatusEnum;
  private _role: RoleEnum;
  private _language: string;
  private _organizationID: string;
  private _virtualEmail: boolean;
  private _licenseId?: string;
  private _blockId?: string;

  constructor({
    id = '',
    name = '',
    email = '',
    job = '',
    birthdate = null,
    department = '',
    status = StatusEnum.invited,
    role = RoleEnum.player,
    language = null,
    organizationID = null,
    virtualEmail = false,
    licenseId = '00000000-0000-0000-0000-000000000000',
    blockId = '00000000-0000-0000-0000-000000000000'
  }: IUserConstructor) {
    this._id = id;
    this._name = name;
    this._email = email;
    this._job = job;
    this._birthdate = birthdate;
    this._department = department;
    this._status = status;
    this._role = role;
    this._language = language;
    this._organizationID = organizationID;
    this._virtualEmail = virtualEmail;
    this._licenseId = licenseId;
    this._blockId = blockId;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get email(): string {
    return this._email;
  }

  public set email(value: string) {
    this._email = value;
  }

  public get job(): string {
    return this._job;
  }

  public set job(value: string) {
    this._job = value;
  }

  public get birthdate(): Date {
    return this._birthdate;
  }

  public set birthdate(value: Date) {
    this._birthdate = value;
  }

  public get department(): string {
    return this._department;
  }

  public set department(value: string) {
    this._department = value;
  }

  public get status(): StatusEnum {
    return this._status;
  }

  public set status(value: StatusEnum) {
    this._status = value;
  }
  public get role_name(): string{
    switch(this._role){
      default:
      case RoleEnum.player: return "Player";
      case RoleEnum.admin: return "Admin";
      case RoleEnum.creator: return "Creator";
      case RoleEnum.serviceAccount: return "ServiceAccount";
      case RoleEnum.superuser: return "SuperUser";
    }
  }


  public get role(): number {
    return this._role;
  }

  public set role(value: number) {
    this._role = value;
  }

  public get language(): string {
    return this._language;
  }

  public set language(value: string) {
    this._language = value;
  }

  public get organizationID(): string {
    return this._organizationID;
  }

  public set organizationID(value: string) {
    this._organizationID = value;
  }

  public get virtualEmail(): boolean {
    return this._virtualEmail;
  }

  public set virtualEmail(value: boolean) {
    this._virtualEmail = value;
  }

  public get licenseId(): string {
    return this._licenseId
  }

  public set licenseId(value: string) {
    this._licenseId = value
  }

  public get blockId(): string {
    return this._blockId
  }

  public set blockId(value: string) {
    this._blockId = value
  }

  public update(
    id: string,
    name: string,
    email: string,
    job: string,
    birthdate: Date,
    department: string,
    status: StatusEnum,
    role: number,
    language: string,
    organizationID: string,
    virtualEmail: boolean,
    licenseId?: string,
    blockId?: string
  ): void {
    this._id = id;
    this._name = name;
    this._email = email;
    this._job = job;
    this._birthdate = birthdate;
    this._department = department;
    this._status = status;
    this._role = role;
    this._language = language;
    this._organizationID = organizationID;
    this._virtualEmail = virtualEmail; 
    this._licenseId = licenseId;
    this._blockId = blockId;
  }
}
