import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import RoleEnum from 'app/domain/roleEnum';
import { Observable } from 'rxjs';
import { DataService } from '../../infrastructure/dataservice';
import { INotifications } from '../interfaces/notifications.interface';

@Injectable({ providedIn: 'root' })
export class LicencesNotificationsHTTPService {

  headers = new HttpHeaders({ apiVersion: 'version2' });

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) {}

  getNotifications(organizationId?): Observable<INotifications[]> {
    const { role } = this.dataService.AuthenticatedUser
    const isSuperAdmin = role === RoleEnum.superuser
    const id = organizationId || this.dataService.organizationID
    const adminUrl = `/v2/Organizations/${id}/Notifications`
    const superAdminUrl = '/v2/Notifications'
    const url = isSuperAdmin ? superAdminUrl : adminUrl

    return this.http.get<INotifications[]>(url, { headers: this.headers })
  }

  markAsRead(notificationId: string) {
    const { role } = this.dataService.AuthenticatedUser
    const isSuperAdmin = role === RoleEnum.superuser
    const adminUrl = `/v2/Organizations/${this.dataService.organizationID}/Notifications/${notificationId}/mark-as-read`
    const superAdminUrl = `/v2/Notifications/${notificationId}/mark-as-read`
    const url = isSuperAdmin ? superAdminUrl : adminUrl

    return this.http.post(url, null, { headers: this.headers })
  }

  markAllRead() {
    const { role } = this.dataService.AuthenticatedUser
    const isSuperAdmin = role === RoleEnum.superuser
    const adminUrl = `/v2/Organizations/${this.dataService.organizationID}/Notifications/mark-as-read`
    const superAdminUrl = `/v2/Notifications/mark-as-read`
    const url = isSuperAdmin ? superAdminUrl : adminUrl

    return this.http.post(url, null, { headers: this.headers })
  }

  deleteNotification(notificationId: string) {
    const { role } = this.dataService.AuthenticatedUser
    const isSuperAdmin = role === RoleEnum.superuser
    const adminUrl = `/v2/Organizations/${this.dataService.organizationID}/Notifications/${notificationId}`
    const superAdminUrl = `/v2/Notifications/${notificationId}`
    const url = isSuperAdmin ? superAdminUrl : adminUrl

    return this.http.delete(url, { headers: this.headers })
  }
}
