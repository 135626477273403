import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LicencesNotificationsService } from 'app/application/licences-notifications/licences-notifications.service';
import { AuthenticationService } from 'app/infrastructure/http/authentication/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications-button',
  templateUrl: './notifications-button.component.html',
  styleUrls: ['./notifications-button.component.scss'],
  animations: [trigger('notifications', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('200ms ease-out')
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(100, style({ opacity: 0 }))
    ])
  ])]
})
export class NotificationsButtonComponent implements OnInit, OnDestroy {

  isNotificationsOpen = false
  badgeCounter = 0
  unreadNumber$: Subscription

  constructor(
    private notificationsService: LicencesNotificationsService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit() {
    const isUserLoggedIn = this.authenticationService.isLoggedIn()

    this.unreadNumber$ = this.notificationsService.unreadNumber$.subscribe((value) => {
      this.badgeCounter = value
    })

    if (isUserLoggedIn) {
      this.notificationsService.getNotifications()
    }
  }

  closeNotifications() {
    this.isNotificationsOpen = false
  }

  ngOnDestroy() {
    this.unreadNumber$.unsubscribe()
  }
}
