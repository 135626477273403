<div class="notifications-popover__pointer"></div>
<div class="notifications-popover__container">
    <div class="notifications-popover__header" *ngIf="generatedScorms.length">
        <p class="notifications-popover__unread-count">
            {{ generatedScorms.length }} New Scorm{{ generatedScorms.length === 1 ? '' : 's' }}
        </p>
    </div>
    <mat-grid-list *ngIf="generatedScorms.length; else emptyMessage" cols="1" rowHeight="8:1">
        <mat-grid-tile *ngFor="let scorm of generatedScorms">
            <div class="notification-text">{{scorm.workingName}}</div>
            <div class="notification-buttons">
                <button type="button" mat-icon-button (click)="downloadScorm(scorm.downloadUri)" cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin">
                    <mat-icon class="notifications-icon">download</mat-icon>
                </button>
                <button type="button" class="notification-item__close-button" mat-icon-button
                    (click)="deleteNotificationScorm(scorm.pathId)">
                    <mat-icon class="icon-clear">clear</mat-icon>
                </button>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
    <ng-template #emptyMessage>
        <p class="empty-message">You have no SCORM for download</p>
    </ng-template>
</div>