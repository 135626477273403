import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class LayoutNavService{

  public isNavExpanded: boolean = true;

  toggleSetExpanded(): boolean{
    return this.isNavExpanded = !this.isNavExpanded;
  }

  getIsNavExpanded():boolean{
    return this.isNavExpanded;
  }

  private expandEvent = new Subject<any>();

  sendExpandEvent() {
    this.expandEvent.next();
  }
  getExpandEvent(): Observable<any>{ 
    return this.expandEvent.asObservable();
  }
}
