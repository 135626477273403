import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Scorm } from 'app/domain/scorm';
import { HttpScormService } from 'app/infrastructure/http/scorm/httpscorm.service';
import { EventBusService } from 'app/core/event-bus.service';
import { Subscription } from 'rxjs';
import { ACTIONS } from 'app/features/learning-path/learning-path.actions';

@Component({
  selector: 'app-notifications-button-scorm',
  templateUrl: './notifications-button-scorm.component.html',
  styleUrls: ['./notifications-button-scorm.component.scss'],
  animations: [trigger('notifications', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('200ms ease-out')
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(100, style({ opacity: 0 }))
    ])
  ])]
})
export class NotificationsButtonScormComponent implements OnInit {
  isNotificationsOpen = false;
  badgeCounter = 0;
  generatedScorms: Scorm[] = [];
  eventBusScormGeneratedSubscription: Subscription;
  eventBusScormRemovedSubscription: Subscription;

  constructor(
    private scormService: HttpScormService,
    private eventService: EventBusService
  ) { }

  ngOnInit(): void {
    this.eventBusScormGeneratedSubscription = this.eventService.subscribeEvent(ACTIONS.LEARNING_MODULE_SCORM_CREATED, (scormFile : Scorm) => {
      this.generatedScorms.push(scormFile);
      this.badgeCounter = this.generatedScorms.length;
    });

    this.eventBusScormRemovedSubscription = this.eventService.subscribeEvent(ACTIONS.LEARNING_MODULE_SCORM_REMOVED, (scormFile : Scorm) => {
      this.badgeCounter--;
    });
  }

  ngOnDestroy(): void {
    this.eventService.removeSubscriptions([this.eventBusScormGeneratedSubscription, this.eventBusScormRemovedSubscription]);
  }
  
  closeNotifications() {
    this.isNotificationsOpen = false
  }

  onDeleteNotification(id: string) {
    const pathId = this.generatedScorms.findIndex(scorm => scorm.pathId === id);
    if (pathId !== -1) {
      this.generatedScorms.splice(pathId, 1);
      this.badgeCounter = this.generatedScorms.length;
    }
  }

}


