<section fxFlex fxLayout="column">
  <header class="primary-color">
    <button class="close-button" mat-icon-button type="button" (keypress)="keyPressed($event)" (click)="cancel()">
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>
  </header>
  <mat-dialog-content fxFlex fxLayout="column" class="send-reminder-dialog-container">
    <h5 *ngIf="isReceiverTypeOfGroup()">
      {{
        "reminder.receiver_type_group" | translate: { groupName: receiverGroupName }
      }}
    </h5>
    <h5 *ngIf="isReceiverTypeOfAll()">
      {{ "reminder.receiver_type_all" | translate }}
    </h5>
    <h5 *ngIf="isReceiverTypeOfUser()">
      {{ "reminder.receiver_type_user" | translate }}
    </h5>
    <p>
      {{ "reminder.number_of_users_to_remind" | translate }}
      <span>
        {{ numberOfUsersApplicableForReminder }}
      </span>
    </p>

    <p>{{ "reminder.content-title" | translate }}</p>

    <p class="reminder-content" [innerHtml]="'reminder.content' | translate"></p>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutGap="10px">
    <button fxFlex mat-button (click)="cancel()" class="mat-secondary">
      {{ "CANCEL" | translate }}
    </button>
    <button fxFlex mat-flat-button color="primary" (click)="confirm()" [disabled]="!canSendReminder()">
      {{ "OK" | translate }}
    </button>
  </mat-dialog-actions>
</section>
