<div class="learning-module-item">
  <h4 class="learning-module-item__name" (click)="openEditDialog()">
    {{item?.workingName}}
  </h4>

  <div class="learning-module-item__actions">
    <app-button class="more-button" shape="icon" [matMenuTriggerFor]="menu">
      <mat-icon>more_horiz</mat-icon>
    </app-button>

    <mat-menu class="learning-module-item__menu" #menu="matMenu" xPosition="before" yPosition="above">
      <app-button shape="icon" title="Edit" (onButtonClick)="openEditDialog()">
        <mat-icon svgIcon="learning-path-edit"></mat-icon>
      </app-button>

      <app-button shape="icon" title="Copy" (onButtonClick)="copyLearningModule()">
        <mat-icon svgIcon="learning-path-copy"></mat-icon>
      </app-button>

      <app-button shape="icon" title="Delete" (onButtonClick)="openDeleteDialog()">
        <mat-icon svgIcon="learning-path-delete"></mat-icon>
      </app-button>
    </mat-menu>
  </div>
</div>
