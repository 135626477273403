

export class AzureReportConfig {
    private _id: string;
    private _embedToken: string;
    private _embedUrl: string;
    private _pages: any[];

    public constructor(id: string, embedToken: string, embedUrl: string, pages: any[]) {
        this._id = id;
        this._embedToken = embedToken;
        this._embedUrl = embedUrl;
        this._pages = pages;
    }

    public get id(): string {
        return this._id;
    }

    public get embedToken(): string {
        return this._embedToken;
    }

    public get embedUrl(): string {
        return this._embedUrl;
    }

    public get pages(): any[] {
        return this._pages;
    }
}
