import { Injectable } from "@angular/core";

import { Assets } from "./assets";

import { DataService } from "app/infrastructure/dataservice";
import { CollectionCache } from "app/domain/collectioncache";
import { Asset } from "app/domain/asset";

import { Observable, ReplaySubject } from "rxjs";

import { HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable()
export class HttpAssetService implements Assets, CollectionCache {
    private headers = new HttpHeaders({ apiVersion: "version2" });
    
    private assetRequests = new Map<string, Observable<Asset[]>>();
    private assetSubjects = new Map<string, Observable<Asset[]>>();
    
    public constructor(private httpClient: HttpClient, private dataService: DataService) {
    }
      
    clear(): void {
        this.assetRequests.clear();        
        this.assetSubjects.clear();
    }

    public getAsset(id:string, refresh: boolean = false): Observable<Asset> {
        if (refresh || !this.assetRequests[id]) {
            const organizationID = this.dataService.organizationID;

            this.assetSubjects[id] = new ReplaySubject(1);
            
            this.assetRequests[id] = this.httpClient.get<Asset>(
                    `/v2/assets/${id}/content?BuildVersion=3.22.0&PlatformID=default`,
                    { headers: this.headers }
            );

            this.assetRequests[id].subscribe(
                (result) => this.assetSubjects[id].next(result),
                (err) => this.assetSubjects[id].error(err) 
            );

            return this.assetSubjects[id];
        }

        return this.assetSubjects[id].asObservable(); 
    }
}