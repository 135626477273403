import { Language } from "../../domain/language";
import { Organization } from "../../domain/organization";
import { PlayEnvironment } from "../../domain/playEnvironment";

export function ConvertToOrganization(organization: any): Organization {
  const responsibleUserId = organization.responsibleUserID || ''
  const language: Language = organization.languageID;
  const playEnvironmentId: PlayEnvironment = organization.environmentID;
  return new Organization({
    id: organization.id,
    name: organization.name,
    isUnlicensed: organization.isUnlicensed || false,
    internalEmergencyNumber: organization.internalEmergencyNumber,
    externalEmergencyNumber: organization.externalEmergencyNumber,
    logo: organization.logo,
    responsibleUserId,
    language,
    playEnvironmentId
  });
}
