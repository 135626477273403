import { ILearningModuleListItem, ILearningPathItem } from "app/application/interfaces/learning-paths.interface"

/**
 * Utility function to generate new working name for learning path or learning module.
 * The function checks names for a match in existed names list and increments copy index if needed or just
 * adds 'Copy' prefix without index
 *
 * examples of work:
 *  1) input name - "Learning Path"
 *     output name - "Learning Path Copy"
 *
 *  2) input name - "Learning Path Copy"
 *     output name - "Learning Path Copy 1"
 *
 *  3) input name - "Learning Path Copy 1"
 *     output name - "Learning Path Copy 2"
 *
 *  etc.
 *
 */
export function generateNewWorkingName(name: string, namesList: string[]): string {
  let copyPartIndex
  let copyPartIndexString
  let nameWithIndex

  const regExp = /(copy \d+)$|(copy)$/gmi
  const matchResult = name.match(regExp)
  const copyPart = matchResult && matchResult[0]
  const wordPart = copyPart && name.split(copyPart)[0].trim() || name

  if (copyPart) {
    const copyPartLength = copyPart.split(' ').length

    if (copyPartLength === 2) {
      copyPartIndexString = copyPart.split(' ')[1]
      copyPartIndex = parseInt(copyPartIndexString)

      do {
        copyPartIndex++
        nameWithIndex = `${wordPart} Copy ${copyPartIndex}`
      } while (namesList.includes(nameWithIndex.toLowerCase()));

      return nameWithIndex
    } else {
      copyPartIndex = 1
      nameWithIndex = `${name} ${copyPartIndex}`

      while (namesList.includes(nameWithIndex.toLowerCase())) {
        copyPartIndex++
        nameWithIndex = `${name} ${copyPartIndex}`
      }

      return nameWithIndex
    }
  }

  const nameWithCopy = `${wordPart} Copy`

  if (!namesList.includes(nameWithCopy.toLowerCase())) {
    return nameWithCopy
  }

  return generateNewWorkingName(nameWithCopy, namesList)
}

export function sortByName({item: itemA}: ILearningPathItem, {item: itemB}: ILearningPathItem) {
  const nameA = itemA.workingName.toLowerCase();
  const nameB = itemB.workingName.toLowerCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
}

export function sortByOrderIndex({orderIndex: indexA}: ILearningModuleListItem, {orderIndex: indexB}: ILearningModuleListItem) {
  return indexA - indexB
}
