<section fxFlex fxLayout="column">
	<header class="primary-color">
		<button class="close-button" mat-icon-button type="button" mat-dialog-close>
			<mat-icon class="icon-clear">clear</mat-icon>
		</button>
		<h3>{{'SEND MESSAGE' | translate}}</h3>
	</header>
	<mat-dialog-content fxFlex fxLayout="column" class="send-message-container">

		<form [formGroup]="messageForm">
			<div class="input-row" (keydown)="keyDownFunction($event)">
				<mat-form-field class="full-width">
					<mat-error>{{'SUBJECT IS REQUIRED' | translate}}</mat-error>
					<input matInput placeholder="{{'SUBJECT' | translate}}" formControlName="subject">
				</mat-form-field>
			</div>
			<div class="input-row">
				<mat-form-field class="full-width">
					<mat-error>{{'CONTENT IS REQUIRED' | translate}}</mat-error>
					<textarea rows="5" matInput placeholder="{{'CONTENT' | translate}}" formControlName="content"></textarea>
				</mat-form-field>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions fxLayoutGap="10px">
		<button fxFlex mat-flat-button color="primary" (click)="sendMessage(messageForm.value)"
			[disabled]="!canSendMessage()" (keydown)="keyDownFunction($event)">{{'SEND MESSAGE' | translate}}
		</button>
	</mat-dialog-actions>
</section>
