import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ILearningPath, ILearningPathData } from "../../../application/interfaces/learning-paths.interface";
import { DataService } from "../../../infrastructure/dataservice";
import { Observable } from "rxjs";
import { Learningpaths } from "./learningpaths";


@Injectable({
  providedIn: "root",
})
export class LearningPathService implements Learningpaths {
  headers = new HttpHeaders({ apiVersion: "version2" });


  constructor(private http: HttpClient, private dataService: DataService) { }

  // LEARNING PATHS REQUESTS
  getLearningPathList(): Observable<ILearningPath[]> {
    const { organizationID } = this.dataService;
    return this.http.get<ILearningPath[]>(
      `/v2/Organizations/${organizationID}/LearningPaths?pageSize=100`,
      { headers: this.headers }
    );
  }

  createLearningPath(data: ILearningPathData): Observable<ILearningPath> {
    const { organizationID } = this.dataService;
    return this.http.post<ILearningPath>(
      `/v2/Organizations/${organizationID}/LearningPaths`,
      data,
      { headers: this.headers }
    );
  }

  copyLearningPath(learningPathId: string, newWorkingName: string): Observable<ILearningPath> {
    const { organizationID } = this.dataService;
    return this.http.post<ILearningPath>(
      `/v2/Organizations/${organizationID}/LearningPaths/${learningPathId}/Copy?workingName=${newWorkingName}&copyModules=true`,
      {},
      { headers: this.headers }
    );
  }

  deleteLearningPath(learningPathId: string) {
    const { organizationID } = this.dataService;
    return this.http.delete(
      `/v2/Organizations/${organizationID}/LearningPaths/${learningPathId}`,
      { headers: this.headers }
    );
  }

  getLearningPathDetails(learningPathId: string): Observable<ILearningPath> {
    const { organizationID } = this.dataService;
    return this.http.get<ILearningPath>(
      `/v2/Organizations/${organizationID}/LearningPaths/${learningPathId}`,
      { headers: this.headers }
    );
  }

  getUserLearningPaths(userId: string): Observable<ILearningPath[]> {
    const { organizationID } = this.dataService;
    let lang = this.dataService.language;
    return this.http.get<ILearningPath[]>(
      `/v2/Organizations/${organizationID}/Users/${userId}/LearningPaths?lang=${lang}`,
      { headers: this.headers }
    );
  }

  updateLearningPath(learningPathId: string, data: ILearningPathData): Observable<ILearningPath> {
    const { organizationID } = this.dataService;
    const dataWithIds = {
      ...data,
      id: learningPathId,
      organizationID,
    };
    return this.http.put<ILearningPath>(
      `/v2/Organizations/${organizationID}/LearningPaths/${learningPathId}`,
      dataWithIds,
      { headers: this.headers }
    );
  }
}
