import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ICourseItem } from "app/application/interfaces/courses.interface";
import { ILearningModuleCreated, ILearningModuleCreationData, ILearningModuleListItem, ILearningModuleTranslation } from "./../../../../application/interfaces/learning-paths.interface";
import { noEmptySpaceValidator } from "app/application/validators/empty-space.validator";
import { LearningModuleTranslationsComponent } from "app/features/learning-path/forms/learning-module-translations/learning-module-translations.component";
import { HttpCourseService } from "app/infrastructure/http/course/httpcourse.service";
import { ICourseTranslation } from '../../../../application/interfaces/courses.interface';
import { dateFormatValidator } from "../../learning-path.validators";
import * as _ from "lodash";
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-learning-module-form',
  templateUrl: './learning-module-form.component.html',
  styleUrls: ['./learning-module-form.component.scss']
})
export class LearningModuleFormComponent implements OnInit, AfterViewInit {
  isCoursesLoading = false
  coursesList = []

  @Input() isFormLoading = false
  @Input() learningModuleData: ILearningModuleCreated
  @Input() editMode = false
  @Input() lockedByModuleList: ILearningModuleListItem[] = []
  @Input() mode = 0
  @Output() onSubmit: EventEmitter<any> = new EventEmitter()
  @Output() onDelete: EventEmitter<boolean> = new EventEmitter()
  @Output() currentStepChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() isWorkingNameFilledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() courseIdChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() estimatedTimeInSecondsChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() invalidStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();


  @ViewChild('languageSelect') languageSelect: NgSelectComponent
  @ViewChild('environmentSelect') environmentSelect: NgSelectComponent
  @ViewChild('translationsFormArray') translationsFormComponent: LearningModuleTranslationsComponent
  @ViewChild('stepper') stepper: MatStepper;

  isCoursesInfoExpanded = false
  isFormDisabled = false
  startDate = new Date()
  endDate = new Date()
  learningModuleForm: FormGroup
  translationsForm: FormArray
  estimatedTimeControl: FormControl;
  currentStep = 0
  isWorkingNameFilled = false;
  estimatedTimeInSecondsFilled = 0;

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  get names(): FormArray {
    return this.learningModuleForm.get('names') as FormArray
  }

  constructor(
    private _formBuilder: FormBuilder,
    private courseService: HttpCourseService,
  ) {
    const courseIdControl = new FormControl(null, Validators.required)
    const workingNameControl = new FormControl('', [Validators.required, noEmptySpaceValidator])
    const estimatedTimeControl = new FormControl(null, Validators.min(1))
    const startControl = new FormControl(null, dateFormatValidator)
    const endControl = new FormControl(null, dateFormatValidator)
    const mandatoryControl = new FormControl(null)
    const lockedByControl = new FormControl(null)

    this.learningModuleForm = new FormGroup({
      courseId: courseIdControl,
      workingName: workingNameControl,
      estimatedTimeInSeconds: estimatedTimeControl,
      start: startControl,
      end: endControl,
      mandatory: mandatoryControl,
      lockedBy: lockedByControl
    })
    this.estimatedTimeControl = estimatedTimeControl;
  }

  ngOnInit(): void {
    this.getCoursesList();
    this.learningModuleForm.get('workingName').valueChanges.subscribe(() => {
      this.isWorkingNameFilled = this.learningModuleForm.get('workingName').value.trim() !== '';
      this.isWorkingNameFilledChange.emit(this.isWorkingNameFilled);
    });
    this.learningModuleForm.get('estimatedTimeInSeconds').valueChanges.subscribe(() => {
      this.estimatedTimeInSecondsFilled = this.learningModuleForm.get('estimatedTimeInSeconds').value;
      this.estimatedTimeInSecondsChange.emit(this.estimatedTimeInSecondsFilled)
    });
  }

  onTranslationsFormChange() {
    this.invalidStatusChanged.emit(this.translationsForm.invalid);
  }

  onStepChange(stepper: MatStepper) {
    this.currentStep = stepper.selectedIndex;
    this.currentStepChange.emit(this.currentStep);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setTranslationsForm()

      if (this.editMode) {
        this.initForm()
      }
    }, 0);
  }

  setTranslationsForm() {
    this.translationsForm = this.translationsFormComponent.translationsFormArray
    this.learningModuleForm.addControl('translations', this.translationsForm);
  }

  getCoursesList() {
    this.isCoursesLoading = true
    this.courseService.getOrganizationCourses().subscribe((courses) => {
      this.coursesList = _.sortBy(courses, (course) => course.name);
      this.isCoursesLoading = false;
    })
  }

  getCourseInfo(courseId: string) {
    this.courseService.getCourseInfo(courseId).subscribe((courseInfo) => {
      const translations: ICourseTranslation[] = courseInfo.translations;

      this.translationsFormComponent.setTranslations(translations.map(t => {
        const data: ILearningModuleTranslation =
        {
          languageId: t.languageId,
          name: t.name,
          description: t.description
        }
        return data;
      }));
      this.estimatedTimeControl.setValue(courseInfo.estimatedTimeInSeconds / 60);
    })
  }

  onCourseSelectHandler(course: ICourseItem) {
    if (course) {
      this.getCourseInfo(course.id);
      this.courseIdChange.emit(course.id);
    }
  }

  initForm() {
    const {
      courseId: courseIdControl,
      workingName: workingNameControl,
      estimatedTimeInSeconds: estimatedTimeControl,
      start: startControl,
      end: endControl,
      mandatory: mandatoryControl,
      lockedBy: lockedByControl
    } = this.learningModuleForm.controls

    const {
      courseId,
      workingName,
      estimatedTimeInSeconds,
      translations,
      end,
      start,
      mandatory,
      lockedBy
    } = this.learningModuleData

    courseIdControl.setValue(courseId)
    workingNameControl.setValue(workingName)
    estimatedTimeControl.setValue(estimatedTimeInSeconds / 60)
    startControl.setValue(start)
    endControl.setValue(end)
    lockedByControl.setValue(lockedBy)
    mandatoryControl.setValue(mandatory)
    this.translationsFormComponent.setTranslations(translations);


    this.formDisable()
  }

  formDisable() {
    this.isFormDisabled = true
    this.learningModuleForm.disable()
  }

  formEnable() {
    this.isFormDisabled = false
    this.learningModuleForm.enable()
  }

  deleteLearningModule() {
    this.onDelete.emit(true)
  }

  submitLearningModuleForm() {
    this.learningModuleForm.markAllAsTouched()
    this.translationsForm.markAllAsTouched()

    if (this.learningModuleForm.invalid || this.translationsForm.invalid) {
      return
    }

    const { workingName, translations, estimatedTimeInSeconds, mandatory, ...formValue } = this.learningModuleForm.value
    const trimmedTranslations = this.trimTranslationFields(translations)

    const data: ILearningModuleCreationData = {
      ...formValue,
      estimatedTimeInSeconds: estimatedTimeInSeconds * 60,
      translations: trimmedTranslations,
      workingName: workingName.trim(),
      mandatory: mandatory ?? false,
      importance: 1,
    }
    this.onSubmit.emit(data)
  }

  trimTranslationFields(translations: ILearningModuleTranslation[]): ILearningModuleTranslation[] {
    return translations.map(({ name, description, languageId }) => {
      return {
        languageId,
        name: name.trim(),
        description: description.trim()
      }
    })
  }
}