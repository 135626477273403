import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ILicenseBlock, IUsers } from "app/application/interfaces/licenses.interface";
import { DataService } from "app/infrastructure/dataservice";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root'})
export class LicenseManagementHttpService {

  headers = new HttpHeaders({
    apiVersion: 'version2'
  });

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) {}

  getLicenses(id?: string): Observable<ILicenseBlock[]> {
    const organizationId = id || this.dataService.organizationID

    return this.http.get<ILicenseBlock[]>(`/v2/Organizations/${organizationId}/Licenses/Blocks`, { headers: this.headers })
  }

  getLicense(blockId: string): Observable<ILicenseBlock> {
    return this.http.get<ILicenseBlock>(`/v2/Organizations/${this.dataService.organizationID}/Licenses/Blocks/${blockId}`, { headers: this.headers })
  }

  getUsers(blockId: string): Observable<IUsers[]> {
    return this.http.get<IUsers[]>(`/v2/Organizations/${this.dataService.organizationID}/Licenses/Blocks/${blockId}/UserLicenses`, { headers: this.headers })
  }
}
