import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastService } from "app/application/toast/toast.service";
import { Observable, Subject, Subscription, timer } from "rxjs";
import { delay, filter, map as rxjsMap, retry, share, switchMap, takeUntil, tap } from "rxjs/operators";
import { GameReport } from "./gamereport";

export interface IDownloadParams {
  startDate: string
  endDate: string
  groups: string[]
  categories: string[]
}

export interface IReportFile {
  resultId?: string
  resultName?: string
  resultFile?: string
  completed : boolean
}

export interface IReportFileId {
  result_id: string
}

@Injectable()
export class HttpGameReportService implements GameReport {
  httpHeaders = new HttpHeaders().set("apiVersion", "version2")
  isReportGenerating = false

  report: IReportFile = { completed: false }
  reportSubject = new Subject<IReportFile>()
  statusSubscription$: Subscription
  stopChecking = new Subject()

  constructor(
    private httpClient: HttpClient,
    private toastService: ToastService
  ) {}

  getGeneratedReports(organizationID: string) {
    return this.httpClient.get<IReportFile>(`/Organizations/${organizationID}/results/download`, { headers: this.httpHeaders })
  }

  checkGenerationStatus(organizationID: string) {
    this.statusSubscription$ = timer(1, 5000).pipe(
      switchMap(() => {
        return this.getGeneratedReports(organizationID)
      }),
      filter((reportFile) => reportFile.completed),
      retry(),
      share(),
      takeUntil(this.stopChecking),
      tap((reportFile) => {
        if (reportFile.completed) {
          this.stopCheckingGenerationStatus()
          this.report = {...reportFile}
          this.reportSubject.next(this.report)
          this.toastService.show('Report is successfully generated')
        }
      }),
    ).subscribe()

    return this.reportSubject.asObservable()
  }

  stopCheckingGenerationStatus() {
    this.isReportGenerating = false
    this.stopChecking.next()
  }

  startGenerateReport(organizationID: string, params: IDownloadParams) {
    this.isReportGenerating = true
    return this.httpClient.post(`/Organizations/${organizationID}/results/generate`, params, { headers: this.httpHeaders }).pipe(delay(3000))
  }

  public getCategories(
    organizationId: string,
    language: string
  ): Observable<any> {
    return this.httpClient.get(
      `/Organizations/${organizationId}/groups/results/?language=${language}`,
      { headers: this.httpHeaders }
    );
  }

  public getGroupDetailsForCategoryAndGroup(
    organizationId: string,
    categoryId: string,
    groupId: string
  ): Observable<any> {
    let httpHeaders = new HttpHeaders().set("apiVersion", "version2");
    return this.httpClient.get(
      `/Organizations/${organizationId}/groups/${groupId}/results?categoryid=${categoryId}`,
      { headers: httpHeaders }
    );
  }

  public getDownloadContentCategories(
    organizationId: string,
    language: string
  ): Observable<Blob> {
    const httpHeaders = new HttpHeaders().set("apiVersion", "version2");

    return this.httpClient
      .get(
        `/Organizations/${organizationId}/groups/results/download/?language=${language}`,
        { headers: httpHeaders, responseType: "blob" }
      )
      .pipe(
        rxjsMap((res) => {
          return new Blob([res], { type: "application/vnd.ms-excel" });
        })
      );
  }

  public getDownloadExternalResults(organizationId: string): Observable<Blob> {
    const httpHeaders = new HttpHeaders().set("apiVersion", "version2");

    return this.httpClient
      .get(
        `/v2/Organizations/${organizationId}/external/results/download`,
        { headers: httpHeaders, responseType: "blob" }
      )
      .pipe(
        rxjsMap((res) => {
          return new Blob([res], { type: "application/vnd.ms-excel" });
        })
      );
  }
}
