import { TokenType } from 'ngx-powerbi';
import { TType } from './../results/export-results-dialog/export-results-dialog.component';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import * as _ from 'lodash';
import {Observable, Subscription} from 'rxjs';
import {AzureReportConfig} from '../../../domain/azure-report-config';
import {Feature} from '../../../domain/feature';
import {HttpReportsService} from '../../../infrastructure/http/report/httpreports.service';
import {DataService} from '../../../infrastructure/dataservice';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {DashboardGuideDialogComponent} from '../dashboard/dashboard-guide-dialog/dashboard-guide-dialog.component';
import {OnboardingService} from '../../../infrastructure/http/onboarding/onboarding.service';
import { max } from 'moment';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { IDashboardEmbedConfiguration, models } from 'powerbi-client';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

    public isShowGuideMark = false;
    private tokenTimeout = 1800*1000;
    private dashboardEnabled = false;
    private azureReportConfig: AzureReportConfig;
    private currentReportPageToShow;
    private guides = [];
    private subscriptionOnboarding: Subscription;
    private reloading = false;
    private tokenExpired = false;
    private reportPages: any[];
    private dialogConfigGuide: MatDialogConfig = <MatDialogConfig>{
        height: '560px', width: '600px'
    };

    private itemsReportGuide = [
        {
            title: 'statistic_guide_module.completed_courses_title',
            description: 'statistic_guide_module.completed_courses_hint',
            src: '/assets/img/guides/screen1-reports.png'
        },
        {
            title: 'statistic_guide_module.user_stats_title',
            description: 'statistic_guide_module.user_stats_hint',
            src: '/assets/img/guides/screen2-reports.png'
        },
        {
            title: 'statistic_guide_module.group_stats_title',
            description: 'statistic_guide_module.group_stats_hint',
            src: '/assets/img/guides/screen3-reports.png'
        },
        {
            title: 'statistic_guide_module.platform_usage_title',
            description: 'statistic_guide_module.platform_usage_hint',
            src: '/assets/img/guides/screen4-reports.png'
        },
        {
            title: 'statistic_guide_module.common_mistakes_title',
            description: 'statistic_guide_module.common_mistakes_hint',
            src: '/assets/img/guides/screen5-reports.png'
        },
        {
            title: 'statistic_guide_module.user_live_stats_title',
            description: 'statistic_guide_module.user_live_stats_hint',
            src: '/assets/img/guides/screen6-reports.png'
        },
        {
            title: 'statistic_guide_module.top_statistic_title',
            description: 'statistic_guide_module.top_statistic_hint',
            src: '/assets/img/guides/screen7-reports.png'
        },
        {
            title: 'statistic_guide_module.year_stats_title',
            description: 'statistic_guide_module.year_stats_hint',
            src: '/assets/img/guides/screen8-reports.png'
        },
        {
            title: 'statistic_guide_module.filter_groups_title',
            description: 'statistic_guide_module.filter_groups_hint',
            src: '/assets/img/guides/screen9-reports.png'
        },
        {
            title: 'statistic_guide_module.mode_title',
            description: 'statistic_guide_module.mode_hint',
            src: '/assets/img/guides/screen10-reports.png'
        },
        {
            title: 'statistic_guide_module.filter_user_title',
            description: 'statistic_guide_module.filter_user_hint',
            src: '/assets/img/guides/screen11-reports.png'
        },
        {
            title: 'statistic_guide_module.filter_category_title',
            description: 'statistic_guide_module.filter_category_hint',
            src: '/assets/img/guides/screen12-reports.png'
        },
        {
            title: 'statistic_guide_module.filter_course_title',
            description: 'statistic_guide_module.filter_course_hint',
            src: '/assets/img/guides/screen13-reports.png'
        },
        {
            title: 'statistic_guide_module.more_options_title',
            description: 'statistic_guide_module.more_options_hint',
            src: '/assets/img/guides/screen14-reports.png'
        },
        {
            title: 'statistic_guide_module.times_played_title',
            description: 'statistic_guide_module.times_played_hint',
            src: '/assets/img/guides/screen15-reports.png'
        },
        {
            title: 'statistic_guide_module.stars_title',
            description: 'statistic_guide_module.stars_hint',
            src: '/assets/img/guides/screen16-reports.png'
        },
        {
            title: 'statistic_guide_module.scores_title',
            description: 'statistic_guide_module.scores_hint',
            src: '/assets/img/guides/screen17-reports.png'
        },
    ];

    public constructor(
        private reportsService: HttpReportsService,
        private dataService: DataService,
        private dialog: MatDialog,
        private onboarding: OnboardingService,
    ) {
    }

    public ngOnInit(): void {
      /*   this.checkOnboarding(); */
        this.dashboardEnabled = _.some(this.dataService.features, x => x == 'reporting_powerbi_report');
        if (this.dashboardEnabled) {
           this.reload();
        }


    }

    private reload(): void{
      this.reloading = true;

      this.reportsService.getReportsConfig(this.dataService.organizationID)
                .subscribe((azureReportConfig: AzureReportConfig) => {
                    this.azureReportConfig = azureReportConfig;

                    if(!this.reportPages){
                      this.reportPages = azureReportConfig.pages;
                    }
                    if(!this.currentReportPageToShow){
                      this.currentReportPageToShow = this.reportPages[0];
                    }

                    this.reloading = false;
                });
      this.tokenExpired = false;
      setTimeout(() => {
        this.tokenExpired = true;
      }, this.tokenTimeout);
    }

    private checkOnboarding(): void {
        this.subscriptionOnboarding = this.onboarding.getOnboarding().subscribe(res => {

            for (const key in res) {
                if (res[key].name === 'Statistics' && !res[key].isCompleted) {
                    this.isShowGuideMark = true;
                    this.onboarding.openOnboardingDialog('statistic_guide', [], this.guides, true)
                        .subscribe(() => {
                            this.startOnboardingNavigation();
                        });
                    this.guides.push(res[key].id);
                } else if (res[key].name === 'Statistics' && res[key].isCompleted) {
                    this.isShowGuideMark = true;
                }
            }
        });
    }

    public startOnboardingNavigation(): void {
        const dialogRef: MatDialogRef<DashboardGuideDialogComponent> =
            this.dialog.open(DashboardGuideDialogComponent, this.dialogConfigGuide);
        dialogRef.componentInstance.isShowCloseButton = true;
        dialogRef.componentInstance.items = this.itemsReportGuide;
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(() => {
            if (this.guides.length) {
                this.onboarding.openFinishOnboardingDialog('statistic_guide', this.guides);
                this.guides = [];
            }
        });
    }

    public selectedTabChanged(matTabChangeEvent: MatTabChangeEvent) {
        this.currentReportPageToShow = this.getReportPages()[matTabChangeEvent.index];
        if(this.tokenExpired){
          this.reload();
        }
    }

    public getReportPages(): any[] {
        return this.reportPages;
    }

    public getCurrentReportPageToShow(): any {
        return this.currentReportPageToShow;
    }

    public isLoading(): Observable<boolean> {
        return this.reportsService.isLoadingFeatures();
    }

    public canShowReports(): boolean {
        return this.dashboardEnabled;
    }

    public canShowTabs(): boolean{
      return this.canShowReports() && !_.isNil(this.azureReportConfig);
    }
    public canShowReportsDetail(): boolean {
        return this.canShowReports() && !this.reloading && !_.isNil(this.azureReportConfig);
    }

    public getAzureReportConfig(): AzureReportConfig {
        return this.azureReportConfig;
    }

    public ngOnDestroy() {
        this.subscriptionOnboarding?.unsubscribe();
    }
}
