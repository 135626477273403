<section fxFlex fxLayout="column">
	<header class="primary-color">
		<button class="close-button" mat-icon-button type="button" (keypress)="keyPressed($event)" (click)="cancel()">
			<mat-icon class="icon-clear">clear</mat-icon>
		</button>
	</header>
	<mat-dialog-content fxFlex fxLayout="column" class="confirmation-dialog-container">
		<h5>{{textContent | translate}}</h5>
		<p class="summary-content" *ngIf="canShowSummaryContent()">{{summaryContent}}</p>
	</mat-dialog-content>
	<mat-dialog-actions fxLayoutGap="10px">
		<button fxFlex mat-button (click)="cancel()" class="mat-secondary">{{cancelText | translate}}</button>
		<button fxFlex mat-flat-button color="primary" (click)="confirm()">{{confirmText | translate}}
		</button>
	</mat-dialog-actions>
</section>
