<div class="lp-form__group-container" [formGroup]="languageNameGroup">
  <div class="select-wrapper">
    <ng-select class="lp-form__language" formControlName="languageId" [items]="languages" [loading]="isLanguagesLoading"
      [searchable]="false" [ngClass]="{
        'custom-error': isUniqueError
      }" appendTo="body" bindLabel="name" bindValue="id"
      placeholder="{{'learningpaths_module.choose_language' | translate }}">
    </ng-select>

    <div *ngIf="languageId.hasError('required') && languageId.touched" class="error-message">
      Language is <strong>required</strong>
    </div>
    <div *ngIf="isUniqueError" class="error-message">
      Language should be <strong>unique</strong>
    </div>
  </div>

  <mat-form-field class="lp-form__name">
    <mat-label>{{'learningpaths_module.lp_name' | translate }}</mat-label>
    <input matInput formControlName="value" />
    <mat-error> Name is <strong>required</strong> </mat-error>
  </mat-form-field>

  <app-button *ngIf="!shouldDisableButton" class="delete-button" shape="icon" (onButtonClick)="onDeleteHandler()"
    [disabled]="languageNameGroup.disabled">
    <mat-icon class="delete-button__icon" svgIcon="learning-path-delete"></mat-icon>
  </app-button>
</div>
