<div fxFlex fxLayout="column">
  <header mat-dialog-title class="primary-color">
    <button class="close-button" mat-icon-button (click)="cancel()">
      <mat-icon class="icon-clear">clear</mat-icon>
    </button>

    <h3>{{'ADD A NEW USER' | translate}}</h3>
  </header>
  <mat-dialog-content fxFlex fxLayout="column" fxLayoutAlign="center center" class="add-user-container">
    <form [formGroup]="addUserForm" (keydown)="keyDownFunction($event)">
      <div class="input-row">
        <mat-form-field class="full-width">
          <mat-error>{{'NAME IS REQUIRED' | translate}}</mat-error>
          <input autocomplete="off" id="srch-username" matInput type="text" class="add-user-name" placeholder="{{'NAME' | translate}}" formControlName="name"
            [(ngModel)]="user.name">
        </mat-form-field>
      </div>
      <div class="input-row">
        <mat-form-field class="full-width">
          <mat-error
            *ngIf="addUserForm.controls.email.hasError('email') || addUserForm.controls.email.hasError('required')">
            {{'PROVIDE A VALID EMAIL' | translate}}</mat-error>
          <mat-error *ngIf="addUserForm.controls.email.hasError('userEmailDuplicate')">
            {{'common.email_duplicate' | translate}}</mat-error>
          <input matInput type="email" id="srch-email" class="add-user-email" placeholder="{{'EMAIL' | translate}}"
            formControlName="email" email [(ngModel)]="user.email" appValidatorUserEmail>
        </mat-form-field>
      </div>
      <div class="input-row">
        <div class="full-width">
          <mat-checkbox formControlName="virtualEmail" (change)="checkUpdateForm($event)"
            [(ngModel)]="user.virtualEmail">{{'VIRTUAL_EMAIL' | translate}}</mat-checkbox>
        </div>
      </div>

      <div formGroupName="passwordGroup" *ngIf="canShowPassword()">
        <div class="input-row password">
          <mat-form-field class="full-width">
            <mat-error>{{'PASSWORD GUIDE' | translate}}</mat-error>
            <input matInput type="password" id="srch-pw1" placeholder="{{'PASSWORD' | translate}}"
              pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})" formControlName="password">
          </mat-form-field>

        </div>
        <div class="input-row password">
          <mat-form-field class="full-width" [ngClass]="{'mat-input-invalid': canShowErrorForConfirmedPassword()}">
            <mat-hint *ngIf="canShowErrorForConfirmedPassword()">{{'REPEAT PASSWORD ERROR' | translate}}</mat-hint>
            <input matInput type="password" id="srch-pw2"  placeholder="{{'REPEAT PASSWORD' | translate}}"
              formControlName="passwordConfirm">
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutGap="10px">
    <button fxFlex mat-button mat-dialog-close class="mat-secondary"
      (click)="cancel()">{{'CANCEL' | translate}}</button>
    <button fxFlex mat-flat-button color="primary" (click)="registerUser(user)" [disabled]="!addUserForm.valid">
      {{'ADD USER' | translate}}
    </button>
  </mat-dialog-actions>
</div>
