<div *ngIf="courseAvailable()" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
	<div class="course-not-played" matTooltip="{{'NUMBER OF USERS NOT STARTED' | translate:{numberOfUsersNotStarted: getNumberOfUsersNotStarted(), numberOfUsersInGroup: getNumberOfUsersInGroup()} }}">
		<span *ngIf="!showAbsolutes">{{getPercentNumberOfUsersNotStarted()}}%</span>
		<span *ngIf="showAbsolutes">{{getNumberOfUsersNotStarted()}}</span>
		<mat-progress-bar
			[value]="getPercentNumberOfUsersNotStarted()">
		</mat-progress-bar>
	</div>
	<div class="course-in-progress" matTooltip="{{'NUMBER OF USERS IN PROGRESS' | translate:{numberOfUsersInProgress: getNumberOfUsersInProgress(), numberOfUsersInGroup: getNumberOfUsersInGroup()} }}">
		<span *ngIf="!showAbsolutes">{{getPercentNumberOfUsersInProgress()}}%</span>
		<span *ngIf="showAbsolutes">{{getNumberOfUsersInProgress()}}</span>
		<mat-progress-bar
			[value]="getPercentNumberOfUsersInProgress()">
		</mat-progress-bar>
	</div>
	<div class="course-completed" matTooltip="{{'NUMBER OF USERS COMPLETED' | translate:{numberOfUsersCompleted: getNumberOfUsersCompleted(), numberOfUsersInGroup: getNumberOfUsersInGroup()} }}">
		<span *ngIf="!showAbsolutes">{{getPercentNumberOfUsersCompleted()}}% </span>
		<span *ngIf="showAbsolutes">{{getNumberOfUsersCompleted()}}</span>
		<mat-progress-bar
			value="{{getPercentNumberOfUsersCompleted()}}">
		</mat-progress-bar>
	</div>
	<div class="course-expired" matTooltip="{{'NUMBER OF USERS EXPIRED' | translate:{numberOfUsersExpired: getNumberOfUsersExpired(), numberOfUsersInGroup: getNumberOfUsersInGroup()} }}">
		<span *ngIf="!showAbsolutes">{{getPercentNumberOfUsersExpired()}}%</span>
		<span *ngIf="showAbsolutes">{{getNumberOfUsersExpired()}}</span>
		<mat-progress-bar
			[value]="getPercentNumberOfUsersExpired()">
		</mat-progress-bar>
	</div>
</div>
