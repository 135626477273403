import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-group-detail-result-legenda',
  templateUrl: './group-detail-result-legenda.component.html',
  styleUrls: ['./group-detail-result-legenda.component.scss']
})
export class GroupDetailResultLegendaComponent {
    @Input() title: string;
}
