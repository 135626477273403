import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import {
  IEditModuleData,
  IEditPathData,
  ILearningPath,
  ILearningPathItem,
} from "app/application/interfaces/learning-paths.interface";
import { EventBusService } from "app/core/event-bus.service";
import { Scorm } from "app/domain/scorm";
import { DataService } from "app/infrastructure/dataservice";
import {
  HttpScormService,
  ScormStatus,
} from "app/infrastructure/http/scorm/httpscorm.service";
import { interval, of } from "rxjs";
import { catchError, last, mergeMap, take, takeWhile } from "rxjs/operators";
import { ACTIONS } from "../../learning-path.actions";
import { LearningPathService } from "../../services/learning-path.service";
import { LearningModuleCreateDialogComponent } from "../learning-module-create-dialog/learning-module-create-dialog.component";
import { LearningPathCreateDialogComponent } from "../learning-path-create-dialog/learning-path-create-dialog.component";
import { LearningPathDeleteDialogComponent } from "../learning-path-delete-dialog/learning-path-delete-dialog.component";

@Component({
  selector: "app-learning-path-item",
  templateUrl: "./learning-path-item.component.html",
  styleUrls: ["./learning-path-item.component.scss"],
})
export class LearningPathItemComponent implements OnInit, AfterViewInit {
  @Input() scormReady = false;
  @Output() scormReadyChange = new EventEmitter<boolean>();

  isListLoading = false;
  isExpanded = false;
  shouldShowProgressBar = false;
  showSnackbar = false;
  hasScorm = false;
  hasScormFeatures = false;

  intervalId: any;

  @ViewChild("expandPanel") panel: MatExpansionPanel;

  @Input() item: ILearningPathItem;
  @Input() itemIndex: number;

  learningPath: ILearningPath;

  constructor(
    private eventService: EventBusService,
    private learningPathsService: LearningPathService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private scormService: HttpScormService,
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    const { item, isLoading, isExpanded } = this.item;
    this.isListLoading = isLoading;
    this.isExpanded = isExpanded;
    this.learningPath = item;
    this.hasScormFeatures =
      this.dataService.features.includes("organization_key_authentication") &&
      this.dataService.features.includes("auto_course_assignment") &&
      this.dataService.features.includes("direct_user_assignments");

    if(this.hasScormFeatures) {
      this.scormService.scormStatus().subscribe(
        (result) => this.hasScorm = this.hasScormFeatures && result,
        (err) => this.hasScorm = false
      );
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.isExpanded) {
        this.panel.open();
        this.expandPanel();
      }
    }, 0);
  }

  expandPanel() {
    this.item.isExpanded = true;
    this.isExpanded = true;
  }

  closePanel() {
    this.item.isExpanded = false;
    this.isExpanded = false;
  }

  switchPanelState() {
    if (this.isExpanded) {
      this.closePanel();
    } else {
      this.expandPanel();
    }
  }

  openDeleteDialog() {
    const dialogRef = this.dialog.open(LearningPathDeleteDialogComponent);
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.deleteLearningPath();
      }
    });
  }

  openEditDialog(event?: MouseEvent) {
    event?.stopPropagation();

    const data: IEditPathData = {
      learningPathId: this.learningPath.id,
      learningPathIndex: this.itemIndex,
      editMode: true,
    };

    this.dialog.open(LearningPathCreateDialogComponent, { data });
  }

  openSuccessSnackBar(result: Scorm) {
    this.showSnackbar = true;
    this.translate
      .get("learningpaths_item.snack_bar_success_message")
      .subscribe((successMessage: string) => {
        this.translate
          .get("learningpaths_item.download")
          .subscribe((downloadText: string) => {
            const snackBarRef: MatSnackBarRef<any> = this.snackBar.open(
              successMessage,
              downloadText,
              {
                duration: 5000,
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: "success-snackbar",
              },
            );
            snackBarRef.onAction().subscribe(() => {
              this.startDownload(result);
            });
          });
      });
  }

  openErrorSnackBar(message?: string) {
    this.showSnackbar = true;
    this.translate
      .get("learningpaths_item.snack_bar_error_message")
      .subscribe((errorMessage: string) => {
        this.snackBar.open(errorMessage, "OK", {
          duration: 5000,
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: "error-snackbar",
        });
      });
  }

  deleteLearningPath() {
    this.eventService.sendEvent(
      ACTIONS.LEARNING_PATH_DELETE,
      this.learningPath.id,
    );
  }

  changeLearningPathState(event: MouseEvent) {
    event.stopPropagation();

    const data: ILearningPath = {
      ...this.learningPath,
      isEnabled: !this.learningPath.isEnabled,
    };

    this.learningPathsService
      .updateLearningPath(data.id, data)
      .subscribe((updatedLearningPath) => {
        const updatedPathWithModules: ILearningPath = {
          ...updatedLearningPath,
          learningModules: this.learningPath.learningModules,
        };
        this.eventService.sendEvent(ACTIONS.LEARNING_PATH_UPDATE, {
          learningPath: updatedPathWithModules,
          index: this.itemIndex,
        });
      });
  }

  openCreateLearningModuleDialog() {
    const data: IEditModuleData = {
      learningPathId: this.learningPath.id,
      learningPathIndex: this.itemIndex,
      lockedByModuleList: this.learningPath.learningModules,
      editMode: false,
    };

    this.dialog.open(LearningModuleCreateDialogComponent, { data });
  }

  copyLearningPath() {
    this.eventService.sendEvent(ACTIONS.LEARNING_PATH_COPY, this.learningPath);
  }

  onCheckHandler(event: MouseEvent) {
    event.stopPropagation();

    if (this.item.checked) {
      this.eventService.sendEvent(
        ACTIONS.LEARNING_PATH_UNCHECK,
        this.learningPath.id,
      );
    } else {
      this.eventService.sendEvent(
        ACTIONS.LEARNING_PATH_CHECK,
        this.learningPath.id,
      );
    }
  }

  startDownload(result: Scorm) {
    window.open(result.downloadUri, "_self");
  }

  generateScorm() {
    if(!this.hasScorm) return;

    this.scormService.generateScormFile(this.learningPath.id);
    this.shouldShowProgressBar = true;

    this.scormService.markScormFileOutdated(this.learningPath.id).pipe(catchError(err => of(new Scorm(null, ScormStatus.None, null, '')))).subscribe(result => {
      interval(5000)
        .pipe(
          mergeMap(() =>
            this.scormService.scormFileStatus(this.learningPath.id, true),
          ),
          takeWhile(
            (result) =>
              (result.status == ScormStatus.InProgress ||
              result.status == ScormStatus.None ||
              result.status == ScormStatus.OutOfDate),
            true,
          ),
          last(),
        )
        .subscribe((result) => {
          this.updateScormStatus(result.status);

          if (result.status == ScormStatus.Completed) {
            this.openSuccessSnackBar(result);
            result.workingName = this.learningPath.workingName;
            this.eventService.sendEvent(
              ACTIONS.LEARNING_MODULE_SCORM_CREATED,
              result,
            );
          } else {
            this.openErrorSnackBar();
          }

          this.shouldShowProgressBar = false;
        });
      });
  }

  onScormGenerate() {
    if(!this.hasScorm) return;

    this.generateScorm();
  }

  onScormDownload() {
    if(!this.hasScorm) return;

    this.scormService
      .scormFileStatus(this.learningPath.id)
      .subscribe((result) => {
        switch (result.status) {
          case ScormStatus.Completed:
            this.startDownload(result);
            break;
          default:
            this.generateScorm();
        }
      });
  }

  canGenerateScorm() : boolean {
    return this.hasScorm && this.learningPath.learningModules.length > 0
  }

  onMenuOpen() {
    if(this.canGenerateScorm) {
      this.scormService
        .scormFileStatus(this.learningPath.id)
        .subscribe((result) => this.updateScormStatus(result.status));
    }
  }

  updateScormStatus(status: ScormStatus) {
    switch (status) {
      case ScormStatus.Completed:
        this.scormReady= true
        break;
      default:
        this.scormReady = false;
    }

    this.scormReadyChange.emit(this.scormReady);
  }
}
