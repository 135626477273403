import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { IEditModuleData, ILearningModuleCopyData, ILearningModuleInfo, ILearningModuleListItem, ILearningPathInfo } from "app/application/interfaces/learning-paths.interface";
import { EventBusService } from "app/core/event-bus.service";
import { ACTIONS } from "../../learning-path.actions";
import { LearningModuleCreateDialogComponent } from "../learning-module-create-dialog/learning-module-create-dialog.component";
import { LearningModuleDeleteDialogComponent } from "../learning-module-delete-dialog/learning-module-delete-dialog.component";

@Component({
  selector: 'app-learning-module-item',
  templateUrl: './learning-module-item.component.html',
  styleUrls: ['./learning-module-item.component.scss'],
})
export class LearningModuleItemComponent implements OnInit {

  @Input() item: ILearningModuleListItem
  @Input() learningPath: ILearningPathInfo
  @Input() lockedByModuleList: ILearningModuleListItem[]
  @Input() itemIndex: number

  constructor(
    private dialog: MatDialog,
    private eventService: EventBusService
  ) {}

  ngOnInit() {
    this.lockedByModuleList = this.lockedByModuleList.filter(({ id })=> {
      return id !== this.item.id
    })
  }

  openEditDialog() {
    const data: IEditModuleData = {
      learningPathId: this.learningPath.id,
      learningModuleId: this.item.id,
      learningModuleIndex: this.itemIndex,
      learningPathIndex: this.learningPath.index,
      lockedByModuleList: this.lockedByModuleList,
      editMode: true
    }

    this.dialog.open(LearningModuleCreateDialogComponent, { data })
  }

  openDeleteDialog() {
    const dialogRef = this.dialog.open(LearningModuleDeleteDialogComponent)
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.deleteLearningModule()
      }
    })
  }

  copyLearningModule() {
    const learningModule: ILearningModuleInfo = {
      ...this.item,
      index: this.itemIndex
    }

    const data: ILearningModuleCopyData = {
      learningPath: this.learningPath,
      learningModule
    }

    this.eventService.sendEvent(ACTIONS.LEARNING_MODULE_COPY, data)
  }

  deleteLearningModule() {
    const learningModule: ILearningModuleInfo = {
      ...this.item,
      index: this.itemIndex
    }

    const data: ILearningModuleCopyData = {
      learningPath: this.learningPath,
      learningModule
    }

    this.eventService.sendEvent(ACTIONS.LEARNING_MODULE_DELETE, data)
  }
}
