import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MaterialModule } from "app/material.module";

import { ButtonComponent } from "./app-button.component";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
})
export class ButtonModule {}
