import {Injectable} from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Notification} from './notification';

@Injectable()
export class NotificationService implements Notification {
	private snackBar: MatSnackBar;
	private translate: TranslateService;

	constructor(snackBar: MatSnackBar, translate: TranslateService) {
		this.snackBar = snackBar;
		this.translate = translate;
	}

	public show(message: string): void {
		this.translate.get(message, {}).subscribe((res: string) => {
			this.snackBar.open(res, null, <MatSnackBarConfig>{
				duration: 2000
			});
		});

	}
}
