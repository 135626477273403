<!-- main container within app navigation -->
<mat-sidenav-container [ngClass]="{ 'is-expanded': this.LayoutNavService.isNavExpanded }" class="nav-container">

  <!-- single sidenav -->
  <mat-sidenav [ngClass]="{ 'is-expanded': this.LayoutNavService.isNavExpanded }" [disableClose]="true" #sidenav class="nav-sidenav" mode="side" opened="true">
    
    <!-- header -->
    <div class="nav-header">

      <!-- header logo -->
      <header div fxLayout="row" fxLayoutAlign="space-around center">
        <img *ngIf="this.LayoutNavService.isNavExpanded" src="./assets/img/logo_kleur.png" alt="logo" fxFlex="70" />
        <img *ngIf="!this.LayoutNavService.isNavExpanded" src="./assets/img/logo_minimal.png" alt="logo verticaal" fxFlex="50" />
      </header>
    
      <!-- help tooltip -->
      <div *ngIf="this.LayoutNavService.isNavExpanded" class="navigation-guide tooltip-guide-nav"
      matTooltip="{{ 'on_boarding_module.tooltip_info' | translate }}" (click)="startOnboardingNavigation()">
        <mat-icon>help_outline</mat-icon>
          <div class="text-block">
            {{ "on_boarding_module.repeat_guide" | translate }}
          </div>
      </div>

      <!-- seperation line -->
      <div class="nav-line nav-line-top"></div>

    </div> 
    <!-- end header -->

    <!-- middle / Navigation buttons -->
    <mat-list>
      <!-- Loop and show list of buttons for the pages -->
      <mat-list-item *ngFor="let page of pages; let i = index" routerLink="{{ page.path }}"
        [class.active]="isActive(page.path)">
        <mat-icon class="nav__icon" mat-list-icon joyrideStep="{{ i + 1 }}Step" stepPosition="right" title="{{
          'navigation_guide_module.' + page.name.toLowerCase() + '_title'
            | translate
        }}" text="{{
          'navigation_guide_module.' + page.name.toLowerCase() + '_hint'
            | translate
        }}" svgIcon="{{ page.icon }}"></mat-icon>
        <span matLine *ngIf="this.LayoutNavService.getIsNavExpanded()"  class="menu-label">{{ page.name | translate }}</span>
      </mat-list-item>

      <mat-list-item [class.active]="isActive('support')" onclick="window.open('https://playit.freshdesk.com/en/support/home', '_blank');">
        <mat-icon class="nav__icon" mat-list-icon stepPosition="right" title="{{
          'navigation_guide_module.support_title'
            | translate
        }}" text="{{
          'navigation_guide_module.support_hint'
            | translate
        }}" svgIcon="support"></mat-icon>
        <span matLine *ngIf="this.LayoutNavService.getIsNavExpanded()"  class="menu-label">{{ 'SUPPORT' | translate }}</span>
      </mat-list-item>
    </mat-list>
    <!-- end middle / Navigation buttons -->

    <!-- bottom -->
    <div class="nav-bottom">
      <!-- seperation line -->
      <div class="nav-line nav-line-bottom"></div>

      <!-- User Information -->
      <button joyrideStep="accountStep" stepPosition="right"
        title="{{ 'navigation_guide_module.account_title' | translate }}"
        text="{{ 'navigation_guide_module.account_hint' | translate }}" #appTrigger="matMenuTrigger"
        [ngClass]="{ 'is-expanded': this.LayoutNavService.isNavExpanded }" class="logged-in-user" mat-button [matMenuTriggerFor]="appMenu" *ngIf="loggedInUser">
          <mat-icon svgIcon="avatar"></mat-icon>
          <span matLine *ngIf="this.LayoutNavService.getIsNavExpanded()" class="logged-in-user-button-text">
            <span class="nav-user">{{ loggedInUser.name }}</span>
            <span class="organizationOfUser">{{ loggedInOrganization?.name }}</span>
        </span>
      </button>

      <!-- User PopupUp Menu Content-->
      <mat-menu  #appMenu [overlapTrigger]="false" xPosition="after" yPosition="above">
          <button (click)="switchOrganization()" *ngIf="canSwitchOrganization()" mat-menu-item> {{ "navigation.switch_organization" | translate }}</button>
          <button (click)="showUserProfile()" mat-menu-item joyrideStep="profileStep" stepPosition="right" title="{{ 'navigation_guide_module.profile_title' | translate }}" 
            text="{{ 'navigation_guide_module.profile_hint' | translate }}"> {{ "PROFILE" | translate }} </button>
          <button (click)="logout()" mat-menu-item joyrideStep="logoutStep" stepPosition="right" title="{{ 'navigation_guide_module.logout_title' | translate }}"
            text="{{ 'navigation_guide_module.logout_hint' | translate }}" (done)="onFinishGuide()"> {{ "LOGOUT" | translate }} </button>
      </mat-menu> 

      <!-- double black arrow used to expand the menu-->
      <div class="nav-expand">
        <button *ngIf="this.isNavExpanded" (click)="toggleMenu()" class="nav-expand-button material-symbols-outlined">
          keyboard_double_arrow_left
        </button>
        <button *ngIf="!this.isNavExpanded" (click)="toggleMenu()" class="nav-expand-button nav-expand-button-small material-symbols-outlined">
          keyboard_double_arrow_right
        </button>
      </div>
    </div>
    <!-- end bottom -->

  </mat-sidenav>
  <!-- end single sidenav -->

</mat-sidenav-container>
<!-- end main container within app navigation -->