import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { INotifications } from '../interfaces/notifications.interface';
import { LicencesNotificationsHTTPService } from './licences-notifications-http.service';

@Injectable({ providedIn: 'root' })
export class LicencesNotificationsService {
  isDataLoading = false

  notifications: INotifications[] = []
  notifications$ = new Subject<INotifications[]>()
  unreadNumber$ = new Subject<number>()

  constructor(
    private notificationsHttpService: LicencesNotificationsHTTPService,
  ) {}

  getNotifications() {
    this.isDataLoading = true

    return this.notificationsHttpService.getNotifications().subscribe((notifications) => {
      this.setNotifications(notifications)
      this.isDataLoading = false
    })
  }

  getNotificationsByOrgId(id) {
    this.isDataLoading = true

    return this.notificationsHttpService.getNotifications(id).subscribe((notifications) => {
      this.setNotifications(notifications)
      this.isDataLoading = false
    })
  }

  setNotifications(notifications: INotifications[]) {
    this.notifications = [...notifications].sort((a, b) => +a.isRead - +b.isRead)
    this.setUnreadNotificationsNumber()

    this.notifications$.next(this.notifications)
  }

  setUnreadNotificationsNumber() {
    const unreadNotifications = this.notifications.filter(notification => !notification.isRead)
    this.unreadNumber$.next(unreadNotifications.length);
  }

  markAsRead(id: string) {
    this.notificationsHttpService.markAsRead(id).subscribe(() => {
      this.notifications.find((n) => n.id === id).isRead = true
      this.setNotifications(this.notifications)
    })
  }

  markAllRead() {
    this.notificationsHttpService.markAllRead().subscribe(() => {
      this.notifications.forEach((n) => n.isRead = true)
      this.setNotifications(this.notifications)
    }, (error) => {
      console.warn(error)
    })
  }

  deleteNotification(id: string) {
    this.notificationsHttpService.deleteNotification(id).subscribe(() => {
      this.notifications = this.notifications.filter(n => n.id !== id)
      this.setUnreadNotificationsNumber()

      // push changes to observer
      this.notifications$.next(this.notifications)
    })
  }
}
