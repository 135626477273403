<button
  #appButton
  [type]="type"
  mat-button
  [color]="color"
  [class]="resultClass.join(' ')"
  [disabled]="disabled"
  (click)="fireButtonClick()"
>
  <div class="button-ripple" matRipple [matRippleColor]="buttonRippleColor"></div>
  <mat-spinner
    class="button-spinner"
    *ngIf="isLoading; else buttonContent"
    [diameter]="spinnerSize"
  ></mat-spinner>

  <ng-template #buttonContent>
    <ng-content></ng-content>
  </ng-template>
</button>
