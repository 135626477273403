interface IIcons {
  iconLabel: string
  fileName: string
}

export const ICONS_PATH = 'assets/icons/'

export const ICONS_LIST: IIcons[] = [
  {
    iconLabel: 'dashboard',
    fileName: 'icon-dashboard.svg'
  },
  {
    iconLabel: 'statistics',
    fileName: 'icon-statistics.svg'
  },
  {
    iconLabel: 'users',
    fileName: 'icon-users.svg'
  },
  {
    iconLabel: 'groups',
    fileName: 'icon-groups.svg'
  },
  {
    iconLabel: 'courses',
    fileName: 'icon-courses.svg'
  },
  {
    iconLabel: 'results',
    fileName: 'icon-results.svg'
  },
  {
    iconLabel: 'support',
    fileName: 'icon-support.svg'
  },
  {
    iconLabel: 'management',
    fileName: 'icon-management.svg'
  },
  {
    iconLabel: 'overview',
    fileName: 'icon-overview.svg'
  },
  {
    iconLabel: 'learning-paths',
    fileName: 'icon-learning-paths.svg'
  },
  {
    iconLabel: 'add-user',
    fileName: 'icon-add-user.svg'
  },
  {
    iconLabel: 'remove-user',
    fileName: 'icon-remove-user.svg'
  },
  {
    iconLabel: 'add-course',
    fileName: 'icon-add-course.svg'
  },
  {
    iconLabel: 'remove-course',
    fileName: 'icon-remove-course.svg'
  },
  {
    iconLabel: 'add-play-environment',
    fileName: 'icon-add-play-environment.svg'
  },
  {
    iconLabel: 'remove-play-environment',
    fileName: 'icon-remove-play-environment.svg'
  },
  {
    iconLabel: 'completed',
    fileName: 'icon-completed.svg'
  },
  {
    iconLabel: 'avatar',
    fileName: 'icon-avatar.svg'
  },
  {
    iconLabel: 'user-progress',
    fileName: 'icon-user-progress.svg'
  },
  {
    iconLabel: 'star',
    fileName: 'icon-star.svg'
  },
  {
    iconLabel: 'course-level',
    fileName: 'icon-course-level.svg'
  },
  {
    iconLabel: 'user-progress-not-started',
    fileName: 'icon-user-progress-not-started.svg'
  },
  {
    iconLabel: 'user-progress-in-progress',
    fileName: 'icon-user-progress-in-progress.svg'
  },
  {
    iconLabel: 'user-progress-completed-silver',
    fileName: 'icon-user-progress-completed-silver.svg'
  },
  {
    iconLabel: 'user-progress-completed-bronze',
    fileName: 'icon-user-progress-completed-bronze.svg'
  },
  {
    iconLabel: 'user-progress-completed-gold',
    fileName: 'icon-user-progress-completed-gold.svg'
  },
  {
    iconLabel: 'user-progress-expired',
    fileName: 'icon-user-progress-expired.svg'
  },
  {
    iconLabel: 'learning-path-add',
    fileName: 'icon-plus.svg'
  },
  {
    iconLabel: 'learning-path-delete',
    fileName: 'icon-trash.svg'
  },
  {
    iconLabel: 'learning-module-delete',
    fileName: 'icon-trash-white.svg'
  },
  {
    iconLabel: 'learning-path-copy',
    fileName: 'icon-copy.svg'
  },
  {
    iconLabel: 'learning-path-edit',
    fileName: 'icon-edit.svg'
  },
  {
    iconLabel: 'learning-path-download',
    fileName: 'icon-download.svg'
  },
  {
    iconLabel: 'learning-path-generate',
    fileName: 'icon-cached.svg'
  },
  {
    iconLabel: 'drag',
    fileName: 'icon-drag.svg'
  }
]
