<section class="carousel-wrapper" [ngStyle]="carouselWrapperStyle">
  <ul class="carousel-inner" #carousel>
    <li *ngFor="let item of items" class="carousel-item">
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </li>
  </ul>
</section>
<ng-container *ngIf="showControls">
  <div class="carousel_wrapper-button">
    <button
      *ngIf="currentSlide !== 0"
      class="carousel__prev-button"
      mat-flat-button
      color="primary"
      (click)="prev()"
    >
      <mat-icon>chevron_left</mat-icon>
      {{ "common.back" | translate }}
    </button>
    <div class="counter-slides">
      {{ this.currentSlide + 1 }} / {{ this.items.length }}
    </div>
    <button
      *ngIf="this.currentSlide + 1 !== this.items.length; else showFinish"
      mat-flat-button
      color="primary"
      class="carousel__next-button"
      (click)="next()"
    >
      {{ "common.next" | translate }}
      <mat-icon>chevron_right</mat-icon>
    </button>
    <ng-template #showFinish>
      <button
        mat-flat-button
        color="primary"
        class="carousel__next-button"
        (click)="finish()"
      >
        {{ "on_boarding_module.done_button" | translate }}
      </button>
    </ng-template>
  </div>
</ng-container>
