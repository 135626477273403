import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-learning-module-delete-dialog',
  templateUrl: './learning-module-delete-dialog.component.html',
  styleUrls: ['./learning-module-delete-dialog.component.scss']
})
export class LearningModuleDeleteDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<LearningModuleDeleteDialogComponent>
  ) {}

  confirmDelete() {
    this.dialogRef.close(true)
  }
}
