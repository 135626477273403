import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastService } from "app/application/toast/toast.service";
import { Organization } from "app/domain/organization";
import { HttpOrganizationService } from "app/infrastructure/http/organization/httporganization.service";
import { LicenceOverviewHttpService } from "../../services/license-overview-http.service"

import { IOrganizationBlock } from "app/application/interfaces/licenses.interface";

const licenseTypes = [
  {
    id: 1,
    name: 'Demo'
  },
  {
    id: 2,
    name: 'Segmented'
  },
  {
    id: 3,
    name: 'Pro'
  },
]

@Component({
  selector: 'app-license-block-create-modal',
  templateUrl: './license-block-create-modal.component.html',
  styleUrls: ['./license-block-create-modal.component.scss']
})
export class LicenseBlockCreateModal implements OnInit {
  isOrganizationsListLoading = false
  isBlockCreating = false

  organizations: Organization[] = []
  licenseTypes: any[] = []

  createBlockForm: FormGroup

  constructor(
    private organizationsService: HttpOrganizationService,
    @Inject(MAT_DIALOG_DATA) public data: IOrganizationBlock,
    private licenceOverviewService: LicenceOverviewHttpService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<LicenseBlockCreateModal>
  ) {
    this.licenseTypes = licenseTypes
  }

  ngOnInit() {
    this.getOrganizations()

    this.createBlockForm = new FormGroup({
      organizationId: new FormControl(null, [
        Validators.required
      ]),
      licenseType: new FormControl(null, [
        Validators.required
      ]),
      licensesCap: new FormControl(null, [
        Validators.required
      ]),
      enableAutoRenew: new FormControl(false),
      canExceedCap: new FormControl(false),
      hasNoExpiration: new FormControl(false),
    })
  }

  getOrganizations() {
    this.isOrganizationsListLoading = true

    this.organizationsService.getAvailableOrganizations().subscribe((organizations) => {
      this.organizations = organizations
      this.isOrganizationsListLoading = false
    })
  }

  createLicenseBlock() {
    this.createBlockForm.controls.organizationId.markAsTouched()

    if (this.createBlockForm.valid) {
      this.isBlockCreating = true

      this.licenceOverviewService.createLicenseBlock(this.createBlockForm.value).subscribe((response) => {
        this.isBlockCreating = false
        this.toastService.show('You successfully created license block!')
        this.dialogRef.close(response)
      })
    }

  }
}
