import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationPlayer,
  style,
} from "@angular/animations";
import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";

import { CarouselItemDirective } from "./carousel-item.directive";
import { CarouselItemElementDirective } from "./carousel-item-element.directive";

@Component({
  selector: "carousel",
  exportAs: "carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements AfterViewInit {
  @ContentChildren(CarouselItemDirective)
  items: QueryList<CarouselItemDirective>;

  @ViewChildren(CarouselItemElementDirective, { read: ElementRef })
  private itemsElements: QueryList<ElementRef>;
  @ViewChild("carousel") private carousel: ElementRef;
  @Input() timing = "250ms ease-in";
  @Input() showControls = true;
  @Output() guideFinished: EventEmitter<string> = new EventEmitter();
  public hidePrevButton = false;
  private player: AnimationPlayer;
  private itemWidth: number;
  currentSlide = 0;
  carouselWrapperStyle = {};

  constructor(private builder: AnimationBuilder) {}

  private buildAnimation(offset, time: any) {
    return this.builder.build([
      animate(
        time == null ? this.timing : 0,
        style({ transform: `translateX(-${offset}px)` })
      ),
    ]);
  }

  next() {
    if (this.currentSlide + 1 === this.items.length) {
      let arr = this.items.toArray();
      const first = arr.shift();
      arr = arr.concat([first]);
      this.items.reset(arr);
      this.currentSlide--;
      this.transitionCarousel(0);
    }
    this.currentSlide = (this.currentSlide + 1) % this.items.length;
    this.transitionCarousel(null);
  }

  prev() {
    if (this.currentSlide === 0) {
      let arr = this.items.toArray();
      const last = arr.pop();
      arr = [last].concat(arr);
      this.items.reset(arr);
      this.currentSlide++;
      this.transitionCarousel(0);
    }

    this.currentSlide =
      (this.currentSlide - 1 + this.items.length) % this.items.length;
    this.transitionCarousel(null);
  }

  ngAfterViewInit() {
    this.reSizeCarousel();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.reSizeCarousel();
  }

  reSizeCarousel(): void {
    this.itemWidth = this.itemsElements.first.nativeElement.getBoundingClientRect().width; //  > '550' ? '550' : '550';
    this.carouselWrapperStyle = {
      width: `${this.itemWidth}px`,
    };

    this.transitionCarousel(null);
  }

  transitionCarousel(time: any) {
    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.buildAnimation(offset, time);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
  }

  finish(): void {
    this.guideFinished.emit();
  }
}
