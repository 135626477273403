<h3 class="title">{{"learningpaths_module.available_courses" | translate}}</h3>

<div class="loading-status" *ngIf="isCategoriesLoading; else content">
  <mat-spinner [color]="'primary'" [diameter]="50"></mat-spinner>
</div>

<ng-template #content>
  <div class="available-courses-panel-container">
    <ng-container *ngFor="let categoryItem of categories; let i = index">
      <mat-expansion-panel
        [expanded]="i === 0"
        (opened)="categoryItem.isExpanded = true"
        (closed)="categoryItem.isExpanded = false"
        class="available-courses-panel"
        [hideToggle]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="category-wrapper">
              <app-button shape="icon">
                <mat-icon
                  class="expand-icon"
                  [ngClass]="{ expanded: categoryItem.isExpanded }"
                >
                  expand_more
                </mat-icon>
              </app-button>
              <h4 class="category-name">{{ categoryItem.category.name }}</h4>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-available-courses-list
          [isExpirationSettingsEnabled]="isExpirationSettingsEnabled"
          [category]="categoryItem.category"
        ></app-available-courses-list>
      </mat-expansion-panel>
    </ng-container>
  </div>
</ng-template>
