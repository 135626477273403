import {Component, Input, OnInit} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as _ from 'lodash';
import {NotificationService} from '../../../../../application/notification/notification.service';
import {Group} from '../../../../../domain/group';
import {PlayEnvironment} from '../../../../../domain/playEnvironment';
import {HttpGroupsService} from '../../../../../infrastructure/http/group/httpgroups.service';
import {HttpOrganizationService} from '../../../../../infrastructure/http/organization/httporganization.service';
import { DataService } from '../../../../../infrastructure/dataservice';
import { concatMap } from 'rxjs/operators';

@Component({
    selector: 'app-group-play-environments',
    templateUrl: './group-play-environments.component.html',
    styleUrls: ['./group-play-environments.component.scss']
})
export class GroupPlayEnvironmentsComponent implements OnInit {
    private organizationService: HttpOrganizationService;
    private groupService: HttpGroupsService;
    private notificationService: NotificationService;
    public playEnvironments: PlayEnvironment[] = [];
    public loading: boolean;
    @Input() public selectedGroup: Group;
    private dialog: MatDialog;
    private dialogConfig: MatDialogConfig = <MatDialogConfig>{
        height: '223px', width: '506px'
    };

    constructor(organizationService: HttpOrganizationService, groupService: HttpGroupsService, dialog: MatDialog, notificationService: NotificationService, private dataservice: DataService) {
        this.organizationService = organizationService;
        this.groupService = groupService;
        this.dialog = dialog;
        this.notificationService = notificationService;
    }

    ngOnInit(): void {
        this.reload();
    }

    public playEnvironmentChanged(matSlideToggleChange: MatSlideToggleChange, playEnvironment: PlayEnvironment): void {
        if (matSlideToggleChange.checked) {
            this.addPlayEnvironmentToSelectedGroup(matSlideToggleChange.source, playEnvironment);
        } else {
            this.removePlayEnvironmentFromSelectedGroup(matSlideToggleChange.source, playEnvironment);
        }
    }


    public canRemovePlayEnvironmentFromSelectedGroup(playEnvironment: PlayEnvironment): boolean {
        let result = false;
        if (this.selectedGroup) {
            result = _.includes(this.selectedGroup.playEnvironmentIDs, playEnvironment.id);
        }
        return result;
    }

    public removePlayEnvironmentFromSelectedGroup(matSlideToggle: MatSlideToggle, playEnvironment: PlayEnvironment): void {

        this.groupService.removePlayEnvironmentFromGroup(this.dataservice.organizationID, this.selectedGroup, playEnvironment).subscribe(() => {
            this.selectedGroup.playEnvironmentIDs.splice(this.selectedGroup.playEnvironmentIDs.indexOf(playEnvironment.id), 1);
        }, () => {
            matSlideToggle.toggle();
        });

    }

    public canAddPlayEnvironmentToSelectedGroup(playEnvironment: PlayEnvironment): boolean {
        let result = false;
        if (this.selectedGroup) {
            result = !_.includes(this.selectedGroup.playEnvironmentIDs, playEnvironment.id);
        }
        return result;
    }

    public addPlayEnvironmentToSelectedGroup(matSlideToggle: MatSlideToggle, playEnvironment: PlayEnvironment): void {

        this.groupService.addPlayEnvironmentsToGroup(this.dataservice.organizationID, this.selectedGroup, [playEnvironment]).subscribe(() => {
            this.selectedGroup.playEnvironmentIDs.push(playEnvironment.id);
        }, () => {
            matSlideToggle.toggle();
        });

    }



    private reload(): void {

        this.loading = true;
        this.groupService.getPlayEnvironmentsFromGroup(this.dataservice.organizationID, this.selectedGroup).subscribe(resp => {
            this.selectedGroup.playEnvironmentIDs = resp;
            this.organizationService.getAvailablePlayEnvironments(this.dataservice.organizationID, this.dataservice.language).subscribe((playEnvironments: PlayEnvironment[]) =>
            {
                this.playEnvironments = _.sortBy(_.sortBy(playEnvironments, (playEnvironment: PlayEnvironment) => {
                    return _.lowerCase(playEnvironment.name);
                }), (playEnvironment: PlayEnvironment) => {
                    return !_.includes(this.selectedGroup.playEnvironmentIDs, playEnvironment.id);

                });
                this.loading = false;
            });

        });
    
       
    }

}
