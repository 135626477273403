export function StringComparator(valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: any): number {
	if ((valueA === null && valueB === null) || (valueA === undefined && valueB === undefined)) {
		return 0;
	}

	if (valueA === null || valueA === undefined) {
		return -1;
	}
	if (valueB === null || valueB === undefined) {
		return 1;
	}

	const upperValueA = valueA.toUpperCase();
	const upperValueB = valueB.toUpperCase();
	return upperValueA === upperValueB ? 0 : (upperValueA > upperValueB) ? 1 : -1;
}
